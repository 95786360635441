/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Appbar from '../../components/Appbar/Appbar';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Button,
    Theme,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
    IconButton,
    Fab,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableBody,
    Typography,
    TablePagination,
    Snackbar,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState, InfoListItem } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import AddIcon from '@mui/icons-material/Add';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { AdministratorsProps } from '../../components/Administrators/AdministratorsTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchAdmins, fetchAdminsLength } from '../../redux/actions/administratorsActions';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import AdminDetailsModal from '../../components/Administrators/AdminDetailsModal';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddAdminModal from '../../components/Administrators/AddAdmin/AddAdminModal';
import EditAdminModal from '../../components/Administrators/EditAdmin/EditAdminModal';
import DeleteAdminModal from '../../components/Administrators/DeleteAdmin/DeleteAdminModal';
import SortListMenu from '../../components/SortListButton/SortListMenu';
import { getSearchInstallerListLength } from '../../redux/actions/installersActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function Admins(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    let { administrators, administratorsLength } = useSelector((state: any) => state.administratorReducer);
    const { adminSearchlistLength, deleteAdminStatus } = useSelector((state: any) => state.administratorReducer);
    const [query, setQuery] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [counter, setCounter] = React.useState(0);
    const [deleteAdminMessage, setDeleteAdminMessage] = React.useState('');
    const [admins, setAdmins] = React.useState(administrators);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [fetchLen, setFetchLen] = React.useState(true);
    const [openAddAdminModal, setOpenAddAdminModal] = React.useState(false);
    const [openEditAdminModal, setOpenEditAdminModal] = React.useState(false);
    const [openDeleteAdminModal, setOpenDeleteAdminModal] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortListType, setSortListType] = React.useState<string>('A-Z');
    const open = Boolean(anchorEl);
    const [adminData, setAdminData] = React.useState<AdministratorsProps>({
        ID: 0,
        creationTimeInMilliSeconds: 0,
        currentLoginTimeInMilliSeconds: 0,
        firstName: '',
        groupId: 0,
        lastLoginTimeInMilliSeconds: 0,
        lastName: '',
        locale: '',
        login: '',
        userType: '',
    });
    const [openAdminModal, setOpenAdminModal] = React.useState(false);

    const fetching = async () => {
        if (query === '') {
            await dispatch(fetchAdmins(page * 10, ''));
            if (fetchLen) {
                setFetchLen(false);
                dispatch(fetchAdminsLength());
            }
        } else {
            if (query.length >= 3) {
                await dispatch(fetchAdmins(0, query));
                await dispatch(getSearchInstallerListLength(query))
            }
        }
    };

    React.useEffect(() => {
        void fetching();
        setAdmins(administrators);
        if (counter !== 0) {
            if (deleteAdminStatus === 204) {
                setDeleteAdminMessage(`${t('msg_adminDeleted')}`);
            } else {
                setDeleteAdminMessage(`${t('msg_errorDeleteAdmin')}`);
            }
        }
    }, [ count, page, counter, query === '']);

        

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleAdminDetailsModal = (adminDetails: AdministratorsProps) => {
        setAdminData(adminDetails);
        setOpenAdminModal(true);
    };

    const handleAddAdminModal = () => {
        setOpenAddAdminModal(true)
    };
    const handleEditAdminModal = (admin: AdministratorsProps) => {
        setOpenEditAdminModal(true)
        setAdminData(admin);
    };
    const handleDeleteAdminModal = (admin: AdministratorsProps) => {
        setOpenDeleteAdminModal(true)
        setAdminData(admin);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const handleSortClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSearchCancelClick = () => {
        setQuery('')
        setPage(0);
        dispatch(fetchAdmins(0, ''));
    }


    try {
        if (query !== '' && query.length >= 3) {
            if (administrators.length > 0) {
                const filterList = administrators?.filter(
                    (list: AdministratorsProps) => (
                        list.firstName?.toLowerCase().includes(query.toLowerCase()) ||
                        list.lastName?.toLowerCase().includes(query.toLowerCase()) ||
                        list.userType?.toLowerCase().includes(query.toLowerCase()) ||
                        list.login?.toLowerCase().includes(query.toLowerCase())
                    )
                );

                administrators = query.length > 0 ? filterList : administrators;
                administratorsLength = administrators?.length
            } else if (adminSearchlistLength === 0) {
                administrators = [];
                administratorsLength = adminSearchlistLength;
            }
        }
    } catch (exception) {
        console.log(`Exception in Administrators filtering: ${JSON.stringify(exception)}`);
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValue = e.target.value;
        setQuery(newValue)
        if (newValue.length >= 3) {
            setPage(0);
            dispatch(fetchAdmins(0, newValue));
            dispatch(adminSearchlistLength(newValue));
        }
    }

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    try {
        if (sortListType.length > 0) {
            if (sortListType === 'A-Z') {
                const compareAdminNames = (a: AdministratorsProps, b: AdministratorsProps): any => {
                    if ((a.lastName + a.firstName).toLowerCase() < (b.lastName + b.firstName).toLowerCase()) {
                        return -1;
                    }
                    if ((a.lastName + a.firstName).toLowerCase() > (b.lastName + b.firstName).toLowerCase()) {
                        return 1;
                    }
                    return 0;
                };

                administrators.length ? administrators.sort(compareAdminNames) : (administrators = []);
            }
            if (sortListType === 'Z-A') {
                const compareAdminNames = (a: AdministratorsProps, b: AdministratorsProps): any => {
                    if ((a.lastName + a.firstName).toLowerCase() < (b.lastName + b.firstName).toLowerCase()) {
                        return 1;
                    }
                    if ((a.lastName + a.firstName).toLowerCase() > (b.lastName + b.firstName).toLowerCase()) {
                        return -1;
                    }
                    return 0;
                };

                administrators.length ? administrators.sort(compareAdminNames) : (administrators = []);
            }
        } else {
            administrators.length ? administrators : (administrators = []);
        }
    } catch (exception) {
        console.log(`Exception in Administrators filtering: ${JSON.stringify(exception)}`);
    }

    return (
        <>
            <div className="main-div">
                <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                    <Appbar toolbarMenu={false} title={'Administrators'} />
                    {md ? (
                        <Fab onClick={handleAddAdminModal} color="primary" sx={{ position: 'fixed', top: '80%', left: '80%' }}>
                            <AddIcon />
                        </Fab>
                    ) : (
                        <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 5 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={2.2}>
                                    <Item elevation={0}>
                                        <Button onClick={handleAddAdminModal} fullWidth variant="contained" sx={{ fontSize: 13 }}>
                                            <AddIcon sx={{ fontSize: 15 }} />
                                            &nbsp;&nbsp;{t('lbl_addAdmin')}
                                        </Button>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    )}

                    <AddAdminModal count={count} setCount={setCount} open={openAddAdminModal} setOpen={setOpenAddAdminModal} />

                    {md ? (
                        <div style={{ backgroundColor: 'white' }}>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    marginLeft: 2,
                                    marginRight: 2,
                                    marginTop: 2,
                                    backgroundColor: 'background.paper',
                                    height: '100vh',
                                }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                placeholder={t('lbl_search')}
                                                onChange={(e) => handleChange(e)}
                                                value={query}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {query.length !== 0 && (
                                                                <IconButton
                                                                    onClick={(event) => handleSearchCancelClick()}
                                                                >
                                                                    <CancelIcon />
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Item>
                                    </Grid>
                                    <Grid item sx={{ mb: 15 }} xs={12}>
                                        <Item elevation={1}>
                                            {administrators !== undefined &&
                                                administrators?.map((accountUser: AdministratorsProps, key: number) => (
                                                    <>
                                                        <Item
                                                            sx={{ marginLeft: -8, marginRight: -2, alignContent: 'normal' }}
                                                            elevation={0}
                                                        >
                                                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                alignItems="flex-start"
                                                                title={
                                                                    <strong>
                                                                        {accountUser.lastName} {accountUser.firstName}
                                                                    </strong>
                                                                }
                                                                info={accountUser.login}
                                                                divider="full"
                                                                rightComponent={
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                        >
                                                                            <IconButton style={{ paddingTop: 15 }}>
                                                                                <ChevronRightIcon
                                                                                    onClick={() =>
                                                                                        handleAdminDetailsModal(accountUser)
                                                                                    }
                                                                                />
                                                                            </IconButton>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            />
                                                        </Item>
                                                    </>
                                                ))}
                                        </Item>
                                    </Grid>
                                    <AdminDetailsModal
                                        counter={counter} setCounter={setCounter}
                                        count={count} setCount={setCount}
                                        adminData={adminData}
                                        openAdminModal={openAdminModal}
                                        setOpenAdminModal={setOpenAdminModal}
                                    />
                                    <Snackbar
                                        open={snackOpen}
                                        autoHideDuration={6000}
                                        onClose={handleSnackClose}
                                        message={deleteAdminMessage}
                                        action={action}
                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                    />
                                    <Item
                                        sx={
                                            md
                                                ? {
                                                    position: 'fixed',
                                                    top: '100%',
                                                    transform: 'translate(-0%,-100%)',
                                                    width: '100%',
                                                    boxShadow:
                                                        '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                                    borderRadius: 0,
                                                    p: 1,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }
                                                : {}
                                        }
                                        elevation={md ? 2 : 0}
                                    >
                                        <TablePagination
                                            component="div"
                                            count={administratorsLength}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[10]}
                                            showFirstButton
                                            showLastButton
                                        />
                                    </Item>
                                    {(query !== '' && administrators?.length === 0) && (
                                        <div
                                            style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}
                                        >
                                            <div style={{ flex: '1 1 0px' }}>
                                                <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                    title={`${t('msg_noSearch_results')} ${query}...`}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Grid>
                            </Box>
                        </div>
                    ) : (
                        <Box sx={{ flexGrow: 1, marginLeft: '4%', marginRight: '4%', marginTop: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item elevation={1}>
                                        <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, pt: 3, pb: 1,marginBottom:4 }}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Item elevation={0}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder={t('lbl_search')}
                                                            value={query}
                                                            onChange={(e) => handleChange(e)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {query.length !== 0 && (
                                                                            <IconButton
                                                                                onClick={(event) => handleSearchCancelClick()}
                                                                            >
                                                                                <CancelIcon />
                                                                            </IconButton>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Item>
                                                </Grid>
                                                {/* <Grid item xs={0.5}>
                                                    <Item elevation={0}>
                                                        <IconButton onClick={(e) => handleSortClick(e)}>
                                                            <SwapVertIcon sx={{ mt: 1 }} />
                                                        </IconButton>
                                                        <SortListMenu setSortListType={setSortListType} anchorEl={anchorEl} open={open} setAnchorEl={setAnchorEl} />
                                                    </Item>
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <Item elevation={1}>
                                                        <Table>
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell width="20%">
                                                                        {t('lbl_name')}&ensp;
                                                                        <Checkbox
                                                                            icon={
                                                                                <ArrowDownwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <ArrowUpwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width="20%">
                                                                        {t('lbl_userType')}&ensp;
                                                                        <Checkbox
                                                                            icon={
                                                                                <ArrowDownwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <ArrowUpwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width="25%">
                                                                        {t('lbl_login')}&ensp;
                                                                        <Checkbox
                                                                            icon={
                                                                                <ArrowDownwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <ArrowUpwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width="25%">
                                                                        Language&ensp;
                                                                        <Checkbox
                                                                            icon={
                                                                                <ArrowDownwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                            checkedIcon={
                                                                                <ArrowUpwardIcon
                                                                                    fontSize="small"
                                                                                    color="disabled"
                                                                                />
                                                                            }
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell width="5%"></TableCell>
                                                                    <TableCell width="5%"></TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {administrators !== undefined &&
                                                                    administrators?.length > 0 &&
                                                                    administrators?.map(
                                                                        (accountUser: AdministratorsProps, key: number) => (
                                                                            <>
                                                                                <TableRow sx={{ backgroundColor: 'white' }}>
                                                                                    <TableCell
                                                                                        align="left"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <label>
                                                                                            {
                                                                                                <Typography variant="body1">
                                                                                                    {accountUser.lastName}{' '}
                                                                                                    {accountUser.firstName}
                                                                                                </Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        align="left"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <label>
                                                                                            {
                                                                                                <Typography variant="body1">
                                                                                                    {accountUser?.userType === 'ADMIN' ? 'Administrator' : 'Normal Administrator'}
                                                                                                </Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        align="left"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <label>
                                                                                            {
                                                                                                <Typography variant="body1">
                                                                                                    {accountUser.login}
                                                                                                </Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        align="left"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <label>
                                                                                            {
                                                                                                <Typography variant="body1">
                                                                                                    {accountUser.locale ===
                                                                                                        'en_US'
                                                                                                        ? 'English'
                                                                                                        : accountUser.locale === 'de_DE' ? 'Deutsch'
                                                                                                            : accountUser.locale === 'nl_NL' ? 'Nederlands'
                                                                                                                : accountUser.locale === 'fr_FR' ? 'Francais'
                                                                                                                    : accountUser.locale === 'el_GR' ? 'Ἑλληνική'
                                                                                                                        : accountUser.locale === 'it_IT' ? 'Italiano'
                                                                                                                            : accountUser.locale === 'pt_PT' ? 'Portugues'
                                                                                                                                : 'None'
                                                                                                    }
                                                                                                </Typography>
                                                                                            }
                                                                                        </label>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        align="right"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => handleDeleteAdminModal(accountUser)}
                                                                                            sx={{ mt: -1 }}
                                                                                            size="small"
                                                                                        >
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>

                                                                                    <TableCell
                                                                                        align="right"
                                                                                        padding="normal"
                                                                                    >
                                                                                        <IconButton
                                                                                            onClick={() => handleEditAdminModal(accountUser)}
                                                                                            sx={{ mt: -1 }}
                                                                                            size="small"
                                                                                        >
                                                                                            <EditIcon />
                                                                                        </IconButton>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </>
                                                                        )
                                                                    )}
                                                            </TableBody>
                                                        </Table>
                                                    </Item>
                                                </Grid>
                                                <EditAdminModal data={adminData} count={count} setCount={setCount} open={openEditAdminModal} setOpen={setOpenEditAdminModal} setOpenAdminModal={setOpenAdminModal} />
                                                <DeleteAdminModal isMobile={false} counter={counter} setCounter={setCounter} data={adminData} open={openDeleteAdminModal} setOpen={setOpenDeleteAdminModal} />
                                                <Grid item xs={12}>
                                                    <Item elevation={0}>
                                                        <TablePagination
                                                            component="div"
                                                            count={administratorsLength}
                                                            page={page}
                                                            onPageChange={handleChangePage}
                                                            rowsPerPage={rowsPerPage}
                                                            rowsPerPageOptions={[10]}
                                                            showFirstButton
                                                            showLastButton
                                                        // nextIconButtonProps={{disabled:administrators?.length < 10}}
                                                        />
                                                    </Item>
                                                </Grid>
                                                {(query !== '' && administrators?.length === 0) && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            height: '100%',
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <div style={{ flex: '1 1 0px' }}>
                                                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                                title={`${t('msg_noSearch_results')} ${query}...`}
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </div>
            </div>
        </>
    );
}