import { RegisterScreenActionTypes } from "../constants/registerScreenAgreementActionTypes";

const initialState = {
    license:<any>{},
    countries:<any>[],
    emailVerifyStatus:<number>0,
    userDataAndStatus:<any>{},
    resetPasswordStatus:<number>0,
    deleteAccountStatus:<number>0,
    deleteAccountLinkStatus:<number>0,
}

export const registerScreenReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case RegisterScreenActionTypes.SET_LICENSE_AGREEMENT:
            return {...state, license: action.payload }
            break;
        case RegisterScreenActionTypes.SET_COUNTRIES:
            return {...state, countries: action.payload }
            break;
        case RegisterScreenActionTypes.SET_EMAIL_VERIFY_STATUS:
            return {...state, emailVerifyStatus: action.payload }
            break;
        case RegisterScreenActionTypes.SET_REGISTERED_USER:
            return {...state, userDataAndStatus: action.payload }
            break;
        case RegisterScreenActionTypes.SET_RESET_PASSWORD_STATUS:
            return {...state, resetPasswordStatus: action.payload }
            break;
        case RegisterScreenActionTypes.SET_DELETE_ACCOUNT_STATUS:
            return { ...state, deleteAccountStatus: action.payload }
            break;
        case RegisterScreenActionTypes.SET_DELETE_ACCOUNT_LINK_STATUS:
            return { ...state, deleteAccountLinkStatus: action.payload }
            break;
        default:
            return state
            break;

    }
}