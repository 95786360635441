/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, MobileStepper, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './pwdReset.css';
import logo from '../assets/images/eaton_stacked_logo.png'
import tile from '../assets/images/tileGrad.png'

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
    borderRadius:10,
  };


export default function PasswordResetSuccess():JSX.Element {
  //const [confirmPwd, setConfirmPwd] = React.useState('')
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [password, setPassword] = React.useState('')
  const [confirmPwd, setConfirmPwd] = React.useState('')
  const [helperText, setHelperText] = React.useState('')
  const [helperTextPwd, setHelperTextPwd] = React.useState('')
  const [spokenHelperText, setSpokenHelperTest] = React.useState('')
  const { t, i18n } = useTranslation();

  const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPwd(e.target.value)
    if(e.target.value === password || password.length === 0){
        setHelperText('')
    }
    else{
        setHelperText(`Unmatched passwords!`)
    }
  }

  const isValidPwd = (pwd:string) => {
    const valid = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(pwd)
    return valid
  }

  const handlePwd = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPassword(e.target.value);
      if(e.target.value.length >= 8){
        if (isValidPwd(e.target.value)){
          setHelperTextPwd('');
        }
        else{
          setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
        }
      }
      else if(e.target.value.length > 0 && e.target.value.length < 8){
        setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
      }
      else{
        setHelperTextPwd('This field is required')
      }
  };

  return (
      <div id="main">
          <Box
              sx={
                  md
                      ? {
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100vw',
                            height: '100dvh',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }
                      : style
              }
          >
              <Paper elevation={0} sx={{ overflow: 'hidden' }}>
                  <Paper
                      elevation={0}
                      sx={{
                          paddingTop: 6,
                          paddingBottom: 3,
                          paddingLeft: 4,
                          paddingRight: 4,
                          backgroundImage: `url(${tile})`,
                      }}
                  >
                      <img src={logo} alt="logo" />
                  </Paper>
              </Paper>
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
                  <Stack direction={'column'} spacing={2}>
                      <Typography variant="h6">{t('lbl_pwdResetSuccess')}</Typography>
                      <Typography variant="body1">
                      {t('lbl_pwdResetSuccessMsg')}
                      </Typography>
                  </Stack>
              </Paper>
          </Box>
      </div>
  );
}
