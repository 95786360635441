/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { systemConfigurationTypes } from "../constants/systemConfigurationTypes"
import { SystemConfigurationPropTypeArrayType, AccountSettingdPropTypeArrayType, InstallerStateTypeArrayType, ARCPropArrayType, MailArrayPropType } from "../../components/SystemConfiguration/SystemConfigurationPropType"


const initialState = {
    systemConfigurationDetails: <SystemConfigurationPropTypeArrayType>[],
    applepushNotificationStatus: <any>{},
    accountSettingsDetails: <AccountSettingdPropTypeArrayType>[],
    installerState: <InstallerStateTypeArrayType>[],
    arcDetails: <ARCPropArrayType>[],
    mailDetails: <MailArrayPropType>[]
}


export const systemConfigurationReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case systemConfigurationTypes?.SYSTEMCONFIGURATION:
            return { ...state, systemConfigurationDetails: action.payload }
            break;

        case systemConfigurationTypes?.APPLE_PUSH_NOTIFICATION:
            return { ...state, applepushNotificationStatus: action.payload }
            break;

        case systemConfigurationTypes?.ACCOUNTSETTINGS:
            return { ...state, accountSettingsDetails: action.payload }
            break;

        case systemConfigurationTypes?.INSTALLERSTATE:
            return { ...state, installerState: action.payload }
            break;

        case systemConfigurationTypes?.ARC_DETAILS:
            return { ...state, arcDetails: action.payload }
            break;

        case systemConfigurationTypes?.MAIL_DETAILS:
            return { ...state, mailDetails: action.payload }
            break;

        default:
            return { ...state }
            break;
    }
}

