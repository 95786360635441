/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider, IconButton, InputAdornment, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { InfoListItem, ListItemTag, ThreeLiner, EmptyState } from '@brightlayer-ui/react-components';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import { PanelNotificationProps } from './PanelNotificationTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router-dom';
import { fetchPanelNotifications, fetchPanelNotificationsLength } from '../../redux/actions/specificPanelActions';
import TablePagination from '@mui/material/TablePagination';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink } from 'react-csv';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PanelNotificationModal from './PanelNotificationModal';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { CleaningServices, AccessibilityNew } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getSeconds, getMonth, getTimeFormat, getYear } from '../Timestamp/timestampCal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
    },
  }));

export default function PanelNotifications(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const ms = useMediaQuery(theme.breakpoints.down('sm'));
    let { panelNotifications, data, notificationsLen } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [query, setQuery] = React.useState('');
    const [notificationModalOpen, setNotificationModalOpen] = React.useState(false);
    const [lengthFetch, setLengthFetch] = React.useState(true);
    const [text, setText] = React.useState<string>('');
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const capitalizeAlarmType = (alarmType:string):string => {
        const str = alarmType;
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);

        return str2
    }

    const getNotificationStatus = (deliveryStatus:string):string => {
        const status = deliveryStatus === 'DELIVERED' ? 'Delivered':'Failed'

        return status
    }

    const getAlarmType = (alarm: string): string => {
        const alarmType = alarm ? capitalizeAlarmType(alarm) : 'Information';

        return alarmType;
    };

    React.useEffect(() => {
        if(lengthFetch){
            dispatch(fetchPanelNotificationsLength(Number(id), (page) * 10, 500))
            setLengthFetch(false)
        }
        const getNotifications = async() => {
            await dispatch(fetchPanelNotifications(Number(id), page * 10, 500))
        }
        getNotifications(); 
    }, []); 

    const BootstrapTooltip = (props: any) => {
        const classes = useStylesBootstrap();
        return <Tooltip arrow classes={classes} {...props} />;
      }

    const handleCancelClick = (): any => {
        setQuery('');
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleNotificationClick = (key: number) => {
        setNotificationModalOpen(true);
        setText(panelNotifications[key].text);
    };

    const Headers = [
        { label: 'Time Stamp', key: 'timeStamp' },
        { label: 'Alarm Type', key: 'alarmType' },
        { label: 'Description', key: 'humanReadableMessage' },
        { label: 'Verbose', key: 'verbose' },
        { label: 'Delivery Status', key: 'deliveryStatus' },
    ];

    const csvReport = {
        filename: `panel-${id}-notifications.csv`,
        headers: Headers,
        data: data,
    };

    try {
        if (panelNotifications.length > 0 && panelNotifications !== undefined) {
            const filteredPanels = panelNotifications.filter(
                (notification: PanelNotificationProps) => (
                    JSON.parse(notification.text).text
                        .toLowerCase()
                        .includes(query.toLowerCase()) || getNotificationStatus(notification.deliveryStatus).toLowerCase()
                        .includes(query.toLowerCase()) || getDateFormat(notification.timeStamp).toString().toLowerCase()
                        .includes(query.toLowerCase()) || getTimeFormat(notification.timeStamp).toString().toLowerCase()
                        .includes(query.toLowerCase()) || getAlarmType(notification.alarmType).toString().toLowerCase()
                        .includes(query.toLowerCase()) || notification.text.toLowerCase().includes(query.toLowerCase())
                )
            );
            panelNotifications = query.length > 0 ? filteredPanels : panelNotifications;
        }
    } catch (exception) {
        console.log(`Exception in panel notification filtering: ${JSON.stringify(exception)}`);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            <Box
                sx={
                    md
                        ? {
                              flexGrow: 1,
                              marginLeft: -2,
                              marginRight: -2,
                              marginTop: -2,
                              maxHeight: '80vh',
                              overflow: 'auto',
                              pb: 40,
                              pt: 2,
                          }
                        : { flexGrow: 1, marginLeft: 8, marginRight: 8, marginTop: 4, pb: 40 }
                }
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Item elevation={1}>
                            <Paper sx={{ height: md ? '100vh' : '' }} elevation={0}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={md ? 10.5 : 12}>
                                            <Item elevation={0}>
                                                <TextField
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder={t('lbl_search')}
                                                    onChange={(e) => {
                                                        setPage(0)
                                                        setQuery(e.target.value)}}
                                                    value={query}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {query.length !== 0 && (
                                                                    <IconButton
                                                                        onClick={(event) => handleCancelClick()}
                                                                    >
                                                                        <CancelIcon />
                                                                    </IconButton>
                                                                )}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Item>
                                        </Grid>
                                        {md && (
                                            <Grid item xs={1.5}>
                                                <Item elevation={0}>
                                                    {(panelNotifications?.length > 0 && data.length > 0) ?
                                                        <CSVLink {...csvReport}>
                                                            <IconButton size="small">
                                                                <DownloadIcon style={{ marginTop: 15 }} />
                                                            </IconButton>
                                                        </CSVLink>
                                                        :
                                                        <IconButton disabled size="small">
                                                            <DownloadIcon style={{ marginTop: 15 }} />
                                                        </IconButton>
                                                    }
                                                </Item>
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Item
                                                sx={{ marginLeft: md ? -2 : 0, marginRight: md ? -2 : 0 }}
                                                elevation={md ? 0 : 1}
                                            >
                                                {panelNotifications !== undefined &&
                                                    panelNotifications.length > 0 &&
                                                    panelNotifications?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map(
                                                        (panelNotification: PanelNotificationProps, key: number) => (
                                                            <div key={key}>
                                                                {md ? (
                                                                    <>
                                                                        <div
                                                                            style={{
                                                                                paddingTop: 10,
                                                                                paddingBottom: 10,
                                                                                backgroundColor:'#FBFBFB',
                                                                            }}
                                                                        >
                                                                            <InfoListItem
                                                                                nonce={undefined}
                                                                                onResize={undefined}
                                                                                onResizeCapture={undefined}
                                                                                title={undefined}
                                                                                subtitle={undefined}
                                                                                info={undefined}
                                                                                backgroundColor='#FBFBFB'
                                                                                iconAlign="left"
                                                                                onClick={() =>
                                                                                    handleNotificationClick(key)
                                                                                }
                                                                                chevron
                                                                                leftComponent={
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'row',
                                                                                        }}
                                                                                    >
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                                <strong>
                                                                                                    {getHours(
                                                                                                        panelNotification?.timeStamp
                                                                                                    )}
                                                                                                    :
                                                                                                    {getMinutes(
                                                                                                        panelNotification?.timeStamp
                                                                                                    )}
                                                                                                    :
                                                                                                    {getSeconds(
                                                                                                        panelNotification?.timeStamp
                                                                                                    )}
                                                                                                </strong>
                                                                                                &ensp;
                                                                                                
                                                                                            </div>
                                                                                            <div>
                                                                                                {getDate(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                                /
                                                                                                {getMonth(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                                /
                                                                                                {getYear(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                            </div>
                                                                                        </div>
                                                                                        &ensp;&ensp;&ensp;&ensp;
                                                                                        <div
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                flexDirection: 'column',
                                                                                            }}
                                                                                        >
                                                                                            <input
                                                                                                readOnly
                                                                                                value={
                                                                                                    JSON.parse(
                                                                                                        panelNotification?.text
                                                                                                    ).text
                                                                                                }
                                                                                                style={{
                                                                                                    border: 'none',
                                                                                                    outline: 'none',
                                                                                                    fontSize: 16,
                                                                                                    fontFamily:
                                                                                                        'Open Sans',
                                                                                                    fontStyle: 'normal',
                                                                                                    fontWeight: 400,
                                                                                                    lineHeight: 1,
                                                                                                    width: '100%',
                                                                                                    textAlign: 'left',
                                                                                                    backgroundColor:'#FBFBFB',
                                                                                                }}
                                                                                            />
                                                                                            <Typography variant="body2">
                                                                                                {JSON.parse(
                                                                                                    panelNotification?.text
                                                                                                ).verbose
                                                                                                    ? JSON.parse(
                                                                                                          panelNotification?.text
                                                                                                      ).verbose
                                                                                                    : '-'}
                                                                                            </Typography>
                                                                                            <ListItemTag
                                                                                                nonce={undefined}
                                                                                                onResize={undefined}
                                                                                                onResizeCapture={
                                                                                                    undefined
                                                                                                }
                                                                                                sx={{
                                                                                                    maxWidth:
                                                                                                        panelNotification?.deliveryStatus ===
                                                                                                        'DELIVERED'
                                                                                                            ? 70
                                                                                                            : 50,
                                                                                                    mt: 1,
                                                                                                }}
                                                                                                label={
                                                                                                    panelNotification?.deliveryStatus ===
                                                                                                        'DELIVERED'
                                                                                                        ? `${t(
                                                                                                            'lbl_delivered'
                                                                                                        )}`
                                                                                                        : `-`
                                                                                                }
                                                                                                backgroundColor={
                                                                                                    panelNotification?.deliveryStatus ===
                                                                                                    'DELIVERED'
                                                                                                        ? '#E0F1FD'
                                                                                                        : '#FBFBFB'
                                                                                                }
                                                                                                fontColor={
                                                                                                    panelNotification?.deliveryStatus ===
                                                                                                    'DELIVERED'
                                                                                                        ? '#0088F2'
                                                                                                        : 'black'
                                                                                                }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                                icon={
                                                                                    panelNotification?.alarmType ? (
                                                                                        panelNotification?.alarmType ===
                                                                                        'alarm' ? (
                                                                                            <NotificationsIcon color="error" />
                                                                                        ) : panelNotification?.alarmType ===
                                                                                          'set' ? (
                                                                                            <LockIcon color="action" />
                                                                                        ) : panelNotification?.alarmType ===
                                                                                          'partset' ? (
                                                                                            <LockIcon color="action" />
                                                                                        ) : panelNotification?.alarmType ===
                                                                                          'unset' ? (
                                                                                            <LockOpenIcon
                                                                                                sx={{
                                                                                                    color: '#39B620',
                                                                                                }}
                                                                                            />
                                                                                        ) : panelNotification?.alarmType ===
                                                                                          'fault' ? (
                                                                                            <InfoIcon
                                                                                                sx={{
                                                                                                    color: '#F47721',
                                                                                                }}
                                                                                            />
                                                                                        ) : (
                                                                                            <NotificationsIcon color="error" />
                                                                                        )
                                                                                    ) : JSON.parse(
                                                                                          panelNotification?.text
                                                                                      ).code === 'PA' ? (
                                                                                        <AccessibilityNew color="error" />
                                                                                    ) : (
                                                                                        <InfoIcon color="primary" />
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <Divider />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <InfoListItem
                                                                            nonce={undefined}
                                                                            onResize={undefined}
                                                                            onResizeCapture={undefined}
                                                                            title={undefined}
                                                                            subtitle={undefined}
                                                                            info={undefined}
                                                                            backgroundColor='#FBFBFB'
                                                                            iconAlign="left"
                                                                            divider="full"
                                                                            leftComponent={
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                    }}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                        }}
                                                                                    >
                                                                                        <div>
                                                                                            <strong>
                                                                                                {getHours(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                                :
                                                                                                {getMinutes(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                                :
                                                                                                {getSeconds(
                                                                                                    panelNotification?.timeStamp
                                                                                                )}
                                                                                            </strong>
                                                                                            &ensp;
                                                                                            
                                                                                        </div>
                                                                                        <div>
                                                                                            {getDate(
                                                                                                panelNotification?.timeStamp
                                                                                            )}
                                                                                            /
                                                                                            {getMonth(
                                                                                                panelNotification?.timeStamp
                                                                                            )}
                                                                                            /
                                                                                            {getYear(
                                                                                                panelNotification?.timeStamp
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                    &ensp;&ensp;&ensp;&ensp;&ensp;
                                                                                    <div>
                                                                                        <ThreeLiner
                                                                                            nonce={undefined}
                                                                                            onResize={undefined}
                                                                                            onResizeCapture={undefined}
                                                                                            subtitle={
                                                                                                <input
                                                                                                    readOnly
                                                                                                    value={
                                                                                                        panelNotification?.alarmType
                                                                                                            ? capitalizeAlarmType(
                                                                                                                  panelNotification?.alarmType
                                                                                                              )
                                                                                                            : 'Information'
                                                                                                    }
                                                                                                    style={{
                                                                                                        border: 'none',
                                                                                                        outline: 'none',
                                                                                                        fontSize: 16,
                                                                                                        fontFamily:
                                                                                                            'Open Sans',
                                                                                                        fontStyle:
                                                                                                            'normal',
                                                                                                        fontWeight: 600,
                                                                                                        lineHeight: 2,
                                                                                                        backgroundColor:'#FBFBFB',
                                                                                                    }}
                                                                                                />
                                                                                            }
                                                                                            info=""
                                                                                        />
                                                                                    </div>
                                                                                    &ensp;&ensp;
                                                                                    <div>
                                                                                        <ThreeLiner
                                                                                            nonce={undefined}
                                                                                            onResize={undefined}
                                                                                            onResizeCapture={undefined}
                                                                                            subtitle={
                                                                                                <div
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        flexDirection:
                                                                                                            'column',
                                                                                                    }}
                                                                                                >
                                                                                                    <Typography variant="subtitle2">
                                                                                                        {
                                                                                                            JSON.parse(
                                                                                                                panelNotification?.text
                                                                                                            ).text
                                                                                                        }
                                                                                                    </Typography>
                                                                                                    <Typography variant="subtitle2">
                                                                                                        {
                                                                                                            JSON.parse(
                                                                                                                panelNotification?.text
                                                                                                            ).verbose
                                                                                                        }
                                                                                                    </Typography>
                                                                                                </div>
                                                                                            }
                                                                                            info=""
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            rightComponent={
                                                                                <div
                                                                                    style={{
                                                                                        display: 'flex',
                                                                                        flexDirection: 'row',
                                                                                    }}
                                                                                >
                                                                                    <ListItemTag
                                                                                        nonce={undefined}
                                                                                        onResize={undefined}
                                                                                        onResizeCapture={undefined}
                                                                                        sx={{ mt: 1.5 }}
                                                                                        label={
                                                                                            panelNotification?.deliveryStatus ===
                                                                                            'DELIVERED'
                                                                                                ? `${t(
                                                                                                      'lbl_delivered'
                                                                                                  )}`
                                                                                                : `-`
                                                                                        }
                                                                                        backgroundColor={
                                                                                            panelNotification?.deliveryStatus ===
                                                                                            'DELIVERED'
                                                                                                ? '#E0F1FD'
                                                                                                : '#FBFBFB'
                                                                                        }
                                                                                        fontColor={
                                                                                            panelNotification?.deliveryStatus ===
                                                                                            'DELIVERED'
                                                                                                ? '#0088F2'
                                                                                                : 'black'
                                                                                        }
                                                                                    />
                                                                                    &ensp;&ensp;
                                                                                    <IconButton
                                                                                        onClick={() =>
                                                                                            handleNotificationClick(key)
                                                                                        }
                                                                                    >
                                                                                        <ChevronRightIcon />
                                                                                    </IconButton>
                                                                                </div>
                                                                            }
                                                                            icon={
                                                                                panelNotification?.alarmType ? (
                                                                                    panelNotification?.alarmType ===
                                                                                    'alarm' ? (
                                                                                        <NotificationsIcon color="error" />
                                                                                    ) : panelNotification?.alarmType ===
                                                                                      'set' ? (
                                                                                        <LockIcon color="action" />
                                                                                    ) : panelNotification?.alarmType ===
                                                                                      'partset' ? (
                                                                                        <LockIcon color="action" />
                                                                                    ) : panelNotification?.alarmType ===
                                                                                      'unset' ? (
                                                                                        <LockOpenIcon
                                                                                            sx={{ color: '#39B620' }}
                                                                                        />
                                                                                    ) : panelNotification?.alarmType ===
                                                                                      'fault' ? (
                                                                                        <InfoIcon
                                                                                            sx={{ color: '#F47721' }}
                                                                                        />
                                                                                    ) : (
                                                                                        <NotificationsIcon color="error" />
                                                                                    )
                                                                                ) : JSON.parse(panelNotification?.text)
                                                                                      .code === 'PA' ? (
                                                                                    <AccessibilityNew color="error" />
                                                                                ) : (
                                                                                    <InfoIcon color="primary" />
                                                                                )
                                                                            }
                                                                        />
                                                                    </>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                <PanelNotificationModal
                                                    text={text}
                                                    open={notificationModalOpen}
                                                    setOpen={setNotificationModalOpen}
                                                />
                                            </Item>
                                        </Grid>
                                        {panelNotifications.length === 0 && query !== '' && (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    height: '100%',
                                                    width: '100%',
                                                }}
                                            >
                                                <div style={{ flex: '1 1 0px' }}>
                                                    <EmptyState
                                                        nonce={undefined}
                                                        onResize={undefined}
                                                        onResizeCapture={undefined}
                                                        icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                        title={`${t('msg_noSearch_results')} "${query}"...`}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <Grid item xs={12}></Grid>
                                        {!md &&
                                            <Grid item xs={6}>
                                                <Item elevation={0}>
                                                    {(panelNotifications?.length > 0 && data.length > 0) ?
                                                        <CSVLink {...csvReport}>
                                                            <Button variant='contained' disabled={panelNotifications.length === 0} startIcon={<DownloadIcon />}>
                                                            {t('btn_download_notifications')}
                                                            </Button>
                                                        </CSVLink>
                                                        :
                                                        <Button variant='contained' disabled startIcon={<DownloadIcon />}>
                                                            {t('btn_download_notifications')}
                                                        </Button>
                                                    }
                                                </Item>
                                            </Grid>}
                                        <Grid item xs={md?12:6}>
                                            <Item
                                                sx={
                                                    md
                                                        ? {
                                                              position: 'fixed',
                                                              top: '100%',
                                                              transform: 'translate(-5%,-90%)',
                                                              width: '100%',
                                                              boxShadow:
                                                                  '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                                              borderRadius: 0,
                                                              p: 1,
                                                              display: 'flex',
                                                              justifyContent: 'center',
                                                          }
                                                        : {}
                                                }
                                                elevation={md ? 2 : 0}
                                            >
                                                <TablePagination
                                                    component="div"
                                                    count={panelNotifications?.length}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                                                    showFirstButton
                                                    showLastButton
                                                />
                                            </Item>
                                        </Grid>
                                        {ms && <Grid item xs={12}></Grid>}
                                        {ms && <Grid item xs={12}></Grid>}
                                        {ms && <Grid item xs={12}></Grid>}
                                    </Grid>
                                </Box>
                            </Paper>
                        </Item>
                    </Grid>
                </Grid>
                {/* {md ? (
                    <></>
                ) : (
                    <CSVLink {...csvReport}>
                        {data !== undefined && (
                            <BootstrapTooltip title={t('tootltip_exportNotification')} placement="left">
                                <Fab color="primary" sx={{ position: 'fixed', top: '90%', left: '95%' }}>
                                    <DownloadIcon />
                                </Fab>
                            </BootstrapTooltip>
                        )}
                    </CSVLink>
                )} */}
            </Box>
        </div>
    );
}
