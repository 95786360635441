/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { ARCAccessPanelProps } from './ARCAccessProps';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchArcCountryCodes, fetchCslCountries } from '../../redux/actions/specificPanelActions';


export default function ARCAccessPanel({
    panelARCSettings,
    arcProvider,
    setArcProvider,
    setArcName,
    setAccountNumber,
    siaIpVersion,
    setSiaIpVersion,
    setPrimaryIp,
    setSecondaryIp,
    setPrimaryPort,
    setSecondaryPort,
    encryption,
    setEncryption,
    setReportEmail,
    arcName,
    accountNumber,
    primaryIp,
    secondaryIp,
    primaryPort,
    secondaryPort,
    reportEmail,
    aesKey,
    setAesKey,
    country,
    setCountry,
    arc,
    setArc,
    accNumber,
    setAccNumber,
}: ARCAccessPanelProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { cslCountries, panelARCCountryCodes } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { t, i18n } = useTranslation();
    const dispatch = useTypedDispatch()
    const [helperLoginText, setHelperLoginText] = React.useState('');
    // const { panelARCSettings } = useSelector((state:any) => state.specificPanelAndEstateReducer)

    const arcProviders = [
        {
            code: 'SIA_IP',
            value: 'SIA IP DIRECT',
        },
        {
            code: 'CSL_DUAL_COM',
            value: 'CSL DualCom',
        },
    ];
    const siaIpVersions = ['SC_SIA_3', 'EX_SIA_3', 'EX_SIA_3_V2', 'EX_SIA_3_V3'];

    const encryptions = [
        {
            code: 'ENC_NONE',
            value: 'NO ENCRYPTION',
        },
        {
            code: 'ENC_128',
            value: '128 BITS ENCRYPTION',
        },
        {
            code: 'ENC_192',
            value: '192 BITS ENCRYPTION',
        },
        {
            code: 'ENC_256',
            value: '256 BITS ENCRYPTION',
        },
    ];

    const isValidEmail = (email: string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid;
    };

    const handleAesKey = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAesKey(e.target.value);
        if (aesKey.length < 32) {
            setHelperLoginText(`${'Input length should not be less than 32 characters'}`);
        } else {
            setHelperLoginText('');
        }
    };

    const handleArcProvider = (value:string) => {
      setArcProvider(value)
      if(value === 'CSL_DUAL_COM'){
        dispatch(fetchCslCountries())
      }
    }

    const handleCountry = (value:string) => {
      setCountry(value)
        dispatch(fetchArcCountryCodes(value))
    }

    React.useEffect(() => {
        if(arcProvider === 'CSL_DUAL_COM'){
            dispatch(fetchCslCountries())
          }
    },[])

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: md ? '80vh' : 400,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        select
                        value={arcProvider}
                        label={t('lbl_ARCProvider')}
                        variant="filled"
                        onChange={(e) => handleArcProvider(e.target.value)}
                        
                    >
                        {arcProviders.map((arcc) => (
                            <MenuItem key={arcc.code} value={arcc.code}>
                                {arcc.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                {arcProvider === 'SIA_IP' ? (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={arcName}
                                label={t('lbl_ARCName')}
                                variant="filled"
                                onChange={(e) => setArcName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_accntNo')}
                                variant="filled"
                                value={accountNumber}
                                onChange={(e) => setAccountNumber(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                value={siaIpVersion}
                                label={t('lbl_IP_version')}
                                variant="filled"
                                onChange={(e) => setSiaIpVersion(e.target.value)}
                            >
                                {siaIpVersions.map((version, key) => (
                                    <MenuItem key={key} value={version}>
                                        {version}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_primaryIP')}
                                variant="filled"
                                value={primaryIp}
                                onChange={(e) => setPrimaryIp(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_secondaryIP')}
                                variant="filled"
                                value={secondaryIp}
                                onChange={(e) => setSecondaryIp(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_primaryPort')}
                                variant="filled"
                                value={primaryPort}
                                onChange={(e) => setPrimaryPort(Number(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_secondaryPort')}
                                variant="filled"
                                value={secondaryPort}
                                onChange={(e) => setSecondaryPort(Number(e.target.value))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                value={encryption}
                                label={t('lbl_encryption')}
                                variant="filled"
                                onChange={(e) => setEncryption(e.target.value)}
                            >
                                {encryptions.map((encry, key) => (
                                    <MenuItem key={key} value={encry.code}>
                                        {encry.value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {encryption !== 'ENC_NONE' && (
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label={'AES Key'}
                                    variant="filled"
                                    value={aesKey}
                                    error={helperLoginText.length > 0}
                                    helperText={helperLoginText}
                                    onChange={(e) => handleAesKey(e)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={t('lbl_report_email')}
                                variant="filled"
                                value={reportEmail}
                                error={isValidEmail(reportEmail)}
                                helperText={''}
                                onChange={(e) => setReportEmail(e.target.value)}
                            />
                        </Grid>
                    </>
                ) : (
                    <>
                    <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                value={country}
                                label={'Country'}
                                variant="filled"
                                onChange={(e) => handleCountry(e.target.value)}
                            >
                                {cslCountries !== undefined && cslCountries.length >0 &&
                                cslCountries?.map((co:any, key:number) => (
                                    <MenuItem key={key} value={co.name}>
                                        {co.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </Grid>
                    <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                value={arc}
                                label={'ARC'}
                                variant="filled"
                                onChange={(e) => setArc(e.target.value)}
                            >
                                {panelARCCountryCodes !== undefined && panelARCCountryCodes.length >0 &&
                                panelARCCountryCodes?.map((co:any, key:number) => (
                                    <MenuItem key={key} value={co.code}>
                                        {`${co.value} (${co.code})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </Grid>
                            <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={'Account Number'}
                                variant="filled"
                                value={accNumber}
                                onChange={(e) => setAccNumber((e.target.value))}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
}
