/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Aperçu",
    "menu_endUserInfo": "Informations sur l'utilisateur final",
    "menu_accntUsrs": "Utilisateurs du compte",
    "menu_serviceReports": "Rapports de service",
    "menu_settings": "Paramètres",
    "menu_help": "Information",
    "lbl_cloudId": "ID de nuage",
    "lbl_logOut": "Se déconnecter",
    "lbl_contactSupport": "CONTACTEZ LE SUPPORT",
    "lbl_estates": "Domaines",
    "lbl_ESTATES": "PROPRIÉTÉS",
    "lbl_panels": "Panneaux",
    "lbl_PANELS": "PANNEAUX",
    "lbl_connected": "Connecté",
    "lbl_disconnected": "Débranché",
    "lbl_manageEstates": "Gérer les successions",
    "lbl_newEstate": "Nouveau domaine",
    "lbl_estate": "Domaine",
    "lbl_search": "Rechercher…",
    "lbl_manage_estateAndpanels": "Gérer les domaines et les panneaux",
    "btn_cancel": "Annuler",
    "btn_save": "Sauvegarder",
    "msg_panels_moved": "Panneaux déplacés avec succès",
    "lbl_name": "Nom",
    "lbl_description": "Description",
    "btn_next": "Suivante",
    "lbl_contact_name": "Nom du contact",
    "lbl_contact_phNo": "numéro de téléphone de contact",
    "lbl_country": "Pays",
    "lbl_address": "Adresse",
    "lbl_postcode": "Code postal",
    "lbl_selectedPanels": "Panneaux sélectionnés",
    "lbl_close": "Fermer",
    "msg_newEstate_created": "Nouveau domaine créé avec succès",
    "msg_estateExists": "Le domaine avec ce nom existe déjà",
    "tooltip_ARC_Access": "Accès au CRS",
    "tooltip_usrEmail_alerts": "Alertes par e-mail des utilisateurs",
    "tooltip_usrAccess": "Accès utilisateur",
    "tooltip_installerEmail_alerts": "Alertes par e-mail de l'installateur",
    "tooltip_remoteServicing": "Entretien à distance",
    "lbl_allPanel": "Όλα τα πάνελ",
    "lbl_favorites": "Favoris",
    "lbl_ARC_enabled": "ARC activé",
    "lbl_end_usrEmail_enabled": "E-mail de l'utilisateur final activé",
    "lbl_end_usrAccess_disabled": "Accès utilisateur final désactivé",
    "lbl_installer_emailAlerts_enabled": "Alertes par e-mail de l'installateur activées",
    "lbl_remoteServicing_enabled": "Service à distance activé",
    "msg_no_panels_found": "Aucun panneau disponible pour les filtres ci-dessus",
    "lbl_move_panel": "Déplacer le panneau",
    "lbl_delete_panel": "Supprimer le panneau",
    "lbl_delete": "Supprimer",
    "lbl_delete_estate": "Supprimer le domaine",
    "msg_delete_estate": "Êtes-vous sûr de vouloir supprimer le domaine",
    "msg_no_action_revert": "Cette action ne peut pas être annulée.",
    "msg_estate_deleted": "Domaine supprimé avec succès",
    "msg_deleteEstate_error": "Erreur lors de la suppression du domaine",
    "msg_noSearch_results": "Aucun résultat correspondant",
    "msg_deletePanel": "Voulez-vous vraiment supprimer le panneau ?",
    "msg_deletePanel_error": "Erreur lors de la suppression du domaine",
    "msg_panelDeleted": "Ο πίνακας διαγράφηκε με επιτυχία",
    "lbl_viewEstate": "Voir le domaine",
    "lbl_goToPanel": "Aller au panneau",
    "lbl_subEstate": "Sous-parc",
    "msg_noEstatesConfigured": "Aucun domaine configuré",
    "msg_noPanelsConnected": "Aucun panneau connecté",
    "tab_notifications": "HISTORIQUE DU PANNEAU",
    "tab_overview": "APERÇU",
    "tab_panelLog": "JOURNAL DU PANNEAU",
    "tab_ARC_reporting": "RAPPORT D'ARC",
    "tab_connect": "CONNECTER",
    "tab_keypad": "CLAVIER",
    "tab_remoteServicing": "MAINTENANCE À DISTANCE",
    "tab_mobileApp_usrs": "UTILISATEURS D'APPLICATIONS MOBILES",
    "lbl_mobAppUsrs": "Utilisateurs d'applications mobiles",
    "lbl_summary": "Résumé",
    "lbl_status": "Statut",
    "lbl_panelId": "ID du panneau",
    "lbl_macAddress": "Adresse Mac",
    "lbl_firmware": "Micrologiciel",
    "msg_panelIdCopied": "PanelID copié dans le presse-papiers",
    "lbl_panelAccess": "Accès au panneau",
    "lbl_enabled": "Ενεργοποιήθηκε",
    "lbl_disabled": "Désactivé",
    "lbl_connectionStats": "Statistiques de connexion",
    "lbl_totalTime": "Temps total (heures)",
    "lbl_connectedTime": "Connecté (heures)",
    "lbl_disconnectedTime": "Déconnecté (heures)",
    "lbl_connectedPercent": "Connecté(%)",
    "lbl_siteDetails": "Détails du site",
    "lbl_cloudReference": "Référence cloud",
    "lbl_siteAddress": "Adresse du site",
    "lbl_optional": "Facultative",
    "lbl_somethingWrong": "Quelque chose s'est mal passé",
    "msg_siteDetails_edited": "Détails du site modifiés avec succès",
    "lbl_ARCProvider": "Fournisseur ARC",
    "lbl_ARCName": "Nom du CRS",
    "lbl_accntNo": "Numéro de compte",
    "lbl_IP_version": "Version SIA IP",
    "lbl_primaryIP": "IP principale",
    "lbl_secondaryIP": "IP secondaire",
    "lbl_primaryPort": "Port principal",
    "lbl_secondaryPort": "Port secondaire",
    "lbl_encryption": "Chiffrement",
    "option_no_encryption": "AUCUN CRYPTAGE",
    "lbl_report_email": "Αναφορά email",
    "msg_ARC_edited": "Accès ARC modifié avec succès",
    "msg_ARC_enabled": "Accès ARC activé avec succès",
    "msg_ARC_updated": "Accès ARC activé et mis à jour",
    "btn_back": "Dos",
    "msg_ARC_disabled": "Accès ARC désactivé",
    "msg_error": "Erreur",
    "lbl_dateOfService": "Date du prochain service",
    "lbl_serviceFrquency": "Fréquence d'entretien",
    "option_svc_frqncy_1": "Mensuelle",
    "option_svc_frqncy_2": "6 mois",
    "msg_remote_srvcng_enable": "Service à distance activé avec succès",
    "msg_remote_srvcng_disabled": "Service à distance désactivé avec succès",
    "msg_email_alert_enabled": "Alerte par e-mail de l'installateur activée avec succès",
    "msg_email_alert_edited": "L'alerte par e-mail de l'installateur a été modifiée avec succès",
    "lbl_email_alert_disable": "Désactiver les alertes par e-mail de l'installateur",
    "msg_email_alert_disable": "Voulez-vous vraiment désactiver les alertes par e-mail de l'installateur ?",
    "btn_disable": "Désactiver",
    "msg_emailAlert_disabled": "L'alerte par e-mail de l'installateur a été désactivée avec succès",
    "lbl_enable_usrAccess": "Activer l'accès utilisateur",
    "lbl_disable_usrAccess": "Désactiver l'accès utilisateur",
    "msg_enable_usrAccess": "Voulez-vous vraiment activer l'accès utilisateur ?",
    "btn_enable": "Activer",
    "msg_usrAccess_enabled": "Accès utilisateur activé avec succès",
    "msg_usrAccess_disabled": "Accès utilisateur désactivé avec succès",
    "msg_usrAccess_error": "Erreur lors de l'activation/désactivation de l'accès utilisateur",
    "btn_addUsr": "Ajouter un utilisateur",
    "lbl_addUsr": "ajouter des utilisateurs afin qu'ils soient alertés des événements de ce panneau",
    "lbl_events": "Événements",
    "lbl_sets": "Ensembles",
    "lbl_alarms": "Alarmes",
    "lbl_tampers": "Tampons",
    "lbl_system": "Σύστημα",
    "msg_emailAlert_added": "Alertes par e-mail utilisateur ajoutées avec succès.",
    "lbl_edit_emailAlerts": "Modifier les alertes par e-mail de l'utilisateur",
    "msg_usrEmail_edited": "L'adresse e-mail de l'utilisateur a été modifiée avec succès",
    "lbl_delete_usrEmail": "Supprimer les alertes par e-mail de l'utilisateur",
    "msg_delete_usrEmail": "Voulez-vous vraiment supprimer les alertes par e-mail des utilisateurs ?",
    "msg_usr_emailDeleted": "Alerte e-mail utilisateur supprimée avec succès",
    "tootltip_exportNotification": "Avis d'exportation",
    "lbl_delivered": "LIVRÉ",
    "lbl_failed": "ÉCHOUÉ",
    "lbl_information": "Information",
    "lbl_evntDetails": "Détails de l'évènement",
    "lbl_systmName": "Nom du système",
    "lbl_Id": "IDENTIFIANT",
    "lbl_partition": "Cloison",
    "lbl_user": "Utilisatrice",
    "lbl_username": "Nom d'utilisateur",
    "lbl_text": "Texte",
    "lbl_verbose": "Verbeuse",
    "lbl_timestamp": "Horodatage",
    "lbl_code": "Code",
    "lbl_group": "Groupe",
    "lbl_SIA_IP": "SIA IP DIRECT",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "PAS DE RÉPONSE",
    "lbl_rejected": "REJETÉE",
    "lbl_readyForDelivering": "PRÊT À LIVRER",
    "lbl_sent": "ENVOYÉE",
    "lbl_exportLogs": "Exporter les journaux",
    "lbl_failedSvc": "Services en échec",
    "lbl_ScheduledSvc": "Services programmés",
    "lbl_pendingSvc": "Aucun service planifié ou en échec",
    "lbl_cmpltd_svcReports": "Rapports de service terminés",
    "lbl_report_no": "Signaler le numéro",
    "lbl_cmpltd_date": "Date d'achèvement",
    "lbl_cmpltd_Classification": "Classification",
    "lbl_cmpltd_View": "Voir",
    "lbl_service_report": "Rapport de service",
    "lbl_delete_svcReport": "Supprimer le rapport de service",
    "msg_delete_svcReport": "Voulez-vous vraiment supprimer le rapport de service ?",
    "msg_svcReport_deleted": "Rapport de service supprimé avec succès",
    "btn_perform_remoteSvc": "Effectuer un service à distance",
    "msg_remoteSvc_initiated": "Service à distance lancé",
    "msg_remoteSvc_inProgress": "la prestation est en cours",
    "lbl_errorOccurred": "Erreur est survenue",
    "lbl_noCmpltd_svc": "Aucun service terminé",
    "lbl_noCmpltd_svcReports": "Aucun rapport de service terminé",
    "lbl_mobApp_usrs": "Utilisateurs d'applications mobiles",
    "msg_usr_authorized": "Utilisateur non autorisé",
    "msg_internal_error": "Erreur interne du serveur",
    "msg_try_later_error": "Quelque chose s'est mal passé. Veuillez réessayer.",
    "lbl_info_diplayed": "Informations affichées dans l'application mobile",
    "lbl_compnyName": "Nom de l'entreprise",
    "lbl_officeNo": "Numéro de téléphone du bureau",
    "lbl_outOfHrs": "Numéro de téléphone en dehors des heures d'ouverture",
    "lbl_contactEmail": "Email du contact",
    "lbl_logo": "Logo",
    "btn_uploadLogo": "Télécharger le logo",
    "btn_dragLogo": "ou faites glisser le logo ici",
    "msg_endUsrInfo_edited": "Les informations sur l'utilisateur final ont bien été modifiées",
    "btn_remove": "Retirer",
    "lbl_userType": "Type d'utilisateur",
    "lbl_login": "Connexion",
    "lbl_email_notification": "Notification par e-mail",
    "lbl_delete_accntUsr": "Supprimer l'utilisateur du compte",
    "msg_deleteUsr": "Voulez-vous vraiment supprimer l'utilisateur ?",
    "msg_deleteUsr_error": "Erreur lors de la suppression de l'utilisateur du compte",
    "msg_accntUsr_deleted": "L'utilisateur du compte a bien été supprimé",
    "lbl_firstName": "Prénom",
    "lbl_lastName": "Nom de famille",
    "lbl_typeUsr": "Tapez l'utilisateur",
    "lbl_emailNotifications": "Notifications par email",
    "lbl_userPemissions": "Autorisations utilisateur",
    "msg_userEdited": "Utilisateur modifié avec succès",
    "lbl_unauthorizedUser": "Utilisateur non autorisé",
    "lbl_someError": "Une erreur s'est produite",
    "lbl_pwd": "Mot de passe",
    "lbl_repeat_pwd": "Répéter le mot de passe",
    "lbl_engineer": "Ingénieure",
    "lbl_manager": "Directrice",
    "lbl_installer": "Installatrice",
    "lbl_officeStaff": "Le personnel de bureau",
    "msg_noMatch_pwd": "Mots de passe inégalés !",
    "msg_usr_added": "Utilisateur ajouté avec succès",
    "lbl_panel": "Panneau",
    "lbl_accnt_holder": "Titulaire de compte",
    "lbl_spoken_pwd": "Mot de passe parlé",
    "lbl_language": "Langue",
    "lbl_cmpnyInfo": "Informations sur la société",
    "lbl_firstPh": "Premier téléphone",
    "lbl_secondPh": "Deuxième téléphone",
    "lbl_thirdPh": "Troisième téléphone",
    "lbl_oldPwd": "ancien mot de passe",
    "lbl_newPwd": "nouveau mot de passe",
    "lbl_repeatPwd": "Répéter le mot de passe",
    "msg_accntHolder_edited": "Titulaire du compte modifié avec succès",
    "lbl_firstPhType": "Premier type de téléphone",
    "lbl_firstPhone": "Premier numéro de téléphone",
    "lbl_secondPhone": "Deuxième numéro de téléphone",
    "lbl_thirdPhone": "Troisième numéro de téléphone",
    "lbl_secondPhType": "Deuxième type de téléphone",
    "lbl_thirdPhType": "Troisième type de téléphone",
    "lbl_home": "Maison",
    "lbl_mobile": "Mobile",
    "lbl_office": "Bureau",
    "msg_cmpnyInfo_edited": "Informations sur l'entreprise modifiées avec succès",
    "lbl_termsConditions": "Termes et conditions",
    "lbl_privacyPolicy": "politique de confidentialité",
    "msg_email_invalid": "Le courriel est invalide",
    "msg_unmatchedPwd": "Mots de passe inégalés !",
    "lbl_installerGrp": "Groupe d'installateurs",
    "msg_usrExist": "L'utilisateur avec le login existe déjà",
    "lbl_editUsr": "Modifier l'utilisateur",
    "msg_field_required": "Ce champ est obligatoire",
    "lbl_disable_ARC": "Désactiver l'accès ARC",
    "msg_disable_ARC": "Voulez-vous vraiment désactiver l'accès ARC ?",
    "lbl_SiaEvnts": "Événements Sia",
    "lbl_SIAEvnt": "Événement SIA",
    "msg_prohibited_chars": "l'entrée contient des caractères interdits",
    "msg_inputLength": "la longueur d'entrée ne doit pas dépasser 30 caractères",
    "lbl_favPanels": "PANNEAUX FAVORIS",
    "lbl_lastConnection": "Dernière connexion",
    "lbl_androidPh": "Téléphone Android",
    "lbl_iPhone": "Mon iPhone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Accueil Google",
    "lbl_removePairing": "Supprimer l'association",
    "msg_removePairing": "Voulez-vous vraiment supprimer l'association pour cet utilisateur de l'application ?",
    "lbl_no": "Non",
    "lbl_yes": "Oui",
    "lbl_device": "Appareil",
    "lbl_appDetails": "Détails de la demande",
    "lbl_myAndroid": "Mon Android",
    "lbl_myAlexa": "Mon Alexa",
    "lbl_myiPhone": "Mon iPhone",
    "lbl_mygHome": "Mon Google Home",
    "lbl_appID": "ID d'application",
    "lbl_mobOS": "Système d'exploitation mobile",
    "lbl_token": "Jeton",
    "msg_didNotMovePanl": "N'a déplacé aucun panneau",
    "lbl_panlsSelected": "Panneaux sélectionnés",
    "lbl_panlSelected": "Panneau sélectionné",
    "lbl_ARCCode": "Code ARC",
    "lbl_response": "Réponse",
    "lbl_data": "Données",
    "lbl_state": "État",
    "lbl_signal_Timeout": "Délai d'expiration du signal",
    "lbl_sysID": "ID système",
    "lbl_date": "Date",
    "lbl_remove_destination": "Destinations de messagerie de maintenance à distance",
    "lbl_emailAddress": "Adresse e-mail",
    "Reports": "Rapports",
    "lbl_green": "VERTE",
    "lbl_amber": "AMBRE",
    "lbl_red": "ROUGE",
    "lbl_reportDetails": "Détails du rapport",
    "lbl_dueDate": "Date d'échéance",
    "btn_edit": "modifier",
    "lbl_nameAZ": "Nom (A-Z)",
    "lbl_nameZA": "Nom (Z-A)",
    "lbl_mostAlarms": "La plupart des alarmes",
    "lbl_fewerAlarms": "Moins d'alarmes",
    "lbl_updatedRecent": "Mis à jour récemment",
    "lbl_none": "Aucune",
    "msg_confirmation": "Êtes-vous sûr de vouloir",
    "msg_enable": "activer",
    "msg_disable": "désactiver",
    "msg_edited": "modifié avec succès",
    "btn_addEmail": "Ajouter une destination d'e-mail",
    "lbl_emailDestinationAdd": "ajouter une destination e-mail afin que les rapports de service à distance puissent être envoyés par e-mail",
    "lbl_termsToUseWebsite": "Les conditions dans lesquelles vous pouvez utiliser notre site Web",
    "lbl_termsToUseWebsite_1.1": "Ces conditions d'utilisation (ainsi que les autres documents mentionnés ci-dessous) définissent les conditions dans lesquelles vous pouvez utiliser notre site Web.",
    "lbl_termsToUseWebsite_1.1part": "Nous vous recommandons d'imprimer une copie de ces conditions d'utilisation pour référence future.",
    "lbl_termsToUseWebsite_1.2": "Vous devez lire attentivement ces conditions d'utilisation avant d'utiliser notre site Web, car en utilisant notre site Web, vous confirmez que vous acceptez ces conditions d'utilisation et que vous vous y conformerez.",
    "lbl_termsToUseWebsite_1.3": "Si vous n'acceptez pas ces conditions d'utilisation, vous devez quitter notre site Web immédiatement",
    "lbl_termsToUseWebsite_1.4": "La politique suivante s'applique également à votre utilisation de notre site Web",
    "lbl_termsToUseWebsite_1.4.1": "Notre politique de confidentialité et de cookies",
    "lbl_termsToUseWebsite_1.4.1part": "En utilisant notre site Web, vous consentez à ce que nous traitions toutes les données personnelles que vous nous fournissez ou que nous recueillons auprès de vous à la suite de votre utilisation de notre site Web. Notre politique de confidentialité définit ce que nous faisons avec ces données personnelles",
    "lbl_termsToUseWebsite_1.5": "Vous êtes responsable de vous assurer que toute autre personne accédant à notre site Web via votre connexion Internet est au courant des présentes conditions d'utilisation et des politiques mentionnées ci-dessus et qu'elle les respecte.",
    "lbl_chngesToTerms": "Modifications de ces conditions d'utilisation et de nos autres politiques",
    "lbl_chngesToTerms_2.1": "Nous apportons des modifications à ces conditions d'utilisation de temps à autre. Vous devez donc vérifier ces conditions d'utilisation chaque fois que vous revenez sur notre site Web pour voir si des modifications ont été apportées, car elles vous engagent.",
    "lbl_chngesToTerms_2.2": "Nous apportons également des modifications à notre politique de confidentialité et à notre politique en matière de cookies de temps à autre, vous devez donc également les vérifier régulièrement pour voir si des modifications ont été apportées.",
    "lbl_aboutUs": "À propos de nous",
    "lbl_aboutUs_3.1": "Nous, Eaton Electrical Products Ltd, exploitons ce site Web à l'adresse www.eatonsecureconnect.com. Nous sommes une société enregistrée en Angleterre et au Pays de Galles sous le numéro 01342230 et notre siège social est situé au 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "Vous pouvez nous contacter",
    "lbl_aboutUs_3.2part": "Με email στο",
    "lbl_restrictionsOnUseOfWebsite": "Restrictions sur votre utilisation de notre site Web",
    "lbl_restrictionsOnUseOfWebsite_4.1": "Vous ne pouvez utiliser notre site Web qu'à des fins licites et conformément à toutes les lois applicables, y compris, sans s'y limiter, les lois sur la protection des données et la vie privée, les lois relatives au droit d'auteur du contenu et les lois relatives aux messages électroniques commerciaux non sollicités.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "De plus, vous ne devez pas",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "utiliser notre site Web pour envoyer ou transmettre, directement ou indirectement, du matériel publicitaire ou promotionnel non sollicité ou non autorisé, des chaînes de lettres ou des systèmes de vente pyramidale ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "utiliser notre site Web pour bombarder ou inonder de courrier ou pour tenter délibérément de surcharger un système ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "utiliser notre site Web pour publier du contenu ou créer un lien vers du contenu lorsque vous savez ou auriez dû savoir que le propriétaire de ce contenu n'a pas consenti ou autorisé cette publication ou ce lien ou lorsque cette publication ou ce lien enfreint les droits de ce propriétaire ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "introduire sciemment ou imprudemment sur notre site Web, ou utiliser notre site Web pour transmettre, des virus, des chevaux de Troie, des vers, des bombes logiques, des bombes à retardement, des enregistreurs de frappe, des logiciels espions, des logiciels malveillants, des logiciels publicitaires ou d'autres éléments malveillants ou nuisibles ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "tenter d'obtenir un accès non autorisé à notre site Web, au serveur, à l'équipement ou au réseau sur lequel notre site Web est stocké, à tout serveur, ordinateur ou base de données connecté à notre site Web ou à tout logiciel ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "utiliser notre site Web pour tenter d'obtenir un accès non autorisé à tout autre site Web, compte Internet, serveur, ordinateur, équipement, système, réseau, données ou informations ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "utiliser notre site Web pour surveiller les données ou le trafic sur tout réseau ou système ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "utiliser notre site Web pour collecter ou utiliser des informations, y compris, sans s'y limiter, des adresses e-mail, des pseudonymes ou d'autres identifiants, par tromperie (comme le phishing, l'escroquerie sur Internet, le vol de mot de passe, le spidering, le scraping et la récolte) ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "utiliser notre site Web pour distribuer des logiciels ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "mener une attaque par déni de service ou une attaque par déni de service distribué sur notre site Web ou utiliser notre site Web pour effectuer une telle attaque sur tout autre site Web ;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "utilisez notre site Web :",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "à toute fin qui; ou",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "envoyer, transmettre, publier, charger, télécharger, utiliser, stocker, reproduire ou recevoir sciemment tout matériel qui ne respecte pas les normes de contenu énoncées dans",
    "lbl_para8": "paragraphe 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "utiliser notre site Web pour soigner, nuire ou profiter de mineurs de quelque manière que ce soit ou pour tenter de le faire ; ou",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "utiliser notre site Web en violation des lois sur l'exportation, des contrôles, des réglementations ou des politiques de sanction des États-Unis ou de tout autre pays.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "Si vous enfreignez l'une des conditions de ces conditions d'utilisation, votre droit d'utiliser notre site Web prendra immédiatement fin. En outre, nous pouvons prendre d'autres mesures, y compris, sans s'y limiter, des poursuites judiciaires que nous jugeons appropriées.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "Si vous enfreignez l'une des conditions des présentes conditions d'utilisation, ou si nous recevons une demande d'une autorité chargée de l'application de la loi, nous pouvons signaler votre identité, les détails de toute violation et toute autre information que nous jugeons nécessaire aux autorités chargées de l'application de la loi.",
    "lbl_pwdNSecRequirement": "Mots de passe et exigences de sécurité",
    "lbl_pwdNSecRequirement_5.1": "Si vous enregistrez un compte sur notre site Web, vous devez fournir des informations d'enregistrement exactes et complètes et maintenir ces informations à jour à tout moment.",
    "lbl_pwdNSecRequirement_5.2": "L'utilisation de certaines parties de ce site Web est protégée par un mot de passe. Pour l'utiliser, vous aurez besoin d'un code d'identification d'utilisateur ou d'un mot de passe pour y accéder. Il est de votre responsabilité de garder ces informations secrètes et confidentielles et de ne les divulguer à aucune autre personne.",
    "lbl_pwdNSecRequirement_5.3": "Si vous pensez que quelqu'un d'autre pourrait connaître votre code d'identification d'utilisateur ou votre mot de passe, vous devez vous connecter à votre compte et les modifier dès que possible.",
    "lbl_pwdNSecRequirement_5.4": "Nous nous réservons le droit de désactiver tout code d'identification d'utilisateur ou mot de passe à tout moment si, à notre avis raisonnable, vous ne respectez pas l'une de ces conditions d'utilisation, si nous considérons que vous utilisez notre site Web pour télécharger du contenu ou enfreindre tout manière les droits d'une autre personne ou si nous considérons qu'il peut y avoir un risque pour la sécurité.",
    "lbl_webSiteNContent": "Notre site Web et son contenu",
    "lbl_webSiteNContent_6.1": "Nous ne garantissons pas que l'accès à notre site Web ou à son contenu (y compris tout contenu que vous téléchargez sur notre site Web) sera toujours disponible ou ininterrompu. Votre droit d'accès à notre site Web est accordé à titre temporaire uniquement.",
    "lbl_webSiteNContent_6.2": "Nous nous réservons le droit de modifier notre site Web et son contenu, y compris, sans s'y limiter, en ajoutant ou en supprimant du contenu ou des fonctionnalités, à tout moment et sans préavis.",
    "lbl_webSiteNContent_6.3": "Nous ne pouvons pas garantir que tout le contenu de notre site Web est exact, complet ou à jour. Nous ne donnons donc aucune garantie, représentation, engagement ou garantie que le contenu de notre site Web est exact, complet ou à jour.",
    "lbl_webSiteNContent_6.4": "Le contenu de notre site Web est fourni à des fins d'information générale uniquement. Rien sur notre site Web n'est destiné à être un conseil sur lequel vous devriez vous fier. Vous devez toujours obtenir les conseils d'un spécialiste approprié avant d'entreprendre ou de ne pas entreprendre d'action sur la base de tout contenu de notre site Web.",
    "lbl_webSiteNContent_6.5": "Nous ne donnons aucune garantie, représentation, engagement ou garantie que notre site Web est sécurisé ou exempt de bogues ou de virus. Vous êtes responsable de vous assurer que l'appareil à partir duquel vous accédez à notre site Web dispose d'un logiciel antivirus approprié.",
    "lbl_contentUploaded": "Contenu téléchargé sur notre site Web par vous ou par d'autres utilisateurs",
    "lbl_contentUploaded_7.1": "Si vous téléchargez du contenu sur notre site Web, vous devez vous conformer aux normes de contenu énoncées dans",
    "lbl_contentUploaded_7.1part": "à l'égard de ce contenu",
    "lbl_contentUploaded_7.2": "Vous nous garantissez que vous possédez tout contenu que vous téléchargez sur notre site Web ou, si vous n'êtes pas le propriétaire, que vous avez le droit de le télécharger.",
    "lbl_contentUploaded_7.3": "Vous continuerez à posséder tout contenu que vous téléchargez sur notre site Web, mais tant que ce contenu reste sur notre site Web, vous nous accordez une licence mondiale pour utiliser, stocker, copier, modifier, exécuter, distribuer, afficher et rendre disponible ce contenu et permettre aux autres utilisateurs de notre site Web de l'utiliser, de le télécharger, de l'imprimer et de le reproduire conformément à nos conditions d'utilisation.",
    "lbl_contentUploaded_7.4": "Vous nous indemniserez pour toutes pertes, responsabilités, coûts, dépenses, réclamations ou procédures que nous subissons ou encourons, ou subirons ou encourrons, à la suite de ou en relation avec toute violation par vous de vos obligations ou garanties dans ce",
    "lbl_para7": "paragraphe 7",
    "lbl_contentUploaded_7.5": "Nous ne sommes pas responsables et ne donnons aucune garantie, représentation, engagement ou garantie à l'égard de tout contenu sur notre site Web qui est téléchargé par un utilisateur. Toute opinion exprimée par un utilisateur de notre site Web est son opinion et non notre opinion ni celle de nos dirigeants ou employés.",
    "lbl_contentUploaded_7.6": "Nous ne sommes pas responsables du stockage ou de la sauvegarde de tout contenu téléchargé sur notre site Web, et ne serons pas responsables de toute perte, corruption ou destruction de ce contenu.",
    "lbl_contentUploaded_7.7": "Nous vous recommandons de sauvegarder tout contenu que vous téléchargez sur notre site Web.",
    "lbl_contentUploaded_7.8": "Nous nous réservons le droit de supprimer à tout moment tout contenu que vous avez téléchargé sur notre site Web.",
    "lbl_contentUploaded_7.9": "Nous nous réservons également le droit de divulguer votre identité à toute personne qui prétend que tout contenu que vous avez téléchargé sur notre site Web enfreint ses droits de propriété intellectuelle ou son droit à la vie privée ou est diffamatoire.",
    "lbl_contentStd": "Normes de contenu",
    "lbl_contentStd_8.1": "N'oubliez pas que tout contenu que vous téléchargez sur notre site Web peut être vu par d'autres utilisateurs, alors réfléchissez toujours attentivement avant de soumettre un contenu.",
    "lbl_contentStd_8.2": "Tout contenu que vous téléchargez sur notre site Web doit :",
    "lbl_contentStd_8.2.1": "respecter toutes les lois applicables ;",
    "lbl_contentStd_8.2.2": "être précis (lorsqu'il énonce des faits); et",
    "lbl_contentStd_8.2.3": "être véritablement tenu (lorsqu'il énonce des opinions).",
    "lbl_contentStd_8.3": "Vous ne devez pas télécharger sur notre site Web tout contenu qui :",
    "lbl_contentStd_8.3.1": "est diffamatoire, obscène, offensant, indécent, malveillant, haineux ou incendiaire ;",
    "lbl_contentStd_8.3.2": "est pornographique ou sexuellement explicite",
    "lbl_contentStd_8.3.3": "exploite des mineurs;",
    "lbl_contentStd_8.3.4": "encourage la violence ou le terrorisme ;",
    "lbl_contentStd_8.3.5": "est discriminatoire sur la base de la race, du sexe, de la religion, de la nationalité, du handicap, de l'orientation sexuelle ou de l'âge ;",
    "lbl_contentStd_8.3.6": "enfreint tout droit d'auteur, droit de base de données, marque de commerce ou autre droit de propriété intellectuelle de toute autre personne ;",
    "lbl_contentStd_8.3.7": "sollicite ou tente de solliciter des mots de passe ou des informations d'identification personnelle à des fins commerciales ou illégales auprès d'autres utilisateurs ;",
    "lbl_contentStd_8.3.8": "est frauduleux ou susceptible de tromper quiconque ;",
    "lbl_contentStd_8.3.9": "est faite en violation de toute obligation légale envers quelqu'un d'autre, telle qu'une obligation contractuelle ou une obligation de confidentialité ;",
    "lbl_contentStd_8.3.10": "crée un risque pour la santé ou la sécurité d'une personne ou la santé ou la sécurité publique ;",
    "lbl_contentStd_8.3.11": "promeut ou constitue une activité illégale ou illicite ;",
    "lbl_contentStd_8.3.12": "compromet la sécurité nationale ;",
    "lbl_contentStd_8.3.13": "interfère avec une enquête menée par une autorité chargée de l'application de la loi ou de réglementation ;",
    "lbl_contentStd_8.3.14": "est menaçant ou abusif ou envahit la vie privée d'une autre personne ou cause de la gêne, des inconvénients ou une anxiété inutile ;",
    "lbl_contentStd_8.3.15": "est susceptible de harceler, de contrarier, d'embarrasser, d'alarmer ou d'importuner toute autre personne ;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "donne l'impression qu'il vient de nous ;",
    "lbl_contentStd_8.3.18": "constitue du matériel publicitaire ; ou",
    "lbl_contentStd_8.3.19": "préconise, promeut ou assiste toute activité illégale, y compris, mais sans s'y limiter, la violation du droit d'auteur ou l'utilisation abusive de l'ordinateur.",
    "lbl_IntellctlProprty": "Droits de propriété intellectuelle",
    "lbl_IntellctlProprty_9.1": "Nous, ou nos concédants de licence, possédons le droit d'auteur et tous les autres droits de propriété intellectuelle sur notre site Web et tout son contenu autre que le contenu téléchargé par ou lié par d'autres.",
    "lbl_IntellctlProprty_9.2": "Vous pouvez télécharger le contenu que nous possédons à partir de notre site Web et/ou imprimer un nombre raisonnable de copies de toute page de notre site Web. Vous ne devez apporter aucune modification au contenu que vous avez téléchargé ou imprimé à partir de notre site Web. Si vous reproduisez le contenu téléchargé ou imprimé sur n'importe quel support, vous devez vous assurer que vous le reproduisez de manière exacte et complète et inclure une déclaration reconnaissant qu'il provient de notre site Web.",
    "lbl_IntellctlProprty_9.3": "Si vous utilisez, téléchargez, imprimez ou reproduisez le contenu de notre site Web en violation des présentes conditions d'utilisation, votre droit d'utiliser notre site Web prendra immédiatement fin et vous devrez nous retourner ou détruire (à notre choix) toutes les copies numériques et papier du contenu. que vous avez fait.",
    "lbl_linksToOtherWebsite": "Liens de notre site Web vers d'autres sites Web",
    "lbl_linksToOtherWebsite_text": "Notre site Web peut contenir des liens vers d'autres sites Web de temps à autre. Ces liens sont fournis pour votre commodité. Nous n'avons aucun contrôle sur ces autres sites Web ou leur contenu, et aucune responsabilité ou obligation envers vous, et toute utilisation ou accès de votre part à ces sites Web sera soumis aux conditions d'utilisation pertinentes de ces sites Web et non aux présentes conditions. utile.",
    "lbl_linksFromOtherWebsite": "Liens d'autres sites Web vers notre site Web",
    "lbl_linksFromOtherWebsite_11.1": "Vous pouvez créer un lien vers notre site Web à partir d'un autre site Web, mais uniquement si vous respectez les conditions suivantes :",
    "lbl_linksFromOtherWebsite_11.1.1": "vous devez nous informer de votre intention de créer un lien vers notre site Web à partir d'un autre site Web et obtenir notre approbation écrite avant de créer un tel lien ;",
    "lbl_linksFromOtherWebsite_11.1.2": "vous pouvez uniquement créer un lien vers la page d'accueil de notre site Web. Vous ne devez pas créer de lien vers une autre page de notre site Web, ni encadrer une page de notre site Web ;",
    "lbl_linksFromOtherWebsite_11.1.3": "vous ne pouvez créer un lien vers notre site Web qu'à partir d'un site Web qui vous appartient et qui est conforme aux normes énoncées dans",
    "lbl_linksFromOtherWebsite_11.1.4": "la création du lien n'introduira pas sur notre site Web, ou n'utilisera pas notre site Web pour transmettre, des virus, des chevaux de Troie, des vers, des bombes logiques, des bombes à retardement, des enregistreurs de frappe, des logiciels espions, des logiciels malveillants, des logiciels publicitaires ou d'autres éléments malveillants ou nuisibles ;",
    "lbl_linksFromOtherWebsite_11.1.5": "vous vous conformerez aux exigences et/ou politiques dont nous pouvons vous informer de temps à autre en ce qui concerne tout lien vers notre site Web à partir d'un autre site Web ; et",
    "lbl_linksFromOtherWebsite_11.1.6": "vous ne devez pas suggérer que nous sommes associés à vous ou que nous approuvons ou appuyons votre site Web ou l'un de vos produits ou services.",
    "lbl_linksFromOtherWebsite_11.2": "Nous nous réservons le droit de vous demander de supprimer tout lien vers notre site Web à tout moment et sans préavis.",
    "lbl_ourLiability": "Notre responsabilité envers vous",
    "lbl_ourLiability_12.1": "Rien dans ces conditions d'utilisation ne limite ou n'exclut notre responsabilité envers vous pour :",
    "lbl_ourLiability_12.1.1": "décès ou blessures résultant d'une négligence ;",
    "lbl_ourLiability_12.1.2": "fraude ou fausse déclaration frauduleuse ; ou",
    "lbl_ourLiability_12.1.3": "toute autre question pour laquelle nous ne sommes pas autorisés par la loi à exclure ou limiter notre responsabilité.",
    "lbl_ourLiability_12.2": "Étant donné que ces conditions d'utilisation régissent votre accès et votre utilisation de notre site Web, toutes les garanties, conditions et autres termes relatifs à notre site Web et / ou à son contenu qui seraient autrement implicites par la loi (que ce soit par la loi, la common law ou autrement) sont exclus.",
    "lbl_ourLiability_12.3": "Nous ne serons pas responsables envers vous de toute perte ou dommage résultant de ou en relation avec votre utilisation (ou votre incapacité à utiliser) notre site Web ou votre utilisation ou votre confiance dans tout contenu de notre site Web, que ce soit dans le cadre d'un contrat, d'un délit (y compris sans limitation de négligence), fausse déclaration, restitution, en vertu de la loi ou autrement.",
    "lbl_ourLiability_13": "Loi applicable",
    "lbl_ourLiability_13.1": "Si vous êtes un consommateur, ces Conditions d'utilisation sont régies par la loi d'Angleterre et du Pays de Galles et les tribunaux d'Angleterre et du Pays de Galles ont une compétence non exclusive pour statuer sur tout litige découlant de ou en relation avec celles-ci. Si vous résidez en Écosse, vous pouvez également engager une procédure en Écosse et si vous résidez en Irlande du Nord, vous pouvez également engager une procédure en Irlande du Nord. Les résidents d'autres pays dans lesquels le site Web est commercialisé sont soumis à la loi d'Angleterre et du Pays de Galles",
    "lbl_ourLiability_13.2": "Si vous êtes un utilisateur professionnel, les présentes Conditions d'utilisation (et toutes les obligations non contractuelles qui en découlent ou qui s'y rapportent) sont régies par la loi d'Angleterre et du Pays de Galles et les tribunaux d'Angleterre et du Pays de Galles sont seuls compétents pour trancher tout litige découlant en dehors de ou en relation avec eux (y compris, sans s'y limiter, en ce qui concerne toute obligation non contractuelle). Les résidents d'autres pays dans lesquels le site Web est commercialisé sont soumis à la loi d'Angleterre et du Pays de Galles",
    "lbl_privPolicy": "politique de confidentialité",
    "lbl_privPolicy_text": "Le site Web Eaton SecureConnect est un site Web et un portail exploités par Eaton Electrical Products Limited, une société enregistrée auprès de Companies House sous le numéro d'enregistrement 01342230, dont l'adresse enregistrée est Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "nous",
    "lbl_privPolicy_textUs": "nous",
    "lbl_privPolicy_textOur": "notre",
    "lbl_privPolicy_text2": "est le responsable du traitement des données personnelles que vous nous fournissez via ce site Web.",
    "lbl_privPolicy_text3": "Cet avis (ainsi que nos conditions d'utilisation :",
    "lbl_privPolicy_text4": "et tout autre document auquel il est fait référence) définit la base sur laquelle toutes les données personnelles que nous recueillons auprès de vous, ou que vous nous fournissez, via ce site Web seront traitées par nous. Veuillez lire attentivement ce qui suit pour comprendre nos pratiques concernant vos données personnelles et comment nous les traiterons.",
    "lbl_privPolicy_text5": "Si vous avez des questions concernant cet avis ou si vous n'êtes pas d'accord avec celui-ci, veuillez nous contacter à l'adresse dataprotection@eaton.com avant d'utiliser ce site Web.",
    "lbl_privPolicy_text6": "Nous pouvons modifier cet avis de temps à autre en mettant à jour cette page. Vous devriez vérifier cette page de temps en temps pour vous assurer que vous êtes satisfait de tout changement. Cet avis est effectif depuis le 20 décembre 2019.",
    "lbl_infoWeCollect": "Quelles informations collectons-nous?",
    "lbl_infoWeCollect_text": "Vous pourrez accéder au site sans nous donner aucune information. Cependant, la plupart des services fournis via le site Web (tels que ceux disponibles via le portail) ne seront disponibles que si nous traitons certaines informations vous concernant.",
    "lbl_infoWeCollect_text1": "Nous pouvons collecter les informations suivantes :",
    "lbl_infoWeCollect_text2": "votre nom et vos coordonnées (y compris votre adresse, votre adresse e-mail et vos numéros de téléphone) et les coordonnées de votre entreprise ;",
    "lbl_infoWeCollect_text3": "les informations d'enregistrement de votre compte si vous devenez un utilisateur enregistré du site Web (y compris vos identifiants de connexion et votre mot de passe) ;",
    "lbl_infoWeCollect_text4": "le pays à partir duquel vous accédez au site Web ;",
    "lbl_infoWeCollect_text5": "des informations concernant vos intérêts personnels ou professionnels, vos données démographiques, vos expériences avec nos produits et vos préférences de contact afin de vous fournir une correspondance supplémentaire sur nos produits et services ;",
    "lbl_infoWeCollect_text6": "informations concernant vos clients (y compris leurs noms, adresses, produits qu'ils ont achetés et systèmes installés dans leurs locaux, informations concernant leur état d'alarme (comme si elle est activée ou non et/ou fonctionne correctement), images CCTV, et si elles sont un utilisateur de l'application mobile Eaton SecureConnect) ;",
    "lbl_infoWeCollect_text7": "les enregistrements de tout contact entre vous et nous ; et",
    "lbl_infoWeCollect_text8": "toute information que vous nous fournissez volontairement ou que vous saisissez via le site Web et/ou la ligne d'assistance.",
    "lbl_whatWeDowithInfo": "Que faisons-nous des informations que nous collectons ?",
    "lbl_whatWeDowithInfo_text": "Nous utilisons ces informations pour vous fournir nos produits et services, et notamment pour les raisons suivantes",
    "lbl_whatWeDowithInfo_text1": "pour nous permettre de vous fournir une assistance et une assistance technique concernant les produits que vous avez achetés chez nous ;",
    "lbl_whatWeDowithInfo_text2": "pour vous informer des périodes d'indisponibilité liées à la maintenance du portail et des autres services qui vous sont fournis ;",
    "lbl_whatWeDowithInfo_text3": "tenue de dossiers internes; et",
    "lbl_whatWeDowithInfo_text4": "pour améliorer nos produits et services.",
    "lbl_whatWeDowithInfo_text5": "Nous pouvons vous contacter par email, téléphone ou courrier",
    "lbl_whatWeDowithInfo_text6": "Nous traitons ces données personnelles dans le but de remplir nos obligations et d'exercer nos droits dans le cadre de nos contrats avec vous et pour nos intérêts légitimes qui incluent la fourniture de produits, services et solutions pour nos clients et les installateurs accrédités Eaton, conformément à nos obligations légales, contractuelles et morales, en augmentant l'efficacité de nos opérations et en protégeant notre entreprise",
    "lbl_infoShareWith": "Avec qui partageons-nous ces informations ?",
    "lbl_infoShareWith_text": "Sauf indication contraire ci-dessous, nous ne transférerons, ne divulguerons, ne vendrons, ne distribuerons ni ne louerons vos informations personnelles à des tiers autres que nos filiales et/ou notre société mère, sauf si nous avons votre permission ou si la loi l'exige.",
    "lbl_infoShareWith_text1": "Parfois (et avec votre approbation si nécessaire), nous partagerons vos données personnelles avec des tiers soigneusement sélectionnés en dehors de notre groupe d'entreprises. Nous pouvons le faire pour les raisons suivantes :",
    "lbl_infoShareWith_text2": "pour permettre à ces tiers de nous fournir des services, y compris notre fournisseur d'hébergement de sites Web soigneusement sélectionné ;",
    "lbl_infoShareWith_text3": "pour permettre à votre employeur, mandant et/ou sous-traitant de visualiser les détails contenus dans le portail qui concernent son activité d'installateur et/ou les produits qu'il a achetés ;",
    "lbl_infoShareWith_text4": "en réponse à des demandes légitimes des autorités publiques, y compris pour répondre à des exigences de sécurité nationale ou d'application de la loi ;",
    "lbl_infoShareWith_text5": "lorsque nous pensons qu'il est nécessaire de se conformer à la loi ou de protéger nos droits, notre propriété ou notre sécurité ou ceux d'une autre personne ; et/ou",
    "lbl_infoShareWith_text6": "s'il y a (ou doit y avoir) un changement de propriété de notre entreprise ou de nos actifs, nous pourrions souhaiter partager vos informations afin que les nouveaux propriétaires (potentiels) puissent continuer à exploiter efficacement notre entreprise et continuer à fournir des services aux clients. Cela peut inclure de nouveaux actionnaires ou toute organisation qui pourrait accepter une cession ou un transfert de tout accord que nous avons conclu avec nos clients.",
    "lbl_infoShareWith_text7": "Ce processus peut inclure l'envoi de données personnelles vers d'autres pays de l'Espace économique européen. Si, pour une raison quelconque, nous transférons vos données personnelles en dehors de l'Espace économique européen, nous mettrons en œuvre des mesures de protection pour garantir les niveaux de protection appropriés pour toutes ces données personnelles et nous mettrons à votre disposition ou vous fournirons un moyen d'obtenir une copie de de telles garanties.",
    "lbl_howLongStoreData": "Combien de temps conserverons-nous vos données personnelles ?",
    "lbl_howLongStoreData_text": "La durée pendant laquelle nous conservons vos informations dépend de la base sur laquelle elles ont été fournies, mais généralement :",
    "lbl_howLongStoreData_text1": "nous conserverons les informations nécessaires pour nous permettre de vous fournir un service que vous avez demandé via ou en relation avec ce site Web aussi longtemps qu'il nous faudra pour fournir ce service ;",
    "lbl_howLongStoreData_text2": "nous conserverons généralement des enregistrements de toutes les transactions que vous concluez avec nous pendant au moins six ans à compter de la fin de la relation. Cela nous permet de résoudre les problèmes et de répondre à toute plainte ou litige survenant au cours de cette période. Sinon, nous conserverons les informations le cas échéant pour des besoins commerciaux légitimes ;",
    "lbl_howLongStoreData_text3": "nous conserverons d'autres informations vous concernant si cela nous est nécessaire pour nous conformer à la loi ou, le cas échéant, pour des besoins commerciaux légitimes.",
    "lbl_sensativeInfo": "Information sensible",
    "lbl_sensativeInfo_text": "Les informations vous concernant qui sont considérées comme sensibles ou une catégorie spéciale de données personnelles en vertu des lois sur la protection des données peuvent inclure des informations sur votre état de santé, votre origine raciale ou ethnique, vos opinions politiques, votre appartenance à un syndicat, vos convictions religieuses ou philosophiques, vos données génétiques, vos données biométriques , la vie sexuelle et l'orientation sexuelle, ainsi que les activités criminelles suspectées ou avérées et les procédures connexes. Si nous devons traiter des données personnelles sensibles, vous serez informé de ce traitement et invité à accepter spécifiquement l'utilisation de ces informations, le cas échéant.",
    "lbl_security": "Sécurité",
    "lbl_security_text": "Nous nous engageons à assurer la sécurité de vos informations. Afin d'empêcher tout accès ou divulgation non autorisés, nous avons mis en place des procédures physiques, électroniques et de gestion pour sauvegarder et sécuriser les informations que nous recueillons en ligne.",
    "lbl_security_text1": "Nous nous efforçons de prendre toutes les mesures raisonnables pour protéger vos données personnelles. Cependant, sachez qu'il existe des risques de sécurité inhérents à la fourniture d'informations et aux transactions en ligne sur Internet et nous ne pouvons donc pas garantir la sécurité des données personnelles divulguées en ligne. Nous vous demandons de ne pas nous fournir de données personnelles sensibles en ligne (veuillez consulter la section « Informations sensibles » ci-dessus), sauf si nous vous le demandons spécifiquement.",
    "lbl_cookies": "Biscuits",
    "lbl_cookies_text": "Comme de nombreuses entreprises, nous utilisons des « cookies » et des outils similaires sur notre site Web pour améliorer ses performances et améliorer votre expérience utilisateur.",
    "lbl_cookies_text1": "Que sont les cookies ?",
    "lbl_cookies_text2": "Un cookie est un petit fichier texte qui est placé sur l'appareil que vous utilisez pour accéder au site Web (votre",
    "lbl_cookies_text3": "Les cookies aident à analyser le trafic Web et permettent aux applications Web de vous répondre en tant qu'individu. L'application Web peut adapter ses opérations à vos besoins, à vos goûts et à vos aversions en rassemblant et en mémorisant des informations sur vos préférences. Certains cookies peuvent contenir des données personnelles - par exemple, si vous cliquez sur `se souvenir de moi` lors de la connexion, un cookie peut stocker votre nom d'utilisateur. La plupart des cookies ne collectent pas de données personnelles permettant de vous identifier, mais collectent à la place des informations plus générales, telles que la manière dont les utilisateurs accèdent à notre site Web et l'utilisent, ou l'emplacement général d'un utilisateur.",
    "lbl_cookies_text4": "Pourquoi utilisons-nous les cookies ?",
    "lbl_cookies_text5": "Nous pouvons placer des cookies ou des fichiers similaires sur votre appareil à des fins de sécurité, pour nous dire si vous avez déjà visité le site Web, pour mémoriser vos préférences linguistiques, si vous êtes un nouveau visiteur ou pour faciliter la navigation sur le site et pour personnaliser votre expérience lors de la visite. nos sites Web. Les cookies nous permettent de collecter des informations techniques et de navigation, telles que le type de navigateur, le temps passé sur nos sites Web et les pages visitées. Les cookies peuvent améliorer votre expérience en ligne en enregistrant vos préférences lorsque vous visitez un site Web particulier. Dans l'ensemble, les cookies nous aident à vous fournir un meilleur site Web en nous permettant de surveiller les pages que vous trouvez utiles et celles que vous ne trouvez pas. Un cookie ne nous donne en aucun cas accès à votre Appareil ou à toute information vous concernant, autre que les données que vous choisissez de partager avec nous.",
    "lbl_cookies_text6": "Quels types de cookies utilisons-nous ?",
    "lbl_cookies_text7": "En règle générale, les cookies utilisés sur le site Web peuvent être répartis dans les catégories suivantes :",
    "lbl_cookies_text8": "Cookies de sessions :",
    "lbl_cookies_text9": "Ces cookies sont utilisés « en session » à chaque visite, puis expirent lorsque vous quittez un site Web ou peu de temps après : ils ne sont pas stockés sur votre appareil de manière permanente, ne contiennent aucune donnée personnelle et aident en minimisant le besoin de transférer des données personnelles. sur Internet. Ces cookies peuvent être supprimés ou vous pouvez refuser d'autoriser leur utilisation, mais cela peut entraver les performances et votre expérience d'utilisation des sites Web. Ces cookies prennent également des horodatages qui enregistrent quand vous accédez à un site Web et quand vous quittez un site Web.",
    "lbl_cookies_text10": "Cookies de suivi:",
    "lbl_cookies_text11": "Ces cookies nous permettent de reconnaître les visiteurs réguliers de nos sites Web. En faisant correspondre un identifiant anonyme généré de manière aléatoire, un cookie de suivi garde une trace de la provenance d'un utilisateur de nos sites Web, du moteur de recherche qu'il a pu utiliser, du lien sur lequel il a cliqué, du mot-clé qu'il a utilisé et de l'endroit où il se trouvait dans le monde quand ils ont accédé à un site Web. En surveillant ces données, nous pouvons apporter des améliorations à nos sites Web.",
    "lbl_cookies_text12": "Cookies persistants :",
    "lbl_cookies_text13": "Ce type de cookie est enregistré sur votre Terminal pour une durée déterminée (parfois quelques heures, parfois un an ou plus) et n'est pas supprimé à la fermeture du navigateur. Les cookies persistants sont utilisés lorsque nous devons nous rappeler qui vous êtes pendant plus d'une session de navigation. Par exemple, ce type de cookie peut être utilisé pour stocker vos préférences, afin qu'elles soient mémorisées pour la prochaine visite sur un site Web.",
    "lbl_cookies_text14": "Cookies de performance ou d'analyse :",
    "lbl_cookies_text15": "Les cookies de performance sont utilisés pour analyser la façon dont les sites Web sont utilisés et pour surveiller leurs performances, ce qui nous permet d'améliorer votre expérience d'utilisation des sites Web. Ces cookies nous aident à adapter le contenu des",
    "lbl_cookies_text16": "sites Internet",
    "lbl_cookies_text17": "pour refléter ce que les utilisateurs du site Web trouvent le plus intéressant et pour identifier les problèmes techniques avec les sites Web. Nous pouvons également utiliser ces données pour compiler des rapports afin de nous aider à analyser la façon dont les sites Web sont utilisés, quels sont les problèmes les plus courants et comment nous pouvons améliorer les sites Web.",
    "lbl_cookies_text18": "Les cookies peuvent-ils être bloqués ?",
    "lbl_cookies_text19": "Tu peux choisir d'accepter ou de refuser les cookies. La plupart des navigateurs Web acceptent automatiquement les cookies, mais vous pouvez généralement modifier les paramètres de votre navigateur pour refuser les cookies si vous préférez. Si vous préférez ne pas accepter les cookies, la plupart des navigateurs vous permettront de : (i) modifier les paramètres de votre navigateur pour vous avertir lorsque vous recevez un cookie, ce qui vous permet de choisir de l'accepter ou non ; (ii) pour désactiver les cookies existants ; ou (iii) configurer votre navigateur pour qu'il rejette automatiquement tous les cookies. Cependant, sachez que si vous désactivez ou refusez les cookies, certaines fonctionnalités et certains services de nos sites Web peuvent ne pas fonctionner correctement car nous ne pourrons peut-être pas vous reconnaître et vous associer à votre ou vos comptes. De plus, les offres que nous proposons lorsque vous nous rendez visite peuvent ne pas être aussi pertinentes pour vous ou adaptées à vos intérêts.",
    "lbl_cookies_text20": "Instructions pour bloquer ou autoriser les cookies dans les logiciels de navigation Web populaires Internet Explorer 7 et 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Mise en relation",
    "lbl_linking_text": "Nous pouvons créer des liens vers d'autres sites Web qui ne sont pas sous notre contrôle. Une fois que vous avez quitté notre site Web, nous ne pouvons être tenus responsables de la protection et de la confidentialité des informations que vous fournissez. Vous devez faire preuve de prudence et consulter la déclaration de confidentialité applicable au site Web en question.",
    "lbl_yourRights": "Tes droits",
    "lbl_yourRights_text": "Vous pouvez demander une copie de toute information personnelle que nous détenons à votre sujet. Une somme modique peut être due. Vous pouvez le faire en nous écrivant à dataprotection@eaton.com",
    "lbl_yourRights_text1": "Dans certaines circonstances, vous pouvez être en droit de recevoir vos données personnelles dans un format structuré, couramment utilisé et lisible par machine.",
    "lbl_yourRights_text2": "Si vous pensez que les informations dont nous disposons à votre sujet sont incorrectes ou incomplètes, veuillez nous écrire ou nous envoyer un e-mail dès que possible. Nous corrigerons ou mettrons à jour toute information, le cas échéant, dès que possible.",
    "lbl_yourRights_text3": "Vous avez le droit de nous demander de restreindre notre traitement de vos données personnelles dans certaines circonstances où :",
    "lbl_yourRights_text4": "vous contestez l'exactitude des données personnelles que nous traitons à votre sujet ;",
    "lbl_yourRights_text5": "notre traitement de vos données est illégal ;",
    "lbl_yourRights_text6": "nous n'avons plus besoin des données personnelles aux fins pour lesquelles nous les traitons, mais vous avez besoin des données personnelles pour la constatation, l'exercice ou la défense d'un droit en justice ;",
    "lbl_yourRights_text7": "vous contestez si les motifs légitimes du responsable du traitement prévalent sur ceux de la personne concernée.",
    "lbl_yourRights_text8": "Si vous avez des questions, des commentaires ou des suggestions concernant notre utilisation de vos données personnelles, veuillez nous écrire à dataprotection@eaton.com",
    "lbl_yourRights_text9": "Vous avez également le droit de déposer une plainte auprès du Commissariat à l'information si vous n'êtes pas satisfait du traitement de vos données personnelles.",
    "lbl_noticeToCalfrniaResidents": "Avis aux résidents de Californie",
    "lbl_noticeToCalfrniaResidents_text": "Cette section s'applique aux résidents de Californie.",
    "lbl_noticeToCalfrniaResidents_text1": "Comme décrit dans la section `Quelles informations collectons-nous ?`, nous pouvons collecter des informations personnelles, y compris des identifiants, des informations commerciales, des informations sur Internet ou d'autres informations sur l'activité du réseau, des informations de géolocalisation, des informations professionnelles ou liées à l'emploi et des déductions tirées de l'une des informations identifiées ci-dessus pour créer un profil sur un consommateur.",
    "lbl_noticeToCalfrniaResidents_text2": "Comme décrit dans la section `Avec qui partageons-nous vos informations ?`, les informations personnelles que nous collectons auprès des consommateurs peuvent être partagées à des fins commerciales avec des tiers. Nous pourrions avoir divulgué toutes les catégories d'informations personnelles énumérées ci-dessus, en fonction du cas d'utilisation, à des fins commerciales au cours des 12 derniers mois.",
    "lbl_noticeToCalfrniaResidents_text3": "Nous ne vendons pas vos informations personnelles.",
    "lbl_noticeToCalfrniaResidents_text4": "En tant que résident californien, vous disposez de certains droits, sous réserve des limitations légales et des exceptions applicables, concernant la collecte, l'utilisation et le partage de vos informations personnelles. Ces droits sont notamment :",
    "lbl_noticeToCalfrniaResidents_text5": "Le droit de savoir",
    "lbl_noticeToCalfrniaResidents_text6": "Vous avez le droit de demander des informations sur les catégories d'informations personnelles que nous avons collectées à votre sujet, les catégories de sources à partir desquelles nous avons collecté les informations personnelles, les finalités de la collecte des informations personnelles, les catégories de tiers avec lesquels nous avons partagé vos informations personnelles, et le but pour lequel nous avons partagé vos informations personnelles (`Rapport sur les catégories`). Vous pouvez également demander des informations sur les éléments spécifiques d'informations personnelles que nous avons collectées à votre sujet (`Rapport sur les éléments spécifiques`). Veuillez spécifier la requête que vous souhaitez exécuter.",
    "lbl_noticeToCalfrniaResidents_text7": "Le droit de supprimer",
    "lbl_noticeToCalfrniaResidents_text8": "Vous avez le droit de demander que nous supprimions les informations personnelles que nous avons collectées auprès de vous.",
    "lbl_noticeToCalfrniaResidents_text9": "Le droit de retrait",
    "lbl_noticeToCalfrniaResidents_text10": "Vous avez le droit de vous opposer à la vente de vos informations personnelles.",
    "lbl_noticeToCalfrniaResidents_text11": "Conformément à la loi applicable, nous ne ferons aucune discrimination à votre encontre pour l'exercice de ces droits. Vous pouvez utiliser un agent autorisé pour exercer vos droits en votre nom. Si vous faites l'une des demandes ci-dessus par l'intermédiaire d'un agent autorisé, nous vous demanderons une autorisation écrite et chercherons à vérifier comme décrit ci-dessus ou nous accepterons une procuration légale en vertu du California Probate Code à l'agent autorisé.",
    "lbl_noticeToCalfrniaResidents_text12": "Vous pouvez exercer votre droit à tout moment en nous contactant en envoyant un email à",
    "lbl_noticeToCalfrniaResidents_text13": "en appelant le +1-800-386-1911 ou en utilisant ce",
    "lbl_noticeToCalfrniaResidents_text14": "formulaire en ligne",
    "lbl_noticeToCalfrniaResidents_text15": "Afin d'exercer vos droits, nous aurons besoin d'obtenir des informations pour vous localiser dans nos dossiers ou vérifier votre identité selon la nature de la demande. Si vous soumettez une demande au nom d'un foyer, nous devrons vérifier chaque membre du foyer de la manière indiquée dans cette section. Par conséquent, en fonction de la demande, nous pourrions vous contacter pour plus d'informations.",
    "lbl_noticeToCalfrniaResidents_text16": "Nous répondrons à une demande de retrait dans les 15 jours. Nous répondrons aux demandes de suppression et aux demandes de connaissance dans les 45 jours, sauf si nous avons besoin de plus de temps, auquel cas nous vous en informerons et nous pourrons prendre jusqu'à 90 jours au total pour répondre à votre demande.",
    "lbl_viewSubEstate": "Voir le sous-parc",
    "lbl_installers": "Installateurs",
    "lbl_managedPanels": "Panneaux non gérés",
    "lbl_days": "Jours",
    "lbl_email": "E-mail",
    "lbl_company": "Entreprise",
    "lbl_removeInstaller": "Supprimer le programme d'installation",
    "lbl_confirm": "Confirmer",
    "msg_deleteAdminConfirmation": "Êtes-vous sûr de vouloir désinstaller",
    "lbl_editDetails": "Modifier les détails",
    "msg_adminAdded": "Administrateur modifié avec succès",
    "msg_adminDeleted": "Administrateur supprimé avec succès",
    "msg_errorDeleteAdmin": "Erreur lors de la suppression de l'administrateur",
    "lbl_addAdmin": "Ajouter un administrateur",
    "lbl_info": "Info",
    "lbl_noOfPanels": "Nombre de panneaux",
    "lbl_dateRegistered": "Date d'enregistrement",
    "lbl_cmpnyAddress": "Adresse de la société",
    "lbl_addressDetails": "Détails de l'adresse",
    "txt_addressDetails": "Pour créer un compte d'entreprise, veuillez fournir les coordonnées de votre entreprise.",
    "lbl_contactDetails": "Détails du contact",
    "txt_contactDetails": "Pour créer un compte d'entreprise, veuillez fournir les coordonnées de votre entreprise.",
    "lbl_phoneType": "Type de téléphone",
    "msg_alreadyAccnt": "Il existe déjà un compte pour cet e-mail.",
    "txt_invalidEmail": "email invalide",
    "lbl_createAccnt": "Créer un compte",
    "lbl_createAccntDetails": "Pour créer un compte d'entreprise, saisissez les informations requises ci-dessous.",
    "msg_pwdValidation": "Minimum 8 caractères, au moins 1 lettre majuscule, 1 lettre minuscule, 1 chiffre et 1 caractère spécial",
    "lbl_createPwd": "Créer un mot de passe",
    "txt_pwdLength": "Le mot de passe doit comporter au moins 8 caractères, dont au moins un caractère majuscule, un chiffre et un caractère spécial.",
    "lbl_licenseAgreememnt": "Accord de licence",
    "lbl_licenseAgreememntDetails": "Pour créer un compte d'entreprise, vous devrez lire et accepter les conditions générales.",
    "lbl_accntActivation": "Activation de compte",
    "msg_accntActivation": "Félicitations, vous avez été inscrit avec le login de",
    "lbl_accntActivationEmailSent": "Nous vous avons envoyé un e-mail d'activation. Veuillez cliquer sur le lien dans ce message pour activer votre compte.",
    "lbl_notReceive": "Vous n'avez pas reçu d'e-mail ?",
    "lbl_sendAgain": "Envoyer à nouveau",
    "lbl_cnfrmPwd": "Confirmez le mot de passe",
    "msg_accntExists": "Il existe déjà un compte pour cet e-mail.",
    "lbl_forgotPwd": "Si l'ID utilisateur existe, un lien pour réinitialiser votre mot de passe sera envoyé à",
    "lbl_RmtSvsMsg": "Impossible de continuer, le panneau est en mode installateur",
    "lbl_planned": "PRÉVU",
    "lbl_noPlannedService": "Aucun rapport de service prévu",
    "lbl_rmtServInitiationMsg": "Un service manuel à distance a été demandé, veuillez attendre 5 à 10 minutes pour que le rapport soit complété.",
    "lbl_okay": "D'accord",
    "lbl_pwdReset": "Réinitialisation du mot de passe",
    "lbl_setPwd": "Définir le mot de passe",
    "lbl_pwdResetMsg": "Pour réinitialiser votre mot de passe, veuillez saisir un nouveau mot de passe ci-dessous.",
    "lbl_pwdResetSuccess": "Réinitialisation réussie du mot de passe",
    "lbl_pwdResetSuccessMsg": "Votre mot de passe a été réinitialisé avec succès. Connectez-vous à l'application en utilisant votre mot de passe mis à jour.",
    "lbl_pwdResetErrMsg": "Réinitialisation du mot de passe déjà demandée au cours des 15 dernières minutes.",
    "btn_download_notifications": "Télécharger l'historique du panneau",
    "btn_download_logs": "Télécharger les notifications",
    "lbl_userAccDelete": "Suppression du compte utilisateur",
    "lbl_userAccDeleteConfirm": "Voulez-vous confirmer la suppression du compte ?",
    "lbl_userAccDeleteMsg": "Pour demander la suppression d'un compte utilisateur, veuillez saisir l'adresse e-mail du compte ci-dessous, puis le bouton Soumettre.",
    "lbl_userAccDeleteSubmitMsg": "Votre demande de suppression de compte utilisateur a été soumise. Si l'ID utilisateur existe, un lien pour réinitialiser votre mot de passe sera envoyé à",
    "btn_rmvLogo": "Supprimer le logo",
    "btn_cgnLogo": "Changer de logo",
    "lbl_endUserMsg": "Les informations fournies ici seront affichées à la fois dans l'application mobile et dans les rapports de maintenance à distance.",
    "lbl_dwnPanelLog": "Télécharger les journaux du panneau",
    "lbl_estatelessPanels": "Panneaux ne faisant pas partie d'un domaine",
    "lbl_siteComments": "Commentaires",
    "mainHeadingforsystemConfig": "Entête",
    "lbl_EatonGCMorFCMurl": "URL Eaton GCM ou FCM",
    "lbl_EatonapiKey": "Clé API Eaton",
    "lbl_eatonApplepassword": "Mot de passe Eaton Apple",
    "lbl_applePushNotification": "Apple utilise la notification push de production",
    "headingaccountSettings": "Paramètre du compte",
    "lbl_account_default_state": "État par défaut du compte",
    "lbl_choose_state_for_new_account": "Choisissez l'état pour l'installateur du nouveau compte",
    "lbl_account_disable_after": "Compte désactivé après",
    "lbl_the_top_limit_in_days": "La limite supérieure en jours, après laquelle un compte sera désactivé",
    "lbl_remove_unpaired_account_after": "Supprimer le compte non associé après",
    "lbl_helper_text": "Texte d'aide",
    "lbl_cloud_password": "Mot de passe cloud",
    "lbl_user_registration_disable_time": "Temps de désactivation de l’enregistrement de l’utilisateur",
    "lbl_invalid_login_attempt": "Durée de verrouillage de la tentative de connexion non valide",
    "lbl_maximum_sign_in_attempt": "Nombre maximal de tentatives de connexion",
    "lbl_seconds": "secondes",
    "lbl_times": "fois",
    "headingCertificates": "Certificats",
    "lbl_eaton_Apple_certificaates": "Certificat Apple Eaton",
    "lbl_no_file_selected": "Aucun fichier sélectionné",
    "lbl_upload_file": "Téléverser un fichier",
    "lbl_heartbeat_interval": "Intervalle de battement de coeur",
    "lbl_miliseconds": "millisecondes",
    "lbl_WS_session_drop_after": "Session WS abandonnée après",
    "lbl_jwt_time_to_live": "Il est temps de vivre avec JWT",
    "lbl_communication_log_size": "Taille du journal de communication",
    "lbl_panel_log_size": "Taille de téléchargement du journal du panneau",
    "lbl_nesting_group_level": "Niveau du groupe d'imbrication",
    "lbl_ws_sessions_close_qty": "Quantité de clôture des sessions WS",
    "lbl_async_context_timeout": "Délai d'expiration du contexte asynchrone",
    "lbl_refer_header": "En-tête du référent",
    "heading_Liceense_Agrrement": "Contrat de licence",
    "heading_proxy": "Procuration",
    "lbl_proxy_host": "Hôte proxy",
    "lbl_proxy_port": "Port proxy",
    "lbl_use_proxy": "Utiliser un proxy",
    "lbl_fichet_gcmorfcm": "URL du fichet GCM ou FCM",
    "lbl_fichetApiKey": "Fichet API Key",
    "lbl_fichet_applePsword": "Fiche mot de passe Apple",
    "lbl_fichet_applepassword": "Fichet Certificat Apple",
    "lbl_discard": "Jeter",
    "heading_CSL": "Paramètres CSL",
    "lbl_csl_login": "Connexion CSL",
    "lbl_csl_password": "Mot de passe CSL",
    "lbl_csl_primary_ip": "IP du serveur principal CSL",
    "lbl_csl_primary_port": "Port du serveur principal CSL",
    "lbl_csl_secondary_IP": "IP du serveur secondaire CSL",
    "lbl_csl_secondary_port": "Port du serveur secondaire CSL",
    "lbl_csl_primary_protocol": "Protocole du serveur principal CSL",
    "lbl_csl_secondary_portocol": "Protocole de serveur secondaire CSL",
    "lbl_poll_fails": "Le sondage CSL échoue après",
    "lbl_minutes": "minutes",
    "button_CSL_ARC": "Codes ARC CSL",
    "button__csl_countries": "Pays CSL",
    "lbl_dual_com_login": "Connexion CSL DualCom",
    "lbl_dual_com_password": "Mot de passe CSL DualCom",
    "lbl_poll_failer_helper": "Envoyer un message d'échec du sondage à CSL si le panneau est déconnecté plus longtemps que cette valeur en minutes",
    "lbl_add_email": "Ajouter un e-mail",
    "lbl_csl_support_team_e-mail_address": "Adresses e-mail de l'équipe d'assistance CSL",
    "lbl_Support_email_list_of_CSL_arc_provider": "Liste de diffusion d'assistance du fournisseur d'arc CSL",
    "lbl_support_team_email_address": "Adresses e-mail de l'équipe d'assistance",
    "lbl_email_address_of_support_team": "Adresses e-mail de l'équipe d'assistance",
    "heading_Permissions": "Autorisations",
    "lbl_Enable_auth": "Activer l'authentification",
    "lbl_Mail_transport_protocol_authorization": "Autorisation du protocole de transport du courrier",
    "lbl_EnableTLS": "Activer TLS",
    "lbl_Mail_transport_protocol_enable": "Activation du protocole de transport de courrier",
    "heading_Info_SMTP": "Informations SMTP",
    "lbl_SMTP_host": "Hôte SMTP",
    "lbl_Mail_trasport_protocol_host": "Hôte du protocole de transport de courrier",
    "lbl_SMTP_port": "Port SMTP",
    "lbl_Mail_trasport_protocol_post": "Poste protocolaire de transport du courrier",
    "lbl_SMTP_user": "Utilisateur SMTP",
    "lbl_Mail_transport_protocol_user": "Utilisateur du protocole de transport de courrier",
    "lbl_SMTP_password": "Mot de passe SMTP",
    "lbl_Mail_transport_protocol_password": "Mot de passe du protocole de transport de courrier",
    "lbl_Protocol": "Protocole",
    "lbl_Mail_transport_protocol": "Protocole de transport du courrier",
    "lbl_Form": "Formulaire",
    "lbl_Mail_transport_protocol_source": "Source du protocole de transport de courrier",
    "heading_Email_Settings": "Paramètres de messagerie",
    "lbl_Email_Text_Language": "Langue du texte de l'e-mail",
    "lbl_User_registration_e-mail": "E-mail d'inscription de l'utilisateur",
    "lbl_helper_text_for_user_register_mail": "Ce texte sera envoyé à l'e-mail de l'utilisateur. ${user.login} sera remplacé lors de la connexion de l'utilisateur, qui recevra l'e-mail ${user.name} - nom d'utilisateur, ${user-cloudId} - identifiant cloud de l'utilisateur, ${confirm.link} - lien de confirmation par e-mail",
    "lbl_State_change_e-mail": "E-mail de changement d'état",
    "lbl_helpertext_for_state_email_change": "Ce texte sera envoyé à l'e-mail de l'utilisateur. ${user.login} sera remplacé lors de la connexion de l'utilisateur, qui recevra l'e-mail ${user.name} - nom d'utilisateur, ${user.state} état de l'utilisateur, ${ user-cloudId} - identifiant du cloud de l'utilisateur",
    "lbl_Forgot_login_e-mail_subject": "Objet de l'e-mail de connexion oublié",
    "lbl_Email_subject_for_login_reminder": "Objet de l'e-mail pour le rappel de connexion",
    "lbl_Forgotlogine-mail": "E-mail de connexion oublié",
    "lbl_helpertext_for_forget_login_mail": "Lorsque vous modifiez la description, veuillez insérer les variables ${user.name} et ${user.login} lorsque vous souhaitez voir respectivement le nom d'utilisateur et la connexion de l'utilisateur.",
    "lbl_App_user_creation_e-mail": "E-mail de création d'utilisateur de l'application",
    "lbl_helper_text_for_app_user_creation": "Ce texte sera envoyé à l'e-mail de l'utilisateur. ${user.login} sera remplacé lors de la connexion de l'utilisateur, qui recevra un e-mail ${user.name} - nom d'utilisateur ${confirm.link} - lien de confirmation par e-mail",
    "lbl_Forgot_passworde-mail": "Mot de passe oublié",
    "lbl_helper_text_for_forgetpassworde-mail": "Ce texte sera envoyé à l'e-mail de l'utilisateur. ${user.name} - nom d'utilisateur ${secretLink} - URL pour changer le mot de passe",
    "lbl_Maintenance_notification_subject": "Objet de la notification de maintenance",
    "lbl_Subject_for_all_user_maintenancenotification": "Objet de toute notification de maintenance utilisateur",
    "lbl_Maintenance_notificationtext": "Texte de notification de maintenance",
    "lbl_helper_text_for_maintain_notigication": "Ce texte sera envoyé à tous les utilisateurs lorsqu'il est nécessaire de les informer d'un événement sur le serveur cloud",
    "lbl_Raised_alert_event_e-mail_text": "Texte de l'e-mail d'événement d'alerte déclenché",
    "lbl_helper_text_for_raised_alert_event": "Celui-ci sera envoyé à l’équipe d’assistance du panel. ${site.name} - sera remplacé par le nom de référence du cloud du panneau ${panel.id} - sera remplacé par l'identifiant du panneau cloud ${sia.code} - événement d'alerte Code SIA ${description} - description du code SIA de l'événement d'alerte ${time.stamp} - horodatage du déclenchement de l'alerte ${panel.link} - Lien hypertexte vers l'écran du panneau cloud",
    "lbl_Self_test_failure_text": "Texte d'échec de l'auto-test",
    "lbl_helper_text_for_test_failure_Text": "Ce texte sera envoyé à l'équipe d'assistance.${event.ipAdd} - adresse IP du nœud défaillant ${event.type} - type d'événement ${event.message} - message ${event.connected} - état de la connexion ${event.date} - horodatage de l'événement",
    "lbl_SIA_message_failure_e-mail_subject": "Objet de l'e-mail d'échec du message SIA",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Sujet à un message concernant l'échec de l'envoi des événements sia",
    "lbl_SIA_message_failuree-mail_text": "Texte de l'e-mail d'échec du message SIA",
    "lbl_Email_text_about_siaevent_sendingproblems": "Texte par e-mail concernant les problèmes d'envoi d'événements sia",
    "lbl_time_in_seconds_for_locking_account": "Temps en secondes pour verrouiller le compte lorsque trop de tentatives de connexion échouées se sont produites",
    "lbl_max_failed_login": "Nombre maximum de tentatives de connexion échouées avant de verrouiller le compte",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Temps en secondes pour désactiver temporairement l'enregistrement d'un nouvel utilisateur",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Veuillez fournir les informations d'identification de l'utilisateur du cloud dans le panneau",
    "lbl_Days_to_remove_unpaired_user_accounts": "Jours pour supprimer les comptes d'utilisateurs non appariés",
    "lbl_google_firebase_push_notification": "URL du serveur de notifications push Google ou Firebase",
    "lbl_api_key": "Clé API",
    "lbl_Apple_push_notification_server_password": "Mot de passe du serveur de notifications push Apple",
    "lbl_determines_apple_server": "Détermine quels serveurs Apple doivent être utilisés : production ou sandbox",
    "lbl_upload_certificate_for_eaton": "Télécharger un certificat pour la notification push Apple Eaton",
    "lbl_Heartbeat_intervalinmilliseconds": "Intervalle de battement de coeur en millisecondes",
    "lbl_Atime_delay_between_closing_session": "Un délai entre la clôture des séances",
    "lbl_json_web_token": "Durée de vie du jeton Web JSON",
    "lbl_hazelcast_max_comminication_log_size": "Taille maximale du journal de communication Hazelcast",
    "lbl_Max_size_of_log_entries": "Taille maximale des entrées de journal à télécharger depuis le panneau",
    "lbl_max_nesting_group_level": "Niveau maximum du groupe d'imbrication",
    "lbl_count_of_Web_token_which_will_be_closed": "Un nombre de sessions de socket Web qui seront fermées",
    "lbl_timeout_for_external_async_request": "Délai d'expiration pour la demande asynchrone externe",
    "lbl_pattern_for_refer_header": "Modèle pour l'en-tête de référence",
    "heading_Support_actions": "Actions de soutien",
    "lbl_User_e-mail": "Courriel de l'utilisateur",
    "lbl_Action": "Action",
    "btn_Startaction": "Commencer l'action",
    "heading_Download_Reports": "Télécharger les rapports",
    "lbl_Installer_report": "Rapport de l'installateur",
    "lbl_Download": "Télécharger",
    "lbl_App_userReport": "Rapport sur l'utilisateur de l'application",
    "lbl_Managed_panelsreport": "Rapport sur les panels gérés",
    "lbl_Unmanaged_panels_report": "Rapport sur les panels non gérés",
    "lbl_ARC_report": "Rapport du CRA",
    "heading_confirmation": "Confirmation",
    "msg_for_Pairing": "Cela supprimera toutes les informations de l'utilisateur (appairage, appareils mobiles) et il ne pourra plus y accéder",
    "msg_for_remove_pariring": "Etes-vous sûr de vouloir supprimer le jumelage entre",
    "msg_for_disable_registration": "Êtes-vous sûr de vouloir désactiver temporairement les inscriptions depuis le cloud et l'application ?",
    "lbl_User_Info": "informations utilisateur",
    "error_pwd_info_capital": "Veuillez utiliser des lettres majuscules (X,Y,Z...),",
    "error_pwd_small": "lettres minuscules (x,y,z...),",
    "error_pwd_number": "nombres (1,2,3,4...),",
    "error_pwd_special": "et des caractères spéciaux (!@#$%^&*) dans le mot de passe dans le mot de passe",
    "error_pwd_length": "La longueur du mot de passe ne doit pas être inférieure à 6 caractères.",
    "lbl_installer_Details": "Détails de l'installateur",
    "lbl_error_for_Date_Range": "Aucun résultat correspondant pour la plage de dates sélectionnée",
};
export default resources;