/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme, TextField, IconButton, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { InstallersProps } from '../InstallersTypes';
import { EditInstallerModalProps } from '../EditInstallerTypes';
import { fetchEditInstallerDetails } from '../../../redux/actions/installersActions';
import secureLocalStorage from 'react-secure-storage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function EditInstallerModal({ count, setCount, data, setData, open, setOpen, spokenPwd, setSpokenPwd }: EditInstallerModalProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useTypedDispatch()
  const { editInstallerStatus } = useSelector((state: any) => state.installerReducer);
  const installer: any = secureLocalStorage.getItem('installer') || {};
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [userStatus, setUserStatus] = React.useState('')
  const [cloudId, setCloudId] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [contactEmail, setContactEmail] = React.useState('')
  const [spokenPassword, setSpokenPassword] = React.useState('')
  const [firstPhoneNumber, setFirstPhoneNumber] = React.useState('')
  const [company, setCompany] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [postCode, setPostCode] = React.useState('')
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState<string>('');
  const [status, setStatus] = React.useState(false);
  const { id } = useParams()
  const { t } = useTranslation();

  useEffect(() => {
    if (status === true) {
      if (editInstallerStatus === 200) {
          setCount(count + 1)
          setMessage(`Installer details edited successfully`);
      } else if (editInstallerStatus === 500) {
          setMessage(`${t('msg_internal_error')}`);
      } else if (editInstallerStatus === 400) {
          setMessage(`${t('lbl_unauthorizedUser')}`);
      } else {
          setMessage(`${t('lbl_someError')}`);
      }
      setSnackOpen(true);
      setStatus(false);
  }

    setFirstName(installer?.firstName)
    setLastName(installer?.lastName)
    setUserStatus(installer?.installerInfo?.installerState)
    setCloudId(installer?.installerInfo?.cloudId)
    setEmail(installer?.login)
    setContactEmail(installer?.installerInfo?.contactEmail)
    setSpokenPassword(installer?.installerInfo?.spokenPassword)
    setFirstPhoneNumber(installer?.installerInfo?.firstPhoneNumber.number)
    setCompany(installer?.installerInfo?.installerCompanyName)
    setAddress(installer?.installerInfo?.address.address)
    setCountry(installer?.installerInfo?.address.country)
    setPostCode(installer?.installerInfo?.address.postCode)
  }, [status])

  const handleClose = () => {
    setFirstName(installer?.firstName)
    setLastName(installer?.lastName)
    setUserStatus(installer?.installerInfo?.installerState)
    setCloudId(installer?.installerInfo?.cloudId)
    setEmail(installer?.login)
    setContactEmail(installer?.installerInfo?.contactEmail)
    setSpokenPassword(installer?.installerInfo?.spokenPassword)
    setFirstPhoneNumber(installer?.installerInfo?.firstPhoneNumber.number)
    setCompany(installer?.installerInfo?.installerCompanyName)
    setAddress(installer?.installerInfo?.address.address)
    setCountry(installer?.installerInfo?.address.country)
    setPostCode(installer?.installerInfo?.address.postCode)
    setOpen(false);
  }

  const handleSaveEditSiteDetails = async() => {
    const body = {
        ID: installer.ID,
        creationTimeInMilliSeconds: installer.creationTimeInMilliSeconds,
        currentLoginTimeInMilliSeconds: installer.currentLoginTimeInMilliSeconds,
        firstName: firstName,
        groupId: installer.groupId,
        installerInfo: {
          ID:installer.installerInfo.ID,
          email:email,
          contactEmail:contactEmail,
          cloudId:cloudId,
          address:{
            address:address,
            country:country,
            postCode:postCode
          },
          firstPhoneNumber:{
            number:firstPhoneNumber,
            type:installer.installerInfo.firstPhoneNumber.type
          },
          installerCompanyName:company,
          installerPrimaryContact:installer.installerInfo.installerPrimaryContact,
          installerState:userStatus,
          secondPhoneNumber:installer.installerInfo.secondPhoneNumber,
          spokenPassword:spokenPassword,
          thirdPhoneNumber:installer.installerInfo.thirdPhoneNumber,
        },
        lastLoginTimeInMilliSeconds: installer.lastLoginTimeInMilliSeconds,
        lastName: lastName,
        locale: installer.locale,
        login: email,
        userType: installer.userType,
    };

     await dispatch(fetchEditInstallerDetails(body))
     setStatus(true)
    setOpen(false);
  }

  const handleBackClick = (): any => {
    setOpen(false);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
        {t('lbl_close')}
      </Button>
    </React.Fragment>
  );


  return (
    <div>
      {md ? <>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            backgroundColor: 'background.paper',
            boxShadow: 20,
            p: 0,
          }} >
            <Paper elevation={0} sx={{ pb: 0 }}>
              <Paper
                elevation={4}
                sx={{
                  paddingTop: 2,
                  paddingBottom: 1,
                  paddingLeft: 2,
                  paddingRight: 2,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <IconButton onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
                &ensp;&ensp;
                <Typography sx={{ pt: 0.5 }} variant="h6">
                  Edit Details
                </Typography>
              </Paper>
            </Paper>
            <Divider />
            <div style={{ maxHeight: '80vh', overflow: 'auto' }} >
              <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 10, paddingLeft: 3, paddingRight: 3 }}>
                <Grid container spacing={3}>
                <Grid item xs={12}>
                      <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" value={firstName} onChange={(e) => setFirstName(e.target.value)} label={t('lbl_firstName')} variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" value={lastName} onChange={(e) => setLastName(e.target.value)} label={t('lbl_lastName')} variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField select fullWidth id="filled-basic" label={`Status`} variant="filled" value={userStatus} onChange={(e) => setUserStatus(e.target.value)} >
                        <MenuItem key={`ACTIVE`} value={`ACTIVE`} >ACTIVE</MenuItem>
                        <MenuItem key={`INACTIVE`} value={`INACTIVE`}>INACTIVE</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" label={`Cloud ID`} value={cloudId} onChange={(e) => setCloudId(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField inputProps={{ maxLength: 40 }} fullWidth id="filled-basic" label={`E-mail`} value={email} onChange={(e) => setEmail(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField inputProps={{ maxLength: 40 }} fullWidth id="filled-basic" label={`Contact E-mail`} value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={`Spoken Password`} variant="filled" value={spokenPassword} onChange={(e) => setSpokenPassword(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={`First Phone Number`} variant="filled" value={firstPhoneNumber} onChange={(e) => setFirstPhoneNumber(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={`Company`} variant="filled" value={company} onChange={(e) => setCompany(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Company Address`} variant="filled" value={`${address}`} 
                      onChange={(e) => {
                          setAddress(e.target.value)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Country`} variant="filled" value={`${country}`} 
                      onChange={(e) => {
                          setCountry(e.target.value)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Post Code`} variant="filled" value={`${postCode}`} 
                      onChange={(e) => {
                          setPostCode(e.target.value)
                      }} />
                    </Grid>
                </Grid>
              </Box>
            </div>
            <Paper sx={{
              position: 'absolute' as 'absolute',
              top: '95vh',
              zIndex: 1000,
              transform: 'translateY(-100%)',
              width: '100%',
            }}>
              <MobileStepper
                variant="dots"
                steps={0}
                position="static"
                activeStep={activeStep}
                sx={{
                  maxWidth: '100%',
                  flexGrow: 1,
                  paddingTop: 2,
                  paddingBottom: 4,
                  paddingLeft: 3,
                  paddingRight: 3,
                  backgroundColor: 'inherit',
                }}
                nextButton={
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleSaveEditSiteDetails}
                  >
                    {t('btn_save')}
                  </Button>

                }
                backButton={
                  <Button size="small" variant="outlined" onClick={handleClose}>
                    {t('btn_cancel')}
                  </Button>
                }
              />
            </Paper>
          </Box>
        </Modal>
      </>
        :
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} >
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                <Typography variant='h6'>Edit Details</Typography>
              </Paper>
              <Divider />
              <div>
                <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3,maxHeight:'60vh',overflow:'auto' }}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" value={firstName} onChange={(e) => setFirstName(e.target.value)} label={t('lbl_firstName')} variant="filled" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" value={lastName} onChange={(e) => setLastName(e.target.value)} label={t('lbl_lastName')} variant="filled" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField select fullWidth id="filled-basic" label={`Status`} variant="filled" value={userStatus} onChange={(e) => setUserStatus(e.target.value)} >
                        <MenuItem key={`ACTIVE`} value={`ACTIVE`} >ACTIVE</MenuItem>
                        <MenuItem key={`INACTIVE`} value={`INACTIVE`}>INACTIVE</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth id="filled-basic" label={`Cloud ID`} value={cloudId} onChange={(e) => setCloudId(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField inputProps={{ maxLength: 40 }} fullWidth id="filled-basic" label={`E-mail`} value={email} onChange={(e) => setEmail(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField inputProps={{ maxLength: 40 }} fullWidth id="filled-basic" label={`Contact E-mail`} value={contactEmail} onChange={(e) => setContactEmail(e.target.value)} variant="filled" />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth id="filled-basic"
                        label={`Spoken Password`} variant="filled" value={spokenPassword} onChange={(e) => setSpokenPassword(e.target.value)} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField fullWidth id="filled-basic"
                        label={`First Phone Number`} variant="filled" value={firstPhoneNumber} onChange={(e) => setFirstPhoneNumber(e.target.value)}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={`Company`} variant="filled" value={company} onChange={(e) => setCompany(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Company Address`} variant="filled" value={`${address}`} 
                      onChange={(e) => {
                          setAddress(e.target.value)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Country`} variant="filled" value={`${country}`} 
                      onChange={(e) => {
                          setCountry(e.target.value)
                      }} />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      label={`Post Code`} variant="filled" value={`${postCode}`} 
                      onChange={(e) => {
                          setPostCode(e.target.value)
                      }} />
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <Divider />
              <Paper >
                <MobileStepper
                  variant="dots"
                  steps={0}
                  position="static"
                  activeStep={activeStep}
                  sx={{
                    flexGrow: 1,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                    paddingRight: 3, backgroundColor: 'inherit'
                  }}
                  nextButton={
                    <Button size="medium" disableElevation variant='contained' onClick={handleSaveEditSiteDetails}>
                      {t('btn_save')}
                    </Button>
                  }
                  backButton={
                    <Button size="small" variant='outlined' onClick={handleClose}>
                      {t('btn_cancel')}
                    </Button>
                  }
                />
              </Paper>
            </Box>
          </Modal>
        </>}
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
