/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, MobileStepper, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import './pwdReset.css';
import logo from '../assets/images/eaton_stacked_logo.png'
import tile from '../assets/images/tileGrad.png'
import { useTypedDispatch } from '../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { deleteUserAccount, fetchResetPassword, verifyEmailAddress } from '../redux/actions/registerScreenActions';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
    borderRadius: 10,
};


export default function DeleteAccountSuccess(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const { t, i18n } = useTranslation();
    const { user, login, hash, encexp } = useParams();

    return (
        <div id="main">
            <Box
                sx={
                    md
                        ? {
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100vw',
                            height: '100dvh',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }
                        : style
                }
            >
                <Paper elevation={0} sx={{ overflow: 'hidden' }}>
                    <Paper
                        elevation={0}
                        sx={{
                            paddingTop: 6,
                            paddingBottom: 3,
                            paddingLeft: 4,
                            paddingRight: 4,
                            backgroundImage: `url(${tile})`,
                        }}
                    >
                        <img src={logo} alt="logo" />
                    </Paper>
                </Paper>
                <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography variant="h6">{t('lbl_userAccDelete')}</Typography>
                        <Typography variant="body1">
                            Your user account is deleted successfully
                        </Typography>
                    </Stack>
                </Paper>
            </Box>
        </div>
    );
}