export const SpecificPanelActionTypes = {
    SET_SPECIFIC_PANEL: 'SET_SPECIFIC_PANEL',
    SET_SPECIFIC_PANEL_ESTATE: 'SET_SPECIFIC_PANEL_ESTATE',
    SET_SPECIFIC_PANEL_CONNECTION_STATS: 'SET_SPECIFIC_PANEL_CONNECTION_STATS',
    DELETE_SPECIFIC_PANEL: 'DELETE_SPECIFIC_PANEL',
    SET_SPECIFIC_PANEL_NOTIFICATIONS: 'SET_SPECIFIC_PANEL_NOTIFICATIONS',
    SET_PANEL_VIEW: 'SET_PANEL_VIEW',
    SET_ESTATES_PANEL_VIEW: 'SET_ESTATES_PANEL_VIEW',
    SET_SPECIFIC_PANEL_ARC_REPORTING: 'SET_SPECIFIC_PANEL_ARC_REPORTING',
    SET_SPECIFIC_PANEL_ARC_REPORTING_DATA:'SET_SPECIFIC_PANEL_ARC_REPORTING_DATA',
    SET_SPECIFIC_PANEL_ARC_SETTINGS: 'SET_SPECIFIC_PANEL_ARC_SETTINGS',
    SET_SPECIFIC_PANEL_ARC_COUNTRY_CODES: 'SET_SPECIFIC_PANEL_ARC_COUNTRY_CODES',
    SET_SPECIFIC_PANEL_SITE_DETAILS: 'SET_SPECIFIC_PANEL_SITE_DETAILS',
    SET_SPECIFIC_PANEL_MOBILE_APP_USERS: 'SET_SPECIFIC_PANEL_MOBILE_APP_USERS',
    SET_DELETE_MOBILE_APP_USER_STATUS: 'SET_DELETE_MOBILE_APP_USER_STATUS',
    SET_SPECIFIC_PANEL_USER_ACCESS: ' SET_SPECIFIC_PANEL_USER_ACCESS',
    SET_REPORT_GROUPS: 'SET_REPORT_GROUPS',
    SET_CSL_COUNTRIES:'SET_CSL_COUNTRIES',
    SET_ARC_DISABLE_STATUS: 'SET_ARC_DISABLE_STATUS',
    SET_INSTALLER_EMAIL_ALERTS_STATUS: 'SET_INSTALLER_EMAIL_ALERTS_STATUS',
    SET_INSTALLER_EMAIL_ALERTS_DISABLE_STATUS: 'SET_INSTALLER_EMAIL_ALERTS_DISABLE_STATUS',
    SET_ARC_ENABLE_STATUS: 'SET_ARC_ENABLE_STATUS',
    SET_ARC_CONNECTIVITY_STATUS:'SET_ARC_CONNECTIVITY_STATUS',
    SET_MOBILE_APP_USER_BY_LOGIN: 'SET_MOBILE_APP_USER_BY_LOGIN',
    SET_SPECIFIC_PANEL_LOGS: 'SET_SPECIFIC_PANEL_LOGS',
    SET_SPECIFIC_PANEL_LOGS_DATA:'SET_SPECIFIC_PANEL_LOGS_DATA',
    SET_SPECIFIC_PANEL_INSTALLER_EMAIL_ALERTS: 'SET_SPECIFIC_PANEL_INSTALLER_EMAIL_ALERTS',
    SET_INSTALLER_EMAIL_ALERTS: 'SET_INSTALLER_EMAIL_ALERTS',
    SET_USER_EMAIL_ALERTS: 'SET_USER_EMAIL_ALERTS',
    SET_INSTALLER_ESTATE_ID: 'SET_INSTALLER_ESTATE_ID',
    SET_INSTALLER_ESTATE_LIST: 'SET_INSTALLER_ESTATE_LIST',
    SET_ADD_USER_EMAIL_ALERTS_STATUS: 'SET_ADD_USER_EMAIL_ALERTS_STATUS',
    SET_SPECIFIC_PANEL_NOTIFICATIONS_DATA:'SET_SPECIFIC_PANEL_NOTIFICATIONS_DATA',
    DELETE_SPECIFIC_ESTATE:'DELETE_SPECIFIC_ESTATE',
    SET_MOBILE_APP_USER_BY_LOGIN_STATUS:'SET_MOBILE_APP_USER_BY_LOGIN_STATUS',
    SET_CONNECT_LOGIN_HTML_CODE:'SET_CONNECT_LOGIN_HTML_CODE',
    SET_SPECIFIC_PANEL_NOTIFICATIONS_LENGTH:'SET_SPECIFIC_PANEL_NOTIFICATIONS_LENGTH',
    SET_SPECIFIC_PANEL_LOGS_LENGTH:'SET_SPECIFIC_PANEL_LOGS_LENGTH',
    SET_DRAWER_STATE:'SET_DRAWER_STATE',
    SET_ZONES_MONITORING_LIST:'SET_ZONES_MONITORING_LIST',
    SET_ZONES_MONITORING_LIST_MODIFIED_STATUS:'SET_ZONES_MONITORING_LIST_MODIFIED_STATUS',
}