/* eslint-disable @typescript-eslint/no-unused-vars */
import { PanelArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
import { EstateArrayProps } from '../../components/ListAndGridView/panelEstateTypes';
import { PanelsInEstatesProps } from './../../components/ManagePanelsAndEstatesModal/managePanelsTypes';
import { ManagePanelsAndEstatesActionTypes } from './../constants/managePanelsAndEstatesActionTypes';

const initialState = {
    panelsInEstate : <any>[],
    estates:<EstateArrayProps>[],
    panels:<PanelArrayProps>[],
    status:<number>0,
}

export const managePanelsAndEstatesReducer = (state = initialState , action: { type: any; payload:any }):any => {
    switch(action.type){
        case ManagePanelsAndEstatesActionTypes.PANELS_IN_ESTATE : 
                            return {...state,panelsInEstate : [...state.panelsInEstate,action.payload]}
                            
                            break;
        case ManagePanelsAndEstatesActionTypes.SET_ESTATES : 
                            return {...state,estates : action.payload}
                            break;
        case ManagePanelsAndEstatesActionTypes.SET_PANELS : 
                            return {...state,panels : action.payload}
                            break;
        case ManagePanelsAndEstatesActionTypes.SET_MANAGE_ESTATE_AND_PANELS_STATUS : 
                            return {...state,status : action.payload}
                            break;
        case ManagePanelsAndEstatesActionTypes.RESET_PANELS_IN_ESTATE : 
                            return {...state, panelsInEstate : action.payload}
                            break;
        default :
                     return state
                    break;
    }
}