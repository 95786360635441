import { EndUserInfoProps } from "../../components/EditEndUserInfoModal/EditEndUserInfoTypes";
import { EndUserInfoActionTypes } from "../constants/endUserInfoActionTypes";

const initialState = {
    endUserInfo:<EndUserInfoProps>{},
    installerLogo:<any>'',
    status:<number>0,
    removeLogoStatus:<number>0,
    fetchLogoStatus:<number>0,
}

export const endUserInfoReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case EndUserInfoActionTypes.SET_END_USER_INFO:
            return {...state, endUserInfo: action.payload }
            break;
        case EndUserInfoActionTypes.SET_INSTALLER_LOGO:
            return {...state, installerLogo: action.payload }
            break;
        case EndUserInfoActionTypes.SET_END_USER_INFO_STATUS:
            return {...state, status: action.payload }
            break;
        case EndUserInfoActionTypes.SET_REMOVE_LOGO_STATUS:
            return {...state, removeLogoStatus: action.payload }
            break;
        case EndUserInfoActionTypes.SET_FETCH_LOGO_STATUS:
            return {...state, fetchLogoStatus: action.payload }
            break;
        default:
            return state
            break;

    }
}