export const authWorkflowPortuguese =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Senha ou email incorretos',
            INVALID_CREDENTIALS: 'Sua combinação de nome de usuário/senha não é reconhecida.',
            GENERIC_ERROR: 'Sua solicitação não pôde ser processada neste momento.',
        },
        HEADER: {
            FORGOT_PASSWORD: 'Esqueceu sua senha',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Selecione uma senha. Certifique-se de que sua senha atenda aos requisitos de complexidade necessários descritos abaixo.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                `Insira o e-mail da conta associado à conta.<br/><br/>` +
                `Se este e-mail tiver uma conta na Eaton, você receberá uma resposta dentro de <1>{{time}}</1>.`,
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowPortuguese =  {
    translation: {
        ACTIONS: {
            FINISH: 'Terminar',
            NEXT: 'Próximo',
            BACK: 'Voltar',
            CREATE_ACCOUNT: 'Criar uma conta',
            OKAY: 'OK',
            CANCEL: 'Cancelar',
            CONTINUE: 'Continuar',
            DONE: 'Feito',
            LOG_IN: 'Conecte-se',
            LOG_OUT: 'Sair',
            RESEND: 'Envie novamente',
            REMEMBER: 'Lembre de mim',
            SUBMIT: 'Enviar',
        },
        LABELS: {
            EMAIL: 'Endereço de email',
            PASSWORD: 'Senha',
            FORGOT_PASSWORD: 'Esqueceu sua senha?',
            NEED_ACCOUNT: 'Precisa de uma conta?',
        },
        MESSAGES: {
            EMAIL_SENT: 'E-mail enviado',
            WELCOME: 'Bem-vindo',
            REQUEST_ERROR: 'Desculpe, houve um problema ao enviar sua solicitação.',
            ERROR: 'Erro!',
            EMAIL_ENTRY_ERROR: 'por favor digite um email válido',
            CONTACT: 'Entre em contato com um representante de suporte da Eaton',
            LOADING: 'Carregando...',
            PASSWORD_REQUIRED_ERROR: 'Senha requerida',
        },
        FORMS: {
            FIRST_NAME: 'Primeiro nome',
            LAST_NAME: 'Sobrenome',
            PASSWORD: 'Senha',
            CONFIRM_PASSWORD: 'Confirme sua senha',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 caracteres',
            NUMBERS: 'Um número',
            UPPER: 'Uma letra maiúscula',
            LOWER: 'Uma letra minúscula',
            SPECIAL: 'Um personagem especial',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowPortuguese = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: 'Carregando Contrato de Licença do Usuário Final...',
                AGREE_TERMS: 'Li e concordo com os Termos e Condições',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Crie a sua conta aqui',
                LICENSE: 'Contrato de licença',
                VERIFY_EMAIL: 'Verificar e-mail',
                PASSWORD: 'Criar senha',
                ACCOUNT_DETAILS: 'Detalhes da conta',
                COMPLETE: 'Conta criada!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: 'Insira seus dados abaixo para concluir a criação da conta.',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `Para registrar-se em uma conta Eaton, insira as informações necessárias abaixo. Você precisará verificar seu endereço de e-mail para continuar.`,
                VERIFY_EMAIL: {
                    RESEND: 'Reenviar email de verificação',
                    VERIFICATION: 'Código de verificação',
                },
            },
        },
    },
};