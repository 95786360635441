/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
import React, { useEffect } from 'react';
import { EmptyState, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { Button, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReportDetailsModal from '../ServiceReportsModals/ReportDetailsModal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FolderIcon from '@mui/icons-material/Folder';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchPendingAndFailedReportsListForPanel } from '../../redux/actions/remoteServicingActions';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import DeleteFailedServiceModal from './DeleteFailedServiceModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FailedServiceReportModal from '../ServiceReportsModals/FailedServiceReportModal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

export default function PendingAndFailedServices(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDeleteService, setOpenDeleteService] = React.useState(false)
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [openFailedReportModal, setOpenFailedReportModal] = React.useState(false);
    const [counter, setCounter] = React.useState<number>(0);
    const [snapId, setSnapId] = React.useState<number>(0)
    const [message, setMessage] = React.useState('');
    const [failedReport, setFailedReport] = React.useState<any>({})
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { pendingAndFailedReportsList } = useSelector((state: any) => state.remoteServicingReducer);
    const { deleteFailedServiceStatus } = useSelector((state: any) => state.remoteServicingReducer);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    useEffect(() => {
        if(counter > 0){
            setSnackOpen(true)
            if(deleteFailedServiceStatus === 200){
                setMessage('Failed service deleted')
            }
            else{
                setMessage('unauthorized error')
            }
        }
        dispatch(fetchPendingAndFailedReportsListForPanel(specificPanel?.panelId))
    },[counter])

    const handleDelete = (snapshotId:number) => {
        setSnapId(snapshotId)
        setOpenDeleteService(true)
    }

    const handleFailedServiceModal = (service:any) => {
        setFailedReport(service)
        setSnapId(service.snapshotId)
        setOpenFailedReportModal(true)
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>

        {md ?
            <>
                <Table>
                    <TableBody>
                        {pendingAndFailedReportsList.length !== 0 && pendingAndFailedReportsList !== undefined &&
                        pendingAndFailedReportsList?.map((service: any, key: number) => (
                            <>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell align="left" padding="normal">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2">{service?.snapshotId}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" padding="normal">
                                    <label>
                                        <ListItemTag label='FAILED' fontColor='#fff' backgroundColor={'#CA3C3D'} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                    </label>
                                    </TableCell>
                                    <TableCell align="right" padding="normal">
                                        <IconButton onClick={() => handleFailedServiceModal(service)} sx={{ mt: -1 }} size="small">
                                        <ChevronRightIcon />
                                        </IconButton>
                                    </TableCell>
                                    
                                </TableRow>
                            </>
                        ))}
                    </TableBody>
                </Table>
            </>
            : 
            <Table>
                {pendingAndFailedReportsList.length !== 0 && pendingAndFailedReportsList !== undefined &&
                    <TableHead>
                        <TableRow>
                            <TableCell width="30%">
                           Due date&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="30%">
                            {t('lbl_report_no')}&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="30%">
                            Status&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="10%"></TableCell>
                        </TableRow>
                    </TableHead>
                }
                <TableBody>
                    {pendingAndFailedReportsList?.length !== 0 && pendingAndFailedReportsList !== undefined && pendingAndFailedReportsList?.map((service: any, key: number) => (
                        <>
                            <TableRow sx={{ backgroundColor: 'white' }}>
                            <TableCell align="left" padding="normal">
                                <label>
                                    {service?.serviceDate !== undefined &&
                                        <Typography variant="body1">{service?.serviceDate.split('-')[2]}/{service?.serviceDate.split('-')[1]}/{service?.serviceDate.split('-')[0]}</Typography>}
                                    </label>
                                </TableCell>

                                <TableCell align="left" padding="normal">
                                    <label>{<Typography variant="body1">{service?.snapshotId}</Typography>}</label>
                                </TableCell>

                                <TableCell align="left" padding="normal">
                                <label>
                                <ListItemTag label='FAILED' fontColor='#fff' backgroundColor={'#CA3C3D'} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                    </label>
                                </TableCell>

                                <TableCell align="right" padding="normal">
                                    <IconButton onClick={() => handleDelete(service?.snapshotId)} sx={{ mt: -1 }} size="small">
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </>
                    ))}
                </TableBody>
            </Table>
            }
        {pendingAndFailedReportsList.length === 0 &&
            <div>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            icon={
                                <Maintenance
                                    sx={{ color: '#727E84' }}
                                    fontSize="large"
                                />
                            }
                            description={[
                                <Typography
                                    sx={{
                                        color: '#6A767C',
                                        fontWeight: 600,
                                        fontSize: 14,
                                    }}
                                    key={0}
                                    color="action"
                                >
                                    No Failed Services
                                </Typography>,
                            ]}
                            title={undefined}
                        />
                    </Item>
                </Grid>
            </div>
        }
        <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={message}
            action={action}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
        <DeleteFailedServiceModal open={openDeleteService} setOpen={setOpenDeleteService} counter={counter} setCounter={setCounter} snapId={snapId} />
        <FailedServiceReportModal report={failedReport} open={openFailedReportModal} setOpen={setOpenFailedReportModal} setOpenDeleteService={setOpenDeleteService} />
    </div>
    );
}
