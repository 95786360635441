/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/require-await */

import { CurrentUserProps } from '../../components/ListAndGridView/panelEstateTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { settingsActionTypes } from '../constants/settingsActionTypes';
import { Dispatch } from 'react';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

const setCurrentUserSettings = (currentUser: CurrentUserProps): any => {
    return {
        type: settingsActionTypes.INSTALLER_SETTINGS,
        payload: currentUser,
    };
};

const setSelected = (selected: string): any => {
    return {
        type: settingsActionTypes.SET_SELECTED_SETTINGS,
        payload: selected,
    };
};

const setSettingsLanguages = (countries: []): any => {
    return {
        type: settingsActionTypes.INSTALLER_SETTINGS_COUNTRIES,
        payload: countries,
    };
};

const setEditSettings = (editSettingsStatus: number): any => {
    return {
        type: settingsActionTypes.EDIT_INSTALLER_SETTINGS,
        payload: editSettingsStatus,
    };
};

export function setDefaultStatusForCurrentUserSettings() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await dispatch(setEditSettings(0))
    }
}

export function fetchCurrentUserSettings() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setCurrentUserSettings(data))
                await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/countries`, {
                    method: 'GET',
                    credentials: 'include',
                })
                    .then((res) => res.json())
                    .then(async (data) => {
                        await dispatch(setSettingsLanguages(data))
                    })
            })
    }
}

export function getCurrentUserDetails() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setCurrentUserSettings(data))
            })
    }
}

export function setMenuSelected(route: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await dispatch(setSelected(route))
    }
}

export function editCompanyInfoSettings(body: any, currentUserId: number | undefined) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${currentUserId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((res) => {
            dispatch(setEditSettings(res.status));
        });
    }
}

export function editAccountHolderSettings(body: any, currentUserId: number | undefined, spokenPassword?: string, password?: string, changePwdBody?: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        try {
            // if (spokenPassword !== '' && spokenPassword !== undefined) {
            //     await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/spokenPassword`, {
            //         method: 'PUT',
            //         credentials: 'include',
            //         headers: {
            //             'Content-Type': 'application/json',
            //         },
            //         body: spokenPassword,
            //     }).then(async (resSpokenPwd) => {
            //         if (resSpokenPwd.status === 204) {
            //             if (password !== '' && password !== undefined) {
            //                 await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/change/password/${currentUserId}`, {
            //                     method: 'PUT',
            //                     credentials: 'include',
            //                     headers: {
            //                         'Content-Type': 'application/json',
            //                     },
            //                     body: JSON.stringify(changePwdBody),
            //                 }).then(async (resNewPassword) => {
            //                     if (resNewPassword.status === 204) {
            //                         await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${currentUserId}`, {
            //                             method: 'PUT',
            //                             credentials: 'include',
            //                             headers: {
            //                                 'Content-Type': 'application/json',
            //                             },
            //                             body: JSON.stringify(body),
            //                         }).then((res) => {
            //                             dispatch(setEditSettings(res.status));
            //                         });
            //                     } else {
            //                         dispatch(setEditSettings(resNewPassword.status));
            //                     }
            //                 });
            //             } else {
            //                 await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${currentUserId}`, {
            //                     method: 'PUT',
            //                     credentials: 'include',
            //                     headers: {
            //                         'Content-Type': 'application/json',
            //                     },
            //                     body: JSON.stringify(body),
            //                 }).then((res) => {
            //                     dispatch(setEditSettings(res.status));
            //                 });
            //             }
            //         } else {
            //             dispatch(setEditSettings(resSpokenPwd.status));
            //         }
            //     });
            // }

            if (spokenPassword !== '' && spokenPassword !== undefined) {
                    await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/spokenPassword`, {
                        method: 'PUT',
                        credentials: 'include',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: spokenPassword,
                    }).then(async (resSpokenPwd) => {
                        await dispatch(setEditSettings(resSpokenPwd.status));
                    });
                }

            if (password !== '' && password !== undefined) {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/change/password/${currentUserId}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(changePwdBody),
                }).then(async (resNewPassword) => {
                    if (resNewPassword.status === 204) {
                        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${currentUserId}`, {
                            method: 'PUT',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(body),
                        }).then((res) => {
                            dispatch(setEditSettings(res.status));
                        });
                    } else {
                        dispatch(setEditSettings(resNewPassword.status));
                    }
                });
            } else {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${currentUserId}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                }).then((res) => {
                    dispatch(setEditSettings(res.status));
                });
            }
        } catch (e) {
            dispatch(setEditSettings(400));
        }
    };
}
