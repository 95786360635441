/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/require-await */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import {
    Switch,
    Button,
    Divider,
    MobileStepper,
    Paper,
    useTheme,
    useMediaQuery,
    TextField,
    TableCell,
    Table,
    TableRow,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { UserEmailAlertsProps } from './UserEmailAlertsTypes';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { addUserEmailAlerts, fetchUserEmailAlerts } from '../../redux/actions/specificPanelActions';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function UserEmailAlertsModal({ open, setOpen }: UserEmailAlertsProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { specificPanel, userEmailAlerts, addUserEmailAlertsStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const dispatch = useTypedDispatch();
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [email, setEmail] = React.useState<string>('');
    const [sets, setSets] = React.useState(false);
    const [alarms, setAlarms] = React.useState(false);
    const [tampers, setTampers] = React.useState(false);
    const [system, setSystem] = React.useState(false);
    const [count, setCount] = React.useState(0);
    const [helperLoginText, setHelperLoginText] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [status, setStatus] = React.useState(false);
    const { t } = useTranslation();

    React.useEffect(() => {
        dispatch(fetchUserEmailAlerts(specificPanel?.panelId));
        if(status){
            
            if(addUserEmailAlertsStatus === 204){
                setMessage(`${t('msg_emailAlert_added')}`)
                setEmail('')
                setSets(false)
                setAlarms(false)
                setSystem(false)
                setTampers(false)
            }
            else{
                setMessage(`${t('lbl_someError')}`)
                setEmail('')
                setSets(false)
                setAlarms(false)
                setSystem(false)
                setTampers(false)
            }
            setStatus(false)
            setSnackOpen(true)
        }
    }, [count]);

    const handleClose = () => {
        setEmail('')
        setHelperLoginText('')
        setSets(false)
        setSystem(false)
        setAlarms(false)
        setTampers(false)
        setOpen(false);
    };

    const handleSave = async() => {
        let body
        if(userEmailAlerts?.siaEmailSettings){
              body = {
                device: { panelId: specificPanel?.panelId },
                siaEmailSettings: userEmailAlerts?.siaEmailSettings.concat([
                    {
                        alarmsEnabled: alarms,
                        email: email,
                        setEnabled: sets,
                        systemEnabled: system,
                        tampersEnabled: tampers,
                    },
                ])
            };
        }
        else{
            body = {
                device: { panelId: specificPanel?.panelId },
                siaEmailSettings: [
                    {
                        alarmsEnabled: alarms,
                        email: email,
                        setEnabled: sets,
                        systemEnabled: system,
                        tampersEnabled: tampers,
                    },
                ]
            };
        }
        
        await dispatch(addUserEmailAlerts(body));
        setStatus(true)
        setOpen(false);
        setCount((prev) => prev+1)
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const isValidEmail = (login: string) => {
        const isValid = /\S+@\S+\.\S+/.test(login);
        return isValid;
    };

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(e.target.value);
        if (!isValidEmail(e.target.value)) {
            setHelperLoginText(`${t('msg_email_invalid')}`);
        } else {
            setHelperLoginText('');
        }
    };
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: md ? '100%' : 400,
                        backgroundColor: 'background.paper',
                        boxShadow: 20,
                        p: 0,
                    }}
                >
                    {md ? (
                        <Paper
                            elevation={4}
                            sx={{
                                paddingTop: 6,
                                paddingBottom: 3,
                                paddingLeft: 2,
                                paddingRight: 2,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                            &ensp;&ensp;
                            <Typography sx={{ pt: 0.5 }} variant="h6">
                            {t('tooltip_usrEmail_alerts')}
                            </Typography>
                        </Paper>
                    ) : (
                        <Paper elevation={0} sx={{ pb: 1 }}>
                            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant="h6">
                            {t('tooltip_usrEmail_alerts')}
                            </Typography>
                        </Paper>
                    )}
                    <Divider />
                    <Paper
                        elevation={0}
                        sx={{
                            height: md ? '90vh' : 400,
                            overflow: 'auto',
                            paddingRight: md ? 4 : 2,
                            paddingLeft: md ? 4 : 2,
                            paddingTop: 2,
                        }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                fullWidth
                                label="Email Address"
                                variant="filled"
                                error={helperLoginText.length > 0}
                                helperText={helperLoginText}
                                onChange={(e) => handleEmail(e)}
                            />
                        </Grid>
                        <br></br>
                        <Grid item xs={12}>
                            <label>{t('lbl_events')}:</label>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_sets')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={sets}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setSets(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_alarms')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={alarms}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setAlarms(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_tampers')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={tampers}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setTampers(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_system')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={system}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setSystem(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    </Paper>
                    <Paper>
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            sx={
                                md
                                    ? {
                                          position: 'fixed',
                                          top: '100vh',
                                          transform: 'translateY(-100%)',
                                          width: '100%',
                                          p: 2,
                                          backgroundColor: 'inherit',
                                      }
                                    : {
                                          maxWidth: 400,
                                          flexGrow: 1,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                          paddingLeft: 3,
                                          paddingRight: 3,
                                          backgroundColor: 'inherit',
                                      }
                            }
                            nextButton={
                                <Button size="medium" disableElevation variant="contained" disabled={(email === '') || !isValidEmail(email) } onClick={handleSave}>
                                    {t('btn_save')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant="outlined" onClick={handleClose}>
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />
                    </Paper>
                </Box>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </div>
    );
}
