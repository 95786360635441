import { CurrentUserProps, EstateArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
import { UserGroupsProps } from './../../components/AccountUsers/AddUserModal/addUserModalTypes';
import { AccountUsersArrayProps } from '../../components/AccountUsers/AccountUsersTypes';
import { AccountUsersActionTypes } from '../constants/accountUsersActionTypes';
const initialState = {
    accountUsersList: <AccountUsersArrayProps>[],
    notificationEnabledUsersList: <AccountUsersArrayProps>[],
    userGroups: <UserGroupsProps>[],
    addUserStatus:<number>0,
    editUserStatus:<number>0,
    availableGroups:<EstateArrayProps>[],
    deleteUserResponse:<number>0,
    currentUserDetails: <CurrentUserProps>{}
}

export const accountUsersReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case AccountUsersActionTypes.ACCOUNT_USERS_LIST:
            return {...state, accountUsersList: action.payload }
            break;
        case AccountUsersActionTypes.USER_GROUPS_LIST:
            return {...state, userGroups: action.payload }
            break;
        case AccountUsersActionTypes.ADD_USER_STATUS:
            return {...state, addUserStatus: action.payload }
            break;
        case AccountUsersActionTypes.EDIT_USER_STATUS:
            return {...state, editUserStatus: action.payload }
            break;
        case AccountUsersActionTypes.DELETE_USER_STATUS:
            return {...state, deleteUserResponse: action.payload }
            break;
        case AccountUsersActionTypes.AVAILABLE_GROUPS:
            return {...state, availableGroups: action.payload }
            break;
            case AccountUsersActionTypes.CURRENTUSER:
            return {...state, currentUserDetails: action.payload }
            break;
        default:
            return state
            break;

    }
}