/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Divider, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReportGroupsProps } from '../ARCAccessModal/ARCAccessProps';
import { ZoneMonitoringArrayProps, ZoneMonitoringProps } from './ZonesMoniteringListTypes';
import { InfoListItem } from '@brightlayer-ui/react-components';

type ZonesMonitoringFormProps ={
    zones:ZoneMonitoringArrayProps
    availableGroups:ZoneMonitoringArrayProps
    setAvailableGroups: (availableGroups:ZoneMonitoringArrayProps) => void
}

export default function ZonesMoniteringList({ zones, availableGroups, setAvailableGroups }:ZonesMonitoringFormProps ): JSX.Element {
    const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [assignGroups, setAssignGroups] = React.useState(true)
  const [checkedList, setCheckedList] = React.useState<boolean[]>([]);
  let [modifiedZoneList, setModifiedZoneList] = React.useState<any[]>([]);
  let list : boolean[] = []

  if(assignGroups === true ){
    if(zones?.length > 0){
        // for(let i = 0 ; i<zones.length ; i++){
        //     checkedList[i] = zones[i].activityMonitorEnabled
        // }
        list = zones.map((z:ZoneMonitoringProps) => z.activityMonitorEnabled)
        setCheckedList(list)
        setAssignGroups(false);
    }
}

  React.useEffect(() => {   
    
    for (let i = 0; i < checkedList.length; i++) {
           // siaEvents[i] = reportGroups[i].customReportGroup;
           modifiedZoneList[i] = {
            id: zones[i].id,
            name:zones[i].name,
            panelId:zones[i].panelId,
            activityMonitorEnabled:checkedList[i]
           }
    }
    const filteredModifiedList = modifiedZoneList.filter((z:ZoneMonitoringProps,key:number) => z.activityMonitorEnabled !== zones[key].activityMonitorEnabled);
    setAvailableGroups(filteredModifiedList);
      
  }, [...checkedList]);

  console.log(availableGroups)

  const handleClick = (key: number): any => {
    const openListOne = checkedList.slice(0, key);
    const openListTwo = checkedList.slice(key + 1, checkedList.length);
    const openListThree = !checkedList[key];
    setCheckedList([...openListOne, openListThree, ...openListTwo]);
};

    return (
        <>
            {md && 
            <Typography sx={{pt:0,pb:2}} variant='body2'>
              Enabled zones will be monitored for activity in the 7 days prior to the report.
            </Typography>}
            <Divider />
            <FormGroup>
                {zones?.map((report: ZoneMonitoringProps, key: number) => (
                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                      key={key}
                      title={report.name}
                      backgroundColor={'#ffffff'}
                      dense={true}
                      fontColor={'#424e54'}
                      hidePadding={true}
                      iconAlign={'center'}
                      iconColor={'#424e54'}
                      divider={ key === (zones.length -1) ? undefined: 'full'}
                      rightComponent={<>
                         <label className="toggle-switch">
                                      <input type="checkbox" onClick={(): any => handleClick(key)} checked={checkedList[key]} />
                                     <span className="switch" />
                                  </label>
                        </>
                      }
                  />
                ))}
            </FormGroup>
            </>
     
    );
}
