import { NewEstateActionTypes } from './../constants/newEstateActionTypes';
const initialState = {
    newEstate : <number>0
}

export const newEstateReducer = (state:any = initialState  , action: { type: any; payload:any }):any => {
        switch(action.type){
            case NewEstateActionTypes.NEW_ESTATE : 
                                return {newEstateID : action.payload }
                                break;
            default :
                    return state
                    break;
            
        }
}