/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react'
import { InfoListItem, ScoreCard, EmptyState } from '@brightlayer-ui/react-components'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Button, Checkbox, Divider, Grid, IconButton, List, Paper, Stack, styled, Typography, useMediaQuery, useTheme, Tooltip, TablePagination } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { PanelContext, EstateContext } from '../ListAndGridView/PanelsView';
import { EstateProps, PanelProps, EstateArrayProps, PanelArrayProps } from './panelEstateTypes';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { PANEL_FILTER, SEARCH_PARAM_PANELS } from '../../constants/index';
import { useNavigate } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import PanelMenuList from '../PanelMenuButton/PanelMenuList';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import { setPanelView } from '../../redux/actions/specificPanelActions';
import { useSelector } from 'react-redux';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import PeopleIcon from '@mui/icons-material/People';
import { Report } from '@mui/icons-material';
import { userEmailAlertsForAllPanelsToEmpty } from '../../redux/actions/panelEstateActions';
import { useTranslation } from 'react-i18next';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import secureLocalStorage  from  "react-secure-storage";
import { url } from 'inspector';
import  panelImage from '../../assets/images/PanelsBackground.png'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

type Props = {
    page:number
    setPage: (page:number) => void
    path?:string
}

export default function GridOfPanels({page,setPage,path}:Props): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const panels: PanelArrayProps = React.useContext(PanelContext);
    const searchParam = secureLocalStorage.getItem(SEARCH_PARAM_PANELS);
    const filterOption = secureLocalStorage.getItem(PANEL_FILTER);
    const { installerEmailStatuses, userEmailAlerts, allPanels } = useSelector((state: any) => state.userReducer);
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [panelId, setPanelId] = React.useState<number | undefined>(undefined);
    const [panelName, setPanelName] = React.useState<string>('');
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const openSort = Boolean(anchorEl);
    const { t } = useTranslation();

    const handlePanelMenuClick = (event: React.MouseEvent<HTMLButtonElement>, panelID: number | undefined, panelsName: string): any => {
        setAnchorEl(event.currentTarget);
        setPanelId(panelID);
        setPanelName(panelsName);
    };

    useEffect(() => {
        dispatch(setPanelView('module'));
        setPage(0)
    }, [searchParam?.toString().length])

    const handlePanelClick = (id: number | undefined): any => {
        dispatch(userEmailAlertsForAllPanelsToEmpty())
        navigate(`/panel/${id}`)
    }

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getHeaderInfo = (panelStatus: String | undefined): any => {
        const status = panelStatus === 'CONNECTED' ? `${t('lbl_connected')}` : `${t('lbl_disconnected')}`;
        const cloudIcon = panelStatus === 'CONNECTED' ? <CloudDoneIcon fontSize='medium' /> : <CloudOffIcon fontSize='medium' />;
        return <React.Fragment>
            <view>
                {cloudIcon}
                <span style={{ verticalAlign: 'super', paddingLeft: '2%' }}>
                    <text>
                        {status}
                    </text>
                </span>
            </view>
        </React.Fragment>
    }

    return (
        <>
            {
                //panels
                panels.length > 0 &&
                    panels !== undefined &&
                    panels?.slice((page * rowsPerPage),(page * rowsPerPage) + rowsPerPage).map((panel: PanelProps, key: number) => (
                        <Grid item xs={md ? 12 : 4} key={key}>
                            <Item elevation={0}>
                                <ScoreCard
                                    nonce={undefined}
                                    onResize={undefined}
                                    onResizeCapture={undefined}
                                    headerTitle={panel.cloudReferenceName}
                                    headerSubtitle={panel.panelId}
                                    headerColor={panel.status === 'CONNECTED' ? '#178E0B' : '#727E84'}
                                    headerBackgroundImage={panelImage}
                                    headerInfo={getHeaderInfo(panel.status)}
                                    actionItems={[
                                        // <Checkbox
                                        //     icon={<StarBorderIcon sx={{ color: 'white' }} />}
                                        //     checkedIcon={<StarIcon sx={{ color: 'white' }} />}
                                        // />,
                                        <>
                                            <IconButton
                                                onClick={(event) =>
                                                    handlePanelMenuClick(event, panel.ID, panel.cloudReferenceName)
                                                }
                                            >
                                                <MoreVertIcon sx={{ color: 'white' }} />
                                            </IconButton>
                                            <PanelMenuList
                                                path={path}
                                                screen="overview"
                                                subEstates={undefined}
                                                estatePage={false}
                                                anchorEl={anchorEl}
                                                open={openSort}
                                                setAnchorEl={setAnchorEl}
                                                panelId={panelId}
                                                panelName={panelName}
                                            />
                                        </>,
                                    ]}
                                    actionLimit={2}
                                    actionRow={
                                        <List style={{ padding: 0 }}>
                                            <InfoListItem
                                                nonce={undefined}
                                                onResize={undefined}
                                                onResizeCapture={undefined}
                                                dense
                                                chevron
                                                title={`${t('lbl_goToPanel')}`}
                                                hidePadding
                                                onClick={() => handlePanelClick(panel.ID)}
                                            />
                                        </List>
                                    }
                                >
                                    <Stack
                                        sx={{
                                            marginLeft: '6%',
                                            marginRight: '6%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            p: 1,
                                        }}
                                        direction={'row'}
                                        spacing={'14%'}
                                    >
                                        <>
                                            {panel?.arcAccessEnabled === true ? (
                                                <Tooltip title={`${t('tooltip_ARC_Access')}`} arrow placement="top">
                                                    <Report sx={{color:'#005EAB'}} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={`${t('tooltip_ARC_Access')}`} arrow placement="top">
                                                    <Report color="action" />
                                                </Tooltip>
                                            )}

                                            {userEmailAlerts?.filter((user: any) => user?.device.ID === panel.ID)[0]?.siaEmailSettings?.length > 0 ?
                                                        <Tooltip title={`${t('tooltip_usrEmail_alerts')}`} arrow placement="top" >
                                                            <ContactMailIcon sx={{color:'#005EAB'}} />
                                                        </Tooltip> 
                                                        : 
                                                        <Tooltip title={`${t('tooltip_usrEmail_alerts')}`} arrow placement="top" >
                                                            <ContactMailIcon color="action" />
                                                        </Tooltip>
                                                }

                                            {panel.userAccessEnabled ? (
                                                <Tooltip title={`${t('tooltip_usrAccess')}`} arrow placement="top">
                                                    <PeopleIcon sx={{color:'#005EAB'}} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={`${t('tooltip_usrAccess')}`} arrow placement="top">
                                                    <PeopleIcon color="action" />
                                                </Tooltip>
                                            )}

                                            {installerEmailStatuses?.length >= 0 && installerEmailStatuses !== undefined &&
                                                    <>
                                                    {installerEmailStatuses?.slice(-(allPanels.length)).filter((arr: any) => arr?.ID === panel?.ID)[0]?.installerEmailAlertsStatus ? 
                                                        <Tooltip title={`${t('tooltip_installerEmail_alerts')}`} arrow placement="top" >
                                                            
                                                            <NotificationsActiveIcon sx={{color:'#005EAB'}} />
                                                            
                                                            </Tooltip>
                                                         : 
                                                         <Tooltip title={`${t('tooltip_installerEmail_alerts')}`} arrow placement="top" >
                                                            
                                                            <NotificationsActiveIcon color="action" />
                                                            
                                                            </Tooltip>
                                                    }
                                                    </>
                                                }
                                            {panel?.ardEnabled === true ? (
                                                <Tooltip title={`ARD Access`} arrow placement="top">
                                                    <Maintenance sx={{color:'#005EAB'}} />
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={`ARD Access`} arrow placement="top">
                                                    <Maintenance color="action" />
                                                </Tooltip>
                                            )}
                                        </>
                                    </Stack>
                                </ScoreCard>
                            </Item>
                        </Grid>
                    ))
            }

            {panels.length === 0 && filterOption !== '0' && (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div style={{ flex: '1 1 0px' }}>
                        <EmptyState
                            nonce={undefined}
                            onResize={undefined}
                            onResizeCapture={undefined}
                            icon={<FilterListOffIcon fontSize={'inherit'} />}
                            title={`${t('msg_no_panels_found')}`}
                        />
                    </div>
                </div>
            )}

            {panels.length === 0 && searchParam !== '' && searchParam !== null && filterOption === '0' &&  (
                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                    <div style={{ flex: '1 1 0px' }}>
                        <EmptyState
                            nonce={undefined}
                            onResize={undefined}
                            onResizeCapture={undefined}
                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                            title={`${t('msg_noSearch_results')} "${searchParam}"...`}
                        />
                    </div>
                </div>
            )}
            <Grid item xs={12}>
                <TablePagination
                    component="div"
                    count={panels?.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 50, 100, 250, 500]}
                    onRowsPerPageChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChangeRowsPerPage(e)}
                    showFirstButton
                    showLastButton
                />
            </Grid>
        </>
    );
}