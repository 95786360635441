/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, MobileStepper, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import './pwdReset.css';
import logo from '../assets/images/eaton_stacked_logo.png'
import tile from '../assets/images/tileGrad.png'
import { useTypedDispatch } from '../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchResetPassword } from '../redux/actions/registerScreenActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
    borderRadius:10,
  };


export default function PasswordReset():JSX.Element {
  //const [confirmPwd, setConfirmPwd] = React.useState('')
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate()
  const dispatch = useTypedDispatch()
  const [password, setPassword] = React.useState('')
  const [confirmPwd, setConfirmPwd] = React.useState('')
  const [helperText, setHelperText] = React.useState('')
  const [helperTextPwd, setHelperTextPwd] = React.useState('')
  const [spokenHelperText, setSpokenHelperTest] = React.useState('')
  const { t, i18n } = useTranslation();
  const { user, login, hash, encexp } = useParams();

  const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPwd(e.target.value)
    if(e.target.value === password || password.length === 0){
        setHelperText('')
    }
    else{
        setHelperText(`Unmatched passwords!`)
    }
  }

  const isValidPwd = (pwd:string) => {
    const valid = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(pwd)
    return valid
  }

  const handlePwd = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPassword(e.target.value);
      if(e.target.value.length >= 8){
        if (isValidPwd(e.target.value)){
          setHelperTextPwd('');
        }
        else{
          setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
        }
      }
      else if(e.target.value.length > 0 && e.target.value.length < 8){
        setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
      }
      else{
        setHelperTextPwd('This field is required')
      }
  };

  const handleSetPassword = () => {
     dispatch(fetchResetPassword(login, hash, navigate, password))
  }

  return (
      <div id="main">
          <Box
              sx={
                  md
                      ? {
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100vw',
                            height: '100dvh',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }
                      : style
              }
          >
              <Paper elevation={0} sx={{ overflow: 'hidden' }}>
                  <Paper
                      elevation={0}
                      sx={{
                          paddingTop: 6,
                          paddingBottom: 3,
                          paddingLeft: 4,
                          paddingRight: 4,
                          backgroundImage: `url(${tile})`,
                      }}
                  >
                      <img src={logo} alt="logo" />
                  </Paper>
              </Paper>
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
                  <Stack direction={'column'} spacing={2}>
                      <Typography variant="h6">{t('lbl_pwdReset')}</Typography>
                      <Typography variant="body1">
                      {t('lbl_pwdResetMsg')}
                      </Typography>
                  </Stack>
              </Paper>
              <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                  <Grid container spacing={3}>
                      <Grid item xs={12}>
                          <TextField
                              inputProps={{ maxLength: 24 }}
                              fullWidth
                              id="filled-basic"
                              label={t('lbl_pwd')}
                              variant="filled"
                              value={password}
                              onChange={(e) => {
                                  handlePwd(e);
                              }}
                              helperText={helperTextPwd}
                              error={helperTextPwd.length > 0}
                              type="password"
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                              fullWidth
                              id="filled-basic"
                              label={t('lbl_cnfrmPwd')}
                              variant="filled"
                              helperText={helperText}
                              error={helperText.length > 0 && password.length > 0}
                              value={confirmPwd}
                              onChange={(e) => handleRepeatPassword(e)}
                              type="password"
                          />
                      </Grid>
                  </Grid>
              </Box>
              <MobileStepper
                  variant="dots"
                  steps={0}
                  position="static"
                  activeStep={0}
                  sx={
                      md
                          ? {
                                zIndex: 1000,
                                position: 'fixed',
                                top: '100dvh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                                p: 2,
                                backgroundColor: 'inherit',
                            }
                          : {
                                maxWidth: 400,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                                borderRadius:1,
                            }
                  }
                  nextButton={
                      <Button size="medium" variant="contained" onClick={handleSetPassword}>
                          {t('lbl_setPwd')}
                      </Button>
                  }
                  backButton={undefined}
              />
          </Box>
      </div>
  );
}
