/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Checkbox, Divider, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { PanelArrayProps } from '../ListAndGridView/panelEstateTypes';
import { useTranslation } from 'react-i18next';

type nestedListProps = {
  panels:PanelArrayProps
  setDeviceList: (deviceList : any) => void
}

export default function PanelList({panels,setDeviceList}:nestedListProps):JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [open, setOpen] = React.useState(true);
  const [count, setCount] = React.useState(0);
  const [checked, setChecked] = React.useState<boolean[]>([])
  const [deviceIdList, setDeviceIdList] =React.useState<any>([])
  const { t } = useTranslation();
  
  if (panels !== undefined) {
    deviceIdList.length = checked.length = panels.length;
  }
 
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{ width: '100%', maxWidth: 360, paddingTop:0, paddingBottom:5, paddingLeft:1, paddingRight:1 }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick} sx={{alignItems:'left', pl : 0,pt:3,width:md? 390:390}} >

        {open ? (count === 0 ? (<><ListItemText sx={{pl:3,color:'#007BC1'}} primary={t('lbl_selectedPanels')} /><ExpandLess /></>):
        (count === 1 ? (
          <><ListItemText sx={{pl:3,color:'#007BC1'}} primary={`${count} ${t('lbl_panlSelected')}`} /><ExpandLess /></>
          ):(
            <><ListItemText sx={{pl:3,color:'#007BC1'}} primary={`${count} ${t('lbl_panlsSelected')}`} /><ExpandLess /></>
            ))):(count === 0 ? (<><ListItemText sx={{pl:3,color:'#007BC1'}} primary={`${t('lbl_selectedPanels')}`} /><ExpandMore /></>):
            (count === 1 ? (
              <><ListItemText sx={{pl:3,color:'#007BC1'}} primary={`${count} ${t('lbl_panlSelected')}`} /><ExpandMore /></>
              ):(
                <><ListItemText sx={{pl:3,color:'#007BC1'}} primary={`${count} ${t('lbl_panlsSelected')}`} /><ExpandMore /></>
                )))}
      </ListItemButton>
      <Divider />
      <Collapse in={open} timeout="auto">
        <Paper elevation={0} sx={{maxHeight: 300 ,overflow:'auto',width:md? 390:390}} >
        <List component="div" disablePadding>
         {panels !== undefined && panels.map((panel,key:number) => 
         <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined} 
                key={key}
                 title={<Typography variant='body1'>{panel.cloudReferenceName}</Typography>}
                 icon={
                 <Checkbox icon={<CheckBoxOutlineBlankIcon />} 
                 checkedIcon={<CheckBoxIcon />}
                  onChange={(e) => {
                    if(e.target.checked === true){
                      checked[key] = true;
                      deviceIdList[key] = panel.ID;
                    }
                    else{
                        checked[key] = false
                        deviceIdList[key] = 0;
                    }
                    if(checked[key] === true){
                      setCount((prev) => prev+1)
                    }
                    else{
                      setCount((prev) => prev-1)
                    }
                    const list = deviceIdList.filter((dev :any) => dev > 0 )
                    setDeviceList(list)
                  }}
                 />
                }
                 divider='full'
                 iconAlign='left'
             />)}
          
        </List>
        </Paper>
      </Collapse>
    </List>
  );
}
