/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { AppBar, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '@brightlayer-ui/react-components';
import { ARCReportModalProps } from './PanelARCReportingTypes';
import { useTranslation } from 'react-i18next';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getSeconds, getMonth, getTimeFormat, getYear } from '../Timestamp/timestampCal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 0,
};

export default function ARCReportModal({arcReport, open, setOpen }: ARCReportModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();

    React.useEffect(() => {}, []);

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: md ? '0%' : '45%',
                        left: md ? '0%' : '50%',
                        transform: md ? 'translate(0%, 0%)' : 'translate(-50%, -50%)',
                        width: md ? '100%' : 400,
                        height: md ? '100%' : '60%',
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}
                >
                    {md ? (
                        <AppBar
                            elevation={2}
                            sx={{
                                pl: 2,
                                pt: 4,
                                pb: 2,
                                backgroundColor: 'white',
                            }}
                            position="static"
                        >
                            <Toolbar variant="dense">
                                <IconButton onClick={handleClose} edge="start" aria-label="menu" sx={{ mr: 2 }}>
                                    <CloseIcon color="action" />
                                </IconButton>
                                <Typography variant="h6" color="#424E54" component="div">
                                    {t('lbl_evntDetails')}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography sx={{ p: 2 }} id="modal-modal-title" variant="h6" component="h2">
                                    {t('lbl_evntDetails')}
                                </Typography>
                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </>
                    )}
                    <Paper
                        elevation={0}
                        sx={{
                            pl: md ? 3 : 0,
                            maxHeight: md ? '80%' : 450,
                            overflow: 'auto',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_ARCProvider')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.arcProvider
                                ? arcReport?.arcProvider === 'SIA_IP'
                                    ? `${t('lbl_SIA_IP')}`
                                    : arcReport?.arcProvider === 'CSL_DUAL_COM'
                                    ? `${t('lbl_CLS_Dualcom')}`
                                    : 'WebWayOne'
                                : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_ARCCode')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.code ? arcReport?.code : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_response')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.responseText ? arcReport?.responseText : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_SIAEvnt')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.ascii ? arcReport?.ascii : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_data')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.data ? arcReport?.data : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_state')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.state
                                ? arcReport?.state === 'NACK_NO_RESPONSE_FROM_ARC'
                                    ? 'No response'
                                    : arcReport?.state === 'SUSPENDED_ON_CLOUD'
                                    ? 'Rejected'
                                    : arcReport?.state === 'QUEUED'
                                    ? 'Ready for delivering'
                                    : 'Successfully Sent'
                                : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_accntNo')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.transmitterId ? arcReport?.transmitterId : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_signal_Timeout')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.signalTimeout ? arcReport?.signalTimeout : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_sysID')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {arcReport?.systemId ? arcReport?.systemId : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_date')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {getDate(arcReport?.raiseTimestamp || 0)}/{getMonth(arcReport?.raiseTimestamp || 0)}/
                            {getYear(arcReport?.raiseTimestamp || 0)},{getHours(arcReport?.raiseTimestamp || 0)}:
                            {getMinutes(arcReport?.raiseTimestamp || 0)}:{getSeconds(arcReport?.raiseTimestamp || 0)}
                        </Typography>
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
}
