/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { AppBar, IconButton, Theme, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Menu from '@mui/icons-material/Menu';
import { Spacer, ThreeLiner, ToolbarMenu } from '@brightlayer-ui/react-components';
import { useDrawer } from '../../contexts/drawerContextProvider';
import Usermenu from '../UserMenu/UserMenu';
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor:"#007BC1",
            color:"#fff"
        },
    })
);

type appbarProps = {
    title?: string;
    toolbarMenu?: boolean;
    subtitle?: any;
    panels?: {
        name?: string;
        status?: boolean;
        alarms?: number;
        ARCAccess?: boolean;
        userAccess?: boolean;
    }[];
    panelNames?: any[];
};

export default function Appbar({ toolbarMenu, subtitle, title, panelNames }: appbarProps): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const { setDrawerOpen } = useDrawer();
    const md = useMediaQuery(theme.breakpoints.up('md'));

    const menuGroups = [
        {
            items: panelNames,
        },
    ];

    return (
        <>
            <AppBar position={'sticky'}>
                <Toolbar className={classes.toolbar}>
                    {md ? null : (
                        <IconButton
                            color={'inherit'}
                            onClick={(): void => {
                                setDrawerOpen(true);
                            }}
                            edge={'start'}
                            style={{ marginRight: theme.spacing(3) }}
                            size="large"
                        >
                            <Menu />
                        </IconButton>
                    )}
                    <ThreeLiner nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                        title={title}
                        subtitle={
                            toolbarMenu && (
                                <ToolbarMenu nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                    label={subtitle}
                                    menuGroups={[
                                        {
                                            items: [],
                                        },
                                    ]}
                                />
                            )
                        }
                        info={''}
                    />
                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                    <Usermenu />
                </Toolbar>
            </AppBar>
        </>
    );
}
