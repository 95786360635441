/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import { Spacer } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type ReportDetailsProps = {
    report: any
    open: boolean
    setOpen: (open: boolean) => void
    setOpenDeleteService: (openDeleteService: boolean) => void
}

export default function FailedServiceReportModal({ report, open, setOpen, setOpenDeleteService }: ReportDetailsProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        setOpenDeleteService(true)
    };

   

    return (
        <div>
            <>
                <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }}
                    >
                        <Paper elevation={0} sx={{ pb: 0 }}>
                            <Paper
                                elevation={4}
                                sx={{
                                    paddingTop: 2,
                                    paddingBottom: 1,
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                                &ensp;&ensp;
                                <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('lbl_reportDetails')}
                                </Typography>
                            </Paper>
                            <Divider />
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    paddingTop: 3,
                                    paddingBottom: 5,
                                    paddingLeft: 5,
                                    paddingRight: 3,
                                    maxHeight: 800,
                                    overflow: 'auto',
                                }}
                            >                              
                               <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_panelId')}</Typography>
                                        <Typography variant='body2'>{report?.panelId}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_systmName')}</Typography>
                                        <Typography variant='body2'>-</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_status')}</Typography>
                                        <Typography variant="body2">{t('lbl_failed')}</Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: '3vh' }}>
                                        <Typography variant='subtitle1' color={'primary'}>{t('lbl_dueDate')}</Typography>
                                        {report?.serviceDate !== undefined &&
                                            <Typography variant="body2">
                                            {report?.serviceDate.split('-')[2]}/{report?.serviceDate.split('-')[1]}/{report?.serviceDate.split('-')[0]}
                                        </Typography>}
                                    </div>
                                </div>
                            </Box>
                        </Paper>

                        <Paper
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '100dvh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                            }}
                        >
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={0}
                                sx={{
                                    maxWidth: '100%',
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button onClick={handleDelete} size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D',":hover":{backgroundColor:'#CA3C3D'} }} variant='contained'>
                                    {t('lbl_delete')}
                                  </Button>
                                }
                                backButton={
                                    undefined
                                }
                            />
                        </Paper>
                    </Box>
                </Modal>
            </>
        </div>
    );
}
