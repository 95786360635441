/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';

type Props = {
    open:boolean
    setOpen:(open:boolean) => void
}

export default function RemoteServiceInitiationMessageModal({ open, setOpen }:Props): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useTypedDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const [count, setCount] = React.useState(0); 
  const { t } = useTranslation();


  const handleOkay = () => {
    setOpen(false);
  }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: md ? '90dvw' : 600,
          bgcolor: 'background.paper',
          border: '0px solid #000',
          boxShadow: 20,
          p: 0,
        }} >
          <Paper elevation={0} sx={{ pb: 1 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{t('msg_remoteSvc_initiated')}</Typography>
            <Divider />
            <Typography sx={{ pl: 2, pb: 2, pt: 2 }} >{t('lbl_rmtServInitiationMsg')}</Typography>
          </Paper>
          <Divider />
          <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 600, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <Button size="medium" disableElevation  variant='contained' onClick={handleOkay}>
                  {t('lbl_okay')}
                </Button>
              }
              backButton={
                undefined
              }
            />
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}


