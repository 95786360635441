/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Divider, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
      firstPhoneNumber:string
      firstPhoneType:string
      secondPhoneNumber:string
      secondPhoneType:string
      thirdPhoneNumber:string
      thirdPhoneType:string
      setFirstPhoneNumber: (firstPhoneNumber:string) => void
      setFirstPhoneType: (firstPhoneType:string) => void
      setSecondPhoneNumber: (secondPhoneNumber:string) => void
      setSecondPhoneType: (secondPhoneType:string) => void
      setThirdPhoneNumber: (thirdPhoneNumber:string) => void
      setThirdPhoneType: (thirdPhoneType:string) => void
}

export default function ContactDetails({
  firstPhoneNumber,setFirstPhoneNumber,firstPhoneType,setFirstPhoneType,
  secondPhoneNumber,setSecondPhoneNumber,secondPhoneType,setSecondPhoneType,
  thirdPhoneNumber,setThirdPhoneNumber,thirdPhoneType,setThirdPhoneType
}:Props):JSX.Element {

  const [helperText, setHelperText] = React.useState('')
  const [helperTextTwo, setHelperTextTwo] = React.useState('')
  const [helperTextThree, setHelperTextThree] = React.useState('')

  const isValidNumber = (text:string) => {
    const valid = /^\d+$/.test(text)
    return valid
  }

  const phoneTypes = ['HOME','OFFICE','MOBILE']
  const { t, i18n } = useTranslation();
 
  return (
      <div>
        <Paper elevation={0} sx={{ paddingTop:2, paddingBottom:3, paddingLeft:3, paddingRight:2 }}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant='h6'>{t('lbl_contactDetails')}</Typography>
              <Typography variant='body1'>
              {t('txt_contactDetails')}
              </Typography>
              </Stack>
        </Paper>
            <Divider />
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3, maxHeight:'60vh',overflow:'auto' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" 
                      label={t('lbl_firstPhone')} variant="filled" 
                      helperText={helperText}
                      error={helperText.length > 0}
                      value={firstPhoneNumber} onChange={(e) => {
                        setFirstPhoneNumber(e.target.value)
                        if(e.target.value.length > 0){
                          if(isValidNumber(e.target.value)){
                            setHelperText('')
                          }
                          else{
                            setHelperText('Input contains prohibited characters')
                          }
                        }
                        else{
                          setHelperText('This field is required')
                        }
                      }}
                     />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic"  select
                      label={t('lbl_phoneType')} variant="filled" 
                      value={firstPhoneType} onChange={(e) => setFirstPhoneType(e.target.value)}
                       >
                        {phoneTypes.map((user,i) => (
                            <MenuItem key={i} value={user}>
                                {user}
                            </MenuItem>
                        ))}
                       </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" 
                      label={t('lbl_secondPhone')} variant="filled" 
                      helperText={helperTextTwo}
                      error={helperTextTwo.length > 0}
                      value={secondPhoneNumber} onChange={(e) => {
                        setSecondPhoneNumber(e.target.value)
                        if(e.target.value.length > 0){
                          if(isValidNumber(e.target.value)){
                            setHelperTextTwo('')
                          }
                          else{
                            setHelperTextTwo('Input contains prohibited characters')
                          }
                        }
                        else{
                          setHelperTextTwo('')
                        }
                      }}
                     />
              </Grid>
              <Grid item xs={12}>
              <TextField fullWidth id="filled-basic"  select
                      label={t('lbl_phoneType')} variant="filled" 
                      value={secondPhoneType} onChange={(e) => setSecondPhoneType(e.target.value)}
                       >
                        {phoneTypes.map((user,i) => (
                            <MenuItem key={i} value={user}>
                                {user}
                            </MenuItem>
                        ))}
                       </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" 
                      label={t('lbl_thirdPhone')} variant="filled" 
                      helperText={helperTextThree}
                      error={helperTextThree.length > 0}
                      value={thirdPhoneNumber} onChange={(e) => {
                        setThirdPhoneNumber(e.target.value)
                        if(e.target.value.length > 0){
                          if(isValidNumber(e.target.value)){
                            setHelperTextThree('')
                          }
                          else{
                            setHelperTextThree('Input contains prohibited characters')
                          }
                        }
                        else{
                          setHelperTextThree('')
                        }
                      }}
                     />
              </Grid>
              <Grid item xs={12}>
              <TextField fullWidth id="filled-basic"  select
                      label={t('lbl_phoneType')} variant="filled" 
                      value={thirdPhoneType} onChange={(e) => setThirdPhoneType(e.target.value)}
                       >
                        {phoneTypes.map((user,i) => (
                            <MenuItem key={i} value={user}>
                                {user}
                            </MenuItem>
                        ))}
                       </TextField>
              </Grid>
              
            </Grid>
            </Box>
            </div>
  );
}
