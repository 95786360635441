/* eslint-disable @typescript-eslint/naming-convention */

export const remoteServicingActionTypes = {
    All_REPORTS : 'All_REPORTS',
    All_PENDING_AND_FAILED_REPORTS:'All_PENDING_AND_FAILED_REPORTS',
    DELETE_REPORTS : 'DELETE_REPORTS',
    PERFORM_REMOTE_SERVICING: 'PERFORM_REMOTE_SERVICING',
    PERFORM_REMOTE_SERVICING_MESSAGE:'PERFORM_REMOTE_SERVICING_MESSAGE',
    GET_PERFORM_REMOTE_SERVICING_STATUS: 'GET_PERFORM_REMOTE_SERVICING_STATUS',
    GET_DELETE_FAILED_SERVICE_STATUS:'GET_DELETE_FAILED_SERVICE_STATUS',
    GET_ARD_SCHEDULER:'GET_ARD_SCHEDULER',
    GET_ARD_SCHEDULER_STATUS:'GET_ARD_SCHEDULER_STATUS',
    GET_ARD_EMAIL_REGISTER_STATUS:'GET_ARD_EMAIL_REGISTER_STATUS',
    GET_ARD_EMAIL_DESTINATIONS:'GET_ARD_EMAIL_DESTINATIONS',
    GET_ARD_EMAIL_DELETE_STATUS:'GET_ARD_EMAIL_DELETE_STATUS',
    PERFORM_REMOTE_SERVICING_DATA:'PERFORM_REMOTE_SERVICING_DATA',
}