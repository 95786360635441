export const authWorkflowGerman =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Falsche Email oder Passwort',
            INVALID_CREDENTIALS: 'Ihre Kombination aus Benutzername und Passwort wird nicht erkannt.',
            GENERIC_ERROR: 'Ihre Anfrage konnte derzeit nicht bearbeitet werden.',
        },
        HEADER: {
            FORGOT_PASSWORD: 'Passwort vergessen',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Bitte wählen Sie ein Passwort. Stellen Sie sicher, dass Ihr Passwort die unten aufgeführten erforderlichen Komplexitätsanforderungen erfüllt.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                'Bitte geben Sie die mit dem Konto verknüpfte Konto-E-Mail-Adresse ein.<br/><br/>' +
                `Wenn für diese E-Mail ein Konto bei Eaton vorhanden ist, erhalten Sie innerhalb von 24 Stunden eine Antwort <1>{{time}}</1>.`,
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowGerman =  {
    translation: {
        ACTIONS: {
            FINISH: 'Beenden',
            NEXT: 'Nächste',
            BACK: 'Zurück',
            CREATE_ACCOUNT: 'Benutzerkonto erstellen',
            OKAY: 'Okay',
            CANCEL: 'Stornieren',
            CONTINUE: 'Weitermachen',
            DONE: 'Erledigt',
            LOG_IN: 'Anmeldung',
            LOG_OUT: 'Ausloggen',
            RESEND: 'Nochmals senden',
            REMEMBER: 'Erinnere dich an mich',
            SUBMIT: 'Einreichen',
        },
        LABELS: {
            EMAIL: 'E-Mail-Adresse',
            PASSWORD: 'Passwort',
            FORGOT_PASSWORD: 'Haben Sie Ihr Passwort vergessen?',
            NEED_ACCOUNT: 'Brauche ein Konto?',
        },
        MESSAGES: {
            EMAIL_SENT: 'E-Mail gesendet',
            WELCOME: 'Willkommen',
            REQUEST_ERROR: 'Leider ist beim Senden Ihrer Anfrage ein Problem aufgetreten.',
            ERROR: 'Fehler!',
            EMAIL_ENTRY_ERROR: 'Bitte geben Sie eine gültige Email-Adresse ein',
            CONTACT: 'Kontaktieren Sie einen Eaton-Supportmitarbeiter',
            LOADING: 'Wird geladen...',
            PASSWORD_REQUIRED_ERROR: 'Passwort erforderlich',
        },
        FORMS: {
            FIRST_NAME: 'Vorname',
            LAST_NAME: 'Familienname, Nachname',
            PASSWORD: 'Passwort',
            CONFIRM_PASSWORD: 'Bestätige das Passwort',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 Zeichen',
            NUMBERS: 'Eine Nummer',
            UPPER: 'Ein Großbuchstabe',
            LOWER: 'Ein kleiner Buchstabe',
            SPECIAL: 'Ein besonderer Charakter',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowGerman = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: 'Endbenutzer-Lizenzvereinbarung wird geladen...',
                AGREE_TERMS: 'Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Ein Konto erstellen',
                LICENSE: 'Lizenzvereinbarung',
                VERIFY_EMAIL: 'E-Mail bestätigen',
                PASSWORD: 'Passwort erstellen',
                ACCOUNT_DETAILS: 'Kontodetails',
                COMPLETE: 'Account erstellt!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: 'Geben Sie unten Ihre Daten ein, um die Kontoerstellung abzuschließen.',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `Um sich für ein Eaton-Konto zu registrieren, geben Sie unten die erforderlichen Informationen ein. Sie müssen Ihre E-Mail-Adresse bestätigen, um fortzufahren.`,
                VERIFY_EMAIL: {
                    RESEND: 'Bestätigungsmail erneut senden',
                    VERIFICATION: 'Bestätigungscode',
                },
            },
        },
    },
};