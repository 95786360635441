/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/require-await */


import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { Dispatch } from 'react';
import { systemToolsType } from "../constants/systemToolActionType"
import { AppUserReports, ArcDownloadReports, InstallerDownloadReports, ManagedPanelReports } from '../../components/SupportTools/SupportToolsDownloadReports';


const setEnableRegistration = (checkStatus: number): any => {
    return {
        type: systemToolsType?.ENABLE_REGISTRATION,
        payload: checkStatus
    }
}

const setDisableRegistration = (checkDisableStatus: number): any => {
    return {
        type: systemToolsType?.DISABLE_REGISTRATION,
        payload: checkDisableStatus
    }
}

const setMaintenanceNotification = (checkMaintenance: number): any => {
    return {
        type: systemToolsType?.SEND_MAINTENANCE_NOTIFICATION,
        payload: checkMaintenance
    }
}

const setGenerateTestNotification = (checkNotification: number): any => {
    return {
        type: systemToolsType?.GENERATE_TEST_NOTIFICATION,
        payload: checkNotification
    }
}

const setRemovePairing = (checkParingStatus: number): any => {
    return {
        type: systemToolsType?.REMOVE_PAIRING,
        payload: checkParingStatus
    }
}

const setRemoveApplication = (checkApplicationStatus: number): any => {
    return {
        type: systemToolsType?.REMOVE_APPLICATION_USER,
        payload: checkApplicationStatus
    }
}

const setAppUserDownloadReports = (appUser: AppUserReports): any => {
    return {
        type: systemToolsType?.APP_USER_DOWNLOAD_USER,
        payload: appUser
    }
}

const setManagedDownloadReports = (managedPanel: ManagedPanelReports): any => {
    return {
        type: systemToolsType?.MANAGED_PANELS_DOWNLOAD_REPORTS,
        payload: managedPanel
    }
}

const setUnmanagedDownloadReports = (unmanagedPanel: ManagedPanelReports): any => {
    return {
        type: systemToolsType?.UNMANAGED_PANELS_DOWNLOAD_REPORTS,
        payload: unmanagedPanel
    }
}

const setARCDownloadReports = (arcReports: ArcDownloadReports): any => {
    return {
        type: systemToolsType?.ARC_DOWNLOAD_REPORTS,
        payload: arcReports
    }
}

const setInstallerDownloadReports = (installerReports: InstallerDownloadReports): any => {
    return {
        type: systemToolsType?.INSTALLER_DOWNLOAD_REPORTS,
        payload: installerReports
    }
}

export function getEnableRegistration(selectedMenu: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${selectedMenu}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(async (res) => {
                await dispatch(setEnableRegistration(res.status))
            })
    }
}

export function getDisableRegistration(selectedMenu: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${selectedMenu}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(async (res) => {
                await dispatch(setDisableRegistration(res.status))
            })
    }
}

export function getMaintenanceNotification(selectedMenu: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/${selectedMenu}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(async (res) => {
                await dispatch(setMaintenanceNotification(res.status))
            })
    }
}

export function getGenerateTestNotification(selectedMenu: string, userEmail: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/push/${selectedMenu}/${userEmail}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(async (res) => {
                await dispatch(setGenerateTestNotification(res.status))
            })
    }
}


export function getRemovePairing(selectedMenu: string, panelId: any, userEmail: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/pair/${selectedMenu}/${panelId}/${userEmail}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            dispatch((setRemovePairing(res.status)))
        })
    }
}

export function getRemoveApplication(selectedMenu: string, userEmail: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/pair/${selectedMenu}/full/${userEmail}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => {
            dispatch((setRemoveApplication(res.status)))
        })
    }
}

export function getAppUserDownloadReports() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/reports/appUsersReport?limit=&search=&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setAppUserDownloadReports(
                    data?.map((appuser: any) => ({
                        creationTimeInMilliSeconds: new Date(appuser?.creationTimeInMilliSeconds).toUTCString(),
                        firstName: appuser?.firstName,
                        lastName: appuser?.lastName,
                        login: appuser?.login
                    }))

                ))
            })
    }
}


export function getManagedDownloadReports() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/managed?limit=&search=&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setManagedDownloadReports(
                    data?.map((appuser: any) => ({
                        cloudReferenceName: appuser?.cloudReferenceName,
                        name: `${appuser?.installerUser?.firstName} ${appuser?.installerUser?.lastName}`,
                        cloudID: appuser?.installerUser?.installerInfo?.cloudId,
                        status: appuser?.status,
                        arcAccessEnabled: appuser?.arcAccessEnabled === true ? "Enabled" : appuser?.arcAccessEnabled === false ? "Disable" : "",
                        userAccessEnabled: appuser?.userAccessEnabled === true ? "Enabled" : appuser?.userAccessEnabled === false ? "Disable" : ""
                    }))

                ))
            })
    }
}

export function getUnmanagedDownloadReports() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/find?all=true&limit=&search=&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setUnmanagedDownloadReports(
                    data?.map((appuser: any) => ({
                        cloudReferenceName: appuser?.cloudReferenceName,
                        status: appuser?.status,
                        arcAccessEnabled: appuser?.arcAccessEnabled === true ? "Enabled" : appuser?.arcAccessEnabled === false ? "Disable" : "",
                        userAccessEnabled: appuser?.userAccessEnabled === true ? "Enabled" : appuser?.userAccessEnabled === false ? "Disable" : ""
                    }))

                ))
            })
    }
}

export function getARCDownloadReports() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/reports/arcReport?limit=&search=&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setARCDownloadReports(
                    data?.map((appuser: any) => ({
                        panelName: appuser?.panelName,
                        panelId: appuser?.panelId,
                        cloudId: appuser?.cloudId,
                        provider: appuser?.provider,
                        verified: appuser?.verified === true ? "Verified" : appuser?.verified === false ? "Unverified" : "",
                        lastMessageASCII: appuser?.lastMessageASCII,
                        lastMessageStatus: appuser?.lastMessageStatus === "ACK" ? "Successfully sent" : appuser?.lastMessageStatus === "NACK_NO_RESPONSE_FROM_ARC" ? "No response from ARC" : "",
                        firstConnectionDate: new Date(appuser?.firstConnectionDate).toUTCString()
                    }))

                ))
            })
    }
}

export function getInstallerDownloadReports() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/reports/installerReport?limit=&search=&start=0`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setInstallerDownloadReports(
                    data?.map((appuser: any) => ({
                        name: `${appuser?.firstName} ${appuser?.lastName}`,
                        cloudId: appuser?.cloudId,
                        email: appuser?.email,
                        installerCompanyName: appuser?.installerCompanyName,
                        fullAddress: `${appuser?.country},${appuser?.address},${appuser?.postCode}`,
                        contact: `${appuser?.firstNumber} ${appuser?.firstPhoneType} ${appuser?.secondNumber} ${appuser?.secondPhoneType} ${appuser?.thirdNumber} ${appuser?.thirdPhoneType}`,
                        deviceCount: appuser?.deviceCount
                    }))

                ))
            })
    }
}