/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Visão geral",
    "menu_endUserInfo": "Informações do usuário final",
    "menu_accntUsrs": "Usuários da conta",
    "menu_serviceReports": "Relatórios de serviço",
    "menu_settings": "Configurações",
    "menu_help": "Informação",
    "lbl_cloudId": "ID da nuvem",
    "lbl_logOut": "Sair",
    "lbl_contactSupport": "CONTATO COM O SUPORTE",
    "lbl_estates": "Propriedades",
    "lbl_ESTATES": "PROPRIEDADES",
    "lbl_panels": "Painéis",
    "lbl_PANELS": "PAINÉIS",
    "lbl_connected": "Conectada",
    "lbl_disconnected": "Desconectado",
    "lbl_manageEstates": "Gerenciar propriedades",
    "lbl_newEstate": "Nova propriedade",
    "lbl_estate": "Estado",
    "lbl_search": "Procurar…",
    "lbl_manage_estateAndpanels": "Gerenciar Propriedades e Painéis",
    "btn_cancel": "Cancelar",
    "btn_save": "Salvar",
    "msg_panels_moved": "Painéis movidos com sucesso",
    "lbl_name": "Nome",
    "lbl_description": "Descrição",
    "btn_next": "Próxima",
    "lbl_contact_name": "Nome de contato",
    "lbl_contact_phNo": "Número de telefone de contato",
    "lbl_country": "País",
    "lbl_address": "Endereço",
    "lbl_postcode": "CEP",
    "lbl_selectedPanels": "Painéis selecionados",
    "lbl_close": "Fechar",
    "msg_newEstate_created": "Nova propriedade criada com sucesso",
    "msg_estateExists": "Propriedade com este nome já existe",
    "tooltip_ARC_Access": "Acesso ARC",
    "tooltip_usrEmail_alerts": "Alertas de e-mail do usuário",
    "tooltip_usrAccess": "Acesso do usuário",
    "tooltip_installerEmail_alerts": "Alertas de e-mail do instalador",
    "tooltip_remoteServicing": "Serviço Remoto",
    "lbl_allPanel": "Todos os painéis",
    "lbl_favorites": "Favoritas",
    "lbl_ARC_enabled": "ARC ativado",
    "lbl_end_usrEmail_enabled": "E-mail do usuário final ativado",
    "lbl_end_usrAccess_disabled": "Acesso do usuário final desativado",
    "lbl_installer_emailAlerts_enabled": "Alertas de e-mail do instalador ativados",
    "lbl_remoteServicing_enabled": "Serviço Remoto ativado",
    "msg_no_panels_found": "Nenhum painel disponível para os filtros acima",
    "lbl_move_panel": "Mover Painel",
    "lbl_delete_panel": "Excluir painel",
    "lbl_delete": "Excluir",
    "lbl_delete_estate": "Excluir propriedade",
    "msg_delete_estate": "Tem certeza de que deseja excluir a propriedade",
    "msg_no_action_revert": "Esta ação não pode ser revertida.",
    "msg_estate_deleted": "Propriedade excluída com sucesso",
    "msg_deleteEstate_error": "Erro ao deletar imóvel",
    "msg_noSearch_results": "Nenhum resultado correspondente",
    "msg_deletePanel": "Tem certeza de que deseja excluir o painel",
    "msg_deletePanel_error": "Erro ao excluir painel",
    "msg_panelDeleted": "Painel excluído com sucesso",
    "lbl_viewEstate": "Ver propriedade",
    "lbl_goToPanel": "Ir para o painel",
    "lbl_subEstate": "sub-propriedade",
    "msg_noEstatesConfigured": "Nenhuma propriedade configurada",
    "msg_noPanelsConnected": "Nenhum painel conectado",
    "tab_notifications": "HISTÓRICO DO PAINEL",
    "tab_overview": "VISÃO GERAL",
    "tab_panelLog": "LOG DO PAINEL",
    "tab_ARC_reporting": "RELATÓRIO DE ARC",
    "tab_connect": "CONECTAR",
    "tab_keypad": "TECLADO",
    "tab_remoteServicing": "SERVIÇO REMOTO",
    "tab_mobileApp_usrs": "USUÁRIOS DE APLICATIVOS MÓVEIS",
    "lbl_mobAppUsrs": "Usuários de aplicativos móveis",
    "lbl_summary": "Resumo",
    "lbl_status": "Status",
    "lbl_panelId": "ID do painel",
    "lbl_macAddress": "Endereço MAC",
    "lbl_firmware": "firmware",
    "msg_panelIdCopied": "PanelID copiado para a área de transferência",
    "lbl_panelAccess": "Painel de acesso",
    "lbl_enabled": "Habilitada",
    "lbl_disabled": "Desabilitada",
    "lbl_connectionStats": "Estatísticas de conexão",
    "lbl_totalTime": "Tempo total (horas)",
    "lbl_connectedTime": "Conectado (horas)",
    "lbl_disconnectedTime": "Desconectado (horas)",
    "lbl_connectedPercent": "Conectada(%)",
    "lbl_siteDetails": "Detalhes do local",
    "lbl_cloudReference": "Referência de nuvem",
    "lbl_siteAddress": "Endereço do site",
    "lbl_optional": "Opcional",
    "lbl_somethingWrong": "algo deu errado",
    "msg_siteDetails_edited": "Detalhes do site editados com sucesso",
    "lbl_ARCProvider": "Provedor ARC",
    "lbl_ARCName": "Nome ARC",
    "lbl_accntNo": "Número de conta",
    "lbl_IP_version": "Versão SIA-IP",
    "lbl_primaryIP": "IP primário",
    "lbl_secondaryIP": "IP secundário",
    "lbl_primaryPort": "Porta Primária",
    "lbl_secondaryPort": "Porta Secundária",
    "lbl_encryption": "Criptografia",
    "option_no_encryption": "SEM CRIPTOGRAFIA",
    "lbl_report_email": "Denunciar e-mail",
    "msg_ARC_edited": "Acesso ARC editado com sucesso",
    "msg_ARC_enabled": "Acesso ARC habilitado com sucesso",
    "msg_ARC_updated": "Acesso ARC ativado e atualizado",
    "btn_back": "Voltar",
    "msg_ARC_disabled": "Acesso ARC desativado",
    "msg_error": "Erro",
    "lbl_dateOfService": "Data do próximo serviço",
    "lbl_serviceFrquency": "Frequência de serviço",
    "option_svc_frqncy_1": "Por mês",
    "option_svc_frqncy_2": "6 mensal",
    "msg_remote_srvcng_enable": "Manutenção remota habilitada com sucesso",
    "msg_remote_srvcng_disabled": "Manutenção remota desativada com sucesso",
    "msg_email_alert_enabled": "Alerta de e-mail do instalador ativado com sucesso",
    "msg_email_alert_edited": "Alerta de e-mail do instalador editado com sucesso",
    "lbl_email_alert_disable": "Desabilitar alertas de e-mail do instalador",
    "msg_email_alert_disable": "Tem certeza de que deseja desativar os alertas de e-mail do instalador?",
    "btn_disable": "Desativar",
    "msg_emailAlert_disabled": "Alerta de e-mail do instalador desativado com sucesso",
    "lbl_enable_usrAccess": "Ativar acesso do usuário",
    "lbl_disable_usrAccess": "Desativar acesso do usuário",
    "msg_enable_usrAccess": "Tem certeza de que deseja ativar o acesso do usuário?",
    "btn_enable": "Habilitar",
    "msg_usrAccess_enabled": "Acesso do usuário habilitado com sucesso",
    "msg_usrAccess_disabled": "Acesso do usuário desativado com sucesso",
    "msg_usrAccess_error": "Fout bij het in-/uitschakelen van gebruikerstoegang",
    "btn_addUsr": "Adicionar usuário",
    "lbl_addUsr": "voeg gebruikers toe zodat ze op de hoogte worden gehouden van gebeurtenissen voor dit paneel",
    "lbl_events": "Eventos",
    "lbl_sets": "Conjuntas",
    "lbl_alarms": "Alarmes",
    "lbl_tampers": "Adulteradoras",
    "lbl_system": "Sistema",
    "msg_emailAlert_added": "Alertas de e-mail do usuário adicionados com sucesso.",
    "lbl_edit_emailAlerts": "Editar alertas de e-mail do usuário",
    "msg_usrEmail_edited": "E-mail do usuário editado com sucesso",
    "lbl_delete_usrEmail": "Excluir alertas de e-mail do usuário",
    "msg_delete_usrEmail": "Tem certeza de que deseja excluir os alertas de e-mail do usuário?",
    "msg_usr_emailDeleted": "Alerta de e-mail do usuário excluído com sucesso",
    "tootltip_exportNotification": "Notificações de exportação",
    "lbl_delivered": "ENTREGUE",
    "lbl_failed": "FRACASSADA",
    "lbl_information": "Informação",
    "lbl_evntDetails": "detalhes do evento",
    "lbl_systmName": "Nome do sistema",
    "lbl_Id": "EU IA",
    "lbl_partition": "partição",
    "lbl_user": "Do utilizador",
    "lbl_username": "Nome de usuário",
    "lbl_text": "Texto",
    "lbl_verbose": "Prolixa",
    "lbl_timestamp": "carimbo de data/hora",
    "lbl_code": "Código",
    "lbl_group": "Groep",
    "lbl_SIA_IP": "SIA IP DIRETO",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "SEM RESPOSTA",
    "lbl_rejected": "REJEITADA",
    "lbl_readyForDelivering": "PRONTO PARA ENTREGA",
    "lbl_sent": "ENVIADO",
    "lbl_exportLogs": "Exportar registros",
    "lbl_failedSvc": "Serviços com falha",
    "lbl_ScheduledSvc": "Serviços agendados",
    "lbl_pendingSvc": "Nenhum serviço agendado ou com falha",
    "lbl_cmpltd_svcReports": "Relatórios de serviço concluídos",
    "lbl_report_no": "Número do relatório",
    "lbl_cmpltd_date": "Data de conclusão",
    "lbl_cmpltd_Classification": "Classificação",
    "lbl_cmpltd_View": "Visualizar",
    "lbl_service_report": "Relatório de serviço",
    "lbl_delete_svcReport": "Excluir relatório de serviço",
    "msg_delete_svcReport": "Tem certeza de que deseja excluir o relatório de serviço?",
    "msg_svcReport_deleted": "Relatório de serviço excluído com sucesso",
    "btn_perform_remoteSvc": "Executar serviço remoto",
    "msg_remoteSvc_initiated": "Serviço remoto iniciado",
    "msg_remoteSvc_inProgress": "o serviço está em andamento",
    "lbl_errorOccurred": "Ocorreu um erro",
    "lbl_noCmpltd_svc": "Nenhum serviço concluído",
    "lbl_noCmpltd_svcReports": "Nenhum relatório de serviço concluído",
    "lbl_mobApp_usrs": "Usuários de aplicativos móveis",
    "msg_usr_authorized": "Usuário não autorizado",
    "msg_internal_error": "Erro do Servidor Interno",
    "msg_try_later_error": "Algo deu errado. Por favor, tente novamente.",
    "lbl_info_diplayed": "Informações exibidas no aplicativo móvel",
    "lbl_compnyName": "nome da empresa",
    "lbl_officeNo": "Número de telefone do escritório",
    "lbl_outOfHrs": "Número de telefone fora de horas",
    "lbl_contactEmail": "Email de contato",
    "lbl_logo": "Logotipo",
    "btn_uploadLogo": "Carregar logotipo",
    "btn_dragLogo": "ou arraste o logotipo aqui",
    "msg_endUsrInfo_edited": "Informações do usuário final editadas com sucesso",
    "btn_remove": "Remover",
    "lbl_userType": "Tipo de usuário",
    "lbl_login": "Conecte-se",
    "lbl_email_notification": "Notificação de Email",
    "lbl_delete_accntUsr": "Excluir usuário da conta",
    "msg_deleteUsr": "Tem certeza de que deseja excluir o usuário?",
    "msg_deleteUsr_error": "Erro ao excluir usuário da conta",
    "msg_accntUsr_deleted": "Usuário da conta excluído com sucesso",
    "lbl_firstName": "Primeiro nome",
    "lbl_lastName": "Sobrenome",
    "lbl_typeUsr": "Tipo de usuário",
    "lbl_emailNotifications": "Notificações por e-mail",
    "lbl_userPemissions": "Permissões de usuário",
    "msg_userEdited": "Usuário editado com sucesso",
    "lbl_unauthorizedUser": "usuário não autorizado",
    "lbl_someError": "Ocorreu algum erro",
    "lbl_pwd": "Senha",
    "lbl_repeat_pwd": "Repita a senha",
    "lbl_engineer": "Engenheira",
    "lbl_manager": "Gerente",
    "lbl_installer": "Instaladora",
    "lbl_officeStaff": "Equipe do escritório",
    "msg_noMatch_pwd": "Senhas incomparáveis!",
    "msg_usr_added": "Usuário adicionado com sucesso",
    "lbl_panel": "Painel",
    "lbl_accnt_holder": "titular da conta",
    "lbl_spoken_pwd": "Senha falada",
    "lbl_language": "Linguagem",
    "lbl_cmpnyInfo": "Informações da Empresa",
    "lbl_firstPh": "Primeiro Telefone",
    "lbl_secondPh": "segundo telefone",
    "lbl_thirdPh": "terceiro telefone",
    "lbl_oldPwd": "Senha Antiga",
    "lbl_newPwd": "Nova Senha",
    "lbl_repeatPwd": "Repita a senha",
    "msg_accntHolder_edited": "Titular da conta editado com sucesso",
    "lbl_firstPhType": "Primeiro tipo de telefone",
    "lbl_firstPhone": "Primeiro número de telefone",
    "lbl_secondPhone": "Segundo número de telefone",
    "lbl_thirdPhone": "Terceiro número de telefone",
    "lbl_secondPhType": "Segundo tipo de telefone",
    "lbl_thirdPhType": "Terceiro tipo de telefone",
    "lbl_home": "Lar",
    "lbl_mobile": "Móvel",
    "lbl_office": "Escritório",
    "msg_cmpnyInfo_edited": "Informações da empresa editadas com sucesso",
    "lbl_termsConditions": "Termos e Condições",
    "lbl_privacyPolicy": "política de Privacidade",
    "msg_email_invalid": "Email inválido",
    "msg_unmatchedPwd": "Senhas incomparáveis!",
    "lbl_installerGrp": "Grupo instalador",
    "msg_usrExist": "O usuário com o login já existe",
    "lbl_editUsr": "Editar usuário",
    "msg_field_required": "Este campo é obrigatório",
    "lbl_disable_ARC": "Desativar acesso ARC",
    "msg_disable_ARC": "Tem certeza de que deseja desabilitar o acesso ao ARC?",
    "lbl_SiaEvnts": "Sia Eventos",
    "lbl_SIAEvnt": "Evento SIA",
    "msg_prohibited_chars": "entrada contém caracteres proibidos",
    "msg_inputLength": "comprimento de entrada não deve ser superior a 30 caracteres",
    "lbl_favPanels": "PAINÉIS FAVORITOS",
    "lbl_lastConnection": "última conexão",
    "lbl_androidPh": "Telefone Android",
    "lbl_iPhone": "meu iphone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Página inicial do Google",
    "lbl_removePairing": "Remover emparelhamento",
    "msg_removePairing": "Tem certeza de que deseja remover o emparelhamento deste usuário do aplicativo?",
    "lbl_no": "Não",
    "lbl_yes": "Sim",
    "lbl_device": "Dispositiva",
    "lbl_appDetails": "Detalhes da aplicação",
    "lbl_myAndroid": "meu Android",
    "lbl_myAlexa": "minha Alexa",
    "lbl_myiPhone": "meu iphone",
    "lbl_mygHome": "Minha página inicial do Google",
    "lbl_appID": "ID do aplicativo",
    "lbl_mobOS": "SO móvel",
    "lbl_token": "Símbolo",
    "msg_didNotMovePanl": "Não moveu nenhum painel",
    "lbl_panlsSelected": "Painéis selecionados",
    "lbl_panlSelected": "Painel selecionado",
    "lbl_ARCCode": "Código ARC",
    "lbl_response": "Resposta",
    "lbl_data": "Dadas",
    "lbl_state": "Estada",
    "lbl_signal_Timeout": "Tempo Limite do Sinal",
    "lbl_sysID": "ID do sistema",
    "lbl_date": "Data",
    "lbl_remove_destination": "Destinos de e-mail de manutenção remota",
    "lbl_emailAddress": "Endereço de email",
    "Reports": "Relatórios",
    "lbl_green": "VERDE",
    "lbl_amber": "ÂMBAR",
    "lbl_red": "VERMELHA",
    "lbl_reportDetails": "Detalhes do relatório",
    "lbl_dueDate": "Data de vencimento",
    "btn_edit": "editar",
    "lbl_nameAZ": "Nome (A-Z)",
    "lbl_nameZA": "Nome (Z-A)",
    "lbl_mostAlarms": "A maioria dos alarmes",
    "lbl_fewerAlarms": "Menos alarmes",
    "lbl_updatedRecent": "Atualizado recentemente",
    "lbl_none": "Nenhum",
    "msg_confirmation": "Você tem certeza que quer",
    "msg_enable": "habilitar",
    "msg_disable": "desabilitar",
    "msg_edited": "editado com sucesso",
    "btn_addEmail": "Adicionar destino de e-mail",
    "lbl_emailDestinationAdd": "adicionar destino de e-mail para que os relatórios de serviço remoto possam ser enviados por e-mail",
    "lbl_termsToUseWebsite": "Os termos em que você pode usar nosso site",
    "lbl_termsToUseWebsite_1.1": "Estes Termos de Uso (juntamente com os outros documentos mencionados abaixo) estabelecem os termos nos quais você pode usar nosso site",
    "lbl_termsToUseWebsite_1.1part": "Recomendamos que você imprima uma cópia destes Termos de Uso para referência futura.",
    "lbl_termsToUseWebsite_1.2": "Você deve ler atentamente estes Termos de Uso antes de usar nosso site, pois ao usar nosso site você confirma que aceita estes Termos de Uso e que os cumprirá.",
    "lbl_termsToUseWebsite_1.3": "Se você não aceitar estes Termos de Uso, você deve sair do nosso site imediatamente",
    "lbl_termsToUseWebsite_1.4": "A seguinte política também se aplica ao seu uso do nosso site",
    "lbl_termsToUseWebsite_1.4.1": "Nossa política de privacidade e cookies",
    "lbl_termsToUseWebsite_1.4.1part": "Ao usar nosso site, você concorda com o processamento de quaisquer dados pessoais que você nos fornece ou que coletamos de você como resultado do uso de nosso site. Nossa política de privacidade define o que fazemos com esses dados pessoais",
    "lbl_termsToUseWebsite_1.5": "Você é responsável por garantir que qualquer outra pessoa que acesse nosso site através de sua conexão com a Internet esteja ciente destes Termos de Uso e das políticas mencionadas acima e que as cumpra.",
    "lbl_chngesToTerms": "Alterações a estes Termos de Uso e nossas outras políticas",
    "lbl_chngesToTerms_2.1": "Periodicamente, fazemos alterações a estes Termos de Uso. Portanto, você deve verificar estes Termos de Uso sempre que retornar ao nosso site para ver se foram feitas alterações, pois elas serão vinculativas para você.",
    "lbl_chngesToTerms_2.2": "Também fazemos alterações em nossa política de privacidade e política de cookies de tempos em tempos, então você também deve verificar isso regularmente para ver se alguma alteração foi feita.",
    "lbl_aboutUs": "Sobre nós",
    "lbl_aboutUs_3.1": "Nós, Eaton Electrical Products Ltd, operamos este website em www.eatonsecureconnect.com. Somos uma empresa registrada na Inglaterra e no País de Gales sob o número 01342230 e nossa sede social é em 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "Você pode entrar em contato conosco",
    "lbl_aboutUs_3.2part": "Por e-mail em",
    "lbl_restrictionsOnUseOfWebsite": "Restrições ao seu uso do nosso site",
    "lbl_restrictionsOnUseOfWebsite_4.1": "Você só pode usar nosso site para fins legais e em conformidade com todas as leis aplicáveis, incluindo, sem limitação, proteção de dados e leis de privacidade, leis relacionadas a direitos autorais de conteúdo e leis relacionadas a mensagens eletrônicas comerciais não solicitadas.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "Além disso, você não deve",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "usar nosso site para enviar ou transmitir, direta ou indiretamente, publicidade não solicitada ou não autorizada ou material promocional, correntes ou esquemas de venda em pirâmide;",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "usar nosso site para bombardeio ou inundação de e-mail ou para fazer tentativas deliberadas de sobrecarregar um sistema;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "usar nosso site para postar conteúdo ou link para conteúdo onde você sabe ou deveria saber que o proprietário desse conteúdo não consentiu ou autorizou essa postagem ou link ou onde essa postagem ou link infringe os direitos desse proprietário;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "introduzir intencionalmente ou imprudentemente em nosso site, ou usar nosso site para transmitir, quaisquer vírus, cavalos de Tróia, worms, bombas lógicas, bombas-relógio, registradores de pressionamento de tecla, spyware, malware, adware ou outros materiais maliciosos ou prejudiciais;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "tentar obter acesso não autorizado ao nosso site, ao servidor, equipamento ou rede em que nosso site está armazenado, a qualquer servidor, computador ou banco de dados conectado ao nosso site ou a qualquer software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "usar nosso site para tentar obter acesso não autorizado a qualquer outro site, conta de internet, servidor, computador, equipamento, sistema, rede, dados ou informações;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "usar nosso site para monitorar dados ou tráfego em qualquer rede ou sistema;",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "usar nosso site para coletar ou usar informações, incluindo, sem limitação, endereços de e-mail, nomes de tela ou outros identificadores, por engano (como phishing, golpes na Internet, roubo de senha, spidering, scraping e colheita);",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "usar nosso site para distribuir software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "realizar um ataque de negação de serviço ou um ataque distribuído de negação de serviço em nosso site ou usar nosso site para realizar tal ataque em qualquer outro site;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "use nosso site:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "para qualquer finalidade que; ou",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "enviar, transmitir, publicar, fazer upload, baixar, usar, armazenar, reproduzir ou receber intencionalmente qualquer material que não esteja em conformidade com os padrões de conteúdo estabelecidos no",
    "lbl_para8": "parágrafo 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "usar nosso site para aliciar, prejudicar ou tirar vantagem de menores de qualquer forma ou tentar fazê-lo; ou",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "usar nosso site em violação das leis de exportação, controles, regulamentos ou políticas de sanção dos Estados Unidos ou de qualquer outro país.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "Se você violar qualquer termo destes Termos de Uso, seu direito de usar nosso site será encerrado imediatamente. Além disso, podemos tomar outras ações, incluindo, sem limitação, ações legais que considerarmos apropriadas.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "Se você violar qualquer termo destes Termos de Uso, ou se recebermos uma solicitação de uma autoridade policial para fazê-lo, podemos relatar sua identidade, detalhes de qualquer violação e qualquer outra informação que considerarmos necessária às autoridades policiais relevantes.",
    "lbl_pwdNSecRequirement": "Senhas e requisitos de segurança",
    "lbl_pwdNSecRequirement_5.1": "Se você registrar uma conta em nosso site, deverá fornecer informações de registro precisas e completas e manter essas informações sempre atualizadas.",
    "lbl_pwdNSecRequirement_5.2": "O uso de certas partes deste site é protegido por senha. Para a sua utilização necessitará de um código de identificação de utilizador ou palavra-passe para aceder ao mesmo. É sua responsabilidade manter essas informações secretas e confidenciais e não divulgá-las a qualquer outra pessoa.",
    "lbl_pwdNSecRequirement_5.3": "Se você acha que outra pessoa pode saber seu código de identificação de usuário ou senha, você deve fazer login em sua conta e alterá-los o mais rápido possível",
    "lbl_pwdNSecRequirement_5.4": "Reservamo-nos o direito de desativar qualquer código de identificação de usuário ou senha a qualquer momento se, em nossa opinião razoável, você não cumprir qualquer um destes Termos de Uso, se considerarmos que você está usando nosso site para baixar conteúdo ou infringir qualquer forma os direitos de outra pessoa ou se considerarmos que pode haver um risco de segurança.",
    "lbl_webSiteNContent": "Nosso site e seu conteúdo",
    "lbl_webSiteNContent_6.1": "Não garantimos que o acesso ao nosso site, ou ao conteúdo dele (incluindo qualquer conteúdo que você enviar para o nosso site), esteja sempre disponível ou ininterrupto. Seu direito de acessar nosso site é concedido apenas temporariamente.",
    "lbl_webSiteNContent_6.2": "Reservamo-nos o direito de alterar nosso site e o conteúdo nele, incluindo, sem limitação, adicionando ou removendo conteúdo ou funcionalidade, a qualquer momento e sem aviso prévio.",
    "lbl_webSiteNContent_6.3": "Não podemos garantir que todo o conteúdo do nosso site seja preciso, completo ou atualizado. Portanto, não oferecemos garantias, representações, compromissos ou garantias de que o conteúdo do nosso site seja preciso, completo ou atualizado.",
    "lbl_webSiteNContent_6.4": "O conteúdo do nosso site é fornecido apenas para fins de informação geral. Nada em nosso site pretende ser um conselho no qual você deva confiar. Você deve sempre obter aconselhamento especializado apropriado antes de tomar, ou não tomar, uma ação com base em qualquer conteúdo em nosso site.",
    "lbl_webSiteNContent_6.5": "Não oferecemos garantias, representações, compromissos ou garantias de que nosso site seja seguro ou livre de bugs ou vírus. Você é responsável por garantir que o dispositivo a partir do qual você acessa nosso site tenha um software antivírus adequado instalado.",
    "lbl_contentUploaded": "Conteúdo carregado em nosso site por você ou por outros usuários",
    "lbl_contentUploaded_7.1": "Se você enviar conteúdo para o nosso site, deverá cumprir os padrões de conteúdo estabelecidos em",
    "lbl_contentUploaded_7.1part": "em relação a esse conteúdo",
    "lbl_contentUploaded_7.2": "Você nos garante que possui qualquer conteúdo que você carregue em nosso site ou, se você não for o proprietário, que você tem o direito de carregá-lo.",
    "lbl_contentUploaded_7.3": "Você continuará a possuir qualquer conteúdo que você enviar para o nosso site, mas enquanto esse conteúdo permanecer em nosso site, você nos concede uma licença mundial para usar, armazenar, copiar, modificar, executar, distribuir, exibir e disponibilizar esse conteúdo e permitir que outros usuários de nosso site o usem, baixem, imprimam e reproduzam de acordo com nossos Termos de Uso.",
    "lbl_contentUploaded_7.4": "Você nos indenizará por quaisquer perdas, responsabilidades, custos, despesas, reivindicações ou procedimentos que sofrermos ou incorrermos, ou sofreremos ou incorreremos, como resultado ou em conexão com qualquer violação por você de suas obrigações ou garantias neste",
    "lbl_para7": "parágrafo 7",
    "lbl_contentUploaded_7.5": "Não somos responsáveis ​​e não oferecemos garantias, representações, compromissos ou garantias em relação a qualquer conteúdo em nosso site que seja carregado por um usuário. Qualquer opinião expressa por qualquer usuário do nosso site é a opinião deles e não a nossa opinião nem a visão de nossos executivos ou funcionários.",
    "lbl_contentUploaded_7.6": "Não somos responsáveis ​​pelo armazenamento ou backup de qualquer conteúdo carregado em nosso site e não seremos responsáveis ​​por qualquer perda, corrupção ou destruição de tal conteúdo.",
    "lbl_contentUploaded_7.7": "Recomendamos que você faça backup de qualquer conteúdo que você enviar para o nosso site.",
    "lbl_contentUploaded_7.8": "Reservamo-nos o direito de remover qualquer conteúdo que você tenha carregado em nosso site a qualquer momento.",
    "lbl_contentUploaded_7.9": "Também nos reservamos o direito de divulgar sua identidade a qualquer pessoa que afirme que qualquer conteúdo que você tenha carregado em nosso site infrinja seus direitos de propriedade intelectual ou seu direito à privacidade ou seja difamatório.",
    "lbl_contentStd": "Padrões de conteúdo",
    "lbl_contentStd_8.1": "Lembre-se de que qualquer conteúdo que você enviar para o nosso site pode ser visualizado por outros usuários, portanto, pense sempre com cuidado antes de enviar qualquer conteúdo.",
    "lbl_contentStd_8.2": "Qualquer conteúdo que você enviar para o nosso site deve:",
    "lbl_contentStd_8.2.1": "cumprir todas as leis aplicáveis;",
    "lbl_contentStd_8.2.2": "ser preciso (onde declara fatos); e",
    "lbl_contentStd_8.2.3": "ser genuinamente sustentado (onde expressa opiniões).",
    "lbl_contentStd_8.3": "Você não deve fazer upload para o nosso site de qualquer conteúdo que:",
    "lbl_contentStd_8.3.1": "seja difamatório, obsceno, ofensivo, indecente, malicioso, odioso ou inflamatório;",
    "lbl_contentStd_8.3.2": "é pornográfico ou sexualmente explícito",
    "lbl_contentStd_8.3.3": "explora menores;",
    "lbl_contentStd_8.3.4": "promove violência ou terrorismo;",
    "lbl_contentStd_8.3.5": "seja discriminatório com base em raça, sexo, religião, nacionalidade, deficiência, orientação sexual ou idade;",
    "lbl_contentStd_8.3.6": "viole qualquer direito autoral, direito de banco de dados, marca registrada ou outro direito de propriedade intelectual de qualquer outra pessoa;",
    "lbl_contentStd_8.3.7": "solicita ou tenta solicitar senhas ou informações de identificação pessoal para fins comerciais ou ilegais de outros usuários;",
    "lbl_contentStd_8.3.8": "seja fraudulento ou susceptível de enganar qualquer pessoa;",
    "lbl_contentStd_8.3.9": "seja feito em violação de qualquer dever legal devido a qualquer outra pessoa, como um dever contratual ou um dever de confiança;",
    "lbl_contentStd_8.3.10": "crie um risco para a saúde ou segurança de uma pessoa ou para a saúde ou segurança pública;",
    "lbl_contentStd_8.3.11": "promova ou constitua atividade ilegal ou ilícita;",
    "lbl_contentStd_8.3.12": "compromete a segurança nacional;",
    "lbl_contentStd_8.3.13": "interfere com uma investigação por uma autoridade policial ou reguladora;",
    "lbl_contentStd_8.3.14": "seja ameaçador ou abusivo ou invada a privacidade de outra pessoa ou cause aborrecimento, inconveniência ou ansiedade desnecessária;",
    "lbl_contentStd_8.3.15": "é susceptível de assediar, perturbar, embaraçar, alarmar ou irritar qualquer outra pessoa;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "dá a impressão de que vem de nós;",
    "lbl_contentStd_8.3.18": "constitui material publicitário; ou",
    "lbl_contentStd_8.3.19": "defende, promove ou auxilia qualquer atividade ilegal, incluindo, entre outros, violação de direitos autorais ou uso indevido de computador.",
    "lbl_IntellctlProprty": "Direito de propriedade intelectual",
    "lbl_IntellctlProprty_9.1": "Nós, ou nossos licenciadores, possuímos os direitos autorais e todos os outros direitos de propriedade intelectual em nosso site e todo o conteúdo nele, exceto o conteúdo carregado por ou vinculado a outros.",
    "lbl_IntellctlProprty_9.2": "Você pode baixar o conteúdo que possuímos de nosso site e/ou imprimir um número razoável de cópias de qualquer página de nosso site. Você não deve fazer nenhuma alteração em nenhum conteúdo que você baixou ou imprimiu de nosso site. Se você reproduzir o conteúdo baixado ou impresso em qualquer meio, você deve garantir que o reproduza de forma precisa e completa e incluir uma declaração reconhecendo que é originário de nosso site",
    "lbl_IntellctlProprty_9.3": "Se você usar, baixar, imprimir ou reproduzir o conteúdo do nosso site em violação destes Termos de Uso, seu direito de usar nosso site terminará imediatamente e você deverá nos devolver ou destruir (a nosso critério) todas as cópias digitais e em papel do conteúdo que você fez.",
    "lbl_linksToOtherWebsite": "Links do nosso site para outros sites",
    "lbl_linksToOtherWebsite_text": "Nosso site pode conter links para outros sites de tempos em tempos. Esses links são fornecidos para sua conveniência. Não temos controle e nenhuma responsabilidade ou responsabilidade perante você por esses outros sites ou seu conteúdo, e qualquer uso ou acesso por você a esses sites estará sujeito aos termos e condições relevantes de uso desses sites e não a estes Termos de uso.",
    "lbl_linksFromOtherWebsite": "Links de outros sites para o nosso site",
    "lbl_linksFromOtherWebsite_11.1": "Pode criar um link para o nosso site a partir de outro site, mas apenas se cumprir as seguintes condições:",
    "lbl_linksFromOtherWebsite_11.1.1": "você deve nos notificar de sua intenção de criar um link para nosso site a partir de outro site e obter nossa aprovação por escrito antes de criar tal link;",
    "lbl_linksFromOtherWebsite_11.1.2": "você só pode criar um link para a página inicial do nosso site. Você não deve criar um link para qualquer outra página do nosso site, nem enquadrar qualquer página do nosso site;",
    "lbl_linksFromOtherWebsite_11.1.3": "você só pode acessar nosso site a partir de um site de sua propriedade e que esteja em conformidade com os padrões estabelecidos em",
    "lbl_linksFromOtherWebsite_11.1.4": "a criação do link não introduzirá em nosso site ou usará nosso site para transmitir quaisquer vírus, cavalos de tróia, worms, bombas lógicas, bombas-relógio, registradores de pressionamento de tecla, spyware, malware, adware ou outros materiais maliciosos ou prejudiciais ;",
    "lbl_linksFromOtherWebsite_11.1.5": "você cumprirá com os requisitos e/ou políticas que podemos notificá-lo de tempos em tempos em relação a qualquer link para nosso site a partir de outro site; e",
    "lbl_linksFromOtherWebsite_11.1.6": "você não deve sugerir que temos qualquer associação com você ou que aprovamos ou endossamos seu site ou qualquer um de seus produtos ou serviços.",
    "lbl_linksFromOtherWebsite_11.2": "Reservamo-nos o direito de exigir que você remova qualquer link para nosso site a qualquer momento e sem aviso prévio",
    "lbl_ourLiability": "Nossa responsabilidade com você",
    "lbl_ourLiability_12.1": "Nada nestes Termos de Uso limita ou exclui nossa responsabilidade perante você por:",
    "lbl_ourLiability_12.1.1": "morte ou danos pessoais resultantes de negligência;",
    "lbl_ourLiability_12.1.2": "fraude ou deturpação fraudulenta; ou",
    "lbl_ourLiability_12.1.3": "qualquer outro assunto para o qual não temos permissão por lei para excluir ou limitar nossa responsabilidade.",
    "lbl_ourLiability_12.2": "Como estes Termos de Uso regem o seu acesso e uso do nosso site, todas as garantias, condições e outros termos relacionados ao nosso site e/ou seu conteúdo que de outra forma seriam implícitos por lei (seja por estatuto, direito comum ou outro) são excluídos.",
    "lbl_ourLiability_12.3": "Não seremos responsáveis ​​perante você por qualquer perda ou dano decorrente ou relacionado ao uso (ou incapacidade de uso) de nosso site ou ao uso ou confiança em qualquer conteúdo de nosso site, seja por contrato, ato ilícito (incluindo sem limitação, negligência), deturpação, restituição, sob estatuto ou de outra forma.",
    "lbl_ourLiability_13": "Lei aplicável",
    "lbl_ourLiability_13.1": "Se você é um consumidor, estes Termos de Uso são regidos pela lei da Inglaterra e do País de Gales e os tribunais da Inglaterra e do País de Gales têm jurisdição não exclusiva para determinar qualquer disputa decorrente ou relacionada a eles. Se residir na Escócia, também pode intentar uma ação na Escócia e, se residir na Irlanda do Norte, também pode intentar uma ação na Irlanda do Norte. Residentes de outros países nos quais o site é comercializado estão sujeitos à lei da Inglaterra e do País de Gales",
    "lbl_ourLiability_13.2": "Se você é um usuário comercial, estes Termos de Uso (e quaisquer obrigações extracontratuais decorrentes ou relacionadas a eles) são regidos pela lei da Inglaterra e do País de Gales e os tribunais da Inglaterra e do País de Gales têm jurisdição exclusiva para determinar qualquer disputa decorrente fora ou em conexão com eles (incluindo, sem limitação, em relação a quaisquer obrigações extracontratuais). Residentes de outros países nos quais o site é comercializado estão sujeitos à lei da Inglaterra e do País de Gales",
    "lbl_privPolicy": "política de Privacidade",
    "lbl_privPolicy_text": "O site Eaton SecureConnect é um site e portal operado pela Eaton Electrical Products Limited, uma empresa registrada na Companies House sob o número de registro 01342230, cujo endereço registrado é Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "nós",
    "lbl_privPolicy_textUs": "nós",
    "lbl_privPolicy_textOur": "nosso",
    "lbl_privPolicy_text2": "é o controlador de dados em relação às informações pessoais enviadas por você para nós por meio deste site.",
    "lbl_privPolicy_text3": "Este aviso (junto com nossos termos de uso:",
    "lbl_privPolicy_text4": "e quaisquer outros documentos nele referidos) estabelece a base sobre a qual quaisquer dados pessoais que coletamos de você, ou que você nos fornece, por meio deste site, serão processados ​​por nós. Leia atentamente o seguinte para entender nossas práticas em relação aos seus dados pessoais e como os trataremos.",
    "lbl_privPolicy_text5": "Se você tiver alguma dúvida sobre este aviso ou não concordar com ele, entre em contato conosco em dataprotection@eaton.com antes de usar este site.",
    "lbl_privPolicy_text6": "Podemos alterar este aviso de tempos em tempos, atualizando esta página. Você deve verificar esta página de tempos em tempos para garantir que está satisfeito com as alterações. Este aviso é válido a partir de 20 de dezembro de 2019.",
    "lbl_infoWeCollect": "Quais são as informações que coletamos?",
    "lbl_infoWeCollect_text": "Você poderá acessar o site sem nos fornecer nenhuma informação. No entanto, a maioria dos serviços fornecidos através do site (como os disponíveis através do portal) só estarão disponíveis se processarmos determinadas informações sobre você.",
    "lbl_infoWeCollect_text1": "Podemos coletar as seguintes informações:",
    "lbl_infoWeCollect_text2": "seu nome e informações de contato (incluindo endereço, endereço de e-mail e números de telefone) e detalhes da empresa;",
    "lbl_infoWeCollect_text3": "suas informações de registro de conta se você se tornar um usuário registrado do site (incluindo seus detalhes de login de usuário e senha);",
    "lbl_infoWeCollect_text4": "o país de onde você acessa o site;",
    "lbl_infoWeCollect_text5": "informações sobre seus interesses pessoais ou profissionais, dados demográficos, experiências com nossos produtos e preferências de contato para fornecer mais correspondência sobre nossos produtos e serviços;",
    "lbl_infoWeCollect_text6": "informações sobre seus clientes (incluindo seus nomes, endereços, produtos que adquiriram e sistemas instalados em suas instalações, informações sobre o status de alarme (como se está ou não ativado e/ou funcionando corretamente), imagens de CFTV e se estão um usuário do aplicativo móvel Eaton SecureConnect);",
    "lbl_infoWeCollect_text7": "verslagen van elk contact tussen u en ons; En",
    "lbl_infoWeCollect_text8": "qualquer informação que você nos forneça voluntariamente ou insira através do site e/ou linha de apoio.",
    "lbl_whatWeDowithInfo": "O que fazemos com as informações que coletamos?",
    "lbl_whatWeDowithInfo_text": "Usamos essas informações para fornecer nossos produtos e serviços e, em particular, pelos seguintes motivos",
    "lbl_whatWeDowithInfo_text1": "para nos permitir fornecer suporte e assistência técnica em relação aos produtos que você comprou de nós;",
    "lbl_whatWeDowithInfo_text2": "para informá-lo sobre períodos de inatividade de manutenção em relação ao portal e outros serviços prestados a você;",
    "lbl_whatWeDowithInfo_text3": "manutenção de registros internos; e",
    "lbl_whatWeDowithInfo_text4": "para melhorar nossos produtos e serviços.",
    "lbl_whatWeDowithInfo_text5": "Podemos entrar em contato com você por e-mail, telefone ou correio",
    "lbl_whatWeDowithInfo_text6": "Processamos esses dados pessoais para cumprir nossas obrigações e exercer nossos direitos em conexão com nosso(s) contrato(s) com você e para nossos interesses legítimos, que incluem o fornecimento de produtos, serviços e soluções para nossos clientes e instaladores credenciados pela Eaton, em conformidade com nossas obrigações legais, contratuais e morais, aumentando a eficiência de nossas operações e protegendo nossos negócios",
    "lbl_infoShareWith": "Com quem compartilhamos essas informações?",
    "lbl_infoShareWith_text": "Exceto conforme estabelecido abaixo, não iremos transferir, divulgar, vender, distribuir ou alugar suas informações pessoais a terceiros que não sejam nossas subsidiárias e/ou matriz, a menos que tenhamos sua permissão ou sejamos obrigados por lei.",
    "lbl_infoShareWith_text1": "Às vezes (e com sua aprovação quando necessário), compartilharemos seus dados pessoais com terceiros cuidadosamente selecionados fora de nosso grupo corporativo. Podemos fazer isso pelos seguintes motivos:",
    "lbl_infoShareWith_text2": "para permitir que tais terceiros realizem serviços para nós, incluindo nosso provedor de hospedagem de sites cuidadosamente selecionado;",
    "lbl_infoShareWith_text3": "para permitir que seu empregador, principal e/ou contratado visualize os detalhes contidos no portal relacionados ao seu negócio de instalador e/ou aos produtos que comprou;",
    "lbl_infoShareWith_text4": "em resposta a solicitações legais de autoridades públicas, inclusive para atender a requisitos de segurança nacional ou de aplicação da lei;",
    "lbl_infoShareWith_text5": "quando acreditamos que é necessário cumprir a lei ou proteger nossos direitos, propriedade ou segurança ou de outra pessoa; e/ou",
    "lbl_infoShareWith_text6": "se houver (ou houver) qualquer mudança na propriedade de nossos negócios ou ativos, podemos desejar compartilhar suas informações para que os novos (possíveis) proprietários possam continuar a operar nossos negócios com eficiência e continuar a fornecer serviços aos clientes. Isso pode incluir novos acionistas ou qualquer organização que possa assumir uma cessão ou transferência de quaisquer contratos que tenhamos firmado com nossos clientes.",
    "lbl_infoShareWith_text7": "Este processo pode incluir o envio de dados pessoais para outros países do Espaço Econômico Europeu. Se, por qualquer motivo, transferirmos seus dados pessoais para fora do Espaço Econômico Europeu, implementaremos salvaguardas para garantir os níveis adequados de proteção para todos esses dados pessoais e disponibilizaremos ou forneceremos um meio para você obter uma cópia de tais salvaguardas.",
    "lbl_howLongStoreData": "Durante quanto tempo guardaremos os seus dados pessoais?",
    "lbl_howLongStoreData_text": "Por quanto tempo mantemos suas informações depende da base em que foram fornecidas, mas geralmente:",
    "lbl_howLongStoreData_text1": "manteremos as informações necessárias para nos permitir fornecer a você um serviço que você solicitou por meio ou em conexão com este site pelo tempo que for necessário para fornecermos esse serviço;",
    "lbl_howLongStoreData_text2": "geralmente manteremos registros de todas as transações que você fizer conosco por um período mínimo de seis anos a partir do término do relacionamento. Isso é para que possamos resolver problemas e responder a quaisquer reclamações ou disputas que surjam nesse período. Caso contrário, manteremos as informações quando apropriado para necessidades comerciais legítimas;",
    "lbl_howLongStoreData_text3": "manteremos outras informações sobre você se for necessário fazê-lo para cumprir a lei ou, quando apropriado, para necessidades comerciais legítimas.",
    "lbl_sensativeInfo": "Informação sensível",
    "lbl_sensativeInfo_text": "As informações sobre você que são consideradas confidenciais ou uma categoria especial de dados pessoais sob as leis de proteção de dados podem incluir informações sobre suas condições médicas ou de saúde, origem racial ou étnica, opiniões políticas, filiação sindical, crenças religiosas ou filosóficas, dados genéticos, dados biométricos , vida sexual e orientação sexual e atividades criminosas suspeitas ou comprovadas e procedimentos relacionados. Se precisarmos processar dados pessoais confidenciais, você será notificado sobre tal processamento e solicitado a concordar especificamente com o uso de tais informações conforme apropriado.",
    "lbl_security": "Segurança",
    "lbl_security_text": "Estamos empenhados em garantir que suas informações estejam seguras. Para evitar o acesso não autorizado ou a divulgação, implementamos procedimentos físicos, eletrônicos e gerenciais para salvaguardar e proteger as informações que coletamos online.",
    "lbl_security_text1": "Nós nos esforçamos para tomar todas as medidas razoáveis ​​para proteger seus dados pessoais. No entanto, esteja ciente de que existem riscos de segurança inerentes ao fornecer informações e negociar on-line pela Internet e, portanto, não podemos garantir a segurança de quaisquer dados pessoais divulgados on-line. Pedimos que você não nos forneça nenhum dado pessoal sensível on-line (consulte a seção 'Informações confidenciais' acima), a menos que solicitemos isso especificamente.",
    "lbl_cookies": "Biscoitos",
    "lbl_cookies_text": "Como muitas empresas, usamos “cookies” e ferramentas semelhantes em nosso site para melhorar seu desempenho e aprimorar sua experiência de usuário.",
    "lbl_cookies_text1": "O que são cookies?",
    "lbl_cookies_text2": "Um cookie é um pequeno arquivo de texto que é colocado no dispositivo que você usa para acessar o site (seu",
    "lbl_cookies_text3": "Os cookies ajudam a analisar o tráfego da web e permitem que os aplicativos da web respondam a você como um indivíduo. O aplicativo da web pode adaptar suas operações às suas necessidades, gostos e desgostos, reunindo e lembrando informações sobre suas preferências. Certos cookies podem conter dados pessoais – por exemplo, se você clicar para “lembrar de mim” ao fazer login, um cookie pode armazenar seu nome de usuário. A maioria dos cookies não coleta dados pessoais que identificam você e, em vez disso, coleta informações mais gerais, como como os usuários chegam e usam nosso site ou a localização geral de um usuário.",
    "lbl_cookies_text4": "Para que usamos cookies?",
    "lbl_cookies_text5": "Podemos colocar cookies ou arquivos semelhantes em seu dispositivo para fins de segurança, para nos informar se você já visitou o site antes, para lembrar suas preferências de idioma, se você é um novo visitante ou para facilitar a navegação no site e para personalizar sua experiência ao visitar nossos sites. Os cookies nos permitem coletar informações técnicas e de navegação, como tipo de navegador, tempo gasto em nossos sites e páginas visitadas. Os cookies podem aprimorar sua experiência online salvando suas preferências enquanto você visita um site específico. No geral, os cookies nos ajudam a fornecer a você um site melhor, permitindo-nos monitorar quais páginas você considera úteis e quais não. Um cookie de forma alguma nos dá acesso ao seu dispositivo ou qualquer informação sobre você, além dos dados que você escolhe compartilhar conosco.",
    "lbl_cookies_text6": "Que tipos de cookies usamos?",
    "lbl_cookies_text7": "Geralmente, os cookies usados ​​no site podem ser divididos nas seguintes categorias:",
    "lbl_cookies_text8": "Cookies de sessão:",
    "lbl_cookies_text9": "Esses cookies são usados ​​'dentro da sessão' cada vez que você visita e expiram quando você sai de um site ou logo depois: eles não são armazenados em seu dispositivo permanentemente, não contêm nenhum dado pessoal e ajudam a minimizar a necessidade de transferir dados pessoais pela internet. Esses cookies podem ser excluídos ou você pode se recusar a permitir seu uso, mas isso pode prejudicar o desempenho e sua experiência de uso dos sites. Esses cookies também levam carimbos de data/hora que registram quando você acessa um site e quando sai de um site.",
    "lbl_cookies_text10": "Cookies de rastreamento:",
    "lbl_cookies_text11": "Esses cookies nos permitem reconhecer visitantes repetidos em nossos sites. Ao combinar um identificador anônimo gerado aleatoriamente, um cookie de rastreamento rastreia de onde veio um usuário de nossos sites, qual mecanismo de pesquisa ele pode ter usado, em qual link clicou, qual palavra-chave usou e onde estava no mundo quando eles acessaram um site. Ao monitorar esses dados, podemos fazer melhorias em nossos sites.",
    "lbl_cookies_text12": "Cookies persistentes:",
    "lbl_cookies_text13": "Esse tipo de cookie é salvo em seu dispositivo por um período fixo (às vezes por algumas horas, às vezes por um ano ou mais) e não é excluído quando o navegador é fechado. Cookies persistentes são usados ​​onde precisamos lembrar quem você é por mais de uma sessão de navegação. Por exemplo, esse tipo de cookie pode ser usado para armazenar suas preferências, para que sejam lembradas na próxima visita a um site.",
    "lbl_cookies_text14": "Cookies analíticos ou de desempenho:",
    "lbl_cookies_text15": "Os cookies de desempenho são utilizados para analisar a forma como os websites são utilizados e monitorizar o seu desempenho, o que nos permite melhorar a sua experiência de utilização dos websites. Esses cookies nos ajudam a adaptar o conteúdo do",
    "lbl_cookies_text16": "sites",
    "lbl_cookies_text17": "para refletir o que os usuários do site acham mais interessante e para identificar quando surgem problemas técnicos com os sites. Também podemos usar esses dados para compilar relatórios para nos ajudar a analisar como os sites são usados, quais são os problemas mais comuns e como podemos melhorar os sites.",
    "lbl_cookies_text18": "Os cookies podem ser bloqueados?",
    "lbl_cookies_text19": "Você pode optar por aceitar ou recusar cookies. A maioria dos navegadores da Web aceita cookies automaticamente, mas geralmente você pode modificar a configuração do navegador para recusar cookies, se preferir. Se você preferir não aceitar cookies, a maioria dos navegadores permitirá que você: (i) altere as configurações do navegador para notificá-lo quando receber um cookie, o que permite que você escolha aceitá-lo ou não; (ii) desativar os cookies existentes; ou (iii) configurar seu navegador para rejeitar automaticamente quaisquer cookies. No entanto, esteja ciente de que, se você desabilitar ou rejeitar cookies, alguns recursos e serviços em nossos sites podem não funcionar corretamente porque podemos não ser capazes de reconhecê-lo e associá-lo à(s) sua(s) conta(s). Além disso, as ofertas que oferecemos quando você nos visita podem não ser tão relevantes para você ou adaptadas aos seus interesses.",
    "lbl_cookies_text20": "Instruções para bloquear ou permitir cookies em softwares populares de navegação na Web Internet Explorer 7 e 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "ligando",
    "lbl_linking_text": "Podemos criar links para outros sites que não estão sob nosso controle. Depois de sair do nosso site, não podemos ser responsáveis ​​pela proteção e privacidade de qualquer informação que você fornecer. Você deve ter cuidado e consultar a declaração de privacidade aplicável ao site em questão.",
    "lbl_yourRights": "Seus direitos",
    "lbl_yourRights_text": "Você pode solicitar uma cópia de qualquer informação pessoal que mantemos sobre você. Uma pequena taxa pode ser paga. Você pode fazer isso escrevendo para dataprotection@eaton.com",
    "lbl_yourRights_text1": "Em algumas circunstâncias, você pode ter direito a receber seus dados pessoais em um formato estruturado, comumente usado e legível por máquina.",
    "lbl_yourRights_text2": "Se você acha que qualquer informação que temos sobre você está incorreta ou incompleta, por favor, escreva ou envie um e-mail para nós o mais rápido possível. Corrigiremos ou atualizaremos qualquer informação, conforme apropriado, o mais rápido possível.",
    "lbl_yourRights_text3": "Você tem o direito de exigir que restrinjamos o processamento de seus dados pessoais em algumas circunstâncias em que:",
    "lbl_yourRights_text4": "você contesta a exatidão dos dados pessoais que processamos sobre você;",
    "lbl_yourRights_text5": "nosso processamento de seus dados é ilegal;",
    "lbl_yourRights_text6": "não precisamos mais dos dados pessoais para os fins para os quais os estamos processando, mas você precisa dos dados pessoais para o estabelecimento, exercício ou defesa de reivindicações legais;",
    "lbl_yourRights_text7": "você contesta se os fundamentos legítimos do controlador prevalecem sobre os do titular dos dados.",
    "lbl_yourRights_text8": "Se você tiver alguma dúvida, comentário ou sugestão sobre o uso que fazemos de seus dados pessoais, escreva-nos para dataprotection@eaton.com",
    "lbl_yourRights_text9": "Você também tem o direito de apresentar uma reclamação junto ao Information Commissioner’s Office se estiver insatisfeito com o processamento de seus dados pessoais.",
    "lbl_noticeToCalfrniaResidents": "Aviso aos residentes da Califórnia",
    "lbl_noticeToCalfrniaResidents_text": "Esta seção se aplica aos residentes da Califórnia.",
    "lbl_noticeToCalfrniaResidents_text1": "Conforme descrito em 'Que informações coletamos?', podemos coletar informações pessoais, incluindo identificadores, informações comerciais, internet ou outras informações de atividade de rede, informações de geolocalização, informações profissionais ou relacionadas ao emprego e inferências extraídas de qualquer uma das informações identificadas acima para criar um perfil sobre um consumidor.",
    "lbl_noticeToCalfrniaResidents_text2": "Conforme descrito na seção `Com quem compartilhamos suas informações?`, as informações pessoais que coletamos dos consumidores podem ser compartilhadas para fins comerciais com terceiros. Podemos ter divulgado todas as categorias de informações pessoais listadas acima, com base no caso de uso, para fins comerciais nos últimos 12 meses.",
    "lbl_noticeToCalfrniaResidents_text3": "Nós não vendemos suas informações pessoais.",
    "lbl_noticeToCalfrniaResidents_text4": "Como residente da Califórnia, você tem certos direitos, sujeitos a limitações legais e exceções aplicáveis, em relação à coleta, uso e compartilhamento de suas informações pessoais. Esses direitos são, em particular:",
    "lbl_noticeToCalfrniaResidents_text5": "o direito de saber",
    "lbl_noticeToCalfrniaResidents_text6": "Você tem o direito de solicitar informações sobre as categorias de informações pessoais que coletamos sobre você, as categorias de fontes das quais coletamos as informações pessoais, os propósitos da coleta das informações pessoais, as categorias de terceiros com quem compartilhamos suas informações informações pessoais e a finalidade para a qual compartilhamos suas informações pessoais (“Relatório de categorias”). Você também pode solicitar informações sobre as informações pessoais específicas que coletamos sobre você (“Relatório de Partes Específicas”). Especifique qual solicitação você deseja executar.",
    "lbl_noticeToCalfrniaResidents_text7": "O direito de deletar",
    "lbl_noticeToCalfrniaResidents_text8": "Você tem o direito de solicitar que excluamos as informações pessoais que coletamos de você.",
    "lbl_noticeToCalfrniaResidents_text9": "O direito de exclusão",
    "lbl_noticeToCalfrniaResidents_text10": "Você tem o direito de cancelar a venda de suas informações pessoais.",
    "lbl_noticeToCalfrniaResidents_text11": "De acordo com a lei aplicável, não o discriminaremos por exercer esses direitos. Você pode usar um agente autorizado para exercer seus direitos em seu nome. Se você estiver fazendo qualquer uma das solicitações acima por meio de um agente autorizado, solicitaremos sua autorização por escrito e buscaremos verificar conforme descrito acima ou aceitaremos uma procuração legal de acordo com o Código de Sucessões da Califórnia para o agente autorizado.",
    "lbl_noticeToCalfrniaResidents_text12": "Você pode exercer seu direito a qualquer momento entrando em contato conosco enviando um e-mail para",
    "lbl_noticeToCalfrniaResidents_text13": "ligando para +1-800-386-1911 ou usando este",
    "lbl_noticeToCalfrniaResidents_text14": "formulário online",
    "lbl_noticeToCalfrniaResidents_text15": "Para exercer seus direitos, precisaremos obter informações para localizá-lo em nossos registros ou verificar sua identidade, dependendo da natureza da solicitação. Se você estiver enviando uma solicitação em nome de uma família, precisaremos verificar cada membro da família da maneira estabelecida nesta seção. Portanto, com base na solicitação, podemos entrar em contato com você para obter mais informações.",
    "lbl_noticeToCalfrniaResidents_text16": "Responderemos a uma Solicitação de Desativação em até 15 dias. Responderemos às Solicitações para excluir e Solicitações para saber dentro de 45 dias, a menos que precisemos de mais tempo. Nesse caso, iremos notificá-lo e podemos levar até 90 dias no total para responder à sua solicitação.",
    "lbl_viewSubEstate": "Ver sub-propriedade",
    "lbl_installers": "Instaladores",
    "lbl_managedPanels": "Painéis não gerenciados",
    "lbl_days": "Dias",
    "lbl_email": "E-mail",
    "lbl_company": "Empresa",
    "lbl_removeInstaller": "Remover instalador",
    "lbl_confirm": "confirme",
    "msg_deleteAdminConfirmation": "Você tem certeza que deseja remover",
    "lbl_editDetails": "Editar Detalhes",
    "msg_adminAdded": "Administrador editado com sucesso",
    "msg_adminDeleted": "Administrador excluído com sucesso",
    "msg_errorDeleteAdmin": "Erro ao deletar administartor",
    "lbl_addAdmin": "Adicionar Administrador",
    "lbl_info": "Informações",
    "lbl_noOfPanels": "Número de painéis",
    "lbl_dateRegistered": "Data de registro",
    "lbl_cmpnyAddress": "Endereço da companhia",
    "lbl_addressDetails": "Detalhes do Endereço",
    "txt_addressDetails": "Para registrar uma conta corporativa, forneça os detalhes do endereço da sua empresa.",
    "lbl_contactDetails": "Detalhes do contato",
    "txt_contactDetails": "Para registrar uma conta corporativa, forneça os dados de contato da sua empresa.",
    "lbl_phoneType": "Tipo de telefone",
    "msg_alreadyAccnt": "Já existe uma conta para este e-mail.",
    "txt_invalidEmail": "e-mail inválido",
    "lbl_createAccnt": "Crie a sua conta aqui",
    "lbl_createAccntDetails": "Para registrar uma conta corporativa, insira as informações necessárias abaixo.",
    "msg_pwdValidation": "Mínimo de 8 caracteres, pelo menos 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial",
    "lbl_createPwd": "Criar uma senha",
    "txt_pwdLength": "A senha deve ter pelo menos 8 caracteres, conter pelo menos um caracter maiúsculo, uma vez um número e uma vez um caractere especial.",
    "lbl_licenseAgreememnt": "Contrato de licença",
    "lbl_licenseAgreememntDetails": "Para se registrar em uma conta corporativa, você precisará ler e concordar com os Termos e Condições.",
    "lbl_accntActivation": "ativação de conta",
    "msg_accntActivation": "Parabéns, você foi cadastrado com o login de",
    "lbl_accntActivationEmailSent": "Enviamos um e-mail de ativação para você. Clique no link dessa mensagem para ativar sua conta.",
    "lbl_notReceive": "Não recebeu e-mail?",
    "lbl_sendAgain": "Envie novamente",
    "lbl_cnfrmPwd": "Confirme sua senha",
    "msg_accntExists": "Já existe uma conta para este e-mail.",
    "lbl_forgotPwd": "Se o ID do usuário existir, um link para redefinir sua senha será enviado para",
    "lbl_RmtSvsMsg": "Não é possível prosseguir, o painel está no modo instalador",
    "lbl_planned": "PLANEJADO",
    "lbl_noPlannedService": "Nenhum relatório de serviço planejado",
    "lbl_rmtServInitiationMsg": "Um serviço remoto manual foi solicitado. Aguarde de 5 a 10 minutos para que o relatório seja concluído.",
    "lbl_okay": "Ok",
    "lbl_pwdReset": "Redefinição de senha",
    "lbl_setPwd": "Configurar senha",
    "lbl_pwdResetMsg": "Para redefinir sua senha, digite uma nova senha abaixo.",
    "lbl_pwdResetSuccess": "Sucesso na redefinição de senha",
    "lbl_pwdResetSuccessMsg": "Sua senha foi redefinida com sucesso. Faça login no aplicativo usando sua senha atualizada.",
    "lbl_pwdResetErrMsg": "Redefinição de senha já solicitada nos últimos 15 minutos.",
    "btn_download_notifications": "Baixar histórico do painel",
    "btn_download_logs": "Baixar notificações",
    "lbl_userAccDelete": "Exclusão de conta de usuário",
    "lbl_userAccDeleteConfirm": "Quer confirmar a exclusão da conta?",
    "lbl_userAccDeleteMsg": "Para solicitar a remoção de uma conta de usuário, insira o endereço de e-mail da conta abaixo e depois o botão enviar.",
    "lbl_userAccDeleteSubmitMsg": "Sua solicitação de exclusão de conta de usuário foi enviada. Se o ID do usuário existir, um link para redefinir sua senha será enviado para",
    "btn_rmvLogo": "Remover logotipo",
    "btn_cgnLogo": "Alterar logotipo",
    "lbl_endUserMsg": "As informações fornecidas aqui serão exibidas tanto no aplicativo Mobile quanto nos relatórios de Atendimento Remoto",
    "lbl_dwnPanelLog": "Baixar registros do painel",
    "lbl_estatelessPanels": "Painéis fora de uma propriedade",
    "lbl_siteComments": "Comentários",
    "mainHeadingforsystemConfig": "Cabeçalho",
    "lbl_EatonGCMorFCMurl": "URL Eaton GCM ou FCM",
    "lbl_EatonapiKey": "Chave API Eaton",
    "lbl_eatonApplepassword": "Senha da Eaton Apple",
    "lbl_applePushNotification": "Apple usa notificação push de produção",
    "headingaccountSettings": "Configurações de conta",
    "lbl_account_default_state": "Estado padrão da conta",
    "lbl_choose_state_for_new_account": "Escolha o estado do instalador da nova conta",
    "lbl_account_disable_after": "Conta desativada após",
    "lbl_the_top_limit_in_days": "O limite máximo em dias, após o qual uma conta será desativada",
    "lbl_remove_unpaired_account_after": "Remover conta não pareada após",
    "lbl_helper_text": "Texto auxiliar",
    "lbl_cloud_password": "Cloud password",
    "lbl_user_registration_disable_time": "Tempo de desativação do registro do usuário",
    "lbl_invalid_login_attempt": "Duração de bloqueio de tentativa de login inválida",
    "lbl_maximum_sign_in_attempt": "Máximo de tentativas de login",
    "lbl_seconds": "segundas",
    "lbl_times": "vezes",
    "headingCertificates": "Certificados",
    "lbl_eaton_Apple_certificaates": "Certificado Eaton Apple",
    "lbl_no_file_selected": "Nenhum arquivo selecionado",
    "lbl_upload_file": "Subir arquivo",
    "lbl_heartbeat_interval": "Intervalo de batimentos cardíacos",
    "lbl_miliseconds": "milissegundos",
    "lbl_WS_session_drop_after": "Sessão WS cai após",
    "lbl_jwt_time_to_live": "JWT é hora de viver",
    "lbl_communication_log_size": "Tamanho do log de comunicação",
    "lbl_panel_log_size": "Tamanho de download do log do painel",
    "lbl_nesting_group_level": "Nível de grupo de aninhamento",
    "lbl_ws_sessions_close_qty": "Quantidade de fechamento de sessões WS",
    "lbl_async_context_timeout": "Tempo limite de contexto assíncrono",
    "lbl_refer_header": "Cabeçalho do referenciador",
    "heading_Liceense_Agrrement": "Contrato de licença",
    "heading_proxy": "Procurador",
    "lbl_proxy_host": "Anfitrião proxy",
    "lbl_proxy_port": "Porta proxy",
    "lbl_use_proxy": "Usar proxy",
    "lbl_fichet_gcmorfcm": "Ficheiro URL do GCM ou FCM",
    "lbl_fichetApiKey": "Arquivo de chave de API",
    "lbl_fichet_applePsword": "Insira a senha da Apple",
    "lbl_fichet_applepassword": "Certificado Fichet Apple",
    "lbl_discard": "Descartar",
    "heading_CSL": "Configurações de CSL",
    "lbl_csl_login": "Login CSL",
    "lbl_csl_password": "Senha CSL",
    "lbl_csl_primary_ip": "IP do servidor primário CSL",
    "lbl_csl_primary_port": "Porta do servidor primário CSL",
    "lbl_csl_secondary_IP": "IP do servidor secundário CSL",
    "lbl_csl_secondary_port": "Porta do servidor secundário CSL",
    "lbl_csl_primary_protocol": "Protocolo do servidor primário CSL",
    "lbl_csl_secondary_portocol": "Protocolo de servidor secundário CSL",
    "lbl_poll_fails": "A pesquisa CSL falha após",
    "lbl_minutes": "minutos",
    "button_CSL_ARC": "Códigos CSL ARC",
    "button__csl_countries": "Países CSL",
    "lbl_dual_com_login": "Login CSL DualCom",
    "lbl_dual_com_password": "Senha CSL DualCom",
    "lbl_poll_failer_helper": "Enviar mensagem poll fail sia para CSL se o painel estiver desconectado por mais tempo que esse valor em minutos",
    "lbl_add_email": "Adicionar e-mail",
    "lbl_csl_support_team_e-mail_address": "Endereços de e-mail da equipe de suporte da CSL",
    "lbl_Support_email_list_of_CSL_arc_provider": "Lista de e-mail de suporte do provedor CSL arc",
    "lbl_support_team_email_address": "Endereços de e-mail da equipe de suporte",
    "lbl_email_address_of_support_team": "Endereços de e-mail da equipe de suporte",
    "heading_Permissions": "Permissões",
    "lbl_Enable_auth": "Habilitar autenticação",
    "lbl_Mail_transport_protocol_authorization": "Autorização do protocolo de transporte de correio",
    "lbl_EnableTLS": "Habilitar TLS",
    "lbl_Mail_transport_protocol_enable": "Habilitar protocolo de transporte de correio",
    "heading_Info_SMTP": "Informações SMTP",
    "lbl_SMTP_host": "Anfitrião SMTP",
    "lbl_Mail_trasport_protocol_host": "Host do protocolo de transporte de correio",
    "lbl_SMTP_port": "Porta SMTP",
    "lbl_Mail_trasport_protocol_post": "Postagem de protocolo de transporte de correio",
    "lbl_SMTP_user": "Usuário SMTP",
    "lbl_Mail_transport_protocol_user": "Usuário do protocolo de transporte de correio",
    "lbl_SMTP_password": "Senha SMTP",
    "lbl_Mail_transport_protocol_password": "Senha do protocolo de transporte de correio",
    "lbl_Protocol": "Protocolo",
    "lbl_Mail_transport_protocol": "Protocolo de transporte de correio",
    "lbl_Form": "Forma",
    "lbl_Mail_transport_protocol_source": "Fonte do protocolo de transporte de correio",
    "heading_Email_Settings": "Configurações de e-mail",
    "lbl_Email_Text_Language": "Idioma do texto do e-mail",
    "lbl_User_registration_e-mail": "E-mail de cadastro do usuário",
    "lbl_helper_text_for_user_register_mail": "Este texto será enviado para o e-mail do usuário.${user.login} será substituído no login do usuário, que receberá o e-mail ${user.name} - nome de usuário, ${user-cloudId} - ID da nuvem do usuário, ${confirm.link} - link de confirmação por e-mail",
    "lbl_State_change_e-mail": "E-mail de mudança de estado",
    "lbl_helpertext_for_state_email_change": "Este texto será enviado para o e-mail do usuário.${user.login} será substituído no login do usuário, que receberá o e-mail ${user.name} - nome de usuário, ${user.state} estado do usuário, ${user-cloudId} - ID da nuvem do usuário",
    "lbl_Forgot_login_e-mail_subject": "Esqueci o assunto do e-mail de login",
    "lbl_Email_subject_for_login_reminder": "Assunto do email para lembrete de login",
    "lbl_Forgotlogine-mail": "Esqueci o e-mail de login",
    "lbl_helpertext_for_forget_login_mail": "Ao editar a descrição, insira as variáveis.​​${user.name} e ${user.login} quando quiser ver o nome de usuário e login do usuário, respectivamente.",
    "lbl_App_user_creation_e-mail": "E-mail de criação de usuário do aplicativo",
    "lbl_helper_text_for_app_user_creation": "Este texto será enviado para o e-mail do usuário. ${user.login} será substituído no login do usuário, que receberá o e-mail ${user.name} - nome de usuário ${confirm.link} - link de confirmação por e-mail",
    "lbl_Forgot_passworde-mail": "Esqueci o e-mail da senha",
    "lbl_helper_text_for_forgetpassworde-mail": "Este texto será enviado para o e-mail do usuário. ${user.name} - nome de usuário ${secretLink} - URL para alteração de senha",
    "lbl_Maintenance_notification_subject": "Assunto da notificação de manutenção",
    "lbl_Subject_for_all_user_maintenancenotification": "Assunto para todas as notificações de manutenção do usuário",
    "lbl_Maintenance_notificationtext": "Texto de notificação de manutenção",
    "lbl_helper_text_for_maintain_notigication": "Este texto será enviado a todos os usuários quando for necessário notificá-los sobre algum evento no servidor nuvem",
    "lbl_Raised_alert_event_e-mail_text": "Texto de e-mail de evento de alerta gerado",
    "lbl_helper_text_for_raised_alert_event": "Isso será enviado para a equipe de suporte do painel. ${site.name} - será substituído no nome de referência da nuvem do painel ${panel.id} - será substituído no id do painel da nuvem ${sia.code} - evento de alerta Código SIA ${description} - evento de alerta Descrição do código SIA ${time.stamp} - carimbo de data e hora em que o alerta foi gerado ${panel.link} - Hiperlink para a tela do painel da nuvem",
    "lbl_Self_test_failure_text": "Texto de falha no autoteste",
    "lbl_helper_text_for_test_failure_Text": "Este texto será enviado para a equipe de suporte.${event.ipAdd} - endereço IP do nó com falha ${event.type} - tipo de evento ${event.message} - mensagem ${event.connected} - status da conexão ${event.date} - carimbo de data/hora do evento",
    "lbl_SIA_message_failure_e-mail_subject": "Assunto do e-mail de falha na mensagem SIA",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Sujeito a mensagem sobre falha no envio de eventos sia",
    "lbl_SIA_message_failuree-mail_text": "Texto do e-mail de falha na mensagem SIA",
    "lbl_Email_text_about_siaevent_sendingproblems": "Texto de e-mail sobre problemas de envio de eventos sia",
    "lbl_time_in_seconds_for_locking_account": "Tempo em segundos para bloquear a conta quando ocorreram muitas tentativas de login malsucedidas",
    "lbl_max_failed_login": "Número máximo de tentativas de login malsucedidas antes de bloquear a conta",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Tempo em segundos para desativar temporariamente o registro de novos usuários",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Forneça credenciais para o usuário da nuvem no painel",
    "lbl_Days_to_remove_unpaired_user_accounts": "Dias para remover contas de usuário não pareadas",
    "lbl_google_firebase_push_notification": "URL do servidor de notificação push do Google ou Firebase",
    "lbl_api_key": "Chave API",
    "lbl_Apple_push_notification_server_password": "Senha do servidor de notificação push da Apple",
    "lbl_determines_apple_server": "Determina quais servidores Apple devem ser usados: produção ou sandbox",
    "lbl_upload_certificate_for_eaton": "Carregar um certificado para Eaton Apple Push Notification",
    "lbl_Heartbeat_intervalinmilliseconds": "Intervalo de batimentos cardíacos em milissegundos",
    "lbl_Atime_delay_between_closing_session": "Um atraso de tempo entre o fechamento da sessão",
    "lbl_json_web_token": "Tempo de vida do token da web JSON",
    "lbl_hazelcast_max_comminication_log_size": "Tamanho máximo do log de comunicação do Hazelcast",
    "lbl_Max_size_of_log_entries": "Tamanho máximo das entradas de log para download do painel",
    "lbl_max_nesting_group_level": "Nível máximo do grupo de aninhamento",
    "lbl_count_of_Web_token_which_will_be_closed": "Uma contagem de sessões de web socket, que serão fechadas",
    "lbl_timeout_for_external_async_request": "Tempo limite para solicitação assíncrona externa",
    "lbl_pattern_for_refer_header": "Padrão para cabeçalho de referência",
    "heading_Support_actions": "Ações de apoio",
    "lbl_User_e-mail": "E-mail do usuário",
    "lbl_Action": "Ação",
    "btn_Startaction": " Iniciar ação",
    "heading_Download_Reports": "Baixar relatórios",
    "lbl_Installer_report": "Relatório do instalador",
    "lbl_Download": "Download",
    "lbl_App_userReport": "Relatório de usuário do aplicativo",
    "lbl_Managed_panelsreport": "Relatório de painéis gerenciados",
    "lbl_Unmanaged_panels_report": "Relatório de painéis não gerenciados",
    "lbl_ARC_report": "Relatório ARC",
    "heading_confirmation": "Confirmação",
    "msg_for_Pairing": "Isso removerá todas as informações do usuário (emparelhamento, dispositivos móveis) e ele não poderá mais acessá-las",
    "msg_for_remove_pariring": "Tem certeza de que deseja remover o emparelhamento entre",
    "msg_for_disable_registration": "Tem certeza de que deseja desativar temporariamente os registros da nuvem e do aplicativo?",
    "lbl_User_Info": "informação de usuário",
    "error_pwd_info_capital": "Utilize letras maiúsculas (X,Y,Z...),",
    "error_pwd_small": "letras minúsculas (x,y,z...),",
    "error_pwd_number": "números (1,2,3,4...),",
    "error_pwd_special": "e caracteres especiais (!@#$%^&*)na senha na senha",
    "error_pwd_length": "O comprimento da senha não deve ser inferior a 6 caracteres.",
    "lbl_installer_Details": "Detalhes do instalador",
    "lbl_error_for_Date_Range": "Nenhum resultado correspondente para o período selecionado",
};
export default resources;