/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import Appbar from '../../components/Appbar/Appbar';
import { useNavigate } from 'react-router-dom';
import { AppBar, Button, Paper, Toolbar, Typography, useMediaQuery, useTheme, Theme, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ToggleButtons from '../../components/ToggleListAndGrid/ToggleButtonGroup';
import './background.css';
import PanelsView from '../../components/ListAndGridView/PanelsView';
import { fetchPanelsAndEstates } from '../../redux/actions/panelEstateActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { setEstatesPanelView, setPanelView } from '../../redux/actions/specificPanelActions';
import { ROLES } from '../../entities/Constants';
import PanelsOverView from '../../components/ListAndGridView/PanelsOverView';
import ManagePanelsModal from '../../components/ManagePanelsAndEstatesModal/ManagePanelsModal';
import { fetchEstatesAndPanels, fetchPanelsInEstate } from '../../redux/actions/managePanelsAndEstatesActions';
import { EstateProps } from '../../components/ListAndGridView/panelEstateTypes';
import { setMenuSelected } from '../../redux/actions/settingsActions';
import { createStyles, makeStyles } from '@mui/styles';
import Usermenu from '../UserMenu/UserMenu';
import { Spacer } from '@brightlayer-ui/react-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {  fetchEstates, fetchPanels, fetchUnAssignedPanels, setEstate } from '../../redux/actions/esateActions';
import EstatePanelsView from './EstatePanelsView';
import NewSubEstateModal from '../NewSubEstate/NewEstateModal';
import { useTranslation } from 'react-i18next';
import ManagePanelsModalForEstate from '../ManagePanelsAndEstatesModal/ManagePanelsModalForEstate';
import secureLocalStorage  from  "react-secure-storage";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: 'white'
        },
    })
);

export function Estate(): JSX.Element {
    const dispatchEstate = useDispatch()
    const dispatch = useTypedDispatch();
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const specificEstate: any = secureLocalStorage.getItem('estate') || {};
    const navPath: any = secureLocalStorage.getItem('pathArray') || '';
    const role = secureLocalStorage.getItem('role')
    const [view, setView] = React.useState('list');
    const [open, setOpen] = React.useState(false);
    const [managePanelsOpen, setManagePanelsOpen] = React.useState(false);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { estates } = useSelector((state: any) => state.estateReducer);
    const { unassignedPanels } = useSelector((state: any) => state.userReducer);
    const { estatesPanelView } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { panelsInEstate } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
    const [panelsInEstates, setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
    const [count, setCount] = React.useState(0);
    const [pathArray, setPathArray] = React.useState<Array<any>>(navPath);
    const [movePanel, setMovePanel] = React.useState(false);
    const [assignPath, setAssignPath] = React.useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackClick = (): any => {
        const path = pathArray[pathArray.length - 2];
        console.log(path)
        setPathArray(pathArray.slice(0,pathArray.length - 1))
        secureLocalStorage.setItem('pathArray',pathArray)
        if (pathArray.length >1) {
            dispatchEstate(setEstate(path));
            navigate(-1);
            const fetching = async (id: any) => {
                await dispatch(fetchEstates(id));
                await dispatch(fetchPanels(id));
                await dispatch(fetchUnAssignedPanels(id));
            };
            fetching(path.ID);
            secureLocalStorage.setItem('estate',path)
        } else {
            navigate(-1);
            secureLocalStorage.setItem('viewSubEstate', 'list');
        }
    };

    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleManagePanelsModal = async () => {
        await dispatch(
            fetchPanelsInEstate(
                estates.sort((a: EstateProps, b: EstateProps) => {
                    if (a.ID !== undefined && b.ID !== undefined) {
                        return a.ID - b.ID;
                    }
                })
            )
        );
        await dispatch(fetchEstatesAndPanels(specificEstate));
        setMovePanel(false);
        setManagePanelsOpen(true);
    };
    const fetching = async() => {
        await dispatch(fetchEstates(specificEstate?.ID))
       // await dispatch(fetchPanels(specificEstate?.ID))
        await dispatch(fetchUnAssignedPanels(specificEstate?.ID))
    }
    useEffect(() => {
        if(assignPath && specificEstate !== undefined && pathArray.length === 0){
            setPathArray([specificEstate])
            setAssignPath(false)
        }
        dispatch(setMenuSelected('panels'));
        if (estatesPanelView === 'list') {
            secureLocalStorage.setItem('viewSubEstate', 'list');
            dispatch(setEstatesPanelView('list'));
        }
        setView(estatesPanelView);

        if (estatesPanelView === '') {
            const viewType:any = secureLocalStorage.getItem('viewSubEstate') || '';
            if (viewType !== '') {
                setView(viewType);
            } else {
                setView('list');
            }
        }
        fetching()
        
    }, [count,specificEstate.ID]);

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        secureLocalStorage.setItem('viewSubEstate', nextView);
        setView(nextView);
    };
    const title = `${t('menu_oveview')}`;
    return (
        <>
            <div className="main-div">
                <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                    <AppBar elevation={4} position={'sticky'}>
                        <Toolbar className={classes.toolbar}>
                            {md ? (
                                <IconButton
                                    color={'default'}
                                    onClick={handleBackClick}
                                    edge={'start'}
                                    style={{ marginRight: theme.spacing(3) }}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            ) : (
                                <IconButton
                                    color={'default'}
                                    onClick={handleBackClick}
                                    edge={'start'}
                                    style={{ marginRight: theme.spacing(3) }}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {!md? <Typography variant="h6" sx={{ color: '#424E54' }}>
                                    {specificEstate !== undefined &&
                                        pathArray.map((specificEstate, key) =>
                                            key > 0
                                                ? ` - ${specificEstate?.name.slice(0, 20).toString()}`
                                                : `${specificEstate?.name.slice(0, 20).toString()}`
                                        )}
                                </Typography>
                                :
                                <input
                                    readOnly
                                    value={ pathArray.map((specificEstate, key) =>
                                        key > 0
                                            ? ` - ${specificEstate?.name.slice(0, 20).toString()}`
                                            : `${specificEstate?.name.slice(0, 20).toString()}`
                                    )}
                                    style={{
                                        border: 'none',
                                        outline: 'none',
                                        fontSize: 16,
                                        fontFamily: 'Open Sans',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: 1,
                                        width: '60dvw',
                                        textAlign: 'left',
                                        backgroundColor: '#FBFBFB',
                                    }}
                                />
                                }
                            </div>
                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                            <Usermenu />
                        </Toolbar>
                    </AppBar>
                    {md ? (
                        <div style={{ backgroundColor: '' }}>
                            <Box sx={{ flexGrow: 1, marginLeft: 0, marginRight: 0, mb: 4 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={11.7}>
                                        <Item sx={{ float: 'right', mt: 2 }} elevation={0}>
                                            <ToggleButtons view={view} handleChange={handleChange} />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    ) : (
                        <Box sx={{ flexGrow: 1, marginLeft: 10, marginRight: 10, marginTop: 5 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={2.3}>
                                    {(role === 'ADMIN' || role === 'INSTALLER' || role === 'MANAGER') && (
                                        <Item elevation={0}>
                                            <Button
                                                disabled={
                                                    currentUser.userType === 'ENGINEER' ||
                                                    currentUser.userType === 'OFFICE_STAFF'
                                                }
                                                variant="contained"
                                                fullWidth
                                                disableElevation
                                                onClick={handleManagePanelsModal}
                                            >
                                                <EditIcon fontSize="small" />
                                                &nbsp;&nbsp;{t('lbl_manageEstates')}
                                            </Button>
                                            <ManagePanelsModalForEstate
                                                estatePage={true}
                                                movePanel={movePanel}
                                                setCount={setCount}
                                                count={count}
                                                panelsInEstates={panelsInEstates}
                                                setPanelsInEstates={setPanelsInEstates}
                                                open={managePanelsOpen}
                                                setOpen={setManagePanelsOpen}
                                            />
                                        </Item>
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    {(role === 'ADMIN' || role === 'INSTALLER' || role === 'MANAGER') && (
                                        <Item elevation={0}>
                                            <Button
                                                disabled={
                                                    currentUser.userType === 'ENGINEER' ||
                                                    currentUser.userType === 'OFFICE_STAFF' ||
                                                    pathArray.length === 4
                                                }
                                                fullWidth
                                                variant="outlined"
                                                onClick={handleOpen}
                                            >
                                                <AddIcon fontSize="small" />
                                                &nbsp;&nbsp;{t('lbl_newEstate')}
                                            </Button>
                                            <NewSubEstateModal open={open} setOpen={setOpen} />
                                        </Item>
                                    )}
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={1.7}>
                                    <Item sx={{ float: 'right' }} elevation={0}>
                                        <ToggleButtons view={view} handleChange={handleChange} />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                    {md ? (
                        <div style={{ backgroundColor: '' }}>
                            <Box sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, marginTop: 0 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EstatePanelsView
                                            pathArray={pathArray}
                                            setPathArray={setPathArray}
                                            view={view}
                                        />
                                    </Grid>
                                    <Grid item xs={12}></Grid>
                                    <Grid item xs={12}></Grid>
                                </Grid>
                            </Box>
                        </div>
                    ) : (
                        <>
                            <Box sx={{ flexGrow: 1, marginLeft: 7, marginRight: 7, marginTop: 4 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <EstatePanelsView
                                            pathArray={pathArray}
                                            setPathArray={setPathArray}
                                            view={view}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    )}
                    {md && (
                        <>
                            {(role === 'ADMIN' || role === 'INSTALLER' || role === 'MANAGER') && (
                                <Paper
                                    sx={{
                                        zIndex:1000,
                                        position: 'fixed',
                                        top: '100dvh',
                                        transform: 'translateY(-100%)',
                                        width: '100%',
                                        p: 2,
                                        backgroundColor: 'white',
                                        borderRadius: 0,
                                        boxShadow:
                                            '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1, marginLeft: 0, marginRight: 0 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Item elevation={1}>
                                                    <Button
                                                        disabled={
                                                            currentUser.userType === 'ENGINEER' ||
                                                            currentUser.userType === 'OFFICE_STAFF'
                                                        }
                                                        variant="contained"
                                                        fullWidth
                                                        disableElevation
                                                        sx={{ fontSize: 12 }}
                                                        onClick={handleManagePanelsModal}
                                                    >
                                                        <EditIcon sx={{ fontSize: 15 }} />
                                                        &nbsp;&nbsp;
                                                        <Typography variant="body2">
                                                            {t('lbl_manageEstates')}
                                                        </Typography>
                                                    </Button>
                                                    <ManagePanelsModalForEstate
                                                        estatePage={true}
                                                        movePanel={movePanel}
                                                        setCount={setCount}
                                                        count={count}
                                                        panelsInEstates={panelsInEstates}
                                                        setPanelsInEstates={setPanelsInEstates}
                                                        open={managePanelsOpen}
                                                        setOpen={setManagePanelsOpen}
                                                    />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {((role === 'ADMIN' && specificEstate.type === 'INSTALLER') ||
                                                    role === 'INSTALLER' ||
                                                    role === 'MANAGER') && (
                                                    <Item elevation={0}>
                                                        <Button
                                                            disabled={
                                                                currentUser.userType === 'ENGINEER' ||
                                                                currentUser.userType === 'OFFICE_STAFF' ||
                                                                pathArray.length === 4
                                                            }
                                                            fullWidth
                                                            variant="outlined"
                                                            sx={{ fontSize: 13 }}
                                                            onClick={handleOpen}
                                                        >
                                                            <AddIcon sx={{ fontSize: 15 }} />
                                                            &nbsp;&nbsp;{t('lbl_newEstate')}
                                                        </Button>
                                                        <NewSubEstateModal open={open} setOpen={setOpen} />
                                                    </Item>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                            )}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
