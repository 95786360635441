/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useState } from 'react'
import { Box, Theme, Typography, IconButton, Toolbar, AppBar, useTheme, useMediaQuery, Paper, Grid, Divider, MobileStepper, Button, styled, } from '@mui/material'
import { useNavigate } from 'react-router';
import { createStyles, makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { Spacer } from '@brightlayer-ui/react-components';
import Usermenu from '../../../UserMenu/UserMenu';
import { useSelector } from 'react-redux';
import  secureLocalStorage  from  "react-secure-storage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: 'white'
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));


const SIAEmailSubject = (): JSX.Element => {
    const { mailDetails } = useSelector((state: any) => state.systemConfigurationReducer)
    const mailData = mailDetails?.length > 0 && mailDetails !== undefined && mailDetails?.filter((data: any) => data.key === 'csl.sia.fail.email.subject')[0].value
    const [textarea, setTextArea] = useState(mailData)
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const { t } = useTranslation()

    React.useEffect(() => {
        secureLocalStorage.setItem('mailTab', `3`)
    },[]);

    const handleBackClick = () => {
        navigate('/system_configuration')
    }

    const handleAreaChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTextArea(e.target.value)
    }
    return (
        <>
            <div className='main-div'>
                <AppBar elevation={4} position={'sticky'}>
                    <Toolbar className={classes.toolbar}>
                        {md ?
                            <IconButton
                                color={'default'}
                                onClick={handleBackClick}
                                edge={'start'}
                                style={{ marginRight: theme.spacing(3) }}
                                size="large"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            : (
                                <IconButton
                                    color={'default'}
                                    onClick={handleBackClick}
                                    edge={'start'}
                                    style={{ marginRight: theme.spacing(3) }}
                                    size="large"
                                >
                                    <ArrowBackIcon />
                                </IconButton>
                            )}
                        <div style={{ display: 'flex', flexDirection: 'column' }} >
                            <Typography variant='h6' sx={{ color: '#424E54' }} >
                                {t('lbl_SIA_message_failure_e-mail_subject')}
                            </Typography>

                        </div>
                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        <Usermenu />
                    </Toolbar>
                </AppBar>

                <Box sx={{ flexGrow: 1, marginLeft: md ? '1%' : '10%', marginRight: md ? '1%' : '10%', marginTop: 2, pb: md ? 5 : 17 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2, marginTop: 2, }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle2" color={'primary'}>
                                                {t('lbl_SIA_message_failure_e-mail_subject')}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.5}>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>

                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2, }}>
                                    <textarea
                                        style={{
                                            width: '102%',
                                            border: 'none',
                                            outline: 'none',
                                            fontSize: 16,
                                            fontFamily: 'Open Sans',
                                            fontStyle: 'normal',
                                            height: '401px',
                                        }}
                                        onChange={handleAreaChange}
                                        value={textarea}
                                    />
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                <Item elevation={1}>
                    <Box>
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="bottom"
                            sx={{
                                width: '100%',
                                flexGrow: 1,
                                height: '9%',
                                backgroundColor: '#fff',
                                paddingRight: '7%',
                                paddingLeft: '7%'
                            }}
                            nextButton={
                                <Button size="medium" variant="contained">
                                    {t('btn_save')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant="outlined">
                                    {t('lbl_discard')}
                                </Button>
                            }
                        />
                    </Box>
                </Item>
            </div>
        </>
    )
}

export default SIAEmailSubject
