/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    Button,
    Checkbox,
    Chip,
    Collapse,
    IconButton,
    InputAdornment,
    List,
    ListItemButton,
    ListItemText,
    Stack,
    TableCell,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EstateProps, PanelProps } from '../../components/ListAndGridView/panelEstateTypes';
import { useSelector } from 'react-redux';
import { SEARCH_PARAM_PANELS, SEARCH_PARAM_ESTATES, PANEL_FILTER } from '../../constants/index';
import { EmptyState } from '@brightlayer-ui/react-components';
import FolderIcon from '@mui/icons-material/Folder';
import DeviceWithKeypad from '@brightlayer-ui/icons-mui/DeviceWithKeypad';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PanelFilterMenu from '../ListAndGridView/FilterPanelsMenu';
import ListOfEstates from './ListOfEstates';
import GridOfEstates from './GridOfEstates';
import ListOfPanels from './ListOfPanels';
import GridOfPanels from './GridOfPanels';
import FilterListIcon from '@mui/icons-material/FilterList';
import '../ListAndGridView/button.css';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';
import { fetchEstatePanelsAlertsAndInstallerEmailOption } from '../../redux/actions/esateActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

type panelCardProps = {
    pathArray: any[]
    setPathArray: (pathArray: any[]) => void
    view: string;
};


export const E_PanelContext = React.createContext([]);
export const E_EstateContext = React.createContext([]);

export default function EstatePanelsView({ pathArray, setPathArray, view }: panelCardProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    //const { installerEmailStatuses, userEmailAlerts, allPanels } = useSelector((state: any) => state.userReducer);
    const [sortListType, setSortListType] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
    const [mobileView, setMobileView] = React.useState('list');
    const [viewCheck, setViewCheck] = React.useState<boolean>(true);
    const [collapseEstate, setCollapseEstate] = React.useState<boolean>(true);
    const [collapsePanels, setCollapsePanels] = React.useState<boolean>(true);
    const [listOpen, setListOpen] = React.useState<boolean>(false);
    const openPanelFilter = Boolean(anchorE2);
    const [panelQuery, setPanelQuery] = React.useState('');
    const [estateQuery, setEstateQuery] = React.useState('');
    let { estates } = useSelector((state: any) => state.estateReducer);
    const { unassignedPanel, allPanels, installerEmailStatuses, userEmailAlerts } = useSelector((state: any) => state.estateReducer);
    const [filterArray, setFilterArray] = React.useState<Array<any>>([]);
    const [page, setPage] = React.useState(0);
    const { t } = useTranslation();

    secureLocalStorage.setItem(SEARCH_PARAM_PANELS, panelQuery.toString());
    secureLocalStorage.setItem(SEARCH_PARAM_ESTATES, estateQuery.toString());
    secureLocalStorage.setItem(PANEL_FILTER, filterArray.length.toString());
    let [panels, setPanels] = React.useState<any>([]);

    React.useEffect(() => {
        if(page === 0 ){
            if(filterArray.length === 0){
                dispatch(fetchEstatePanelsAlertsAndInstallerEmailOption(unassignedPanel?.slice((page * 10),(page * 10) + 10)))
                }
                else{
                dispatch(fetchEstatePanelsAlertsAndInstallerEmailOption(panels?.slice((page * 10),(page * 10) + 10)))
                }
        viewCheck ? setMobileView('list') : setMobileView('module');
        if(unassignedPanel !== undefined && unassignedPanel.length >= 0){
            setPanels(unassignedPanel)
        }
    }
    else{
        dispatch(fetchEstatePanelsAlertsAndInstallerEmailOption(panels?.slice((page * 10),(page * 10) + 10)))
    }
    }, [viewCheck,unassignedPanel,page,panelQuery,filterArray.length]);

    const handleListOpen = (event: any): any => {
        setAnchorE2(event);
        setListOpen(!listOpen)
    }

    const handlePanelSearchCancelClick = (): any => {
        setPanelQuery('');
    };

    const handleEstateSearchCancelClick = (): any => {
        setEstateQuery('');
    }

    try {
        if (sortListType.length > 0) {
            if (sortListType === 'A-Z') {
                const comparePanelNames = (a: PanelProps, b: PanelProps): any => {
                    if (a.cloudReferenceName < b.cloudReferenceName) {
                        return -1;
                    }
                    if (a.cloudReferenceName > b.cloudReferenceName) {
                        return 1;
                    }
                    return 0;
                };
                const compareEstateNames = (a: EstateProps, b: EstateProps): any => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                };

                panels.length ? panels.sort(comparePanelNames) : (panels = []);
                estates.length ? estates.sort(comparePanelNames) : (estates = []);
            }
            if (sortListType === 'Z-A') {
                const comparePanelNames = (a: PanelProps, b: PanelProps): any => {
                    if (a.cloudReferenceName < b.cloudReferenceName) {
                        return 1;
                    }
                    if (a.cloudReferenceName > b.cloudReferenceName) {
                        return -1;
                    }
                    return 0;
                };
                const compareEstateNames = (a: EstateProps, b: EstateProps): any => {
                    if (a.name < b.name) {
                        return 1;
                    }
                    if (a.name > b.name) {
                        return -1;
                    }
                    return 0;
                };

                panels.length ? panels.sort(comparePanelNames) : (panels = []);
                estates.length ? estates.sort(comparePanelNames) : (estates = []);
            }
        } else {
            panels.length ? panels : (panels = []);
            estates.length ? estates : (estates = []);
        }
    } catch (exception) {
        console.log(`Exception in panels/estates filtering: ${JSON.stringify(exception)}`);
    }

    try {
        if (panels.length > 0) {
            const filteredPanels = panels.filter((panel: PanelProps) =>
                panel.cloudReferenceName.toLowerCase().includes(panelQuery.toLowerCase())
            );
            panels = panelQuery.length > 0 ? filteredPanels : panels;
        }

        if (estates.length > 0) {
            const filteredEstates = estates.filter((estate: EstateProps) =>
                estate.name.toLowerCase().includes(estateQuery.toLowerCase())
            );
            estates = estateQuery.length > 0 ? filteredEstates : estates;
        }
    } catch (exception) {
        console.log(`Exception in panels/estates filtering: ${JSON.stringify(exception)}`);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setViewCheck(event.target.checked);
    };

    const handleFilterDelete = (filter: string) => {
        setFilterArray(filterArray.filter((f: string) => f !== filter))
    }

    try {
        if (filterArray.length !== 0) {
            for (let i = 0; i < filterArray.length; i++) {
                // panels = allPanels
                let filteredPanels
                switch (filterArray[i]) {
                    case 'Disconnected': filteredPanels = panels.filter((panel: PanelProps) =>
                        panel.status === 'DISCONNECTED');
                        panels = filteredPanels
                        break;
                    case 'Connected': filteredPanels = panels.filter((panel: PanelProps) =>
                        panel.status === 'CONNECTED');
                        panels = filteredPanels
                        break;
                    case 'ARC enabled': filteredPanels = panels.filter((panel: PanelProps) =>
                        panel.arcAccessEnabled === true);
                        panels = filteredPanels
                        break;
                    case 'End user access disabled': filteredPanels = panels.filter((panel: PanelProps) =>
                        panel.userAccessEnabled === false);
                        panels = filteredPanels
                        break;
                    case 'End user email enabled': filteredPanels = panels.filter((panel: PanelProps) =>
                        userEmailAlerts?.filter((user: any) => user?.device.ID === panel.ID)[0]?.siaEmailSettings?.length > 0
                    );
                        panels = filteredPanels
                        break;
                    case 'Installer email alerts enabled': filteredPanels = panels.filter((panel: PanelProps) =>
                        installerEmailStatuses?.slice(-(allPanels.length)).filter((arr: any) => arr?.ID === panel?.ID)[0]?.installerEmailAlertsStatus
                    );
                        panels = filteredPanels
                        break;

                    default:
                        break;
                }
            }
        }
    }
    catch (ex) {
        console.log(ex)
    }

    const handleEstateCollapse = () => {
        setCollapseEstate(!collapseEstate);
      };

    const handlePanelCollapse = () => {
        setCollapsePanels(!collapsePanels);
      };

    return (
        <>
            {md ? (<>
                <Box sx={{ flexGrow: 1, marginLeft: -1, marginRight: -1, mb: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={11}>
                                            <Stack spacing={2} direction={'row'}>
                                                        <FolderIcon />
                                            <Typography variant="subtitle2" color={'info'}>
                                            {t('lbl_estates')} {`(${estates?.length})`}
                                            </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={handleEstateCollapse}>
                                                {collapseEstate ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />    }
                                            </IconButton>
                                        </Grid>
                                        {collapseEstate &&<>
                                        {(estates.length === 0 || estates === undefined) && estateQuery === '' ? (
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                        icon={
                                                            <FolderIcon
                                                                sx={{ color: '#727E84' }}
                                                                fontSize="large"
                                                            />
                                                        }
                                                        description={[
                                                            <Typography
                                                                sx={{
                                                                    color: '#6A767C',
                                                                    fontWeight: 600,
                                                                    fontSize: 14,
                                                                }}
                                                                key={0}
                                                                color="action"
                                                            >
                                                                {t('msg_noEstatesConfigured')}
                                                            </Typography>,
                                                        ]}
                                                        title={undefined}
                                                    />
                                                </Item>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <Item elevation={0}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder={t('lbl_search')}
                                                            value={estateQuery}
                                                            onChange={(e) => setEstateQuery(e.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {estateQuery.length !== 0 && (
                                                                            <IconButton
                                                                                onClick={(event) =>
                                                                                    handleEstateSearchCancelClick()
                                                                                }
                                                                            >
                                                                                <CancelIcon />
                                                                            </IconButton>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Item>
                                                </Grid>
                                                {view === 'list' || view === null ? (
                                                    <Grid item xs={12}>
                                                        <Item elevation={0}>
                                                            <E_EstateContext.Provider value={estates}>
                                                                <ListOfEstates setPathArray={setPathArray} pathArray={pathArray} />
                                                            </E_EstateContext.Provider>
                                                        </Item>
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        <E_EstateContext.Provider value={estates}>
                                                            <GridOfEstates setPathArray={setPathArray} pathArray={pathArray} />
                                                        </E_EstateContext.Provider>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        </>}
                                        <Grid item xs={12}></Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                &emsp;
                <Box sx={{ flexGrow: 1, marginLeft: -1, marginRight: -1, mb: 3,pb:15 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={11}>
                                            <Stack direction={'row'} spacing={2}>
                                                <DeviceWithKeypad />
                                                <Typography variant="subtitle2" color={'info'}>
                                                {t('lbl_estatelessPanels')}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton onClick={handlePanelCollapse}>
                                                {collapsePanels ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />    }
                                            </IconButton>
                                        </Grid>
                                        {collapsePanels &&<>
                                        {(unassignedPanel?.length === 0 || unassignedPanel === undefined) && panelQuery === '' ? (
                                            <>
                                                <Grid item xs={12}>
                                                    <Item elevation={0}>
                                                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                            icon={
                                                                <DeviceWithKeypad
                                                                    sx={{ color: '#727E84' }}
                                                                    fontSize="large"
                                                                />
                                                            }
                                                            description={[
                                                                <Typography
                                                                    sx={{
                                                                        color: '#6A767C',
                                                                        fontWeight: 600,
                                                                        fontSize: 14,
                                                                    }}
                                                                    key={0}
                                                                    color="action"
                                                                >
                                                                    {t('msg_noPanelsConnected')}
                                                                </Typography>,
                                                            ]}
                                                            title={undefined}
                                                        />
                                                    </Item>
                                                </Grid>
                                            </>
                                        ) : (
                                            <>
                                                <Grid item xs={10}>
                                                    <Item elevation={0}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            placeholder={t('lbl_search')}
                                                            value={panelQuery}
                                                            onChange={(e) => setPanelQuery(e.target.value)}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {panelQuery.length !== 0 && (
                                                                            <IconButton
                                                                                onClick={(event) =>
                                                                                    handlePanelSearchCancelClick()
                                                                                }
                                                                            >
                                                                                <CancelIcon />
                                                                            </IconButton>
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Item elevation={0}>
                                                        <ListItemButton
                                                            onClick={(e) => handleListOpen(e.currentTarget)}
                                                            className="button-drop-down"
                                                        >
                                                            <FilterListIcon />
                                                        </ListItemButton>
                                                        <PanelFilterMenu setFilterArray={setFilterArray} filterArray={filterArray} setAnchorE1={setAnchorE2} open={openPanelFilter} anchorE1={anchorE2} />
                                                    </Item>
                                                </Grid>
                                                {filterArray.length !== 0 && (
                                                    <Grid item xs={12}>
                                                        <Item sx={{ float: 'left' }} elevation={0}>

                                                            {filterArray?.map((f: string, i) => (
                                                                <Chip sx={{ margin: 0.5 }} key={i} label={f} onDelete={() => handleFilterDelete(f)} />
                                                            ))}

                                                        </Item>
                                                    </Grid>
                                                )}
                                                {view === 'list' || view === null ? (
                                                    <Grid item xs={12}>
                                                        <Item elevation={0}>
                                                            <E_PanelContext.Provider value={panels}>
                                                                <ListOfPanels page={page} setPage={setPage} />
                                                            </E_PanelContext.Provider>
                                                        </Item>
                                                    </Grid>
                                                ) : (
                                                    <>
                                                        <E_PanelContext.Provider value={panels}>
                                                            <GridOfPanels page={page} setPage={setPage} />
                                                        </E_PanelContext.Provider>
                                                    </>
                                                )}
                                            </>
                                        )}</>}
                                        <Grid item xs={12}></Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            </>
            ) : (
                <>
                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, mb: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item elevation={0}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={11.5}>
                                                <Stack spacing={2} direction={'row'}>
                                                        <FolderIcon />
                                                <Typography variant="subtitle2" color={'primary'}>
                                                {t('lbl_estates')} {`(${estates?.length})`}
                                                </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5}>
                                                    <IconButton onClick={handleEstateCollapse}>
                                                        {collapseEstate ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />    }
                                                    </IconButton>
                                                </Grid>
                                            {collapseEstate &&<>
                                            {(estates.length === 0 || estates === undefined) && estateQuery === '' ? (
                                                <Grid item xs={12}>
                                                    <Item elevation={0}>
                                                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                            icon={
                                                                <FolderIcon
                                                                    sx={{ color: '#727E84' }}
                                                                    fontSize="large"
                                                                />
                                                            }
                                                            description={[
                                                                <Typography
                                                                    sx={{
                                                                        color: '#6A767C',
                                                                        fontWeight: 600,
                                                                        fontSize: 14,
                                                                    }}
                                                                    key={0}
                                                                    color="action"
                                                                >
                                                                   {t('msg_noEstatesConfigured')}
                                                                </Typography>,
                                                            ]}
                                                            title={undefined}
                                                        />
                                                    </Item>
                                                </Grid>
                                            ) : (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Item elevation={0}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                placeholder={t('lbl_search')}
                                                                value={estateQuery}
                                                                onChange={(e) => setEstateQuery(e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {estateQuery.length !== 0 && (
                                                                                <IconButton
                                                                                    onClick={(event) =>
                                                                                        handleEstateSearchCancelClick()
                                                                                    }
                                                                                >
                                                                                    <CancelIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Item>
                                                    </Grid>
                                                    {view === 'list' || view === null ? (
                                                        <Grid item xs={12}>
                                                            <Item elevation={1}>
                                                                <E_EstateContext.Provider value={estates}>
                                                                    <ListOfEstates setPathArray={setPathArray} pathArray={pathArray} />
                                                                </E_EstateContext.Provider>
                                                            </Item>
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            <E_EstateContext.Provider value={estates}>
                                                                <GridOfEstates setPathArray={setPathArray} pathArray={pathArray} />
                                                            </E_EstateContext.Provider>
                                                        </>
                                                    )}
                                                </>
                                            )}</>}
                                            <Grid item xs={12}></Grid>
                                        </Grid>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                    &ensp;
                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, mb: 3, pb:20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item elevation={0}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={11.5}>
                                            <Stack direction={'row'} spacing={2}>
                                                <DeviceWithKeypad />
                                                <Typography variant="subtitle2" color={'primary'}>
                                                {t('lbl_panels')}
                                                </Typography>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={0.5}>
                                                    <IconButton onClick={handlePanelCollapse}>
                                                        {collapsePanels ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />    }
                                                    </IconButton>
                                                </Grid>
                                            {collapsePanels && <>
                                            {(unassignedPanel.length === 0 || unassignedPanel === undefined) && panelQuery === '' ? (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Item elevation={0}>
                                                            <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                icon={
                                                                    <DeviceWithKeypad
                                                                        sx={{ color: '#727E84' }}
                                                                        fontSize="large"
                                                                    />
                                                                }
                                                                description={[
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#6A767C',
                                                                            fontWeight: 600,
                                                                            fontSize: 14,
                                                                        }}
                                                                        key={0}
                                                                        color="action"
                                                                    >
                                                                       {t('msg_noPanelsConnected')}
                                                                    </Typography>,
                                                                ]}
                                                                title={undefined}
                                                            />
                                                        </Item>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <>
                                                    <Grid item xs={11.3}>
                                                        <Item elevation={0}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                placeholder={t('lbl_search')}
                                                                value={panelQuery}
                                                                onChange={(e) => setPanelQuery(e.target.value)}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <SearchIcon />
                                                                        </InputAdornment>
                                                                    ),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            {panelQuery.length !== 0 && (
                                                                                <IconButton
                                                                                    onClick={(event) =>
                                                                                        handlePanelSearchCancelClick()
                                                                                    }
                                                                                >
                                                                                    <CancelIcon />
                                                                                </IconButton>
                                                                            )}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Item>
                                                    </Grid>
                                                    <Grid item xs={0.7}>
                                                        <Item elevation={0}>
                                                            <ListItemButton
                                                                onClick={(e) => handleListOpen(e.currentTarget)}
                                                                className="button-drop-down"
                                                            >
                                                                <FilterListIcon />
                                                            </ListItemButton>
                                                            <PanelFilterMenu setFilterArray={setFilterArray} filterArray={filterArray} setAnchorE1={setAnchorE2} open={openPanelFilter} anchorE1={anchorE2} />
                                                        </Item>
                                                    </Grid>
                                                    {filterArray.length !== 0 && (
                                                        <Grid item xs={12}>
                                                           <Box sx={{ flexGrow: 1 }}>
                                                                 <Grid direction={'row-reverse'} container spacing={2}>
                                                                 {filterArray?.map((f: string, i) => (
                                                                        <Grid key={i} item xs={-3}>
                                                                            <Chip key={i} label={f} onDelete={() => handleFilterDelete(f)} />
                                                                        </Grid>
                                                                    ))}
                                                                 </Grid>
                                                            </Box>
                                                        </Grid>
                                                    )}
                                                    {view === 'list' || view === null ? (
                                                        <Grid item xs={12}>
                                                            <Item elevation={1}>
                                                                <E_PanelContext.Provider value={panels}>
                                                                    <ListOfPanels page={page} setPage={setPage} />
                                                                </E_PanelContext.Provider>
                                                            </Item>
                                                        </Grid>
                                                    ) : (
                                                        <>
                                                            <E_PanelContext.Provider value={panels}>
                                                                <GridOfPanels page={page} setPage={setPage} />
                                                            </E_PanelContext.Provider>
                                                        </>
                                                    )}
                                                </>
                                            )}</>}
                                            <Grid item xs={12}></Grid>
                                        </Grid>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </>
    );
}
