/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Divider, MobileStepper, Paper, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import './pwdReset.css';
import logo from '../assets/images/eaton_stacked_logo.png'
import tile from '../assets/images/tileGrad.png'
import { useTypedDispatch } from '../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchResetPassword, getDeleteUserLinkInEmail, verifyEmailAddress } from '../redux/actions/registerScreenActions';
import { useSelector } from 'react-redux';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
    borderRadius: 10,
};


export default function DeleteUserAccount(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const [email, setEmail] = React.useState('')
    const [helperText, setHelperText] = React.useState('')
    const [verify, setVerify] = React.useState(false)
    const [emailVerify, setEmailVerify] = React.useState(false)
    const [submitted, setSubmitted] = React.useState(false)
    const { emailVerifyStatus } = useSelector((state: any) => state.registerScreenReducer)
    const { t, i18n } = useTranslation();

    const isValidEmail = (mail: string) => {
        const isValid = /\S+@\S+\.\S+/.test(mail);
        return isValid
    }

    const handleMail = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(e.target.value)
        if (isValidEmail(e.target.value)) {
            await dispatch(verifyEmailAddress({ email: e.target.value }))
            setHelperText('')
            setVerify(true)
        }
        else {
            if (e.target.value.length > 0)
                setHelperText('Invalid email address')
            else
                setHelperText('This field is required')
        }
    }

    const handleSubmit = () => {
        setSubmitted(true)
        dispatch(getDeleteUserLinkInEmail(email));
    }

    return (
        <div id="main">
            <Box
                sx={
                    md
                        ? {
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100vw',
                            height: '100dvh',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }
                        : style
                }
            >
                <Paper elevation={0} sx={{ overflow: 'hidden' }}>
                    <Paper
                        elevation={0}
                        sx={{
                            paddingTop: 6,
                            paddingBottom: 3,
                            paddingLeft: 4,
                            paddingRight: 4,
                            backgroundImage: `url(${tile})`,
                        }}
                    >
                        <img src={logo} alt="logo" />
                    </Paper>
                </Paper>
                <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 4, paddingRight: 4 }}>
                    <Stack direction={'column'} spacing={2}>
                        <Typography variant="h6">{t('lbl_userAccDelete')}</Typography>
                        {submitted ?
                            <Typography variant="body1">
                                {t('lbl_userAccDeleteSubmitMsg')} "{email}".
                            </Typography>
                            : <Typography variant="body1">
                                {t('lbl_userAccDeleteMsg')}
                            </Typography>}
                    </Stack>
                </Paper>
                {!submitted && <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 70 }}
                                label={t('lbl_emailAddress')} variant="filled"
                                helperText={helperText}
                                value={email} onChange={(e) => handleMail(e)}
                            />
                        </Grid>
                    </Grid>
                </Box>}
                {!submitted && <MobileStepper
                    variant="dots"
                    steps={0}
                    position="static"
                    activeStep={0}
                    sx={
                        md
                            ? {
                                zIndex: 1000,
                                position: 'fixed',
                                top: '100dvh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                                p: 2,
                                backgroundColor: 'inherit',
                            }
                            : {
                                maxWidth: 400,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                                borderRadius: 1,
                            }
                    }
                    nextButton={
                        <Button size="medium" variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    }
                    backButton={undefined}
                />}
            </Box>
        </div>
    );
}
