/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Button, Theme, InputAdornment, TextField, useMediaQuery, useTheme, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Cancel';
import { EmptyState } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AccountUsersList from './AccountUsersList';
import AddIcon from '@mui/icons-material/Add';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchAccountUsers, fetchUserGroups, getCurrentUserDetails, setDeleteStatusToDefault } from '../../redux/actions/accountUsersActions';
import { AccountUsersProps } from './AccountUsersTypes';
import AddUserModal from './AddUserModal/AddUserModal';
import EditUserModal from './EditUserModal/EditUserModal';
import Appbar from '../Appbar/Appbar';
import Snackbar from '@mui/material/Snackbar';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),

}));

export const AccountUsersContext = React.createContext([]);

export default function AccountUsers(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch()
    const [query, setQuery] = React.useState("");
    const [addUserModalOpen, setAddUserModalOpen] = React.useState(false)
    let { accountUsersList } = useSelector((state: any) => state.accountUsersReducer)
    const { deleteUserResponse, currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const [count, setCount] = React.useState(0)
    const [counter, setCounter] = React.useState(0)
    const [userEditDetails, setUserEditDetails] = React.useState<any>({})
    const [editUserModalOpen, setEditUserModalOpen] = React.useState(false)
    const [message, setMessage] = React.useState<string>('')
    const [deleteUserSnack, setDeleteUserSnack] = React.useState<boolean>(false)
    const [isMobile, setIsMobile] = React.useState<boolean>(false)
    const [selectedID, setSelectedID] = React.useState<number>(0)
    const [deleteUserModalOpen, setDeleteUserModalOpen] = React.useState<boolean>(false)
    const { t } = useTranslation();

    const handleAddUserModalOpen = () => {
        dispatch(fetchUserGroups(currentUserDetails?.ID))
        setAddUserModalOpen(true)
    }

    const handleCancelClick = (): any => {
        setQuery('');
    }

    React.useEffect(() => {
        dispatch(getCurrentUserDetails());
        if (deleteUserResponse !== 0) {
            if (isMobile) {
                setEditUserModalOpen(false)
            }
            setDeleteUserSnack(true);
            if (deleteUserResponse !== 204) {
                setMessage(`${t('msg_deleteUsr_error')}`);
            } else {
                setMessage(`${t('msg_accntUsr_deleted')}`);
                dispatch(fetchAccountUsers())
            }
            dispatch(setDeleteStatusToDefault())
        } else {
            dispatch(fetchAccountUsers());
        }
    }, [count, counter])

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setDeleteUserSnack(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    const getNotificationStatus = (status: boolean): string => {
        const notifiStatus = (status === true ? 'Enabled' : 'Disabled')
        return notifiStatus
    }

    const getFullName = (firstName: string, lastName: string): string => {
        const fullName = `${lastName} ${firstName}`
        return fullName
    }

    try {
        if (query.length > 0 && accountUsersList.length > 0) {
            const filteredPanels = accountUsersList.filter(
                (accountUser: AccountUsersProps) => (
                    accountUser
                        .login
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                    accountUser
                        .firstName
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                    accountUser
                        .lastName
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                    accountUser
                        .userType
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                    getNotificationStatus(accountUser.notificationEnabledUserArray)
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                    getFullName(accountUser.firstName, accountUser.lastName)
                        .toLocaleLowerCase()
                        .includes(query.toLowerCase())
                )
            );
            accountUsersList = query.length > 0 ? filteredPanels : accountUsersList;
        }
    } catch (exception) {
        console.log(`Exception in Account Users filtering: ${JSON.stringify(exception)}`);
    }

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} title={t('menu_accntUsrs')} />
                {md ? null
                    :
                    <Box sx={{ flexGrow: 1, marginLeft: 10, marginRight: 10, marginTop: 5 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                {(currentUserDetails?.userType === 'INSTALLER' || currentUserDetails?.userType === 'MANAGER') &&
                                    <Item elevation={0}>
                                        <Button fullWidth variant='contained' onClick={handleAddUserModalOpen} sx={{ fontSize: 13 }}>
                                            <AddIcon sx={{ fontSize: 15 }} />&nbsp;&nbsp;{t('btn_addUsr')}
                                        </Button>
                                        <AddUserModal count={count} setCount={setCount} open={addUserModalOpen} setOpen={setAddUserModalOpen} />
                                    </Item>}
                            </Grid>
                            <Grid item xs={6.75}>
                            </Grid>
                        </Grid>
                    </Box>
                }

                {md ?
                    <div style={{ backgroundColor: 'white' }}>
                        <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2, marginTop: 2, backgroundColor: 'background.paper', height: '100vh' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Item elevation={0}>
                                        <TextField fullWidth variant='outlined' placeholder={t('lbl_search')}
                                            onChange={(e) => setQuery(e.target.value)}
                                            value={query}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {query.length !== 0 &&
                                                            <IconButton onClick={(event) => handleCancelClick()} ><CancelIcon /></IconButton>
                                                        }
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Item>
                                </Grid>
                                <Grid item xs={12}>
                                    <Item elevation={1} >
                                        {accountUsersList !== null && accountUsersList !== undefined &&
                                            <AccountUsersContext.Provider value={accountUsersList}>
                                                <AccountUsersList setSelectedUserId={setSelectedID} setDeleteUserModalOpen={setDeleteUserModalOpen} setEditUserModalOpen={setEditUserModalOpen} setUserEditDetails={setUserEditDetails} />
                                            </AccountUsersContext.Provider>
                                        }
                                        <EditUserModal isMobile={isMobile} setIsMobile={setIsMobile} counter={counter} setCounter={setCounter} userEditDetails={userEditDetails} count={count} setCount={setCount} open={editUserModalOpen} setOpen={setEditUserModalOpen} />
                                    </Item>
                                </Grid>
                                <Paper sx={{
                                    position: 'fixed' as 'fixed',
                                    top: '100vh',
                                    transform: 'translateY(-100%)',
                                    width: '100%'
                                }}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 4, marginRight: 2, marginBottom: 2 }}>
                                        <Grid container >
                                            <Grid item xs={14}>
                                                <Item elevation={0}>
                                                    <Button fullWidth variant='contained' onClick={handleAddUserModalOpen} sx={{ fontSize: 13 }}>
                                                        <AddIcon sx={{ fontSize: 15 }} />&nbsp;&nbsp;{t('btn_addUsr')}
                                                    </Button>
                                                    <AddUserModal count={count} setCount={setCount} open={addUserModalOpen} setOpen={setAddUserModalOpen} />
                                                </Item>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Paper>
                                {accountUsersList !== undefined && accountUsersList.length === 0 && query !== '' &&
                                    <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                                        <div style={{ flex: '1 1 0px' }}>
                                            <EmptyState
                                                icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                title={`${t('msg_noSearch_results')} ${query}...`} 
                                                nonce={undefined} 
                                                onResize={undefined} 
                                                onResizeCapture={undefined}                                            />
                                        </div>
                                    </div>
                                }
                            </Grid>
                        </Box>
                    </div>
                    :
                    <Box sx={{ flexGrow: 1, marginLeft: 10, marginRight: 10, marginTop: 4, pb: 12 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item elevation={1}>
                                    <Box sx={{ flexGrow: 1, marginLeft: 3, marginRight: 3, pt: 3, pb: 4 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <TextField fullWidth variant='outlined' placeholder={t('lbl_search')}
                                                        value={query}
                                                        onChange={(e) => setQuery(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            ),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {query.length !== 0 &&
                                                                        <IconButton onClick={(event) => handleCancelClick()} ><CancelIcon /></IconButton>
                                                                    }
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={1} >
                                                    {accountUsersList !== undefined && accountUsersList.length > 0 &&
                                                        <AccountUsersContext.Provider value={accountUsersList}>

                                                            <AccountUsersList setSelectedUserId={setSelectedID} setDeleteUserModalOpen={setDeleteUserModalOpen} setEditUserModalOpen={setEditUserModalOpen} setUserEditDetails={setUserEditDetails} />

                                                        </AccountUsersContext.Provider>
                                                    }
                                                    <EditUserModal isMobile={isMobile} setIsMobile={setIsMobile} counter={counter} setCounter={setCounter} userEditDetails={userEditDetails} count={count} setCount={setCount} open={editUserModalOpen} setOpen={setEditUserModalOpen} />
                                                    <DeleteUserModal counter={counter} setCounter={setCounter} open={deleteUserModalOpen} setOpen={setDeleteUserModalOpen} selectedUserId={selectedID} ></DeleteUserModal>
                                                </Item>
                                            </Grid>
                                            {accountUsersList !== undefined && accountUsersList.length === 0 && query !== '' &&
                                                <div style={{ display: 'flex', flexDirection: 'row', height: '100%', width: '100%' }}>
                                                    <div style={{ flex: '1 1 0px' }}>
                                                        <EmptyState 
                                                            icon={<ManageSearchIcon fontSize={'inherit'} />}
                                                            title={`${t('msg_noSearch_results')} ${query}...`} 
                                                            nonce={undefined} 
                                                            onResize={undefined} 
                                                            onResizeCapture={undefined}                                                        />
                                                    </div>
                                                </div>
                                            }
                                        </Grid>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                }
                <Snackbar
                    open={deleteUserSnack}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
                </div>
            </div>
        </>
    );
}
