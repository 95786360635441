export const authWorkflowItalian =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Email o password errati',
            INVALID_CREDENTIALS: 'La tua combinazione nome utente/password non è riconosciuta.',
            GENERIC_ERROR: 'Non è stato possibile elaborare la tua richiesta in questo momento.',
        },
        HEADER: {
            FORGOT_PASSWORD: 'Ha dimenticato la password',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Seleziona una password. Assicurati che la tua password soddisfi i requisiti di complessità necessari descritti di seguito.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                `Inserisci l'indirizzo email associato all'account.<br/><br/>` +
                `Se questa e-mail ha un account Eaton, riceverai una risposta all'interno <1>{{time}}</1>.`,
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowItalian =  {
    translation: {
        ACTIONS: {
            FINISH: 'Fine',
            NEXT: 'Prossimo',
            BACK: 'Indietro',
            CREATE_ACCOUNT: 'Creare un account',
            OKAY: 'Va bene',
            CANCEL: 'Annulla',
            CONTINUE: 'Continua',
            DONE: 'Fatto',
            LOG_IN: 'Login',
            LOG_OUT: 'Disconnettersi',
            RESEND: 'Invia di nuovo',
            REMEMBER: 'Ricordati di me',
            SUBMIT: 'Invia',
        },
        LABELS: {
            EMAIL: 'Indirizzo e-mail',
            PASSWORD: `Parola d'ordine`,
            FORGOT_PASSWORD: 'Forgot your password?',
            NEED_ACCOUNT: 'Need an account?',
        },
        MESSAGES: {
            EMAIL_SENT: 'Email inviata',
            WELCOME: 'Benvenuto',
            REQUEST_ERROR: `Siamo spiacenti, si è verificato un problema durante l'invio della tua richiesta.`,
            ERROR: 'Errore!',
            EMAIL_ENTRY_ERROR: 'Inserisci una email valida',
            CONTACT: `Contattare un rappresentante dell'assistenza Eaton`,
            LOADING: 'Caricamento...',
            PASSWORD_REQUIRED_ERROR: 'Password richiesta',
        },
        FORMS: {
            FIRST_NAME: 'Nome di battesimo',
            LAST_NAME: 'Cognome',
            PASSWORD: `Parola d'ordine`,
            CONFIRM_PASSWORD: 'Conferma password',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 caratteri',
            NUMBERS: 'Un numero',
            UPPER: 'Una lettera maiuscola',
            LOWER: 'Una lettera minuscola',
            SPECIAL: 'Un personaggio speciale',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowItalian = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: "Caricamento del contratto di licenza con l'utente finale...",
                AGREE_TERMS: 'Ho letto e accetto i Termini e Condizioni',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Creare un account',
                LICENSE: 'Contratto di licenza',
                VERIFY_EMAIL: 'Verifica Email',
                PASSWORD: 'Crea password',
                ACCOUNT_DETAILS: 'Dettagli account',
                COMPLETE: 'Account creato!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: "Inserisci i tuoi dati qui sotto per completare la creazione dell'account.",
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `Per registrare un account Eaton, inserire le informazioni richieste di seguito. Dovrai verificare il tuo indirizzo email per continuare.`,
                VERIFY_EMAIL: {
                    RESEND: 'Invia nuovamente email di verifica',
                    VERIFICATION: 'Codice di verifica',
                },
            },
        },
    },
};