/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { LocalStorage } from '../store/local-storage';
import { useApp } from '../contexts/AppContextProvider';
import {
    DrawerLayout,
} from '@brightlayer-ui/react-components';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import * as Colors from '@brightlayer-ui/colors';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material';
//import i18n from '../translations/i18n';
import Usermenu from '../components/UserMenu/UserMenu';
import { DrawerContext } from '../contexts/drawerContextProvider';
import { NavigationDrawer } from '../router/drawer';

export const ExampleHome: React.FC<React.PropsWithChildren> = () => {
    const app = useApp();
    const supportedLanguages = ['en', 'fr', 'es', 'zh', 'pt'];
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const theme = useTheme();

    const containerStyles = {
        width: '100%',
        height: `calc(100vh - ${theme.spacing(8)})`,
        display: 'flex',
        padding: 0,
        overflow: 'auto',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: `calc(100vh - ${theme.spacing(7)})`,
        },
    };

    const emptyStateContainerStyles = {
        margin: 'auto',
        display: 'flex',
        zIndex: 4,
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
    };
    

    return (
        <>
            <DrawerContext.Provider
            value={{
                drawerOpen,
                setDrawerOpen,
            }}
        >
            <DrawerLayout drawer={<NavigationDrawer />} style={{ height: '100%' }} nonce={undefined} onResize={undefined} onResizeCapture={undefined}>
                <Outlet />
            </DrawerLayout>
        </DrawerContext.Provider>
        </>
    );
};