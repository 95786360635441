/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
//import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { Button, Divider, IconButton, MobileStepper, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { styled } from '@mui/material/styles';
import { fetchRemoveInstaller, setRemoveInstaller } from '../../../redux/actions/installersActions';
import { useNavigate } from 'react-router';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

const BootstrapButton = styled(Button)({
    '&:hover': {
      backgroundColor: '#CA3C3D',
    }, 
  });

type Props = {
    data: any
    openInstaller : boolean
    setOpenInstaller : (openInstaller:boolean) => void
}

export default function RemoveInstallerModal({ data, openInstaller, setOpenInstaller }: Props): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const dispatch = useTypedDispatch();
  const navigate = useNavigate()
  const { t } = useTranslation();

  const handleClose = () => {
    setOpenInstaller(false);
    setActiveStep(0);
  }


  React.useEffect(() => {
   
  }, [])

  const handleDelete = async() => {
    await dispatch(fetchRemoveInstaller(data.ID))
    await dispatch(setRemoveInstaller(true))
    setOpenInstaller(false);
    navigate('/installers')
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
      {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <Modal
        open={openInstaller}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={md? {
             position: 'absolute' as 'absolute',
             top: '50%',
             left: '50%',
             transform: 'translate(-50%, -50%)',
             width: 300,
             backgroundColor: 'background.paper',
             boxShadow: 20,
             p: 0,
        } : style} >
          <Paper elevation={0} sx={{ pb: 1,pr:2 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>Confirm</Typography>
            <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >Are you sure you want to remove {data.firstName} {data?.lastName}?</Typography>
           {md &&
            <Stack direction={'row-reverse'} spacing={2}>
                <Button onClick={handleDelete} color='error' disableRipple disableFocusRipple >
                    Remove
                </Button>
                <Button onClick={handleClose} color='primary' disableRipple disableFocusRipple >
                    Cancel
                </Button>
            </Stack>}
          </Paper>
          <Divider />
          {!md && <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <BootstrapButton size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handleDelete}>
                  Remove
                </BootstrapButton>
              }
              backButton={
                <Button size="small" variant='outlined' onClick={handleClose}  >
                  {t('btn_cancel')}
                </Button>
              }
            />
          </Paper>}
        </Box>
      </Modal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
