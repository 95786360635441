/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
import React, { useEffect } from 'react';
import { EmptyState, InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import { Button, Grid, IconButton, Menu, MenuItem, Snackbar, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReportDetailsModal from '../ServiceReportsModals/ReportDetailsModal';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FolderIcon from '@mui/icons-material/Folder';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchARDscheduler } from '../../redux/actions/remoteServicingActions';
import TuneIcon from '@mui/icons-material/Tune';
import PendingServiceReportModal from '../ServiceReportsModals/PendingServiceReportModal';
import RemoteServiceModal from '../RemoteServicingModal/RemoteServicingModal';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

export default function ScheduledServices(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [openPendingReportModal, setOpenPendingReportModal] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [plannedReport, setPlannedReport] = React.useState<any>({})
    const [remoteServicingModalOpen, setRemoteServicingModalOpen] = React.useState(false)
    const [count, setCount] = React.useState(0)
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { scheduler } = useSelector((state: any) => state.remoteServicingReducer);
    const { schedulerStatus } = useSelector((state: any) => state.remoteServicingReducer);
    const open = Boolean(anchorEl);
    const { t } = useTranslation();

    useEffect(() => {
        if(count !== 0){
            setSnackOpen(true)
            if (schedulerStatus === 200) {
                setMessage(`Scheduled successfully`);
            } else if (schedulerStatus === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (schedulerStatus === 400 || schedulerStatus === 404 ) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
        }
        dispatch(fetchARDscheduler(specificPanel?.panelId))
    },[count])


    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    const handlePlannedServiceModal = () => {
        setPlannedReport(scheduler)
        setOpenPendingReportModal(true)
    };


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleEdit = () => {
        setRemoteServicingModalOpen(true)
        setAnchorEl(null);
    };

    const handleSetupRemoteServicing = () => {
        setRemoteServicingModalOpen(true)
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            
        {md ?
            <>
                <Table>
                    {scheduler !== undefined && scheduler?.panelId !== 'no-panel' &&
                        <TableBody>
                                <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell align="left" padding="normal">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2">{scheduler?.nextServiceDate}</Typography>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" padding="normal">
                                    <label>{
                                        <ListItemTag label={t('lbl_planned')} fontColor='#fff' backgroundColor={'#E57F0A'} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                    }</label>
                                    </TableCell>
                                    <TableCell align="right" padding="normal">
                                    <IconButton onClick={handlePlannedServiceModal} sx={{ mt: -1 }} size="small">
                                                <ChevronRightIcon />
                                            </IconButton>
                                    </TableCell>
                                    
                                </TableRow> 
                        
                    </TableBody>}
                </Table>
            </>
            : 
            <Table>
                { scheduler !== undefined && scheduler?.panelId !== 'no-panel' &&
                    <TableHead>
                        <TableRow>
                            <TableCell width="30%">
                            Due date&ensp;
                                
                            </TableCell>
                            <TableCell width="30%">
                            Frequency&ensp;
                                
                            </TableCell>
                            <TableCell width="30%">
                           Status&ensp;
                                
                            </TableCell>
                            <TableCell width="10%">

                            </TableCell>
                           
                        </TableRow>
                    </TableHead>
                }
                {scheduler !== undefined && scheduler?.panelId !== 'no-panel' &&
                    <TableBody>
                    
                            <TableRow sx={{ backgroundColor: 'white' }}>

                                <TableCell align="left" padding="normal">
                                <label>
                                    {scheduler?.nextServiceDate !== undefined &&
                                        <Typography variant="body1">{scheduler?.nextServiceDate.split('-')[2]}/{scheduler?.nextServiceDate.split('-')[1]}/{scheduler?.nextServiceDate.split('-')[0]}</Typography>}
                                    </label>
                                </TableCell>

                                <TableCell align="left" padding="normal">
                                <label>{<Typography variant="body1">{scheduler?.intervalInMonths === 1 ? 'Monthly' :scheduler?.intervalInMonths === 6 ? '6 Monthly':'12 Monthly'}</Typography>}</label>
                                </TableCell>
                                <TableCell align="left" padding="normal">
                                <label>{
                                    <ListItemTag label={t('lbl_planned')} fontColor='#fff' backgroundColor={'#E57F0A'} nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                    }</label>
                                </TableCell>
                                <TableCell align='right' padding='normal'>
                                <IconButton onClick={(e) => handleClick(e)}  sx={{ mt: -1 }} size="small">
                                            <MoreVertIcon />
                                        </IconButton>
                                        <div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                sx={{ width: '100%' }}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                                            >
                                                <MenuItem sx={{ width: 200 }} onClick={handleEdit}>{t('btn_edit')}</MenuItem>
                                            </Menu>
                                        </div>
                                </TableCell>
                            </TableRow>
                       
                </TableBody>}
            </Table>
            }

       
        {scheduler?.panelId === 'no-panel' &&
            <div>
                <Grid item xs={12}>
                    <Item elevation={0}>
                        <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                            icon={
                                <Maintenance
                                    sx={{ color: '#727E84' }}
                                    fontSize="large"
                                />
                            }
                            description={[
                                <Typography
                                    sx={{
                                        color: '#6A767C',
                                        fontWeight: 600,
                                        fontSize: 14,
                                        mb:2,
                                    }}
                                    key={0}
                                    color="action"
                                >
                                    {t('lbl_noPlannedService')}
                                </Typography>,
                                <Button onClick={handleSetupRemoteServicing} startIcon={<TuneIcon sx={{transform:'rotate(90deg)'}} />} variant='outlined'>Set up remote servicing</Button>
                            ]}
                            title={undefined}
                        />
                    </Item>
                </Grid>
            </div>
        }
        <Snackbar
            open={snackOpen}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={message}
            action={action}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
        {<PendingServiceReportModal report={plannedReport} open={openPendingReportModal} setOpen={setOpenPendingReportModal} setRemoteServicingModalOpen={setRemoteServicingModalOpen} />}
        <RemoteServiceModal
                type={'enable'}
                counter={count}
                setCounter={setCount}
                open={remoteServicingModalOpen}
                setOpen={setRemoteServicingModalOpen}
            />
    </div>
    );
}
