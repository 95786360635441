/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import { Button, CircularProgress, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { useSelector } from 'react-redux';
import Loader from '../../CircularLoader/Loader';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchEstatesAndPanels, fetchPanelsInEstate } from '../../../redux/actions/managePanelsAndEstatesActions';
import { EstateProps } from '../../ListAndGridView/panelEstateTypes';
import { setPanelView } from '../../../redux/actions/specificPanelActions';
import ToggleButtons from '../../ToggleListAndGrid/ToggleButtonGroup';
import ManagePanelsModal from '../../ManagePanelsAndEstatesModal/ManagePanelsModal';
import NewEstateModal from '../../NewEstate/NewEstateModal';
import PanelsView from '../../ListAndGridView/PanelsView';
import { fetchPanelsAndEstatesOfInstaller } from '../../../redux/actions/panelEstateActions';
import secureLocalStorage from 'react-secure-storage';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function InstallerPanelTab(): JSX.Element {
    const dispatch = useTypedDispatch();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const role = localStorage.getItem('role')
    const { t } = useTranslation();
    const [view, setView] = React.useState('list');
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const [managePanelsOpen, setManagePanelsOpen] = React.useState(false);
    const { installerDetails } = useSelector((state: any) => state.installerReducer);
    const { estates, unassignedPanels } = useSelector((state: any) => state.userReducer);
    const { panelView } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { panelsInEstate } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
    const [panelsInEstates, setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
    const [count, setCount] = React.useState(0);
    const [movePanel, setMovePanel] = React.useState(false);
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [search, setSearch] = React.useState('');

    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleManagePanelsModal = async () => {
        await dispatch(
            fetchPanelsInEstate(
                estates.sort((a: EstateProps, b: EstateProps) => {
                    if (a.ID !== undefined && b.ID !== undefined) {
                        return a.ID - b.ID;
                    }
                })
            )
        );
        await dispatch(fetchEstatesAndPanels(installerDetails.groupId));
        setMovePanel(false);
        setManagePanelsOpen(true);
    };

    useEffect(() => {
        if (panelView === 'list') {
            secureLocalStorage.setItem('view', 'list');
            dispatch(setPanelView('list'));
        }
        setView(panelView);

        if (panelView === '') {
            const viewType:any = secureLocalStorage.getItem('view') || '';
            if (viewType !== '') {
                setView(viewType);
            } else {
                setView('list');
            }
        }

        const fetching = async () => {
            setDataLoaded(false);
            await dispatch(fetchPanelsAndEstatesOfInstaller(installerDetails?.groupId));
            secureLocalStorage.setItem('estate',installerDetails)
            setDataLoaded(true);
        }
        fetching()
    }, [count]);

    const handleChange = (event: React.MouseEvent<HTMLElement>, nextView: string) => {
        secureLocalStorage.setItem('view', nextView);
        setView(nextView);
    };
    const title = `${t('menu_oveview')}`;
    return (
        <>
            <div style={{maxHeight: '100vh',overflow:'auto',marginLeft:'-5%',marginRight:'-5%'}} >
                {md ? (
                    <div style={{ backgroundColor: '', }}>
                        {!dataLoaded && (
                            <Loader />
                        )}
                        <Box sx={{ flexGrow: 1, marginLeft: 0, marginRight: 0, mb: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={11.7}>
                                    <Item sx={{ float: 'right' }} elevation={0}>
                                        <ToggleButtons view={view} handleChange={handleChange} />
                                    </Item>
                                </Grid>
                            </Grid>
                        </Box>
                    </div>
                ) : (
                    <Box sx={{ flexGrow: 1, marginLeft: 10, marginRight: 10, marginTop: 2 }}>
                        {!dataLoaded && (
                            <Loader />
                        )}
                        <Grid container spacing={2}>
                            <Grid item xs={2.3}>
                                    <Item elevation={0}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            disableElevation
                                            onClick={handleManagePanelsModal}
                                        >
                                            <EditIcon fontSize="small" />
                                            &nbsp;&nbsp;{t('lbl_manageEstates')}
                                        </Button>
                                        <ManagePanelsModal
                                            path='installerPanelTab'
                                            estatePage={false}
                                            movePanel={movePanel}
                                            setCount={setCount}
                                            count={count}
                                            panelsInEstates={panelsInEstates}
                                            setPanelsInEstates={setPanelsInEstates}
                                            open={managePanelsOpen}
                                            setOpen={setManagePanelsOpen}
                                        />
                                    </Item>
                            </Grid>
                            <Grid item xs={2}>
                                    <Item elevation={0}>
                                        <Button
                                            fullWidth
                                            variant="outlined"
                                            onClick={handleOpen}
                                        >
                                            <AddIcon fontSize="small" />
                                            &nbsp;&nbsp;{t('lbl_newEstate')}
                                        </Button>
                                        <NewEstateModal path='installerPanelTab' open={open} setOpen={setOpen} />
                                    </Item>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={1.7}>
                                <Item sx={{ float: 'right' }} elevation={0}>
                                    <ToggleButtons view={view} handleChange={handleChange} />
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {md ? (
                    <div style={{ backgroundColor: '', maxHeight: '60vh', overflow: 'auto' }}>
                        <Box sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, marginTop: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PanelsView path='installerPanelTab' search={search} setSearch={setSearch} page={page} setPage={setPage} view={view} />
                                </Grid>
                                <Grid item xs={12}></Grid>
                                <Grid item xs={12}></Grid>
                            </Grid>
                        </Box>
                    </div>
                ) : (
                    <>
                        <Box sx={{ flexGrow: 1, marginLeft: 7, marginRight: 7, marginTop: 4 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PanelsView path='installerPanelTab' search={search} setSearch={setSearch} page={page} setPage={setPage} view={view} />
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                )}
                {md && (
                    <>
                          <Paper
                                sx={{
                                    zIndex:1000,
                                    position: 'fixed',
                                    top: '90vh',
                                    transform: 'translateY(-100%)',
                                    width: '100vw',
                                    ml:0,
                                    p: 2,
                                    backgroundColor: 'white',
                                    borderRadius: 0,
                                    boxShadow: '0px -3px 5px -1px rgba(0, 0, 0, 0.2), 0px -6px 10px rgba(0, 0, 0, 0.14), 0px -1px 18px rgba(0, 0, 0, 0.12)',
                                }}
                            >
                                <Box sx={{ flexGrow: 1, marginLeft:0, marginRight: 0 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <Item elevation={1}>
                                                <Button
                                                    disabled={
                                                        role === 'ENGINEER' ||
                                                        role === 'OFFICE_STAFF'
                                                    }
                                                    variant="contained"
                                                    fullWidth
                                                    disableElevation
                                                    sx={{ fontSize: 12 }}
                                                    onClick={handleManagePanelsModal}
                                                >
                                                    <EditIcon sx={{ fontSize: 15 }} />
                                                    &nbsp;&nbsp;<Typography variant="body2">{t('lbl_manageEstates')}</Typography>
                                                </Button>
                                                <ManagePanelsModal
                                                    path='installerPanelTab'
                                                    estatePage={false}
                                                    movePanel={movePanel}
                                                    setCount={setCount}
                                                    count={count}
                                                    panelsInEstates={panelsInEstates}
                                                    setPanelsInEstates={setPanelsInEstates}
                                                    open={managePanelsOpen}
                                                    setOpen={setManagePanelsOpen}
                                                />
                                            </Item>
                                        </Grid>
                                            <Grid item xs={6}>
                                                <Item elevation={0}>
                                                    <Button
                                                        disabled={
                                                            role === 'ENGINEER' ||
                                                            role === 'OFFICE_STAFF'
                                                        }
                                                        fullWidth
                                                        variant="outlined"
                                                        sx={{ fontSize: 13 }}
                                                        onClick={handleOpen}
                                                    >
                                                        <AddIcon sx={{ fontSize: 15 }} />
                                                        &nbsp;&nbsp;{t('lbl_newEstate')}
                                                    </Button>
                                                    <NewEstateModal path='installerPanelTab' open={open} setOpen={setOpen} />
                                                </Item>
                                            </Grid>
                                    </Grid>
                                </Box>
                            </Paper>
                    </>
                )}
            </div>
        </>
    );
}
