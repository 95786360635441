/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Überblick",
    "menu_endUserInfo": "Endbenutzerinformationen",
    "menu_accntUsrs": "Kontobenutzer",
    "menu_serviceReports": "Serviceberichte",
    "menu_settings": "Einstellungen",
    "menu_help": "Information",
    "lbl_cloudId": "Cloud-ID",
    "lbl_logOut": "Ausloggen",
    "lbl_contactSupport": "KONTAKTIEREN SIE SUPPORT",
    "lbl_estates": "Stände",
    "lbl_ESTATES": "IMMOBILIEN",
    "lbl_panels": "Panels",
    "lbl_PANELS": "PANEELE",
    "lbl_connected": "In Verbindung gebracht",
    "lbl_disconnected": "Getrennt",
    "lbl_manageEstates": "Nachlässe verwalten",
    "lbl_newEstate": "Neues Anwesen",
    "lbl_estate": "Anwesen",
    "lbl_search": "Suchen…",
    "lbl_manage_estateAndpanels": "Verwalten Sie Nachlässe und Panels",
    "btn_cancel": "Stornieren",
    "btn_save": "Speichern",
    "msg_panels_moved": "Panels wurden erfolgreich verschoben",
    "lbl_name": "Name",
    "lbl_description": "Beschreibung",
    "btn_next": "Nächste",
    "lbl_contact_name": "Kontaktname",
    "lbl_contact_phNo": "Kontakt Telefonnummer",
    "lbl_country": "Land",
    "lbl_address": "Adresse",
    "lbl_postcode": "Postleitzahl",
    "lbl_selectedPanels": "Ausgewählte Panels",
    "lbl_close": "Schließen",
    "msg_newEstate_created": "Neues Anwesen erfolgreich angelegt",
    "msg_estateExists": "Nachlass mit diesem Namen existiert bereits",
    "tooltip_ARC_Access": "ARC-Zugriff",
    "tooltip_usrEmail_alerts": "Benutzer-E-Mail-Benachrichtigungen",
    "tooltip_usrAccess": "Benutzerzugriff",
    "tooltip_installerEmail_alerts": "E-Mail-Benachrichtigungen des Installateurs",
    "tooltip_remoteServicing": "Fernwartung",
    "lbl_allPanel": "Alle Panels",
    "lbl_favorites": "Favoritinnen",
    "lbl_ARC_enabled": "ARC aktiviert",
    "lbl_end_usrEmail_enabled": "Endbenutzer-E-Mail aktiviert",
    "lbl_end_usrAccess_disabled": "Endbenutzerzugriff deaktiviert",
    "lbl_installer_emailAlerts_enabled": "E-Mail-Benachrichtigungen des Installateurs aktiviert",
    "lbl_remoteServicing_enabled": "Fernwartung aktiviert",
    "msg_no_panels_found": "Für die oben genannten Filter sind keine Panels verfügbar",
    "lbl_move_panel": "Panel verschieben",
    "lbl_delete_panel": "Panel löschen",
    "lbl_delete": "Löschen",
    "lbl_delete_estate": "Nachlass löschen",
    "msg_delete_estate": "Sind Sie sicher, dass Sie den Nachlass löschen möchten?",
    "msg_no_action_revert": "Diese Aktion kann nicht rückgängig gemacht werden.",
    "msg_estate_deleted": "Nachlass erfolgreich gelöscht",
    "msg_deleteEstate_error": "Fehler beim Löschen des Nachlasses",
    "msg_noSearch_results": "Keine passenden Ergebnisse",
    "msg_deletePanel": "Sind Sie sicher, dass Sie das Panel löschen möchten",
    "msg_deletePanel_error": "Fehler beim Löschen des Panels",
    "msg_panelDeleted": "Panel erfolgreich gelöscht",
    "lbl_viewEstate": "Nachlass ansehen",
    "lbl_goToPanel": "Gehen Sie zum Panel",
    "lbl_subEstate": "Untergrundstück",
    "msg_noEstatesConfigured": "Keine Anwesen konfiguriert",
    "msg_noPanelsConnected": "Keine Panels angeschlossen",
    "tab_notifications": "PANEL-GESCHICHTE",
    "tab_overview": "ÜBERBLICK",
    "tab_panelLog": "PANEL-LOG",
    "tab_ARC_reporting": "ARC-BERICHTERSTATTUNG",
    "tab_connect": "VERBINDEN",
    "tab_keypad": "TASTENFELD",
    "tab_remoteServicing": "FERNWARTUNG",
    "tab_mobileApp_usrs": "BENUTZER MOBILER APPS",
    "lbl_mobAppUsrs": "Benutzer mobiler Anwendungen",
    "lbl_summary": "Zusammenfassung",
    "lbl_status": "Status",
    "lbl_panelId": "Panel-ID",
    "lbl_macAddress": "MAC-Adresse",
    "lbl_firmware": "Firmware",
    "msg_panelIdCopied": "PanelID in die Zwischenablage kopiert",
    "lbl_panelAccess": "Panel-Zugriff",
    "lbl_enabled": "Ermöglicht",
    "lbl_disabled": "Behinderte",
    "lbl_connectionStats": "Verbindungsstatistiken",
    "lbl_totalTime": "Gesamtzeit (Stunden)",
    "lbl_connectedTime": "Verbunden (Stunden)",
    "lbl_disconnectedTime": "Nicht verbunden (Std.)",
    "lbl_connectedPercent": "In Verbindung gebracht(%)",
    "lbl_siteDetails": "Site-Details",
    "lbl_cloudReference": "Cloud-Referenz",
    "lbl_siteAddress": "Website-Adresse",
    "lbl_optional": "Optional",
    "lbl_somethingWrong": "Etwas ist schief gelaufen",
    "msg_siteDetails_edited": "Site-Details erfolgreich bearbeitet",
    "lbl_ARCProvider": "ARC-Anbieter",
    "lbl_ARCName": "ARC-Name",
    "lbl_accntNo": "Accountnummer",
    "lbl_IP_version": "SIA-IP-Version",
    "lbl_primaryIP": "Primäre IP",
    "lbl_secondaryIP": "Sekundäre IP",
    "lbl_primaryPort": "Primärer Port",
    "lbl_secondaryPort": "Sekundärer Port",
    "lbl_encryption": "Verschlüsselung",
    "option_no_encryption": "KEINE VERSCHLÜSSELUNG",
    "lbl_report_email": "E-Mail melden",
    "msg_ARC_edited": "ARC-Zugriff erfolgreich bearbeitet",
    "msg_ARC_enabled": "ARC-Zugriff erfolgreich aktiviert",
    "msg_ARC_updated": "ARC-Zugriff aktiviert und aktualisiert",
    "btn_back": "Zurück",
    "msg_ARC_disabled": "ARC-Zugriff deaktiviert",
    "msg_error": "Fehler",
    "lbl_dateOfService": "Datum des nächsten Service",
    "lbl_serviceFrquency": "Servicehäufigkeit",
    "option_svc_frqncy_1": "Monatlich",
    "option_svc_frqncy_2": "6 Monate",
    "msg_remote_srvcng_enable": "Fernwartung erfolgreich aktiviert",
    "msg_remote_srvcng_disabled": "Fernwartung erfolgreich deaktiviert",
    "msg_email_alert_enabled": "Die E-Mail-Benachrichtigung des Installationsprogramms wurde erfolgreich aktiviert",
    "msg_email_alert_edited": "E-Mail-Benachrichtigung des Installers erfolgreich bearbeitet",
    "lbl_email_alert_disable": "Deaktivieren Sie die E-Mail-Benachrichtigungen des Installationsprogramms",
    "msg_email_alert_disable": "Sind Sie sicher, dass Sie die E-Mail-Benachrichtigungen des Installationsprogramms deaktivieren möchten?",
    "btn_disable": "Deaktivieren",
    "msg_emailAlert_disabled": "Die E-Mail-Benachrichtigung des Installationsprogramms wurde erfolgreich deaktiviert",
    "lbl_enable_usrAccess": "Aktivieren Sie den Benutzerzugriff",
    "lbl_disable_usrAccess": "Deaktivieren Sie den Benutzerzugriff",
    "msg_enable_usrAccess": "Sind Sie sicher, dass Sie den Benutzerzugriff aktivieren möchten?",
    "btn_enable": "Ermöglichen",
    "msg_usrAccess_enabled": "Benutzerzugriff erfolgreich aktiviert",
    "msg_usrAccess_disabled": "Benutzerzugriff erfolgreich deaktiviert",
    "msg_usrAccess_error": "Fehler beim Aktivieren/Deaktivieren des Benutzerzugriffs",
    "btn_addUsr": "Benutzer hinzufügen",
    "lbl_addUsr": "Fügen Sie Benutzer hinzu, damit sie über Ereignisse für dieses Panel benachrichtigt werden",
    "lbl_events": "Veranstaltungen",
    "lbl_sets": "Sets",
    "lbl_alarms": "Alarm",
    "lbl_tampers": "Manipulationen",
    "lbl_system": "System",
    "msg_emailAlert_added": "Benutzer-E-Mail-Benachrichtigungen erfolgreich hinzugefügt.",
    "lbl_edit_emailAlerts": "Bearbeiten Sie Benutzer-E-Mail-Benachrichtigungen",
    "msg_usrEmail_edited": "Benutzer-E-Mail erfolgreich bearbeitet",
    "lbl_delete_usrEmail": "Benutzer-E-Mail-Benachrichtigungen löschen",
    "msg_delete_usrEmail": "Sind Sie sicher, dass Sie die E-Mail-Benachrichtigungen der Benutzer löschen möchten?",
    "msg_usr_emailDeleted": "Benutzer-E-Mail-Benachrichtigung erfolgreich gelöscht",
    "tootltip_exportNotification": "Benachrichtigungen exportieren",
    "lbl_delivered": "GELIEFERT",
    "lbl_failed": "FEHLGESCHLAGEN",
    "lbl_information": "Information",
    "lbl_evntDetails": "Event Details",
    "lbl_systmName": "Systemname",
    "lbl_Id": "AUSWEIS",
    "lbl_partition": "Partition",
    "lbl_user": "Benutzer",
    "lbl_username": "Username",
    "lbl_text": "Text",
    "lbl_verbose": "Ausführlich",
    "lbl_timestamp": "Zeitstempel",
    "lbl_code": "Code",
    "lbl_group": "Gruppe",
    "lbl_SIA_IP": "SIA IP DIRECT",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "KEINE ANTWORT",
    "lbl_rejected": "ABGELEHNT",
    "lbl_readyForDelivering": "BEREIT ZUR LIEFERUNG",
    "lbl_sent": "GESENDET",
    "lbl_exportLogs": "Protokolle exportieren",
    "lbl_failedSvc": "Fehlgeschlagene Dienste",
    "lbl_ScheduledSvc": "Geplante Dienste",
    "lbl_pendingSvc": "Keine geplanten oder ausgefallenen Dienste",
    "lbl_cmpltd_svcReports": "Abgeschlossene Serviceberichte",
    "lbl_report_no": "Berichtsnummer",
    "lbl_cmpltd_date": "Abschlussdatum",
    "lbl_cmpltd_Classification": "Einstufung",
    "lbl_cmpltd_View": "Sicht",
    "lbl_service_report": "Servicebericht",
    "lbl_delete_svcReport": "Servicebericht löschen",
    "msg_delete_svcReport": "Sind Sie sicher, dass Sie den Servicebericht löschen möchten?",
    "msg_svcReport_deleted": "Servicebericht erfolgreich gelöscht",
    "btn_perform_remoteSvc": "Führen Sie einen Remote-Service durch",
    "msg_remoteSvc_initiated": "Remote-Service initiiert",
    "msg_remoteSvc_inProgress": "Der Dienst ist in Bearbeitung",
    "lbl_errorOccurred": "Ein Fehler ist aufgetreten",
    "lbl_noCmpltd_svc": "Keine abgeschlossenen Dienste",
    "lbl_noCmpltd_svcReports": "Keine abgeschlossenen Serviceberichte",
    "lbl_mobApp_usrs": "Benutzer mobiler Apps",
    "msg_usr_authorized": "Benutzer nicht autorisiert",
    "msg_internal_error": "Interner Serverfehler",
    "msg_try_later_error": "Etwas ist schief gelaufen. Bitte versuche es erneut.",
    "lbl_info_diplayed": "In der mobilen App angezeigte Informationen",
    "lbl_compnyName": "Name der Firma",
    "lbl_officeNo": "Bürotelefonnummer",
    "lbl_outOfHrs": "Telefonnummer außerhalb der Geschäftszeiten",
    "lbl_contactEmail": "Kontakt E-mail",
    "lbl_logo": "Logo",
    "btn_uploadLogo": "Logo hochladen",
    "btn_dragLogo": "oder Logo hierher ziehen",
    "msg_endUsrInfo_edited": "Endbenutzerinformationen erfolgreich bearbeitet",
    "btn_remove": "Entfernen",
    "lbl_userType": "Benutzertyp",
    "lbl_login": "Anmeldung",
    "lbl_email_notification": "Email Benachrichtigung",
    "lbl_delete_accntUsr": "Kontobenutzer löschen",
    "msg_deleteUsr": "Sind Sie sicher, dass Sie den Benutzer löschen möchten?",
    "msg_deleteUsr_error": "Fehler beim Löschen des Kontobenutzers",
    "msg_accntUsr_deleted": "Der Kontobenutzer wurde erfolgreich gelöscht",
    "lbl_firstName": "Vorname",
    "lbl_lastName": "Familienname, Nachname",
    "lbl_typeUsr": "Geben Sie Benutzer ein",
    "lbl_emailNotifications": "E-Mail Benachrichtigungen",
    "lbl_userPemissions": "Benutzerberechtigungen",
    "msg_userEdited": "Benutzer erfolgreich bearbeitet",
    "lbl_unauthorizedUser": "Unbefugter Benutzer",
    "lbl_someError": "Es ist ein Fehler aufgetreten",
    "lbl_pwd": "Passwort",
    "lbl_repeat_pwd": "Passwort wiederholen",
    "lbl_engineer": "Ingenieurin",
    "lbl_manager": "Manager",
    "lbl_installer": "Installateurin",
    "lbl_officeStaff": "Büropersonal",
    "msg_noMatch_pwd": "Nicht übereinstimmende Passwörter!",
    "msg_usr_added": "Benutzer erfolgreich hinzugefügt",
    "lbl_panel": "Panel",
    "lbl_accnt_holder": "Kontoinhaber",
    "lbl_spoken_pwd": "Gesprochenes Passwort",
    "lbl_language": "Sprache",
    "lbl_cmpnyInfo": "Firmeninformation",
    "lbl_firstPh": "Erstes Telefon",
    "lbl_secondPh": "Zweites Telefon",
    "lbl_thirdPh": "Drittes Telefon",
    "lbl_oldPwd": "Altes Passwort",
    "lbl_newPwd": "Neues Kennwort",
    "lbl_repeatPwd": "Passwort wiederholen",
    "msg_accntHolder_edited": "Kontoinhaber erfolgreich bearbeitet",
    "lbl_firstPhType": "Erster Telefontyp",
    "lbl_firstPhone": "Erste Telefonnummer",
    "lbl_secondPhone": "Zweite Telefonnummer",
    "lbl_thirdPhone": "Dritte Telefonnummer",
    "lbl_secondPhType": "Zweiter Telefontyp",
    "lbl_thirdPhType": "Dritter Telefontyp",
    "lbl_home": "Heim",
    "lbl_mobile": "Handy, Mobiltelefon",
    "lbl_office": "Büro",
    "msg_cmpnyInfo_edited": "Unternehmensinformationen erfolgreich bearbeitet",
    "lbl_termsConditions": "Geschäftsbedingungen",
    "lbl_privacyPolicy": "Datenschutz-Bestimmungen",
    "msg_email_invalid": "E-Mail ist ungültig",
    "msg_unmatchedPwd": "Nicht übereinstimmende Passwörter!",
    "lbl_installerGrp": "Installateurgruppe",
    "msg_usrExist": "Der Benutzer mit dem Login existiert bereits",
    "lbl_editUsr": "Benutzer bearbeiten",
    "msg_field_required": "Dieses Feld ist erforderlich",
    "lbl_disable_ARC": "Deaktivieren Sie den ARC-Zugriff",
    "msg_disable_ARC": "Sind Sie sicher, dass Sie den ARC-Zugriff deaktivieren möchten?",
    "lbl_SiaEvnts": "Sia-Events",
    "lbl_SIAEvnt": "SIA-Veranstaltung",
    "msg_prohibited_chars": "Die Eingabe enthält verbotene Zeichen",
    "msg_inputLength": "Die Eingabelänge sollte nicht mehr als 30 Zeichen betragen",
    "lbl_favPanels": "LIEBLINGSPLATTEN",
    "lbl_lastConnection": "Letzte Verbindung",
    "lbl_androidPh": "Android Telefon",
    "lbl_iPhone": "Mein iPhone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Google Home",
    "lbl_removePairing": "Kopplung entfernen",
    "msg_removePairing": "Möchten Sie die Kopplung für diesen App-Benutzer wirklich entfernen?",
    "lbl_no": "NEIN",
    "lbl_yes": "Ja",
    "lbl_device": "Gerät",
    "lbl_appDetails": "Anwendungsdetails",
    "lbl_myAndroid": "Mein Android",
    "lbl_myAlexa": "Meine Alexa",
    "lbl_myiPhone": "Mein iPhone",
    "lbl_mygHome": "Mein Google Home",
    "lbl_appID": "Anwendungs-ID",
    "lbl_mobOS": "Mobiles Betriebssystem",
    "lbl_token": "Zeichen",
    "msg_didNotMovePanl": "Es wurde kein Panel verschoben",
    "lbl_panlsSelected": "Panels ausgewählt",
    "lbl_panlSelected": "Panel ausgewählt",
    "lbl_ARCCode": "ARC-Code",
    "lbl_response": "Antwort",
    "lbl_data": "Daten",
    "lbl_state": "Zustand",
    "lbl_signal_Timeout": "Signal-Timeout",
    "lbl_sysID": "System-ID",
    "lbl_date": "Datum",
    "lbl_remove_destination": "E-Mail-Ziele für die Fernwartung",
    "lbl_emailAddress": "E-Mail-Addresse",
    "Reports": "Berichte",
    "lbl_green": "GRÜN",
    "lbl_amber": "BERNSTEIN",
    "lbl_red": "ROT",
    "lbl_reportDetails": "Berichtsdetails",
    "lbl_dueDate": "Geburtstermin",
    "btn_edit": "bearbeiten",
    "lbl_nameAZ": "Name (A-Z)",
    "lbl_nameZA": "Name (Z-A)",
    "lbl_mostAlarms": "Die meisten Alarme",
    "lbl_fewerAlarms": "Weniger Alarme",
    "lbl_updatedRecent": "Kürzlich aktualisiert",
    "lbl_none": "Keiner",
    "msg_confirmation": "Bist du sicher, dass du das willst",
    "msg_enable": "ermöglichen",
    "msg_disable": "deaktivieren",
    "msg_edited": "erfolgreich bearbeitet",
    "btn_addEmail": "E-Mail-Ziel hinzufügen",
    "lbl_emailDestinationAdd": "Fügen Sie ein E-Mail-Ziel hinzu, damit Remote-Serviceberichte per E-Mail gesendet werden können",
    "lbl_termsToUseWebsite": "Die Bedingungen, zu denen Sie unsere Website nutzen dürfen",
    "lbl_termsToUseWebsite_1.1": "Diese Nutzungsbedingungen (zusammen mit den anderen unten genannten Dokumenten) legen die Bedingungen fest, zu denen Sie unsere Website nutzen dürfen",
    "lbl_termsToUseWebsite_1.1part": "Wir empfehlen Ihnen, eine Kopie dieser Nutzungsbedingungen zum späteren Nachschlagen auszudrucken.",
    "lbl_termsToUseWebsite_1.2": "Sie sollten diese Nutzungsbedingungen sorgfältig lesen, bevor Sie unsere Website nutzen, da Sie durch die Nutzung unserer Website bestätigen, dass Sie diese Nutzungsbedingungen akzeptieren und diese einhalten werden.",
    "lbl_termsToUseWebsite_1.3": "Wenn Sie diese Nutzungsbedingungen nicht akzeptieren, sollten Sie unsere Website sofort verlassen",
    "lbl_termsToUseWebsite_1.4": "Die folgenden Richtlinien gelten auch für Ihre Nutzung unserer Website",
    "lbl_termsToUseWebsite_1.4.1": "Unsere Datenschutz- und Cookie-Richtlinie",
    "lbl_termsToUseWebsite_1.4.1part": "Durch die Nutzung unserer Website erklären Sie sich damit einverstanden, dass wir alle personenbezogenen Daten verarbeiten, die Sie uns zur Verfügung stellen oder die wir im Rahmen Ihrer Nutzung unserer Website von Ihnen erfassen. Unsere Datenschutzrichtlinie legt fest, was wir mit diesen personenbezogenen Daten tun",
    "lbl_termsToUseWebsite_1.5": "Sie sind dafür verantwortlich, sicherzustellen, dass alle anderen Personen, die über Ihre Internetverbindung auf unsere Website zugreifen, diese Nutzungsbedingungen und die oben genannten Richtlinien kennen und diese einhalten.",
    "lbl_chngesToTerms": "Änderungen dieser Nutzungsbedingungen und unserer anderen Richtlinien",
    "lbl_chngesToTerms_2.1": "Wir nehmen von Zeit zu Zeit Änderungen an diesen Nutzungsbedingungen vor. Sie sollten daher bei jedem erneuten Besuch unserer Website diese Nutzungsbedingungen überprüfen, um festzustellen, ob Änderungen vorgenommen wurden, da diese für Sie verbindlich sind.",
    "lbl_chngesToTerms_2.2": "Von Zeit zu Zeit nehmen wir auch Änderungen an unserer Datenschutzerklärung und unserer Cookie-Richtlinie vor. Sie sollten diese daher regelmäßig überprüfen, um festzustellen, ob Änderungen vorgenommen wurden.",
    "lbl_aboutUs": "Über uns",
    "lbl_aboutUs_3.1": "Wir, Eaton Electrical Products Ltd, betreiben diese Website unter www.eatonsecureconnect.com. Wir sind ein in England und Wales unter der Firmennummer 01342230 eingetragenes Unternehmen und unser eingetragener Firmensitz befindet sich in 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "Sie können uns kontaktieren",
    "lbl_aboutUs_3.2part": "Per E-Mail an",
    "lbl_restrictionsOnUseOfWebsite": "Einschränkungen bei Ihrer Nutzung unserer Website",
    "lbl_restrictionsOnUseOfWebsite_4.1": "Sie dürfen unsere Website nur für rechtmäßige Zwecke und in Übereinstimmung mit allen geltenden Gesetzen nutzen, einschließlich, aber nicht beschränkt auf Datenschutz- und Datenschutzgesetze, Gesetze zum Urheberrecht an Inhalten und Gesetze in Bezug auf unerwünschte kommerzielle elektronische Nachrichten.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "Darüber hinaus dürfen Sie nicht",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "Nutzen Sie unsere Website, um direkt oder indirekt unaufgefordert oder unautorisiert Werbung oder Verkaufsförderungsmaterial, Kettenbriefe oder Schneeballsysteme zu versenden oder zu übermitteln.",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "Nutzung unserer Website für E-Mail-Bombing oder Flooding oder für absichtliche Versuche, ein System zu überlasten;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "Verwenden Sie unsere Website, um Inhalte zu veröffentlichen oder auf Inhalte zu verlinken, wenn Sie wissen oder hätten wissen müssen, dass der Eigentümer dieser Inhalte dieser Veröffentlichung oder Verlinkung nicht zugestimmt oder sie nicht autorisiert hat oder wenn diese Veröffentlichung oder Verlinkung die Rechte dieses Eigentümers verletzt;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "wissentlich oder unvorsichtig auf unserer Website Viren, Trojaner, Würmer, Logikbomben, Zeitbomben, Keylogger, Spyware, Malware, Adware oder andere Materialien, die bösartig oder schädlich sind, einzuführen oder unsere Website zur Übertragung zu nutzen;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "Versuchen Sie, sich unbefugten Zugriff auf unsere Website, den Server, die Ausrüstung oder das Netzwerk, auf dem unsere Website gespeichert ist, einen mit unserer Website verbundenen Server, Computer oder eine Datenbank oder Software zu verschaffen;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "unsere Website nutzen, um zu versuchen, sich unbefugten Zugriff auf andere Websites, Internetkonten, Server, Computer, Geräte, Systeme, Netzwerke, Daten oder Informationen zu verschaffen;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "Verwenden Sie unsere Website, um Daten oder Datenverkehr in einem Netzwerk oder System zu überwachen.",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "unsere Website nutzen, um Informationen, einschließlich, aber nicht beschränkt auf E-Mail-Adressen, Bildschirmnamen oder andere Identifikatoren, durch Täuschung (wie Phishing, Internet-Betrug, Passwortraub, Spidering, Scraping und Harvesting) zu sammeln oder zu nutzen;",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "Nutzung unserer Website zum Vertrieb von Software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "einen Denial-of-Service-Angriff oder einen verteilten Denial-of-Service-Angriff auf unsere Website durchführen oder unsere Website nutzen, um einen solchen Angriff auf einer anderen Website durchzuführen;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "Nutzen Sie unsere Website:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "für jeden Zweck, der; oder",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "um Material zu senden, zu übertragen, zu veröffentlichen, hochzuladen, herunterzuladen, zu verwenden, zu speichern, zu reproduzieren oder wissentlich zu empfangen, das nicht den in aufgeführten Inhaltsstandards entspricht",
    "lbl_para8": "Absatz 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "unsere Website nutzen, um Minderjährige in irgendeiner Weise zu belästigen, zu schädigen oder auszunutzen oder dies zu versuchen; oder",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "Nutzung unserer Website unter Verstoß gegen Exportgesetze, Kontrollen, Vorschriften oder Sanktionsrichtlinien der Vereinigten Staaten oder eines anderen Landes.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "Wenn Sie gegen eine Bestimmung dieser Nutzungsbedingungen verstoßen, erlischt Ihr Recht zur Nutzung unserer Website sofort. Darüber hinaus können wir andere Maßnahmen ergreifen, einschließlich, aber nicht beschränkt auf rechtliche Schritte, die wir für angemessen halten.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "Wenn Sie gegen eine Bestimmung dieser Nutzungsbedingungen verstoßen oder wenn wir von einer Strafverfolgungsbehörde eine entsprechende Aufforderung erhalten, können wir Ihre Identität, Einzelheiten zu einem Verstoß und alle anderen Informationen, die wir für notwendig erachten, den zuständigen Strafverfolgungsbehörden melden.",
    "lbl_pwdNSecRequirement": "Passwörter und Sicherheitsanforderungen",
    "lbl_pwdNSecRequirement_5.1": "Wenn Sie auf unserer Website ein Konto registrieren, müssen Sie genaue und vollständige Registrierungsinformationen angeben und diese Informationen stets auf dem neuesten Stand halten.",
    "lbl_pwdNSecRequirement_5.2": "Die Nutzung bestimmter Teile dieser Website ist passwortgeschützt. Um darauf zugreifen zu können, benötigen Sie einen Benutzeridentifikationscode oder ein Passwort. Es liegt in Ihrer Verantwortung, diese Informationen geheim und vertraulich zu halten und sie nicht an andere Personen weiterzugeben.",
    "lbl_pwdNSecRequirement_5.3": "Wenn Sie glauben, dass jemand anderes Ihren Benutzeridentifikationscode oder Ihr Passwort kennt, müssen Sie sich bei Ihrem Konto anmelden und diese so schnell wie möglich ändern",
    "lbl_pwdNSecRequirement_5.4": "Wir behalten uns das Recht vor, Benutzeridentifikationscodes oder Passwörter jederzeit zu deaktivieren, wenn Sie unserer begründeten Meinung nach gegen eine dieser Nutzungsbedingungen verstoßen, wenn wir der Ansicht sind, dass Sie unsere Website zum Herunterladen von Inhalten nutzen oder gegen diese verstoßen die Rechte einer anderen Person verletzen oder wenn wir der Ansicht sind, dass ein Sicherheitsrisiko bestehen könnte.",
    "lbl_webSiteNContent": "Unsere Website und ihre Inhalte",
    "lbl_webSiteNContent_6.1": "Wir garantieren nicht, dass der Zugriff auf unsere Website oder deren Inhalte (einschließlich aller Inhalte, die Sie auf unsere Website hochladen) immer verfügbar oder ununterbrochen ist. Ihr Recht, auf unsere Website zuzugreifen, besteht nur vorübergehend.",
    "lbl_webSiteNContent_6.2": "Wir behalten uns das Recht vor, unsere Website und deren Inhalte jederzeit und ohne Vorankündigung zu ändern, insbesondere durch das Hinzufügen oder Entfernen von Inhalten oder Funktionen.",
    "lbl_webSiteNContent_6.3": "Für die Richtigkeit, Vollständigkeit und Aktualität aller Inhalte auf unserer Website können wir keine Gewähr übernehmen. Wir übernehmen daher keinerlei Gewährleistungen, Zusicherungen, Zusicherungen oder Garantien dafür, dass die Inhalte auf unserer Website korrekt, vollständig oder aktuell sind.",
    "lbl_webSiteNContent_6.4": "Die Inhalte unserer Website dienen ausschließlich allgemeinen Informationszwecken. Nichts auf unserer Website stellt einen Rat dar, auf den Sie sich verlassen sollten. Bevor Sie aufgrund von Inhalten auf unserer Website Maßnahmen ergreifen oder unterlassen, sollten Sie sich stets entsprechend beraten lassen.",
    "lbl_webSiteNContent_6.5": "Wir geben keine Gewährleistungen, Zusicherungen, Zusicherungen oder Garantien dafür, dass unsere Website sicher oder frei von Fehlern oder Viren ist. Sie sind dafür verantwortlich, dass das Gerät, von dem aus Sie auf unsere Website zugreifen, über eine geeignete Antivirensoftware verfügt.",
    "lbl_contentUploaded": "Von Ihnen oder anderen Benutzern auf unsere Website hochgeladene Inhalte",
    "lbl_contentUploaded_7.1": "Wenn Sie Inhalte auf unsere Website hochladen, müssen Sie die in aufgeführten Inhaltsstandards einhalten",
    "lbl_contentUploaded_7.1part": "in Bezug auf diesen Inhalt",
    "lbl_contentUploaded_7.2": "Sie garantieren uns, dass Sie Eigentümer aller Inhalte sind, die Sie auf unsere Website hochladen, oder dass Sie, falls Sie nicht der Eigentümer sind, das Recht haben, diese hochzuladen.",
    "lbl_contentUploaded_7.3": "Sie bleiben weiterhin Eigentümer aller Inhalte, die Sie auf unsere Website hochladen. Solange diese Inhalte jedoch auf unserer Website verbleiben, gewähren Sie uns eine weltweite Lizenz zur Nutzung, Speicherung, Vervielfältigung, Änderung, Aufführung, Verbreitung, Anzeige und Bereitstellung diesen Inhalt zu verwenden und anderen Benutzern unserer Website zu gestatten, ihn gemäß unseren Nutzungsbedingungen zu nutzen, herunterzuladen, auszudrucken und zu reproduzieren.",
    "lbl_contentUploaded_7.4": "Sie werden uns für alle Verluste, Verbindlichkeiten, Kosten, Ausgaben, Ansprüche oder Verfahren entschädigen, die uns als Folge oder im Zusammenhang mit einer Verletzung Ihrer Verpflichtungen oder Garantien hierin entstehen oder entstehen oder entstehen werden oder entstehen werden",
    "lbl_para7": "Absatz 7",
    "lbl_contentUploaded_7.5": "Wir sind nicht verantwortlich für Inhalte auf unserer Website, die von einem Benutzer hochgeladen werden, und geben keine Gewährleistungen, Zusicherungen, Zusicherungen oder Garantien in Bezug auf diese ab. Jede von einem Benutzer unserer Website geäußerte Meinung ist seine Meinung und nicht unsere Meinung oder die Meinung unserer leitenden Angestellten oder Mitarbeiter.",
    "lbl_contentUploaded_7.6": "Wir sind nicht verantwortlich für die Speicherung oder Sicherung von auf unsere Website hochgeladenen Inhalten und übernehmen keine Verantwortung für den Verlust, die Beschädigung oder die Zerstörung dieser Inhalte.",
    "lbl_contentUploaded_7.7": "Wir empfehlen Ihnen, alle Inhalte, die Sie auf unsere Website hochladen, zu sichern.",
    "lbl_contentUploaded_7.8": "Wir behalten uns das Recht vor, Inhalte, die Sie auf unsere Website hochgeladen haben, jederzeit zu entfernen.",
    "lbl_contentUploaded_7.9": "Wir behalten uns außerdem das Recht vor, Ihre Identität gegenüber Personen offenzulegen, die behaupten, dass Inhalte, die Sie auf unsere Website hochgeladen haben, ihre geistigen Eigentumsrechte oder ihr Recht auf Privatsphäre verletzen oder verleumderisch sind.",
    "lbl_contentStd": "Inhaltsstandards",
    "lbl_contentStd_8.1": "Bitte denken Sie daran, dass alle Inhalte, die Sie auf unsere Website hochladen, von anderen Benutzern eingesehen werden können. Denken Sie also immer sorgfältig darüber nach, bevor Sie Inhalte einreichen.",
    "lbl_contentStd_8.2": "Alle Inhalte, die Sie auf unsere Website hochladen, müssen:",
    "lbl_contentStd_8.2.1": "alle geltenden Gesetze einhalten;",
    "lbl_contentStd_8.2.2": "genau sein (wo Fakten angegeben werden); Und",
    "lbl_contentStd_8.2.3": "aufrichtig vertreten werden (wo Meinungen geäußert werden).",
    "lbl_contentStd_8.3": "Sie dürfen keine Inhalte auf unsere Website hochladen, die:",
    "lbl_contentStd_8.3.1": "verleumderisch, obszön, beleidigend, unanständig, böswillig, hasserfüllt oder aufrührerisch ist;",
    "lbl_contentStd_8.3.2": "pornographisch oder sexuell explizit ist",
    "lbl_contentStd_8.3.3": "beutet Minderjährige aus;",
    "lbl_contentStd_8.3.4": "fördert Gewalt oder Terrorismus;",
    "lbl_contentStd_8.3.5": "aufgrund von Rasse, Geschlecht, Religion, Nationalität, Behinderung, sexueller Orientierung oder Alter diskriminierend ist;",
    "lbl_contentStd_8.3.6": "Urheberrechte, Datenbankrechte, Markenrechte oder andere geistige Eigentumsrechte einer anderen Person verletzen;",
    "lbl_contentStd_8.3.7": "andere Benutzer zu kommerziellen oder rechtswidrigen Zwecken um Passwörter oder persönliche Identifikationsdaten bittet oder dies versucht;",
    "lbl_contentStd_8.3.8": "betrügerisch ist oder geeignet ist, jemanden zu täuschen;",
    "lbl_contentStd_8.3.9": "unter Verletzung einer rechtlichen Pflicht gegenüber einer anderen Person erfolgt, beispielsweise einer vertraglichen Pflicht oder einer Geheimhaltungspflicht;",
    "lbl_contentStd_8.3.10": "ein Risiko für die Gesundheit oder Sicherheit einer Person oder die öffentliche Gesundheit oder Sicherheit darstellt;",
    "lbl_contentStd_8.3.11": "illegale oder rechtswidrige Aktivitäten fördert oder diese darstellt;",
    "lbl_contentStd_8.3.12": "gefährdet die nationale Sicherheit;",
    "lbl_contentStd_8.3.13": "eine Untersuchung durch eine Strafverfolgungs- oder Regulierungsbehörde stört;",
    "lbl_contentStd_8.3.14": "bedrohlich oder beleidigend ist oder die Privatsphäre einer anderen Person verletzt oder Ärger, Unannehmlichkeiten oder unnötige Ängste verursacht;",
    "lbl_contentStd_8.3.15": "geeignet ist, eine andere Person zu belästigen, zu verärgern, in Verlegenheit zu bringen, zu beunruhigen oder zu ärgern;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "erweckt den Eindruck, dass es von uns kommt;",
    "lbl_contentStd_8.3.18": "stellt Werbematerial dar; oder",
    "lbl_contentStd_8.3.19": "befürwortet, fördert oder unterstützt rechtswidrige Aktivitäten, einschließlich, aber nicht beschränkt auf Urheberrechtsverletzungen oder Computermissbrauch.",
    "lbl_IntellctlProprty": "Rechte an geistigem Eigentum",
    "lbl_IntellctlProprty_9.1": "Wir oder unsere Lizenzgeber besitzen das Urheberrecht und alle anderen geistigen Eigentumsrechte an unserer Website und allen darauf befindlichen Inhalten, mit Ausnahme der von anderen hochgeladenen oder verlinkten Inhalte.",
    "lbl_IntellctlProprty_9.2": "Sie können die Inhalte, die uns gehören, von unserer Website herunterladen und/oder eine angemessene Anzahl von Kopien einer beliebigen Seite unserer Website ausdrucken. Sie dürfen keine Änderungen an Inhalten vornehmen, die Sie von unserer Website heruntergeladen oder ausgedruckt haben. Wenn Sie den heruntergeladenen oder gedruckten Inhalt auf einem beliebigen Medium reproduzieren, müssen Sie sicherstellen, dass Sie ihn korrekt und vollständig wiedergeben und eine Erklärung beifügen, in der Sie bestätigen, dass er von unserer Website stammt",
    "lbl_IntellctlProprty_9.3": "Wenn Sie Inhalte unserer Website unter Verstoß gegen diese Nutzungsbedingungen verwenden, herunterladen, drucken oder reproduzieren, erlischt Ihr Recht zur Nutzung unserer Website sofort und Sie müssen alle digitalen und Papierkopien der Inhalte an uns zurücksenden oder (nach unserem Ermessen) vernichten das du gemacht hast.",
    "lbl_linksToOtherWebsite": "Links von unserer Website zu anderen Websites",
    "lbl_linksToOtherWebsite_text": "Unsere Website kann von Zeit zu Zeit Links zu anderen Websites enthalten. Diese Links dienen Ihrer Bequemlichkeit. Wir haben keine Kontrolle über diese anderen Websites oder deren Inhalte und übernehmen keine Verantwortung oder Haftung für diese. Jegliche Nutzung oder Zugriff auf solche Websites durch Sie unterliegt den entsprechenden Nutzungsbedingungen dieser Websites und nicht diesen Bedingungen von Nutzen.",
    "lbl_linksFromOtherWebsite": "Links von anderen Websites zu unserer Website",
    "lbl_linksFromOtherWebsite_11.1": "Sie dürfen von einer anderen Website aus einen Link zu unserer Website erstellen, jedoch nur, wenn Sie die folgenden Bedingungen erfüllen:",
    "lbl_linksFromOtherWebsite_11.1.1": "Sie müssen uns über Ihre Absicht informieren, von einer anderen Website aus einen Link zu unserer Website zu erstellen, und vor der Erstellung eines solchen Links unsere schriftliche Genehmigung einholen.",
    "lbl_linksFromOtherWebsite_11.1.2": "Sie dürfen nur auf die Startseite unserer Website verlinken. Sie dürfen keinen Link zu einer anderen Seite unserer Website erstellen oder eine Seite unserer Website in ein Frame umwandeln;",
    "lbl_linksFromOtherWebsite_11.1.3": "Sie dürfen auf unsere Website nur von einer Website aus verlinken, die Ihnen gehört und die den in aufgeführten Standards entspricht",
    "lbl_linksFromOtherWebsite_11.1.4": "Durch die Erstellung des Links werden keine Viren, Trojaner, Würmer, Logikbomben, Zeitbomben, Tastenanschlaglogger, Spyware, Malware, Adware oder andere Materialien, die bösartig oder schädlich sind, auf unsere Website eingeführt oder über unsere Website übertragen ;",
    "lbl_linksFromOtherWebsite_11.1.5": "Sie werden die Anforderungen und/oder Richtlinien einhalten, über die wir Sie von Zeit zu Zeit in Bezug auf Links zu unserer Website von einer anderen Website informieren. Und",
    "lbl_linksFromOtherWebsite_11.1.6": "Sie dürfen nicht den Eindruck erwecken, dass wir mit Ihnen in Verbindung stehen oder dass wir Ihre Website oder eines Ihrer Produkte oder Dienstleistungen genehmigen oder unterstützen.",
    "lbl_linksFromOtherWebsite_11.2": "Wir behalten uns das Recht vor, von Ihnen jederzeit und ohne vorherige Ankündigung die Entfernung eines Links zu unserer Website zu verlangen",
    "lbl_ourLiability": "Unsere Haftung Ihnen gegenüber",
    "lbl_ourLiability_12.1": "Nichts in diesen Nutzungsbedingungen schränkt unsere Haftung Ihnen gegenüber ein oder schließt sie aus für:",
    "lbl_ourLiability_12.1.1": "Tod oder Körperverletzung aufgrund von Fahrlässigkeit;",
    "lbl_ourLiability_12.1.2": "Betrug oder betrügerische Falschdarstellung; oder",
    "lbl_ourLiability_12.1.3": "alle anderen Angelegenheiten, für die es uns gesetzlich nicht gestattet ist, unsere Haftung auszuschließen oder einzuschränken.",
    "lbl_ourLiability_12.2": "Da diese Nutzungsbedingungen Ihren Zugriff und Ihre Nutzung unserer Website regeln, sind alle Garantien, Bedingungen und sonstigen Bedingungen in Bezug auf unsere Website und/oder deren Inhalt, die andernfalls gesetzlich (sei es durch Gesetz, Gewohnheitsrecht oder anderweitig) impliziert würden, ausgeschlossen.",
    "lbl_ourLiability_12.3": "Wir haften Ihnen gegenüber nicht für Verluste oder Schäden, die sich aus oder im Zusammenhang mit Ihrer Nutzung (oder Unmöglichkeit der Nutzung) unserer Website oder Ihrer Nutzung oder Ihrem Vertrauen auf Inhalte unserer Website ergeben, sei es aus Vertrag, unerlaubter Handlung (einschließlich). (ohne Einschränkung: Fahrlässigkeit), Falschdarstellung, Rückerstattung, gesetzlich oder anderweitig.",
    "lbl_ourLiability_13": "Anwendbares Recht",
    "lbl_ourLiability_13.1": "Wenn Sie ein Verbraucher sind, unterliegen diese Nutzungsbedingungen dem Recht von England und Wales und die Gerichte von England und Wales haben die nicht ausschließliche Zuständigkeit für die Entscheidung aller Streitigkeiten, die sich aus oder im Zusammenhang mit ihnen ergeben. Wenn Sie Ihren Wohnsitz in Schottland haben, können Sie auch Klagen in Schottland einreichen, und wenn Sie Ihren Wohnsitz in Nordirland haben, können Sie Klagen auch in Nordirland einreichen. Einwohner anderer Länder, in denen die Website vermarktet wird, unterliegen dem Recht von England und Wales",
    "lbl_ourLiability_13.2": "Wenn Sie ein Geschäftsnutzer sind, unterliegen diese Nutzungsbedingungen (und alle außervertraglichen Verpflichtungen, die sich daraus oder im Zusammenhang mit ihnen ergeben) dem Recht von England und Wales und die Gerichte von England und Wales sind ausschließlich für die Entscheidung etwaiger Streitigkeiten zuständig aus oder im Zusammenhang mit ihnen (insbesondere in Bezug auf außervertragliche Verpflichtungen). Einwohner anderer Länder, in denen die Website vermarktet wird, unterliegen dem Recht von England und Wales",
    "lbl_privPolicy": "Datenschutz-Bestimmungen",
    "lbl_privPolicy_text": "Die Eaton SecureConnect-Website ist eine Website und ein Portal, die von Eaton Electrical Products Limited betrieben werden, einem im Companies House unter der Registrierungsnummer 01342230 eingetragenen Unternehmen mit der eingetragenen Adresse Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "Wir",
    "lbl_privPolicy_textUs": "uns",
    "lbl_privPolicy_textOur": "unser",
    "lbl_privPolicy_text2": "ist der Datenverantwortliche für die personenbezogenen Daten, die Sie uns über diese Website übermitteln.",
    "lbl_privPolicy_text3": "Dieser Hinweis (zusammen mit unseren Nutzungsbedingungen:",
    "lbl_privPolicy_text4": "und alle anderen Dokumente, auf die darin verwiesen wird) legt die Grundlage fest, auf der alle personenbezogenen Daten, die wir von Ihnen erfassen oder die Sie uns über diese Website zur Verfügung stellen, von uns verarbeitet werden. Bitte lesen Sie das Folgende sorgfältig durch, um unsere Vorgehensweise in Bezug auf Ihre personenbezogenen Daten und den Umgang mit diesen zu verstehen.",
    "lbl_privPolicy_text5": "Wenn Sie Fragen zu diesem Hinweis haben oder damit nicht einverstanden sind, kontaktieren Sie uns bitte unter dataprotection@eaton.com, bevor Sie diese Website nutzen.",
    "lbl_privPolicy_text6": "Wir können diesen Hinweis von Zeit zu Zeit ändern, indem wir diese Seite aktualisieren. Sie sollten diese Seite von Zeit zu Zeit überprüfen, um sicherzustellen, dass Sie mit etwaigen Änderungen zufrieden sind. Diese Mitteilung gilt ab dem 20. Dezember 2019.",
    "lbl_infoWeCollect": "Welche Informationen sammeln wir?",
    "lbl_infoWeCollect_text": "Der Zugriff auf die Website ist für Sie möglich, ohne dass Sie uns Angaben machen. Die meisten über die Website bereitgestellten Dienste (z. B. die über das Portal verfügbaren) sind jedoch nur verfügbar, wenn wir bestimmte Informationen über Sie verarbeiten.",
    "lbl_infoWeCollect_text1": "Wir können die folgenden Informationen sammeln:",
    "lbl_infoWeCollect_text2": "Ihr Name und Ihre Kontaktinformationen (einschließlich Adresse, E-Mail-Adresse und Telefonnummern) sowie Firmendaten;",
    "lbl_infoWeCollect_text3": "Ihre Kontoregistrierungsinformationen, wenn Sie ein registrierter Benutzer der Website werden (einschließlich Ihrer Benutzeranmeldedaten und Ihres Passworts);",
    "lbl_infoWeCollect_text4": "das Land, von dem aus Sie auf die Website zugreifen;",
    "lbl_infoWeCollect_text5": "Informationen über Ihre persönlichen oder beruflichen Interessen, Demografie, Erfahrungen mit unseren Produkten und Kontaktpräferenzen, um Ihnen weitere Korrespondenz über unsere Produkte und Dienstleistungen zukommen zu lassen;",
    "lbl_infoWeCollect_text6": "Informationen über Ihre Kunden (einschließlich deren Namen, Adressen, von ihnen gekaufte Produkte und in ihren Räumlichkeiten installierte Systeme, Informationen über ihren Alarmstatus (z. B. ob dieser aktiviert ist und/oder ordnungsgemäß funktioniert), CCTV-Bilder und ob dies der Fall ist ein Benutzer der mobilen Anwendung Eaton SecureConnect);",
    "lbl_infoWeCollect_text7": "Aufzeichnungen aller Kontakte zwischen Ihnen und uns; Und",
    "lbl_infoWeCollect_text8": "alle Informationen, die Sie uns freiwillig zur Verfügung stellen oder über die Website und/oder die Helpline eingeben.",
    "lbl_whatWeDowithInfo": "Was machen wir mit den von uns gesammelten Informationen?",
    "lbl_whatWeDowithInfo_text": "Wir nutzen diese Informationen, um Ihnen unsere Produkte und Dienstleistungen bereitzustellen, insbesondere aus den folgenden Gründen",
    "lbl_whatWeDowithInfo_text1": "um es uns zu ermöglichen, Ihnen Support und technische Hilfe in Bezug auf die Produkte zu bieten, die Sie bei uns gekauft haben;",
    "lbl_whatWeDowithInfo_text2": "um Sie über Wartungsausfallzeiten im Zusammenhang mit dem Portal und anderen für Sie bereitgestellten Diensten zu informieren;",
    "lbl_whatWeDowithInfo_text3": "interne Aufzeichnungen; Und",
    "lbl_whatWeDowithInfo_text4": "um unsere Produkte und Dienstleistungen zu verbessern.",
    "lbl_whatWeDowithInfo_text5": "Wir können Sie per E-Mail, Telefon oder Post kontaktieren",
    "lbl_whatWeDowithInfo_text6": "Wir verarbeiten diese personenbezogenen Daten zum Zweck der Erfüllung unserer Verpflichtungen und der Ausübung unserer Rechte im Zusammenhang mit unseren Verträgen mit Ihnen sowie für unsere berechtigten Interessen, zu denen die Bereitstellung von Produkten, Dienstleistungen und Lösungen für unsere Kunden und von Eaton akkreditierten Installateure gehört, die Einhaltung von Einhaltung unserer rechtlichen, vertraglichen und moralischen Verpflichtungen, Steigerung der Effizienz unserer Abläufe und Schutz unseres Geschäfts",
    "lbl_infoShareWith": "An wen geben wir diese Informationen weiter?",
    "lbl_infoShareWith_text": "Sofern nachstehend nicht anders dargelegt, werden wir Ihre personenbezogenen Daten nicht an Dritte außer unseren Tochtergesellschaften und/oder unserer Muttergesellschaft weitergeben, offenlegen, verkaufen, verteilen oder vermieten, es sei denn, wir haben Ihre Erlaubnis oder sind gesetzlich dazu verpflichtet.",
    "lbl_infoShareWith_text1": "Manchmal (und ggf. mit Ihrer Zustimmung) geben wir Ihre personenbezogenen Daten an sorgfältig ausgewählte Dritte außerhalb unserer Unternehmensgruppe weiter. Wir können dies aus folgenden Gründen tun:",
    "lbl_infoShareWith_text2": "um diesen Dritten die Erbringung von Dienstleistungen für uns zu ermöglichen, einschließlich unseres sorgfältig ausgewählten Website-Hosting-Anbieters;",
    "lbl_infoShareWith_text3": "um Ihrem Arbeitgeber, Auftraggeber und/oder Auftragnehmer die Einsicht in die im Portal enthaltenen Details zu ermöglichen, die sich auf sein Installateurgeschäft und/oder die von ihm gekauften Produkte beziehen;",
    "lbl_infoShareWith_text4": "als Reaktion auf rechtmäßige Anfragen von Behörden, einschließlich zur Erfüllung nationaler Sicherheits- oder Strafverfolgungsanforderungen;",
    "lbl_infoShareWith_text5": "wenn wir glauben, dass es notwendig ist, das Gesetz einzuhalten oder die Rechte, das Eigentum oder die Sicherheit von uns oder einer anderen Person zu schützen; und/oder",
    "lbl_infoShareWith_text6": "Sollte es zu einem Eigentümerwechsel an unserem Unternehmen oder unseren Vermögenswerten kommen (oder einen solchen geben), möchten wir möglicherweise Ihre Informationen weitergeben, damit die neuen (potenziellen) Eigentümer unser Unternehmen weiterhin effektiv betreiben und unseren Kunden weiterhin Dienstleistungen anbieten können. Dazu können neue Aktionäre oder Organisationen gehören, die eine Abtretung oder Übertragung von Vereinbarungen annehmen, die wir mit unseren Kunden geschlossen haben.",
    "lbl_infoShareWith_text7": "Dieser Vorgang kann die Übermittlung personenbezogener Daten in andere Länder innerhalb des Europäischen Wirtschaftsraums umfassen. Wenn wir Ihre personenbezogenen Daten aus irgendeinem Grund außerhalb des Europäischen Wirtschaftsraums übermitteln, werden wir Sicherheitsvorkehrungen treffen, um ein angemessenes Schutzniveau für alle diese personenbezogenen Daten zu gewährleisten, und wir werden Ihnen die Möglichkeit geben, eine Kopie davon zu erhalten oder Ihnen die Möglichkeit zu geben, diese zu erhalten solche Schutzmaßnahmen.",
    "lbl_howLongStoreData": "Wie lange werden wir Ihre personenbezogenen Daten speichern?",
    "lbl_howLongStoreData_text": "Wie lange wir Ihre Daten aufbewahren, hängt von der Grundlage ab, auf der sie bereitgestellt wurden. Im Allgemeinen gilt jedoch Folgendes:",
    "lbl_howLongStoreData_text1": "Wir bewahren die Informationen, die erforderlich sind, um Ihnen die Bereitstellung einer Dienstleistung zu ermöglichen, die Sie über oder im Zusammenhang mit dieser Website angefordert haben, so lange auf, wie wir diese Dienstleistung erbringen müssen.",
    "lbl_howLongStoreData_text2": "Im Allgemeinen bewahren wir Aufzeichnungen über alle Transaktionen, die Sie mit uns abschließen, mindestens sechs Jahre lang ab dem Ende der Geschäftsbeziehung auf. Auf diese Weise können wir Probleme lösen und auf etwaige Beschwerden oder Streitigkeiten reagieren, die in diesem Zeitraum auftreten. Andernfalls werden wir die Informationen aufbewahren, soweit dies für legitime Geschäftszwecke erforderlich ist;",
    "lbl_howLongStoreData_text3": "Wir werden andere Informationen über Sie aufbewahren, wenn dies zur Einhaltung gesetzlicher Vorschriften oder aus legitimen Geschäftsanforderungen erforderlich ist.",
    "lbl_sensativeInfo": "Heikle Informationen",
    "lbl_sensativeInfo_text": "Informationen über Sie, die gemäß den Datenschutzgesetzen als vertraulich oder als besondere Kategorie personenbezogener Daten gelten, können Informationen über Ihren Gesundheits- oder Gesundheitszustand, Ihre Rasse oder ethnische Herkunft, politische Meinungen, Gewerkschaftszugehörigkeit, religiöse oder philosophische Überzeugungen, genetische Daten und biometrische Daten umfassen , Sexualleben und sexuelle Orientierung sowie vermutete oder nachgewiesene Straftaten und damit verbundene Verfahren. Wenn wir sensible personenbezogene Daten verarbeiten müssen, werden Sie über diese Verarbeitung informiert und gebeten, der Verwendung dieser Informationen gegebenenfalls ausdrücklich zuzustimmen.",
    "lbl_security": "Sicherheit",
    "lbl_security_text": "Wir setzen uns dafür ein, dass Ihre Daten sicher sind. Um unbefugten Zugriff oder unbefugte Offenlegung zu verhindern, haben wir physische, elektronische und verwaltungstechnische Verfahren zum Schutz und zur Sicherung der von uns online erfassten Informationen eingerichtet.",
    "lbl_security_text1": "Wir bemühen uns, alle angemessenen Maßnahmen zum Schutz Ihrer personenbezogenen Daten zu ergreifen. Bitte beachten Sie jedoch, dass die Bereitstellung von Informationen und der Online-Handel über das Internet mit Sicherheitsrisiken verbunden sind und wir daher die Sicherheit der online offengelegten personenbezogenen Daten nicht garantieren können. Wir bitten Sie, uns online keine sensiblen personenbezogenen Daten zur Verfügung zu stellen (siehe Abschnitt „Sensible Informationen“ oben), es sei denn, wir bitten ausdrücklich darum.",
    "lbl_cookies": "Kekse",
    "lbl_cookies_text": "Wie viele Unternehmen verwenden wir auf unserer Website „Cookies“ und ähnliche Tools, um deren Leistung zu verbessern und Ihr Benutzererlebnis zu verbessern.",
    "lbl_cookies_text1": "Was sind Cookies?",
    "lbl_cookies_text2": "Ein Cookie ist eine kleine Textdatei, die auf dem Gerät abgelegt wird, mit dem Sie auf die Website zugreifen (Ihr",
    "lbl_cookies_text3": "Cookies helfen bei der Analyse des Webverkehrs und ermöglichen es Webanwendungen, auf Sie als Einzelperson zu reagieren. Die Webanwendung kann ihre Vorgänge an Ihre Bedürfnisse, Vorlieben und Abneigungen anpassen, indem sie Informationen über Ihre Präferenzen sammelt und speichert. Bestimmte Cookies können personenbezogene Daten enthalten. Wenn Sie beispielsweise beim Anmelden auf „Angemeldet bleiben“ klicken, kann ein Cookie Ihren Benutzernamen speichern. Die meisten Cookies erfassen keine personenbezogenen Daten, die Sie identifizieren, sondern vielmehr allgemeinere Informationen, z. B. wie Benutzer auf unsere Website gelangen und diese nutzen, oder den allgemeinen Standort eines Benutzers.",
    "lbl_cookies_text4": "Wofür verwenden wir Cookies?",
    "lbl_cookies_text5": "Aus Sicherheitsgründen können wir Cookies oder ähnliche Dateien auf Ihrem Gerät platzieren, um uns mitzuteilen, ob Sie die Website schon einmal besucht haben, um Ihre Spracheinstellungen zu speichern, wenn Sie ein neuer Besucher sind oder um die Navigation auf der Website auf andere Weise zu erleichtern und um Ihr Besuchserlebnis zu personalisieren unsere Webseiten. Cookies ermöglichen es uns, technische und Navigationsinformationen zu sammeln, wie z. B. den Browsertyp, die auf unseren Websites verbrachte Zeit und die besuchten Seiten. Cookies können Ihr Online-Erlebnis verbessern, indem sie Ihre Präferenzen speichern, während Sie eine bestimmte Website besuchen. Insgesamt helfen uns Cookies dabei, Ihnen eine bessere Website bereitzustellen, indem sie es uns ermöglichen, zu überwachen, welche Seiten Sie nützlich finden und welche nicht. Ein Cookie gewährt uns in keiner Weise Zugriff auf Ihr Gerät oder andere Informationen über Sie, mit Ausnahme der Daten, die Sie uns mitteilen.",
    "lbl_cookies_text6": "Welche Arten von Cookies verwenden wir?",
    "lbl_cookies_text7": "Im Allgemeinen lassen sich die auf der Website verwendeten Cookies in die folgenden Kategorien einteilen:",
    "lbl_cookies_text8": "Sitzungscookies:",
    "lbl_cookies_text9": "Diese Cookies werden „in-session“ bei jedem Besuch verwendet und verfallen dann, wenn Sie eine Website verlassen oder kurz danach: Sie werden nicht dauerhaft auf Ihrem Gerät gespeichert, enthalten keine personenbezogenen Daten und tragen dazu bei, die Notwendigkeit der Übermittlung personenbezogener Daten zu minimieren über das Internet. Diese Cookies können gelöscht werden oder Sie können deren Verwendung verweigern. Dies kann jedoch die Leistung und Ihr Erlebnis bei der Nutzung der Websites beeinträchtigen. Diese Cookies erfassen auch Zeitstempel, die aufzeichnen, wann Sie auf eine Website zugreifen und wann Sie eine Website verlassen.",
    "lbl_cookies_text10": "Tracking cookies:",
    "lbl_cookies_text11": "Diese Cookies ermöglichen es uns, wiederkehrende Besucher unserer Websites wiederzuerkennen. Durch den Abgleich mit einer anonymen, zufällig generierten Kennung verfolgt ein Tracking-Cookie, woher ein Benutzer unserer Websites kam, welche Suchmaschine er möglicherweise verwendet hat, auf welchen Link er geklickt hat, welches Schlüsselwort er verwendet hat und wo auf der Welt er sich wann aufgehalten hat Sie haben eine Website aufgerufen. Durch die Überwachung dieser Daten können wir Verbesserungen an unseren Websites vornehmen.",
    "lbl_cookies_text12": "Dauerhafte Cookies:",
    "lbl_cookies_text13": "Diese Art von Cookies wird für einen bestimmten Zeitraum (manchmal für ein paar Stunden, manchmal für ein Jahr oder länger) auf Ihrem Gerät gespeichert und nicht gelöscht, wenn der Browser geschlossen wird. Dauerhafte Cookies werden verwendet, wenn wir uns für mehr als eine Browsersitzung daran erinnern müssen, wer Sie sind. Diese Art von Cookie kann beispielsweise dazu verwendet werden, Ihre Präferenzen zu speichern, sodass diese beim nächsten Besuch einer Website gespeichert werden.",
    "lbl_cookies_text14": "Leistungs- oder Analyse-Cookies:",
    "lbl_cookies_text15": "Leistungscookies werden verwendet, um zu analysieren, wie die Websites genutzt werden, und um deren Leistung zu überwachen, wodurch wir Ihr Erlebnis bei der Nutzung der Websites verbessern können. Diese Cookies helfen uns, den Inhalt der Website anzupassen",
    "lbl_cookies_text16": "websites",
    "lbl_cookies_text17": "um wiederzugeben, was die Website-Benutzer am interessantesten finden und um zu erkennen, wann technische Probleme mit den Websites auftreten. Wir können diese Daten auch verwenden, um Berichte zu erstellen, die uns helfen zu analysieren, wie Websites genutzt werden, was die häufigsten Probleme sind und wie wir die Websites verbessern können.",
    "lbl_cookies_text18": "Können die Cookies blockiert werden?",
    "lbl_cookies_text19": "Sie können Cookies akzeptieren oder ablehnen. Die meisten Webbrowser akzeptieren Cookies automatisch, aber Sie können Ihre Browsereinstellungen normalerweise so ändern, dass Cookies abgelehnt werden, wenn Sie dies bevorzugen. Wenn Sie keine Cookies akzeptieren möchten, können Sie in den meisten Browsern Folgendes tun: (i) Ihre Browsereinstellungen so ändern, dass Sie benachrichtigt werden, wenn Sie ein Cookie erhalten, sodass Sie entscheiden können, ob Sie es akzeptieren möchten oder nicht; (ii) um vorhandene Cookies zu deaktivieren; oder (iii) Ihren Browser so einzustellen, dass er alle Cookies automatisch ablehnt. Bitte beachten Sie jedoch, dass einige Funktionen und Dienste auf unseren Websites möglicherweise nicht ordnungsgemäß funktionieren, wenn Sie Cookies deaktivieren oder ablehnen, da wir Sie möglicherweise nicht erkennen und Ihrem Konto bzw. Ihren Konten nicht zuordnen können. Darüber hinaus sind die Angebote, die wir Ihnen bei Ihrem Besuch anbieten, möglicherweise nicht so relevant für Sie oder nicht auf Ihre Interessen zugeschnitten.",
    "lbl_cookies_text20": "Anweisungen zum Blockieren oder Zulassen von Cookies in der gängigen Webbrowser-Software Internet Explorer 7 und 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Verlinkung",
    "lbl_linking_text": "Wir verlinken möglicherweise auf andere Websites, die nicht unserer Kontrolle unterliegen. Sobald Sie unsere Website verlassen, übernehmen wir keine Verantwortung für den Schutz und die Privatsphäre der von Ihnen bereitgestellten Informationen. Sie sollten Vorsicht walten lassen und sich die Datenschutzerklärung der betreffenden Website ansehen.",
    "lbl_yourRights": "Deine Rechte",
    "lbl_yourRights_text": "Sie können eine Kopie aller personenbezogenen Daten anfordern, die wir über Sie gespeichert haben. Möglicherweise fällt eine geringe Gebühr an. Sie können dies tun, indem Sie uns an dataprotection@eaton.com schreiben",
    "lbl_yourRights_text1": "Unter bestimmten Umständen haben Sie möglicherweise Anspruch darauf, Ihre personenbezogenen Daten in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten.",
    "lbl_yourRights_text2": "Wenn Sie der Meinung sind, dass Informationen, die wir über Sie haben, falsch oder unvollständig sind, schreiben Sie uns bitte so schnell wie möglich an oder senden Sie uns eine E-Mail. Wir werden alle Informationen so schnell wie möglich korrigieren oder aktualisieren.",
    "lbl_yourRights_text3": "Sie haben das Recht, von uns zu verlangen, dass wir die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Umständen einschränken, wenn:",
    "lbl_yourRights_text4": "Sie bestreiten die Richtigkeit der personenbezogenen Daten, die wir über Sie verarbeiten;",
    "lbl_yourRights_text5": "unsere Verarbeitung Ihrer Daten ist rechtswidrig;",
    "lbl_yourRights_text6": "wir die personenbezogenen Daten für die Zwecke, für die wir sie verarbeiten, nicht länger benötigen, Sie die personenbezogenen Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen;",
    "lbl_yourRights_text7": "Sie bestreiten, ob die berechtigten Gründe des Verantwortlichen Vorrang vor denen der betroffenen Person haben.",
    "lbl_yourRights_text8": "Wenn Sie Fragen, Kommentare oder Vorschläge zur Verwendung Ihrer personenbezogenen Daten haben, schreiben Sie uns bitte an dataprotection@eaton.com",
    "lbl_yourRights_text9": "Sie haben auch das Recht, beim Information Commissioner’s Office eine Beschwerde einzureichen, wenn Sie mit der Verarbeitung Ihrer personenbezogenen Daten durch uns unzufrieden sind.",
    "lbl_noticeToCalfrniaResidents": "Hinweis für Einwohner Kaliforniens",
    "lbl_noticeToCalfrniaResidents_text": "Dieser Abschnitt gilt für Einwohner Kaliforniens.",
    "lbl_noticeToCalfrniaResidents_text1": "Wie im Abschnitt „Welche Informationen sammeln wir?“ beschrieben, erfassen wir möglicherweise personenbezogene Daten, einschließlich Identifikatoren, kommerzieller Informationen, Informationen zu Internet- oder anderen Netzwerkaktivitäten, Geolokalisierungsinformationen, beruflichen oder beschäftigungsbezogenen Informationen und Schlussfolgerungen, die aus den oben genannten Informationen gezogen werden Erstellen Sie ein Profil über einen Verbraucher.",
    "lbl_noticeToCalfrniaResidents_text2": "Wie im Abschnitt „An wen geben wir Ihre Daten weiter?“ beschrieben, können personenbezogene Daten, die wir von Verbrauchern sammeln, zu geschäftlichen Zwecken an Dritte weitergegeben werden. Je nach Anwendungsfall haben wir in den letzten 12 Monaten möglicherweise alle oben aufgeführten Kategorien personenbezogener Daten für geschäftliche Zwecke offengelegt.",
    "lbl_noticeToCalfrniaResidents_text3": "Wir verkaufen Ihre persönlichen Daten nicht.",
    "lbl_noticeToCalfrniaResidents_text4": "Als Einwohner Kaliforniens haben Sie vorbehaltlich gesetzlicher Beschränkungen und geltender Ausnahmen bestimmte Rechte hinsichtlich der Erhebung, Nutzung und Weitergabe Ihrer personenbezogenen Daten. Diese Rechte sind insbesondere:",
    "lbl_noticeToCalfrniaResidents_text5": "Das Recht zu wissen",
    "lbl_noticeToCalfrniaResidents_text6": "Sie haben das Recht, Informationen über die Kategorien personenbezogener Daten, die wir über Sie erfasst haben, die Kategorien der Quellen, aus denen wir die personenbezogenen Daten erhoben haben, die Zwecke der Erhebung der personenbezogenen Daten und die Kategorien Dritter, mit denen wir Ihre Daten geteilt haben, anzufordern personenbezogene Daten und den Zweck, zu dem wir Ihre personenbezogenen Daten weitergegeben haben („Kategorienbericht“). Sie können auch Informationen über die spezifischen personenbezogenen Daten anfordern, die wir über Sie erfasst haben („Bericht über bestimmte personenbezogene Daten“). Bitte geben Sie an, welche Anfrage Sie ausführen möchten.",
    "lbl_noticeToCalfrniaResidents_text7": "Das Recht auf Löschung",
    "lbl_noticeToCalfrniaResidents_text8": "Sie haben das Recht zu verlangen, dass wir die personenbezogenen Daten, die wir von Ihnen erfasst haben, löschen.",
    "lbl_noticeToCalfrniaResidents_text9": "Das Recht auf Opt-out",
    "lbl_noticeToCalfrniaResidents_text10": "Sie haben das Recht, dem Verkauf Ihrer personenbezogenen Daten zu widersprechen.",
    "lbl_noticeToCalfrniaResidents_text11": "Gemäß geltendem Recht werden wir Sie bei der Ausübung dieser Rechte nicht diskriminieren. Sie können einen bevollmächtigten Vertreter beauftragen, Ihre Rechte in Ihrem Namen auszuüben. Wenn Sie eine der oben genannten Anfragen über einen autorisierten Vertreter stellen, werden wir Sie um eine schriftliche Genehmigung bitten und versuchen, dies wie oben beschrieben zu überprüfen, oder wir akzeptieren eine gesetzliche Vollmacht gemäß dem California Probate Code für den autorisierten Vertreter.",
    "lbl_noticeToCalfrniaResidents_text12": "Sie können Ihr Recht jederzeit ausüben, indem Sie uns per E-Mail kontaktieren",
    "lbl_noticeToCalfrniaResidents_text13": "indem Sie +1-800-386-1911 anrufen oder diese nutzen",
    "lbl_noticeToCalfrniaResidents_text14": "Online-Formular",
    "lbl_noticeToCalfrniaResidents_text15": "Um Ihre Rechte auszuüben, müssen wir je nach Art der Anfrage Informationen einholen, um Sie in unseren Unterlagen zu lokalisieren oder Ihre Identität zu überprüfen. Wenn Sie eine Anfrage im Namen eines Haushalts einreichen, müssen wir jedes Mitglied des Haushalts auf die in diesem Abschnitt beschriebene Weise überprüfen. Daher können wir Sie aufgrund Ihrer Anfrage für weitere Informationen kontaktieren.",
    "lbl_noticeToCalfrniaResidents_text16": "Wir werden innerhalb von 15 Tagen auf eine Abmeldeanfrage antworten. Wir werden auf Löschungsanfragen und Informationsanfragen innerhalb von 45 Tagen antworten, es sei denn, wir benötigen mehr Zeit. In diesem Fall werden wir Sie benachrichtigen und es kann insgesamt bis zu 90 Tage dauern, bis wir auf Ihre Anfrage antworten.",
    "lbl_viewSubEstate": "Teilgrundstück ansehen",
    "lbl_installers": "Installateure",
    "lbl_managedPanels": "Nicht verwaltete Panels",
    "lbl_days": "Tage",
    "lbl_email": "Email",
    "lbl_company": "Unternehmen",
    "lbl_removeInstaller": "Entfernen Sie das Installationsprogramm",
    "lbl_confirm": "Bestätigen",
    "msg_deleteAdminConfirmation": "Sind Sie sich sicher, dass Sie das entfernen wollen",
    "lbl_editDetails": "Details bearbeiten",
    "msg_adminAdded": "Der Administrator hat erfolgreich bearbeitet",
    "msg_adminDeleted": "Adminstartor erfolgreich gelöscht",
    "msg_errorDeleteAdmin": "Fehler beim Löschen des Administratorstarters",
    "lbl_addAdmin": "Administrator hinzufügen",
    "lbl_info": "Die Info",
    "lbl_noOfPanels": "Anzahl der Paneele",
    "lbl_dateRegistered": "Registrierungsdatum",
    "lbl_cmpnyAddress": "Firmenanschrift",
    "lbl_addressDetails": "Adressen Details",
    "txt_addressDetails": "Um sich für ein Firmenkonto zu registrieren, geben Sie bitte die Adressdaten Ihres Unternehmens an.",
    "lbl_contactDetails": "Kontaktdetails",
    "txt_contactDetails": "Um sich für ein Firmenkonto zu registrieren, geben Sie bitte die Kontaktdaten Ihres Unternehmens an.",
    "lbl_phoneType": "Telefon-Typ",
    "msg_alreadyAccnt": "Es gibt bereits ein Konto für diese E-Mail.",
    "txt_invalidEmail": "ungültige E-Mail",
    "lbl_createAccnt": "Ein Konto erstellen",
    "lbl_createAccntDetails": "Um sich für ein Unternehmenskonto zu registrieren, geben Sie unten die erforderlichen Informationen ein.",
    "msg_pwdValidation": "Mindestens 8 Zeichen, mindestens 1 Großbuchstabe, 1 Kleinbuchstabe, 1 Zahl und 1 Sonderzeichen",
    "lbl_createPwd": "Erstellen Sie ein Passwort",
    "txt_pwdLength": "Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen enthalten.",
    "lbl_licenseAgreememnt": "Lizenzvereinbarung",
    "lbl_licenseAgreememntDetails": "Um sich für ein Unternehmenskonto zu registrieren, müssen Sie die Allgemeinen Geschäftsbedingungen lesen und ihnen zustimmen.",
    "lbl_accntActivation": "Account Aktivierung",
    "msg_accntActivation": "Herzlichen Glückwunsch, Sie wurden mit dem Login von registriert",
    "lbl_accntActivationEmailSent": "Wir haben Ihnen eine Aktivierungs-E-Mail gesendet. Klicken Sie bitte auf den Link in dieser Nachricht, um Ihr Konto zu aktivieren.",
    "lbl_notReceive": "Keine E-Mail erhalten?",
    "lbl_sendAgain": "Nochmals senden",
    "lbl_cnfrmPwd": "Bestätige das Passwort",
    "msg_accntExists": "Es gibt bereits ein Konto für diese E-Mail.",
    "lbl_forgotPwd": "Wenn die Benutzer-ID vorhanden ist, wird ein Link zum Zurücksetzen Ihres Passworts an gesendet",
    "lbl_RmtSvsMsg": "Der Vorgang kann nicht fortgesetzt werden, das Panel befindet sich im Installationsmodus",
    "lbl_planned": "GEPLANT",
    "lbl_noPlannedService": "Keine geplanten Serviceberichte",
    "lbl_rmtServInitiationMsg": "Es wurde ein manueller Remote-Service angefordert. Bitte warten Sie 5 bis 10 Minuten, bis der Bericht abgeschlossen ist..",
    "lbl_okay": "Okay",
    "lbl_pwdReset": "Passwort zurücksetzen",
    "lbl_setPwd": "Passwort festlegen",
    "lbl_pwdResetMsg": "Um Ihr Passwort zurückzusetzen, geben Sie bitte unten ein neues Passwort ein.",
    "lbl_pwdResetSuccess": "Erfolgreiches Zurücksetzen des Passworts",
    "lbl_pwdResetSuccessMsg": "Ihr Passwort wurde erfolgreich zurückgesetzt. Melden Sie sich mit Ihrem aktualisierten Passwort bei der App an.",
    "lbl_pwdResetErrMsg": "Passwort-Reset wurde in den letzten 15 Minuten bereits angefordert.",
    "btn_download_notifications": "Laden Sie den Panel-Verlauf herunter",
    "btn_download_logs": "Laden Sie Benachrichtigungen herunter",
    "lbl_userAccDelete": "Löschung des Benutzerkontos",
    "lbl_userAccDeleteConfirm": "Möchten Sie die Kontolöschung bestätigen?",
    "lbl_userAccDeleteMsg": "Um die Entfernung eines Benutzerkontos zu beantragen, geben Sie bitte unten die E-Mail-Adresse des Kontos ein und klicken Sie dann auf die Schaltfläche „Senden“.",
    "lbl_userAccDeleteSubmitMsg": "Ihr Antrag auf Löschung Ihres Benutzerkontos wurde übermittelt. Wenn die Benutzer-ID vorhanden ist, wird ein Link zum Zurücksetzen Ihres Passworts an gesendet",
    "btn_rmvLogo": "Logo entfernen",
    "btn_cgnLogo": "Logo ändern",
    "lbl_endUserMsg": "Die hier bereitgestellten Informationen werden sowohl in der mobilen Anwendung als auch in Fernwartungsberichten angezeigt",
    "lbl_dwnPanelLog": "Panel-Protokolle herunterladen",
    "lbl_estatelessPanels": "Panels, die nicht zu einem Anwesen gehören",
    "lbl_siteComments": "Kommentare",
    "mainHeadingforsystemConfig": "Header",
    "lbl_EatonGCMorFCMurl": "Eaton GCM- oder FCM-URL",
    "lbl_EatonapiKey": "Eaton API-Schlüssel",
    "lbl_eatonApplepassword": "Eaton Apple-Passwort",
    "lbl_applePushNotification": "Apple verwendet Produktions-Push-Benachrichtigungen",
    "headingaccountSettings": "Konto-Einstellung",
    "lbl_account_default_state": "Standardstatus des Kontos",
    "lbl_choose_state_for_new_account": "Wählen Sie den Status für das neue Konto-Installationsprogramm",
    "lbl_account_disable_after": "Kontosperrung danach",
    "lbl_the_top_limit_in_days": "Die Höchstgrenze in Tagen, nach der ein Konto deaktiviert wird",
    "lbl_remove_unpaired_account_after": "Entfernen Sie anschließend das nicht gekoppelte Konto",
    "lbl_helper_text": "Hilfstext",
    "lbl_cloud_password": "Cloud-Passwort",
    "lbl_user_registration_disable_time": "Deaktivierungszeit der Benutzerregistrierung",
    "lbl_invalid_login_attempt": "Sperrdauer für ungültigen Anmeldeversuch",
    "lbl_maximum_sign_in_attempt": " Maximale Anmeldeversuche",
    "lbl_seconds": "Sekunden",
    "lbl_times": "mal",
    "headingCertificates": "Zertifikate",
    "lbl_eaton_Apple_certificaates": "Eaton Apple-Zertifikat",
    "lbl_no_file_selected": "Keine Datei ausgewählt",
    "lbl_upload_file": "Datei hochladen",
    "lbl_heartbeat_interval": "Heartbeat-Intervall",
    "lbl_miliseconds": "Millisekunden",
    "lbl_WS_session_drop_after": "WS-Sitzungsabbruch danach",
    "lbl_jwt_time_to_live": "JWT Zeit zum Leben",
    "lbl_communication_log_size": "Größe des Kommunikationsprotokolls",
    "lbl_panel_log_size": "Größe des Panel-Log-Downloads",
    "lbl_nesting_group_level": "Verschachtelungsgruppenebene",
    "lbl_ws_sessions_close_qty": "WS-Sitzungen schließen Menge",
    "lbl_async_context_timeout": "Async-Kontext-Timeout",
    "lbl_refer_header": "Referrer-Header",
    "heading_Liceense_Agrrement": "Lizenzvereinbarung",
    "heading_proxy": "Stellvertreterin(fe",
    "lbl_proxy_host": "Proxy-Host",
    "lbl_proxy_port": "Proxy-Port",
    "lbl_use_proxy": "Verwenden Sie einen Proxy",
    "lbl_fichet_gcmorfcm": "Fichet GCM- oder FCM-URL",
    "lbl_fichetApiKey": "API-Schlüsseldatei",
    "lbl_fichet_applePsword": "Fichet Apple-Passwort",
    "lbl_fichet_applepassword": "Fichet Apple-Zertifikat",
    "lbl_discard": "Verwerfen",
    "heading_CSL": "CSL-Einstellungen",
    "lbl_csl_login": "CSL-Login",
    "lbl_csl_password": "CSL-Passwort",
    "lbl_csl_primary_ip": "CSL-Primärserver-IP",
    "lbl_csl_primary_port": "CSL-Primärserver-Port",
    "lbl_csl_secondary_IP": "CSL-Sekundärserver-IP",
    "lbl_csl_secondary_port": "Port des sekundären CSL-Servers",
    "lbl_csl_primary_protocol": "CSL-Primärserverprotokoll",
    "lbl_csl_secondary_portocol": "CSL-Sekundärserverprotokoll",
    "lbl_poll_fails": "Die CSL-Abfrage schlägt danach fehl",
    "lbl_minutes": "Protokoll",
    "button_CSL_ARC": "CSL ARC-Codes",
    "button__csl_countries": "CSL-Länder",
    "lbl_dual_com_login": "CSL DualCom-Login",
    "lbl_dual_com_password": "CSL DualCom-Passwort",
    "lbl_poll_failer_helper": "Senden Sie eine Poll-Fail-SIA-Nachricht an CSL, wenn die Verbindung des Panels länger als dieser Wert in Minuten unterbrochen ist",
    "lbl_add_email": "E-Mail hinzufügen",
    "lbl_csl_support_team_e-mail_address": "E-Mail-Adressen des CSL-Supportteams",
    "lbl_Support_email_list_of_CSL_arc_provider": "Support-E-Mail-Liste des CSL-Arc-Anbieters",
    "lbl_support_team_email_address": "E-Mail-Adressen des Support-Teams",
    "lbl_email_address_of_support_team": "E-Mail-Adressen des Support-Teams",
    "heading_Permissions": "Berechtigungen",
    "lbl_Enable_auth": "Authentifizierung aktivieren",
    "lbl_Mail_transport_protocol_authorization": "Autorisierung des Mail-Transportprotokolls",
    "lbl_EnableTLS": "Aktivieren Sie TLS",
    "lbl_Mail_transport_protocol_enable": "Mail-Transportprotokoll aktivieren",
    "heading_Info_SMTP": "Info SMTP",
    "lbl_SMTP_host": "SMTP-Host",
    "lbl_Mail_trasport_protocol_host": "Host des Mail-Transportprotokolls",
    "lbl_SMTP_port": "SMTP-Port",
    "lbl_Mail_trasport_protocol_post": "Beitrag zum Posttransportprotokoll",
    "lbl_SMTP_user": "SMTP-Benutzer",
    "lbl_Mail_transport_protocol_user": "Benutzer des Mail-Transportprotokolls",
    "lbl_SMTP_password": "SMTP-Passwort",
    "lbl_Mail_transport_protocol_password": "Passwort für das Mail-Transportprotokoll",
    "lbl_Protocol": "Protokoll",
    "lbl_Mail_transport_protocol": "Mail-Transportprotokoll",
    "lbl_Form": "Bilden",
    "lbl_Mail_transport_protocol_source": "Quelle des Mail-Transportprotokolls",
    "heading_Email_Settings": "Email Einstellungen",
    "lbl_Email_Text_Language": "E-Mail-Textsprache",
    "lbl_User_registration_e-mail": "E-Mail zur Benutzerregistrierung",
    "lbl_helper_text_for_user_register_mail": "Dieser Text wird an die E-Mail-Adresse des Benutzers gesendet. ${user.login} wird bei der Anmeldung des Benutzers ersetzt, der die E-Mail ${user.name} - Benutzername, ${ erhält. {user-cloudId} – Benutzer-Cloud-ID, ${confirm.link} - E-Mail-Bestätigungslink",
    "lbl_State_change_e-mail": "E-Mail zur Statusänderung",
    "lbl_helpertext_for_state_email_change": "Dieser Text wird an die E-Mail-Adresse des Benutzers gesendet. ${user.login} wird bei der Anmeldung des Benutzers ersetzt, der die E-Mail-Adresse ${user.name} - Benutzername, ${ erhält. {user.state} Benutzerstatus, ${user-cloudId} – Benutzer-Cloud-ID",
    "lbl_Forgot_login_e-mail_subject": "Betreff der Login-E-Mail vergessen",
    "lbl_Email_subject_for_login_reminder": "E-Mail-Betreff für Anmeldeerinnerung",
    "lbl_Forgotlogine-mail": "Login-E-Mail vergessen",
    "lbl_helpertext_for_forget_login_mail": "Wenn Sie die Beschreibung ändern, fügen Sie bitte die Variablen ${user.name} und ${user.login} ein, wenn Sie den Benutzernamen bzw. die Benutzeranmeldung sehen möchten",
    "lbl_App_user_creation_e-mail": "E-Mail zur App-Benutzererstellung",
    "lbl_helper_text_for_app_user_creation": "Dieser Text wird an die E-Mail-Adresse des Benutzers gesendet. ${user.login} wird bei der Anmeldung des Benutzers ersetzt, der eine E-Mail erhält. ${user.name} - Benutzername ${confirm.link} - E-Mail-Bestätigungslink",
    "lbl_Forgot_passworde-mail": "E-Mail-Passwort vergessen",
    "lbl_helper_text_for_forgetpassworde-mail": "Dieser Text wird an die E-Mail-Adresse des Benutzers gesendet. ${user.name} - Benutzername ${secretLink} - URL zum Ändern des Passworts",
    "lbl_Maintenance_notification_subject": "Betreff der Wartungsmeldung",
    "lbl_Subject_for_all_user_maintenancenotification": "Betreff für alle Benutzerwartungsbenachrichtigungen",
    "lbl_Maintenance_notificationtext": "Text der Wartungsbenachrichtigung",
    "lbl_helper_text_for_maintain_notigication": "Dieser Text wird an alle Benutzer gesendet, wenn es erforderlich ist, sie über ein Ereignis auf dem Cloud-Server zu informieren",
    "lbl_Raised_alert_event_e-mail_text": "E-Mail-Text zum ausgelösten Alarmereignis",
    "lbl_helper_text_for_raised_alert_event": "Dies wird an das Panel-Support-Team gesendet. ${site.name} – wird durch den Cloud-Referenznamen des Panels ersetzt. ${panel.id} – wird durch die Cloud-Panel-ID ersetzt. ${sia.code} – Alarmereignis SIA-Code ${description} – Warnungsereignis SIA-Codebeschreibung ${time.stamp} – Zeitstempel, als die Warnung ausgelöst wurde ${panel.link} – Hyperlink zum Cloud-Panel-Bildschirm",
    "lbl_Self_test_failure_text": "Text zum Fehlschlagen des Selbsttests",
    "lbl_helper_text_for_test_failure_Text": "Dieser Text wird an das Support-Team gesendet.${event.ipAdd} - IP-Adresse des ausgefallenen Knotens ${event.type} - Ereignistyp ${event.message} - Nachricht ${event.connected} - Verbindungsstatus ${event.date} - Zeitstempel des Ereignisses",
    "lbl_SIA_message_failure_e-mail_subject": "E-Mail-Betreff Fehler bei SIA-Nachricht.",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Betrifft Meldung über Fehler beim Senden von SIA-Ereignissen",
    "lbl_SIA_message_failuree-mail_text": "E-Mail-Text zum SIA-Nachrichtenfehler",
    "lbl_Email_text_about_siaevent_sendingproblems": "E-Mail-Text über Probleme beim Senden von SIA-Ereignissen",
    "lbl_time_in_seconds_for_locking_account": "Zeit in Sekunden zum Sperren des Kontos, wenn zu viele fehlgeschlagene Anmeldeversuche aufgetreten sind",
    "lbl_max_failed_login": "Maximale Anzahl fehlgeschlagener Anmeldeversuche, bevor das Konto gesperrt wird",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Zeit in Sekunden für die vorübergehende Deaktivierung der Registrierung neuer Benutzer",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Bitte geben Sie im Panel die Anmeldeinformationen für den Cloud-Benutzer ein",
    "lbl_Days_to_remove_unpaired_user_accounts": "Tage zum Entfernen ungepaarter Benutzerkonten",
    "lbl_google_firebase_push_notification": "URL des Google- oder Firebase-Push-Benachrichtigungsservers",
    "lbl_api_key": "API-Schlüssel",
    "lbl_Apple_push_notification_server_password": "Passwort für den Apple-Push-Benachrichtigungsserver",
    "lbl_determines_apple_server": "Legt fest, welche Apple-Server verwendet werden sollen: Produktions- oder Sandbox-Server",
    "lbl_upload_certificate_for_eaton": "Laden Sie ein Zertifikat für Eaton Apple Push Notification hoch",
    "lbl_Heartbeat_intervalinmilliseconds": "Heartbeat-Intervall in Millisekunden",
    "lbl_Atime_delay_between_closing_session": "Eine Zeitverzögerung zwischen der Abschlusssitzung",
    "lbl_json_web_token": "Lebensdauer des JSON-Web-Tokens",
    "lbl_hazelcast_max_comminication_log_size": "Hazelcast maximale Kommunikationsprotokollgröße",
    "lbl_Max_size_of_log_entries": "Maximale Größe der Protokolleinträge, die vom Panel heruntergeladen werden können",
    "lbl_max_nesting_group_level": "Maximale Verschachtelungsgruppenebene",
    "lbl_count_of_Web_token_which_will_be_closed": "Eine Anzahl der Web-Socket-Sitzungen, die geschlossen werden",
    "lbl_timeout_for_external_async_request": "Zeitüberschreitung für externe asynchrone Anfrage",
    "lbl_pattern_for_refer_header": "Muster für den Refer-Header",
    "heading_Support_actions": "Unterstützen Sie Aktionen",
    "lbl_User_e-mail": "Benutzer Email",
    "lbl_Action": "Aktion",
    "btn_Startaction": "Aktion starten",
    "heading_Download_Reports": "Berichte herunterladen",
    "lbl_Installer_report": "Installateurbericht",
    "lbl_Download": "Herunterladen",
    "lbl_App_userReport": "App-Benutzerbericht",
    "lbl_Managed_panelsreport": "Bericht über verwaltete Panels",
    "lbl_Unmanaged_panels_report": "Bericht über nicht verwaltete Panels",
    "lbl_ARC_report": "ARC-Bericht",
    "heading_confirmation": "Bestätigung",
    "msg_for_Pairing": "Dadurch werden alle Benutzerinformationen (Kopplung, mobile Geräte) entfernt und er kann nicht mehr darauf zugreifen",
    "msg_for_remove_pariring": "Sind Sie sicher, dass Sie die Kopplung zwischen den Geräten aufheben möchten?",
    "msg_for_disable_registration": "Sind Sie sicher, dass Sie die Registrierungen in der Cloud und in der App vorübergehend deaktivieren möchten?",
    "lbl_User_Info": "Benutzerinformation",
    "error_pwd_info_capital": "Bitte verwenden Sie Großbuchstaben (X,Y,Z...),",
    "error_pwd_small": "Kleinbuchstaben (x,y,z...),",
    "error_pwd_number": "Zahlen (1,2,3,4...),",
    "error_pwd_special": "und Sonderzeichen (!@#$%^&*) im Passwort im Passwort",
    "error_pwd_length": "Die Länge des Passworts sollte nicht weniger als 6 Zeichen betragen.",
    "lbl_installer_Details": "Details zum Installationsprogramm",
    "lbl_error_for_Date_Range": "Kein passendes Ergebnis für den ausgewählten Datumsbereich",
};
export default resources;