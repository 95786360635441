/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SortListMenuProps } from './SortListMenuTypes';
import { ListItemIcon } from '@mui/material';
import { CheckBox } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchPanelsAndEstates } from '../../redux/actions/panelEstateActions';
import { useTranslation } from 'react-i18next';

export default function SortListMenu({setSortListType,anchorEl,open,setAnchorEl}:SortListMenuProps):JSX.Element {
    const { t } = useTranslation();
    const [sortIconArray, setSortIconArray] = React.useState([false,false,false,false,false])
    const dispatch = useTypedDispatch()
  const handleClose = ():any => {
    setAnchorEl(null);
  };
  const handleNameAToZClose = ():any => {
    if(sortIconArray[0]===false){
        setSortIconArray([true,false,false,false,false])
        setAnchorEl(null);
        setSortListType('A-Z')
    }
    else{
        setSortIconArray([false,false,false,false,false])
        setAnchorEl(null);
        setSortListType('')
        dispatch(fetchPanelsAndEstates())
    }
  };
  const handleNameZToAClose = ():any => {
    if(sortIconArray[1]===false){
        setSortIconArray([false,true,false,false,false])
        setAnchorEl(null);
        setSortListType('Z-A')
    }
    else{
        setSortIconArray([false,false,false,false,false])
        setAnchorEl(null);
        setSortListType('')
        dispatch(fetchPanelsAndEstates())
    }
  };

  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={handleNameAToZClose}>
            <ListItemIcon>
               {sortIconArray[0] && <CheckIcon /> } 
            </ListItemIcon>
            {t('lbl_nameAZ')}
            </MenuItem>
        <MenuItem onClick={handleNameZToAClose}>
            <ListItemIcon>
            {sortIconArray[1] && <CheckIcon /> } 
            </ListItemIcon>
            {t('lbl_nameZA')}
            </MenuItem>
        {/* <MenuItem disabled onClick={handleClose}>
            <ListItemIcon>
            {sortIconArray[2] && <CheckIcon /> } 
            </ListItemIcon>
            {t('lbl_mostAlarms')}</MenuItem>
        <MenuItem disabled onClick={handleClose}>
        <ListItemIcon>
            {sortIconArray[3] && <CheckIcon /> } 
            </ListItemIcon>
            {t('lbl_fewerAlarms')}</MenuItem>
        <MenuItem disabled onClick={handleClose}>
        <ListItemIcon>
            {sortIconArray[4] && <CheckIcon /> } 
            </ListItemIcon>
            {t('lbl_updatedRecent')}</MenuItem> */}
      </Menu>
    </div>
  );
}
