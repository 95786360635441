/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import AddressDetails from './AddressDetails';
import CreateAccount from './CreateAccount';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LicenseAgreement from './LicenseAgreement';
import CreatePassword from './CreatePassword';
import ContactDetails from './ContactDetails';
import './register.css'
import { fetchCountries, fetchLicenseAgreement, fetchRegisterInstallerUser } from '../../redux/actions/registerScreenActions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function RegistrationScreen():JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate()
  const dispatch = useTypedDispatch()
  const [activeStep, setActiveStep] = React.useState(0);
  const [email, setEmail] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPwd, setConfirmPwd] = React.useState('')
  const [spokenPwd, setSpokenPwd] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [language, setLanguage] = React.useState('en_US')
  const [address, setAddress] = React.useState('')
  const [postCode, setPostCode] = React.useState('')
  const [firstPhoneNumber, setFirstPhoneNumber] = React.useState('')
  const [firstPhoneType, setFirstPhoneType] = React.useState('OFFICE')
  const [secondPhoneNumber, setSecondPhoneNumber] = React.useState('')
  const [secondPhoneType, setSecondPhoneType] = React.useState('HOME')
  const [thirdPhoneNumber, setThirdPhoneNumber] = React.useState('')
  const [thirdPhoneType, setThirdPhoneType] = React.useState('MOBILE')
  const [emailVerify, setEmailVerify] = React.useState(false)

  const { t } = useTranslation();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep === 3){
      dispatch(fetchCountries())
    }
  };

  const isValidEmail = (mail:string) => {
    const isValid = /\S+@\S+\.\S+/.test(mail);
    return isValid
  }

  const isValidPwd = (pwd:string) => {
    const valid = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(pwd)
    return valid
  }

  const isValidNumber = (text:string) => {
    const valid = /^\d+$/.test(text)
    return valid
  }

  const steps = [
    {
      component:<LicenseAgreement />
    },
    {
      component:<CreateAccount setEmailVerify={setEmailVerify} email={email} setEmail={setEmail} firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} />
    },
    {
      component:<CreatePassword password={password} setPassword={setPassword} confirmPwd={confirmPwd} setConfirmPwd={setConfirmPwd} spokenPwd={spokenPwd} setSpokenPwd={setSpokenPwd} />
    },
    {
      component:<AddressDetails
                  companyName={companyName}
                  country={country}
                  language={language}
                  address={address}
                  postCode={postCode}
                  setCompanyName={setCompanyName}
                  setCountry={setCountry}
                  setLanguage={setLanguage}
                  setAddress={setAddress}
                  setPostCode={setPostCode}
                  />
    },
    {
      component:<ContactDetails
                  firstPhoneNumber={firstPhoneNumber}
                  firstPhoneType={firstPhoneType}
                  secondPhoneNumber={secondPhoneNumber}
                  secondPhoneType={secondPhoneType}
                  thirdPhoneNumber={thirdPhoneNumber}
                  thirdPhoneType={thirdPhoneType}
                  setFirstPhoneNumber={setFirstPhoneNumber}
                  setFirstPhoneType={setFirstPhoneType}
                  setSecondPhoneNumber={setSecondPhoneNumber}
                  setSecondPhoneType={setSecondPhoneType}
                  setThirdPhoneNumber={setThirdPhoneNumber}
                  setThirdPhoneType={setThirdPhoneType}
                  />
    },
  ];

  React.useEffect(() => {
    if(activeStep === 0){
    dispatch(fetchLicenseAgreement())
    }
    if(activeStep === 3){
      dispatch(fetchCountries())
    }

  }, [activeStep]);
  
  const handleClose = () => {
    //setOpen(false);
    setActiveStep(0);
    navigate('/')
  }

  const handleSave = async() => {
      const body = {
          accountType: 'ENABLED',
          firstName: firstName,
          installerInfo: {
              address: { country: country, address: address, postCode: postCode },
              email:email,
              firstPhoneNumber:{number:firstPhoneNumber,type:firstPhoneType},
              installerCompanyName:companyName,
              installerPrimaryContact:'firstPhoneNumber',
              secondPhoneNumber:{number:secondPhoneNumber,type:secondPhoneType},
              spokenPassword:spokenPwd,
              thirdPhoneNumber:{number:thirdPhoneNumber,type:thirdPhoneType}
          },
          lastName: lastName,
          locale: language,
          password: password,
          userType: 'INSTALLER',
      };
     await dispatch(fetchRegisterInstallerUser(body))

      navigate('/register-confirmation');
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
};
  

  return (
    <div id='main'>
        <Box sx={md?
       { position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100vw',
        height:'100dvh',
        backgroundColor: 'background.paper',
        boxShadow: 20,
        p: 0,}
           :style} >
            {steps[activeStep].component}
            <Divider />
        <MobileStepper
      variant="dots"
      steps={5}
      position="static"
      activeStep={activeStep}
      sx={ md
        ? {
              zIndex: 1000,
              position: 'fixed',
              top: '100dvh',
              transform: 'translateY(-100%)',
              width: '100%',
              p: 2,
              backgroundColor: 'inherit',
          }
        : 
        { maxWidth: 400, flexGrow: 1,paddingTop:2, paddingBottom:2, paddingLeft:3, paddingRight:3, backgroundColor:'inherit' }}
      nextButton={
        activeStep < 4 ? 
        (
        <Button size="medium" variant='contained'
        disabled={
          ((!emailVerify || email.length === 0 || !isValidEmail(email) || firstName.length < 2 || firstName.length > 30 || lastName.length < 2 || lastName.length > 30 ) && activeStep === 1) ||
          ((!isValidPwd(password) || spokenPwd.length <2 || spokenPwd.length > 30 || (password !== confirmPwd)) && activeStep === 2) ||
          ((companyName.length < 2 || country.length === 0 || address.length === 0 || postCode.length === 0) && activeStep === 3)
        }
         onClick={handleNext} >
        {t('btn_next')}
      </Button>
      ):(
      <Button size="medium" variant='contained'
      disabled={!isValidNumber(firstPhoneNumber)}
       onClick={handleSave}>
          {'Register'}
        </Button>
        
        )
      }
      backButton={
        activeStep === 0 ? (
          <Button size="small" variant="outlined" onClick={handleClose}>
             {t('btn_back')}
          </Button>
      ) : (
          <Button size="medium" variant="outlined" onClick={handleBack}>
              {t('btn_back')}
          </Button>
      )
      }
    />
    </Box>    
    </div>
  );
}
