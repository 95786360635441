export const AdminActionTypes = {
    SET_ADMINS : 'SET_ADMINS',
    SET_ADMINS_LENGTH:'SET_ADMINS_LENGTH',
    SET_ADD_ADMINS:'SET_ADD_ADMINS',
    SET_EDIT_ADMINS:'SET_EDIT_ADMINS',
    SET_DELETE_ADMINS:'SET_DELETE_ADMINS',
    SET_ADMINS_ACCOUNTS_LOG:'SET_ADMINS_ACCOUNTS_LOG',
    SET_ADMIN_ACCOUNT_SEARCH_LIST_LENGTH: 'SET_ADMIN_ACCOUNT_SEARCH_LIST_LENGTH',
    SET_ALL_ACCOUNT_LOG_DETAILS:'SET_ALL_ACCOUNT_LOG_DETAILS',
    SET_SEARCHED_ACCOUNT_LOGS:'SET_SEARCHED_ACCOUNT_LOGS',
}