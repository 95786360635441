/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ARCAccessProps } from './ARCAccessProps';
import ARCAccessPanel from './ARCAccessPanel';
import {
    fetchPanelARCSettings,
    fetchReportGroups,
    fetchSpecificPanelAndEstate,
    panelArcAccessConnectivityCheckAndEnable,
    panelArcAccessEnabled,
} from '../../redux/actions/specificPanelActions';
import SiaEvents from './SiaEvents';
import { useParams } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';
import Loader from '../CircularLoader/Loader';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    overFlow: '',
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function ARCAccessModal({refreshArcModal, setRefreshArcModal, open, setOpen, setArcModalEnableTrigger, setIsARCEdit, isARCEdit }: ARCAccessProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { id } = useParams();
    const { specificPanel, panelARCSettings, reportGroups, arcEnableStatus, arcConnectivityStatus } = useSelector(
        (state: any) => state.specificPanelAndEstateReducer
    );
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [snackConnectivityOpen, setSnackConnectivityOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [connectivityMessage, setConnectivityMessage] = React.useState('');
    const [availableGroups, setAvailableGroups] = React.useState<string[]>([]);
    const [nextClick, setNextClick] = React.useState<boolean>();
    const [count, setCount] = React.useState(0);
    const { t, i18n } = useTranslation();

    const [arcProvider, setArcProvider] = React.useState(panelARCSettings.arcProvider);
    const [arcName, setArcName] = React.useState(panelARCSettings.serverName);
    const [accountNumber, setAccountNumber] = React.useState(panelARCSettings.accountNumber);
    const [siaIpVersion, setSiaIpVersion] = React.useState(panelARCSettings.siaVersion);
    const [primaryIp, setPrimaryIp] = React.useState(panelARCSettings.primaryIp);
    const [secondaryIp, setSecondaryIp] = React.useState(panelARCSettings.secondaryIp);
    const [primaryPort, setPrimaryPort] = React.useState<number | null>(panelARCSettings.primaryPort);
    const [secondaryPort, setSecondaryPort] = React.useState<number | null>(panelARCSettings.secondaryPort);
    const [encryption, setEncryption] = React.useState(panelARCSettings.encryption);
    const [aesKey, setAesKey] = React.useState(panelARCSettings?.aesKey);
    const [reportEmail, setReportEmail] = React.useState(panelARCSettings.reportEmail);
    const [country, setCountry] = React.useState<string>(panelARCSettings?.country);
    const [arc, setArc] = React.useState<string>(panelARCSettings?.arcCode);
    const [accNumber, setAccNumber] = React.useState(panelARCSettings?.accountNumber);
    const [dataLoaded, setDataLoaded] = React.useState(true);
    let body :any = {}
    const dispatch = useTypedDispatch();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setNextClick(true);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = [
        {
            component: (
                <ARCAccessPanel
                    arcProvider={arcProvider}
                    setArcProvider={setArcProvider}
                    setArcName={setArcName}
                    setAccountNumber={setAccountNumber}
                    siaIpVersion={siaIpVersion}
                    setSiaIpVersion={setSiaIpVersion}
                    setPrimaryIp={setPrimaryIp}
                    setSecondaryIp={setSecondaryIp}
                    setPrimaryPort={setPrimaryPort}
                    setSecondaryPort={setSecondaryPort}
                    encryption={encryption}
                    setEncryption={setEncryption}
                    setReportEmail={setReportEmail}
                    panelARCSettings={panelARCSettings}
                    arcName={arcName}
                    accountNumber={accountNumber}
                    primaryIp={primaryIp}
                    secondaryIp={secondaryIp}
                    primaryPort={primaryPort}
                    secondaryPort={secondaryPort}
                    reportEmail={reportEmail}
                    aesKey={aesKey}
                    setAesKey={setAesKey}
                    country = {country}
                    setCountry={setCountry}
                    arc={arc}
                    setArc={setArc}
                    accNumber={accNumber}
                    setAccNumber={setAccNumber}
                />
            ),
        },
        {
            component: (
                <SiaEvents
                    isARCEdit={isARCEdit}
                    nextClick={nextClick}
                    setNextClick={setNextClick}
                    panelARCSettings={panelARCSettings}
                    reportGroups={reportGroups}
                    setAvailableGroups={setAvailableGroups}
                />
            ),
        },
    ];

    React.useEffect(() => {
        if (arcEnableStatus === 204 && arcConnectivityStatus === 204 ) {
            if (isARCEdit) {
                setMessage(`${t('msg_ARC_edited')}`);
            } else {
                setMessage(`${t('msg_ARC_enabled')}`);
            }
        } 
        else if(arcConnectivityStatus !== 204){
            setMessage('Connection invalid')
        }
        else {
            setMessage(`${t('msg_error')}`);
        }
        
        const apiCalls = async() => {
        await dispatch(fetchReportGroups());
        await dispatch(fetchSpecificPanelAndEstate(Number(id)));
        await dispatch(fetchPanelARCSettings(Number(id)));
        }
        apiCalls();
        setArcProvider(panelARCSettings? panelARCSettings.arcProvider : '')
        setArcName(panelARCSettings? panelARCSettings.serverName : '')
        setAccountNumber(panelARCSettings? panelARCSettings.accountNumber : '')
        setSiaIpVersion(panelARCSettings? panelARCSettings.siaVersion : '')
        setPrimaryIp(panelARCSettings? panelARCSettings.primaryIp : '')
        setSecondaryIp(panelARCSettings? panelARCSettings.secondaryIp : '')
        setPrimaryPort(panelARCSettings? panelARCSettings.primaryPort : '')
        setSecondaryPort(panelARCSettings? panelARCSettings.secondaryPort : '')
        setEncryption(panelARCSettings? panelARCSettings.encryption : '')
        setReportEmail(panelARCSettings? panelARCSettings.reportEmail : '')
        setAesKey(panelARCSettings? panelARCSettings.aesKey : '')
        setCountry(panelARCSettings? panelARCSettings.country :'')
        setArc(panelARCSettings? panelARCSettings.arcCode :'')
        setAccNumber(panelARCSettings? panelARCSettings.accountNumber :'')
        if(refreshArcModal){
            setArcProvider('')
        setArcName('')
        setAccountNumber('')
        setSiaIpVersion('')
        setPrimaryIp('')
        setSecondaryIp('')
        setPrimaryPort(null)
        setSecondaryPort(null)
        setEncryption('')
        setReportEmail('')
        setAesKey('')
        setCountry('')
        setArc('')
        setAccNumber(null)
        setRefreshArcModal(false)
        }
    }, [count,open,arcConnectivityStatus]);

    const handleClose = () => {
        setArcProvider('')
        setArcName('')
        setAccountNumber('')
        setSiaIpVersion('')
        setPrimaryIp('')
        setSecondaryIp('')
        setPrimaryPort(null)
        setSecondaryPort(null)
        setEncryption('')
        setReportEmail('')
        setAesKey('')
        setCountry('')
        setArc('')
        setAccNumber(null)
        setOpen(false);
        setActiveStep(0);
    };

    const handleSave = async () => {
    if(arcProvider === 'SIA_IP'){
        if(encryption === 'ENC_NONE')
        {
         body = {
            accountNumber: accountNumber,
            arcCode: '',
            arcProvider: arcProvider,
            availableGroups: availableGroups,
            encryption: encryption,
            primaryIp: primaryIp,
            primaryPort: primaryPort,
            reportEmail: reportEmail,
            secondaryIp: secondaryIp,
            secondaryPort: secondaryPort,
            serverName: arcName,
            siaVersion: siaIpVersion,
            transmitterId: 'SIA-DCS',
            };
        }
        else {
            body = {
                accountNumber: accountNumber,
                arcCode: '',
                aesKey : aesKey,
                arcProvider: arcProvider,
                availableGroups: availableGroups,
                encryption: encryption,
                primaryIp: primaryIp,
                primaryPort: primaryPort,
                reportEmail: reportEmail,
                secondaryIp: secondaryIp,
                secondaryPort: secondaryPort,
                serverName: arcName,
                siaVersion: siaIpVersion,
                transmitterId: 'SIA-DCS',
                };
        }
    }
    else{
        body={
            accountNumber: accNumber,
            arcCode: arc,
            arcProvider: arcProvider,
            availableGroups: availableGroups,
        }
    }
        setActiveStep(0);
        setOpen(false);
        setDataLoaded(false);
        await dispatch(panelArcAccessConnectivityCheckAndEnable(specificPanel.panelId, body));
        setDataLoaded(true);
        setMessage(`${t('msg_ARC_updated')}`);
        setArcProvider('')
        setArcName('')
        setAccountNumber('')
        setSiaIpVersion('')
        setPrimaryIp('')
        setSecondaryIp('')
        setPrimaryPort(null)
        setSecondaryPort(null)
        setEncryption('')
        setReportEmail('')
        setAesKey('')
        setSnackOpen(true);
        //setSnackConnectivityOpen(true);
        setCount((prev) => prev + 1);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };
    const handleConnectivitySnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackConnectivityOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );
    const connectAction = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleConnectivitySnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {!dataLoaded && (
                        <Loader />
                         )}
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: activeStep === 1 ? 13 : activeStep === 0 ? 43 : 16 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('tooltip_ARC_Access')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <Paper>
                                <MobileStepper
                                    variant="dots"
                                    steps={2}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        zIndex: 1000,
                                        position:'fixed',
                                        top: '93vh',
                                        transform: 'translateY(-100%)',
                                        width: '100%',
                                        p:2,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        activeStep < 1 ? (
                                            <Button disabled={arcProvider === 'SIA_IP'? (arcProvider === '' || arcName === '' || accountNumber === '' || siaIpVersion === '' || primaryIp === '' || secondaryIp === '' || primaryPort === null || secondaryPort === null || encryption === '' || reportEmail === '') : (country === '' || arc === '' ) } size="medium" variant="contained" onClick={handleNext}>
                                                {t('btn_next')}
                                            </Button>
                                        ) : (
                                            <Button size="medium" variant="contained" onClick={handleSave}>
                                                {t('btn_save')}
                                            </Button>
                                        )
                                    }
                                    backButton={
                                        activeStep < 1 ? (
                                            <Button size="small" variant="outlined" onClick={handleClose}>
                                                {t('btn_cancel')}
                                            </Button>
                                        ) : (
                                            <Button size="medium" variant="outlined" onClick={handleBack}>
                                                {t('btn_back')}
                                            </Button>
                                        )
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('tooltip_ARC_Access')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{ maxHeight: 400, overflow: 'auto' }}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={2}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 400,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    activeStep < 1 ? (
                                        <Button disabled={ arcProvider === 'SIA_IP'? (arcProvider === '' || arcName === '' || accountNumber === '' || siaIpVersion === '' || primaryIp === '' || secondaryIp === '' || primaryPort === null || secondaryPort === null || encryption === '' || reportEmail === '') : (country === '' || arc === '' )} size="medium" variant="contained" onClick={handleNext}>
                                            {t('btn_next')}
                                        </Button>
                                    ) : (
                                        <Button size="medium" variant="contained" onClick={handleSave}>
                                            {t('btn_save')}
                                        </Button>
                                    )
                                }
                                backButton={
                                    activeStep < 1 ? (
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    ) : (
                                        <Button size="medium" variant="outlined" onClick={handleBack}>
                                            {t('btn_back')}
                                        </Button>
                                    )
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                    <Snackbar
                        open={snackConnectivityOpen}
                        autoHideDuration={6000}
                        onClose={handleConnectivitySnackClose}
                        message={connectivityMessage}
                        action={connectAction}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
