/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { InputAdornment, MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EditUserFormProps } from './editUserModalTypes';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useTranslation, Trans } from 'react-i18next';

export default function EditUserForm({
    firstName,
    lastName,
    login,
    userType,
    setFirstName,
    setLastName,
    setLogin,
    setUserType,
    notification,
    setNotification
}: EditUserFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const userTypes = [
        {
            code: 'ENGINEER',
            value: 'Engineer',
        },
        {
            code: 'MANAGER',
            value: 'Manager',
        },
        {
            code: 'OFFICE_STAFF',
            value: 'Office Staff',
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: 450,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={md ? 12 : 6}>
                    <TextField inputProps={{ maxLength: 24 }} value={firstName} fullWidth id="filled-basic-first" label={t('lbl_firstName')} variant="filled" onChange={(e) => setFirstName(e.target.value)} />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField inputProps={{ maxLength: 24 }} value={lastName} fullWidth id="filled-basic-last" label={t('lbl_lastName')} variant="filled" onChange={(e) => setLastName(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField inputProps={{ maxLength: 48 }} value={login} fullWidth id="filled-basic-login" label={t('lbl_login')} variant="filled" onChange={(e) => setLogin(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField inputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <ArrowDropDownIcon />
                            </InputAdornment>
                        )
                    }} 
                    value={userType === 'ENGINEER' ? `${t('lbl_engineer')}` : userType === 'MANAGER' ? `${t('lbl_manager')}` : userType === 'OFFICE_STAFF'? `${t('lbl_officeStaff')}`:`${t('lbl_installer')}` } 
                    disabled={true} fullWidth id="filled-basic" label="Type User" variant="filled" 
                    onChange={(e) => setUserType(e.target.value)} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle2">{t('lbl_emailNotifications')}</Typography>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={2}>
                    <Switch checked={notification} onChange={(e) => setNotification(e.target.checked)} sx={{ mt: -1 }} inputProps={{ 'aria-label': 'controlled' }} />
                </Grid>
            </Grid>
        </Box>
    );
}
