/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  password:string
  setPassword:(password:string) => void
  confirmPwd:string
  setConfirmPwd:(confirmPwd:string) => void
  spokenPwd:string
  setSpokenPwd:(spokenPwd:string) => void
}

export default function CreatePassword({password,setPassword,confirmPwd,setConfirmPwd,spokenPwd,setSpokenPwd}:Props):JSX.Element {
  //const [confirmPwd, setConfirmPwd] = React.useState('')
  const [helperText, setHelperText] = React.useState('')
  const [helperTextPwd, setHelperTextPwd] = React.useState('')
  const [spokenHelperText, setSpokenHelperTest] = React.useState('')
  const { t, i18n } = useTranslation();

  const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPwd(e.target.value)
    if(e.target.value === password || password.length === 0){
        setHelperText('')
    }
    else{
        setHelperText(`Unmatched passwords!`)
    }
  }

  const isValidPwd = (pwd:string) => {
    const valid = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(pwd)
    return valid
  }

  const handlePwd = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPassword(e.target.value);
      if(e.target.value.length >= 8){
        if (isValidPwd(e.target.value)){
          setHelperTextPwd('');
        }
        else{
          setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
        }
      }
      else if(e.target.value.length > 0 && e.target.value.length < 8){
        setHelperTextPwd(`Please use uppercase letters (X,Y,Z...), lowercase letters (x,y,z...), numbers (1,2,3,4...) and special characters (!"'#$%&*()+,-.:;<=>?@[]^_{|}~) in the password`)
      }
      else{
        setHelperTextPwd('This field is required')
      }
  };

  return (
      <div>
        <Paper elevation={0} sx={{ paddingTop:2, paddingBottom:3, paddingLeft:3, paddingRight:2 }}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant='h6'>{t('lbl_createPwd')}</Typography>
              <Typography variant='body1'>{t('txt_pwdLength')}</Typography>
              </Stack>
        </Paper>
            <Divider />
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" 
                      label={t('lbl_pwd')} variant="filled" 
                      value={password} onChange={(e) => {
                       handlePwd(e)
                      }}
                      helperText={helperTextPwd}
                      error={helperTextPwd.length > 0}
                      type='password'
                     />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" 
                      label={t('lbl_cnfrmPwd')} variant="filled" 
                      helperText={helperText}
                      error={helperText.length > 0 && password.length > 0}
                      value={confirmPwd} onChange={(e) => handleRepeatPassword(e)}
                      type='password'
                       />
              </Grid>
              <Grid item xs={12}>
                <TextField inputProps={{maxLength : 30, minLength:2}} fullWidth id="filled-basic" 
                      label={t('lbl_spoken_pwd')} variant="filled" 
                      helperText={spokenHelperText}
                      error={spokenHelperText.length > 0}
                      value={spokenPwd} onChange={(e) => {
                        setSpokenPwd(e.target.value)
                        if(e.target.value.length === 0){
                          setSpokenHelperTest('This field is required')
                        }
                        else if(e.target.value.length >= 1 && e.target.value.length <= 2 ){
                          setSpokenHelperTest('Input length should not be less than 2 characters')
                        }
                        else if(e.target.value.length >= 30){ 
                          setSpokenHelperTest('Input length should not be more than 30 characters')
                        }
                        else{
                          setSpokenHelperTest('')
                        }
                      }}
                       />
              </Grid>
            </Grid>
            </Box>
            </div>
  );
}
