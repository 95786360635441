/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Button,
    Divider,
    Grid,
    IconButton,
    MobileStepper,
    Paper,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoteServicingEditForm from './RemoteServicingEditForm';
import { RemoteServiceModalProps } from './remoteServicingTypeProps';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchARDscheduler, performARDScheduler, performRemoteServicing, setRemoteServicingEnabled } from '../../redux/actions/remoteServicingActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function RemoteServiceModal({type, setCounter, counter, open, setOpen }: RemoteServiceModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { performRemoteServicingStatus, enabled, scheduler, schedulerStatus } = useSelector((state: any) => state.remoteServicingReducer)
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer)
    const dispatch = useTypedDispatch();
    const [activeStep, setActiveStep] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [intervalInMonths, setIntervalInMonths] = React.useState<number | null>(scheduler.intervalInMonths)
    const [serviceDate, setServiceDate] = React.useState<string>(scheduler.nextServiceDate)
    const { t } = useTranslation();
    const [message, setMessage] = React.useState<string>(`${t('msg_remote_srvcng_enable')}`);
    const [save, setSave] = React.useState(false);
    const [savePanelInstallerMode, setSavePanelInstallerMode] = React.useState(false);

    React.useEffect(() => {
        if(savePanelInstallerMode){
            setSnackOpen(true)
            setMessage('Unable to proceed, the panel is in installer mode')
            setSavePanelInstallerMode(false)
            setSave(false)
        }
        
        setIntervalInMonths(scheduler?.intervalInMonths)
        setServiceDate(scheduler?.nextServiceDate)
     }, [open,scheduler,save]);

    const handleClose = () => {
        setActiveStep(0);
        setOpen(false);
    };

    const handleSave = async() => {
        if(enabled?.enabled === 'installer'){
            setSavePanelInstallerMode(true)
            setSave(true);
            setOpen(false);
        }
        else{

        // If ARD is being enabled, must enable prior to submitting scheduler settings.
        if(enabled.enabled === false) {
            await dispatch(setRemoteServicingEnabled(specificPanel?.panelId,{enabled:true}));
        }

        const schedulerParams = {
            desiredServiceMonthDay:scheduler.desiredServiceMonthDay,
            panelId:specificPanel?.panelId,
            nextServiceDate:Number(serviceDate.replaceAll('-','')),
            intervalInMonths:intervalInMonths,
        }
        await dispatch(performARDScheduler(specificPanel?.panelId, schedulerParams));
        
        setCounter(counter + 1);
        setSave(true);
        setOpen(false);
    }
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('tooltip_remoteServicing')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                <RemoteServicingEditForm 
                                intervalInMonths={intervalInMonths} 
                                setIntervalInMonths={setIntervalInMonths}
                                serviceDate={serviceDate}
                                setServiceDate={setServiceDate}
                                />
                            </Paper>

                            <Paper
                                sx={{
                                    position: 'absolute' as 'absolute',
                                    top: '100dvh',
                                    transform: 'translateY(-100%)',
                                    width: '100%',
                                }}
                            >
                                <Divider />
                                <MobileStepper
                                    variant="dots"
                                    steps={0}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        zIndex: 1000,
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 4,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        <Button size="medium" variant="contained" onClick={handleSave} 
                                        disabled={ intervalInMonths === 0 }
                                        >
                                            {t('btn_save')}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('tooltip_remoteServicing')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                <RemoteServicingEditForm 
                                intervalInMonths={intervalInMonths} 
                                setIntervalInMonths={setIntervalInMonths}
                                serviceDate={serviceDate}
                                setServiceDate={setServiceDate}
                                />
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    zIndex: 1000,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button size="medium" variant="contained" onClick={handleSave}
                                    disabled={ intervalInMonths === 0 }
                                    >
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="medium" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
