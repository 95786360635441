/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { Divider, Paper, Stack, Typography } from '@mui/material';
import { fetchLicenseAgreement } from '../../redux/actions/registerScreenActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function LicenseAgreement():JSX.Element { 
  const dispatch = useTypedDispatch()
  const {license} = useSelector((state:any) => state.registerScreenReducer)
  const { t, i18n } = useTranslation();

  useEffect(() => {
    
  },[])
 
  return (
      <div>
        <Paper elevation={0} sx={{ paddingTop:2, paddingBottom:3, paddingLeft:3, paddingRight:2 }}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant='h6'>{t('lbl_licenseAgreememnt')}</Typography>
              <Typography variant='body1'>{t('lbl_licenseAgreememntDetails')}</Typography>
              </Stack>
        </Paper>
            <Divider />
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3,maxHeight:'50vh',overflow:'auto' }}>
            <Typography variant='body2'>
              {license?.value !== undefined &&
                license?.value
              }
              <><br/></>
              <><br/></>
              Download Files:<><br/></>
              Privacy Notice: <a href="https://notices.eatonsecureconnect.com/SecureConnectPrivacyNotice.pdf" rel="noreferrer" target='_blank'>SecureConnectPrivacyNotice.pdf</a><><br/></>
              This Terms of Use: <a href="https://notices.eatonsecureconnect.com/SecureConnectTermsOfUse.pdf" rel="noreferrer" target='_blank'>SecureConnectTermsOfUse.pdf</a>
            </Typography>
            </Box>
            </div>
  );
}
