/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import { ReportGroupsProps, SiaEventProps } from './ARCAccessProps';
import { Paper, Typography } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

export default function SiaEvents({
    isARCEdit,
    nextClick,
    setNextClick,
    panelARCSettings,
    reportGroups,
    setAvailableGroups,
}: SiaEventProps): JSX.Element {
    //const { panelARCSettings, reportGroups } = useSelector((state:any) => state.specificPanelAndEstateReducer)
    const [checkedList, setCheckedList] = React.useState<boolean[]>([]);
    const [siaEvents, setSiaEvents] = React.useState<string[]>([]);
    checkedList.length = reportGroups.length;
    siaEvents.length = reportGroups.length;
    const { t, i18n } = useTranslation();

    const isEmptyObject = (obj:any):any => {
        if(typeof obj === 'object' && obj !== null && Object.keys(obj).length !== 0){
            return false;
        } 
        
        return true;
        
    }


    
    if(nextClick === true ){
        if(!isEmptyObject(panelARCSettings)){
            if(isARCEdit){
            for (let i = 0; i < reportGroups.length; i++) {
                for (let j = 0; j < panelARCSettings?.availableGroups.length; j++) {
                    if (reportGroups[i].customReportGroup === panelARCSettings?.availableGroups[j]) {
                        checkedList[i] = true;
                        break;
                    } else checkedList[i] = false;
                }
            }
        }
        else{
            for (let i = 0; i < reportGroups.length; i++) {
                checkedList[i] = true;
            }
        }
            setNextClick(false);
        }
    else{
        for (let i = 0; i < reportGroups.length; i++) {
                    checkedList[i] = true;
                }
                setNextClick(false);
    }
}

    React.useEffect(() => {
        for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i] === true) {
                siaEvents[i] = reportGroups[i].customReportGroup;
            } else {
                siaEvents[i] = '';
            }
        }
        const list = siaEvents.filter((sia) => sia.length > 0);
        setAvailableGroups(list);
    }, [...checkedList]);

    const handleClick = (key: number): any => {
        const openListOne = checkedList.slice(0, key);
        const openListTwo = checkedList.slice(key + 1, checkedList.length);
        const openListThree = !checkedList[key];
        setCheckedList([...openListOne, openListThree, ...openListTwo]);
    };

    return (
        <Paper sx={{ pl: 2 }}>
            <Typography variant="subtitle1" color="#007BC1">
                {t('lbl_SiaEvnts')}
            </Typography>
            <FormGroup>
                {reportGroups?.map((report: ReportGroupsProps, key: number) => (
                    <FormControlLabel
                        key={key}
                        control={<Checkbox onClick={(): any => handleClick(key)} checked={checkedList[key]} />}
                        label={report.description}
                    />
                ))}
            </FormGroup>
        </Paper>
    );
}
