/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */

const d = new Date();
//const diff = d.getTimezoneOffset();
const diff = 0

export const capitalizeAlarmType = (alarmType:string):string => {
    const str = alarmType;
    const str2 = str.charAt(0).toUpperCase() + str.slice(1);

    return str2
}
export const getHours = (timestamp: number): string => {
    const dd = new Date(new Date(timestamp).toUTCString())
    //const hours = dd.toString().split(' ')[4].split(':')[0]
    const hours = (`0${dd.getUTCHours().toString()}`).slice(-2)
    return hours;
};

export const getMinutes = (timestamp: number): string => {

    const dd = new Date(new Date(timestamp))
   // const minutes = dd.toString().split(' ')[4].split(':')[1]
    const minutes = (`0${dd.getUTCMinutes().toString()}`).slice(-2)
    return minutes;
};
export const getSeconds = (timestamp: number): string => {

    const dd = new Date(new Date(timestamp))
   // const minutes = dd.toString().split(' ')[4].split(':')[1]
    const seconds = (`0${dd.getUTCSeconds().toString()}`).slice(-2)
    return seconds;
};

export const getAMorPM = (timestamp: number): string => {
    const dd = new Date(new Date(timestamp))
    const dayType = dd.toLocaleString().split(' ')[2]
    return dayType;
};

export const getDate = (timestamp: number): string => {
    const date = new Date(new Date((timestamp)+(diff*60*1000)).toUTCString()).getUTCDate() > 9 ? (new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()):(`0${new Date(new Date(timestamp).toUTCString()).getUTCDate().toString()}`) ;

    return date;
};
export const getMonth = (timestamp: number): string => {
    const month = new Date(new Date((timestamp)+(diff*60*1000)).toUTCString()).getUTCMonth() + 1 >9 ? (new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString() : `0${(new Date(new Date(timestamp).toUTCString()).getUTCMonth() + 1).toString()}`

    return month;
};
export const getYear = (timestamp: number): number => {
    const year = new Date(new Date((timestamp)+(diff*60*1000)).toUTCString()).getUTCFullYear();

    return year;
};
export const getDateFormat = (timestamp: number):string =>{
    const dateFormat = `${getDate(timestamp).toString()}/${getMonth(timestamp).toString()}/${getYear(timestamp).toString()}`

    return dateFormat
}
export const getTimeFormat = (timestamp: number):string =>{
    const timeFormat = `${getHours(timestamp).toString()}:${getMinutes(timestamp).toString()}:${getSeconds(timestamp).toString()}`
    //${getAMorPM(timestamp).toString()}

    return timeFormat
}




