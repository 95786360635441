/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import { CompanyInformationEditFormProps } from '../SettingsPropTypes';
import { useTranslation, Trans } from 'react-i18next';

export default function CompanyInformationEditForm({ firstName, setFirstName, lastName, setLastName, companyName, setCompanyName, firstPhoneNumber, setfirstPhoneNumber, secondPhoneNumber, setSecondPhoneNumber, thirdPhoneNumber, setThirdPhoneNumber, address, setAddress, postCode, setpostCode, country, setCountry, firstPhoneType, setfirstPhoneType, secondPhoneType, setSecondPhoneType, thirdPhoneType, setThirdPhoneType, countryList }: CompanyInformationEditFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t, i18n } = useTranslation();

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight: md ? '80vh' : 430,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic-first" label={t('lbl_compnyName')} value={companyName} onChange={(e) => setCompanyName(e.target.value)} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic-last" label={t('lbl_address')} value={address} onChange={(e) => setAddress(e.target.value)} variant="filled" />
                </Grid>
                <Grid item xs={12}>
                    <TextField fullWidth type={'email'} id="filled-basic-mail" label={t('lbl_postcode')} variant="filled" value={postCode} onChange={(e) => setpostCode(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField value={country} select fullWidth id="filled-basic-language" label={t('lbl_country')} variant="filled" onChange={(e) => setCountry(e.target.value)}>
                        {countryList.length !== 0 &&
                            countryList.map((countries: any) => (
                                <MenuItem key={countries.code} value={countries.name}>
                                    {countries.name}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_firstPhone')} value={firstPhoneNumber} onChange={(e) => setfirstPhoneNumber(e.target.value)} variant="filled" />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={firstPhoneType} id="filled-basic-spokenPassword" label={t('lbl_firstPhType')} onChange={(e) => setfirstPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_secondPhone')} value={secondPhoneNumber} onChange={(e) => setSecondPhoneNumber(e.target.value)} variant="filled" />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={secondPhoneType} id="filled-basic-spokenPassword" label={t('lbl_secondPhType')} onChange={(e) => setSecondPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth id="filled-basic-repeatPassword" label={t('lbl_thirdPhone')} value={thirdPhoneNumber} onChange={(e) => setThirdPhoneNumber(e.target.value)} variant="filled" />
                </Grid>
                <Grid item xs={md ? 12 : 6}>
                    <TextField fullWidth select value={thirdPhoneType} id="filled-basic-spokenPassword" label={t('lbl_thirdPhType')} onChange={(e) => setThirdPhoneType(e.target.value)} variant="filled" >
                        <MenuItem value="HOME">
                        {t('lbl_home')}
                        </MenuItem>
                        <MenuItem value="MOBILE">
                        {t('lbl_mobile')}
                        </MenuItem>
                        <MenuItem value="OFFICE">
                        {t('lbl_office')}
                        </MenuItem>
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    );
}
