/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { InfoListItem } from '@brightlayer-ui/react-components';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Theme,
    useMediaQuery,
    useTheme,
    Checkbox,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { AccountUsersProps, AccountUsersArrayProps, AccountUserListProps } from './AccountUsersTypes';
import EditIcon from '@mui/icons-material/Edit';
import { AccountUsersContext } from './AccountUsers';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchAvailableGroups, fetchUserGroups, getCurrentUserDetails } from '../../redux/actions/accountUsersActions';
import { useTranslation, Trans } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    })
);

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

export default function AccountUsersList({
    setEditUserModalOpen,
    setDeleteUserModalOpen,
    setUserEditDetails,
    setSelectedUserId,
}: AccountUserListProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    let accountUsersList: AccountUsersArrayProps = React.useContext(AccountUsersContext);
    const dispatch = useTypedDispatch();
    const [sortType, setSortType] = React.useState<string>('names');
    const [userNameOrder, setUserNameOrder] = React.useState<boolean>(false);
    const [userTypeOrder, setUserTypeOrder] = React.useState<boolean>(false);
    const [userEmailOrder, setUserEmailOrder] = React.useState<boolean>(false);
    const [notificationOrder, setNotificationOrder] = React.useState<boolean>(false);
    const { currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const { t } = useTranslation();
    const usersList = accountUsersList

    React.useEffect(() => {
        dispatch(getCurrentUserDetails());
    }, []);

    const handleUserEditButton = async (accountUser: any) => {
        await dispatch(fetchUserGroups(currentUserDetails?.ID));
        await dispatch(fetchAvailableGroups(accountUser.ID));
        setUserEditDetails(accountUser);
        setEditUserModalOpen(true);
    };

    const handleDeleteUser = (ID: number) => {
        setSelectedUserId(ID)
        setDeleteUserModalOpen(true);
    };

    const handleUserNamesOrder = () => {
        setSortType('names');
        setUserNameOrder(!userNameOrder);
    };

    const handleUserTypeOrder = () => {
        setSortType('userType');
        setUserTypeOrder(!userTypeOrder);
    };

    const handleUserEmailOrder = () => {
        setSortType('email');
        setUserEmailOrder(!userEmailOrder);
    };

    const handleNotificationOrder = () => {
        if (usersList.filter((user) => user.notificationEnabledUserArray === false).length === 0 || usersList.filter((user) => user.notificationEnabledUserArray === true).length === 0)
            setSortType('')
        else {
            setSortType('notification');
            setNotificationOrder(!notificationOrder);
        }
    };

    try {
        if (sortType === 'names') {
            if (!userNameOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.firstName.concat(a.lastName).toLowerCase() < b.firstName.concat(b.lastName).toLowerCase()) {
                        return -1;
                    }
                    if (a.firstName.concat(a.lastName).toLowerCase() > b.firstName.concat(b.lastName).toLowerCase()) {
                        return 1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
            if (userNameOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.firstName.concat(a.lastName).toLowerCase() < b.firstName.concat(b.lastName).toLowerCase()) {
                        return 1;
                    }
                    if (a.firstName.concat(a.lastName).toLowerCase() > b.firstName.concat(b.lastName).toLowerCase()) {
                        return -1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
        }
        if (sortType === 'userType') {
            if (!userTypeOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.userType < b.userType) {
                        return -1;
                    }
                    if (a.userType > b.userType) {
                        return 1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
            if (userTypeOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.userType < b.userType) {
                        return 1;
                    }
                    if (a.userType > b.userType) {
                        return -1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
        }
        if (sortType === 'email') {
            if (!userEmailOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.login < b.login) {
                        return -1;
                    }
                    if (a.login > b.login) {
                        return 1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
            if (userEmailOrder) {
                const compareNames = (a: AccountUsersProps, b: AccountUsersProps): any => {
                    if (a.login < b.login) {
                        return 1;
                    }
                    if (a.login > b.login) {
                        return -1;
                    }
                    return 0;
                };

                accountUsersList.length ? accountUsersList.sort(compareNames) : (accountUsersList = []);
            }
        }
        if (sortType === 'notification') {
            if (!notificationOrder) {
                const premiumFirst = accountUsersList.sort((a: AccountUsersProps): any => {
                    const value = a.notificationEnabledUserArray ? -1 : 1
                    return value
                });
                accountUsersList.length ? premiumFirst : accountUsersList = [];
            }
            if (notificationOrder) {
                const premiumFirst = accountUsersList.sort((a: AccountUsersProps): any => {
                    const value = a.notificationEnabledUserArray ? 1 : -1
                    return value
                });
                accountUsersList.length ? premiumFirst : accountUsersList = [];
            }
        }
    }
    catch (exception) {
        console.log(`Exception in account users sorting: ${JSON.stringify(exception)}`);
    }

    return (
        <div>
            {md ? (
                <Grid item xs={12}>
                    {accountUsersList !== undefined &&
                        accountUsersList.map((accountUser: AccountUsersProps, key: number) => (
                            <>
                                <Item sx={{ marginLeft: -8, marginRight: -2, alignContent: 'normal' }} elevation={1}>
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        style={{ paddingTop: 5, paddingBottom: 10 }}
                                        alignItems="flex-start"
                                        title={
                                            <strong>
                                                {accountUser.lastName} {accountUser.firstName}
                                            </strong>
                                        }
                                        subtitle={accountUser.userType}
                                        info={accountUser.login}
                                        divider="full"
                                        rightComponent={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                    }}>
                                                    {(currentUserDetails?.userType === 'INSTALLER' || currentUserDetails?.userType === 'MANAGER') &&
                                                        <IconButton style={{ paddingTop: 15 }}>
                                                            <EditIcon onClick={() => handleUserEditButton(accountUser)} />
                                                        </IconButton>}
                                                </div>
                                            </div>
                                        }
                                    />
                                </Item>
                            </>
                        ))}
                </Grid>
            ) : (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="21%">
                                {t('lbl_name')}&ensp;
                                <Checkbox
                                    onClick={handleUserNamesOrder}
                                    checked={userNameOrder}
                                    icon={<ArrowDownwardIcon fontSize="small" color="disabled" />}
                                    checkedIcon={<ArrowUpwardIcon fontSize="small" color="disabled" />}
                                />
                            </TableCell>
                            <TableCell width="21%">
                                {t('lbl_userType')}&ensp;
                                <Checkbox
                                    onClick={handleUserTypeOrder}
                                    checked={userTypeOrder}
                                    icon={<ArrowDownwardIcon fontSize="small" color="disabled" />}
                                    checkedIcon={<ArrowUpwardIcon fontSize="small" color="disabled" />}
                                />
                            </TableCell>
                            <TableCell width="31%">
                                {t('lbl_login')}&ensp;
                                <Checkbox
                                    onClick={handleUserEmailOrder}
                                    checked={userEmailOrder}
                                    icon={<ArrowDownwardIcon fontSize="small" color="disabled" />}
                                    checkedIcon={<ArrowUpwardIcon fontSize="small" color="disabled" />}
                                />
                            </TableCell>
                            <TableCell width="20%">
                                {t('lbl_email_notification')}&ensp;
                                <Checkbox
                                    onClick={handleNotificationOrder}
                                    checked={notificationOrder}
                                    icon={<ArrowDownwardIcon fontSize="small" color="disabled" />}
                                    checkedIcon={<ArrowUpwardIcon fontSize="small" color="disabled" />}
                                />
                            </TableCell>
                            <TableCell width="5%"></TableCell>
                            <TableCell width="2%"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountUsersList !== undefined &&
                            accountUsersList.map((accountUser: AccountUsersProps, key: number) => (
                                <>
                                    <TableRow sx={{ backgroundColor: 'white' }}>
                                        <TableCell align="left" padding="normal">
                                            <label>
                                                {
                                                    <Typography variant="body1">
                                                        {accountUser.lastName} {accountUser.firstName}
                                                    </Typography>
                                                }
                                            </label>
                                        </TableCell>

                                        <TableCell align="left" padding="normal">
                                            <label>
                                                {<Typography variant="body1">{accountUser.userType}</Typography>}
                                            </label>
                                        </TableCell>

                                        <TableCell align="left" padding="normal">
                                            <label>
                                                {<Typography variant="body1">{accountUser.login}</Typography>}
                                            </label>
                                        </TableCell>

                                        <TableCell align="left" padding="none">
                                            <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                style={{ padding: 0 }}
                                                hidePadding
                                                title={
                                                    <Typography variant="body1">
                                                        {accountUser.notificationEnabledUserArray
                                                            ? 'Enabled'
                                                            : 'Disabled'}
                                                    </Typography>
                                                }
                                                icon={
                                                    <FiberManualRecordIcon
                                                        color={
                                                            accountUser.notificationEnabledUserArray
                                                                ? 'success'
                                                                : 'error'
                                                        }
                                                        fontSize="small"
                                                    />
                                                }
                                                iconAlign="right"
                                            />
                                        </TableCell>

                                        <TableCell align="right" padding="normal">
                                            {(currentUserDetails?.userType === 'INSTALLER' || currentUserDetails?.userType === 'MANAGER') &&
                                                <IconButton
                                                    onClick={() => handleDeleteUser(accountUser.ID)}
                                                    sx={{ mt: -1 }}
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>}

                                        </TableCell>

                                        <TableCell align="right" padding="normal">
                                            {(currentUserDetails?.userType === 'INSTALLER' || currentUserDetails?.userType === 'MANAGER') &&
                                                <IconButton
                                                    onClick={() => handleUserEditButton(accountUser)}
                                                    sx={{ mt: -1 }}
                                                    size="small"
                                                >
                                                    <EditIcon />
                                                </IconButton>}
                                        </TableCell>
                                    </TableRow>
                                </>
                            ))}
                    </TableBody>
                </Table>
            )}
        </div>
    );
}
