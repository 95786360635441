/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */

import { Box, Button, Divider, MobileStepper, Modal, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useTranslation } from 'react-i18next';
import { getRemoveApplication } from '../../../redux/actions/systemToolsActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type removePairingtype = {
    open: boolean,
    setOpen: (open: boolean) => void,
    actionMenu: string,
    snackOpen: boolean,
    setSnackOpen: (open: boolean) => void,
    count: number,
    setCount: (num: number) => void,
    userEmail: string,
}

function RemoveApplicationUserPopUp({ open, setOpen, snackOpen, setSnackOpen, count, setCount, actionMenu, userEmail }: removePairingtype): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
    }

    const handlerRemoveApplication = async () => {
        await dispatch(getRemoveApplication(actionMenu, userEmail))
        setCount(count + 1);
        setSnackOpen(true);
        setOpen(false);
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={md ? {
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    backgroundColor: 'background.paper',
                    boxShadow: 20,
                    p: 0,
                } : style} >
                    <Paper elevation={0} sx={{ pb: 1, pr: 2 }}>
                        <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>
                            {t('heading_confirmation')}
                        </Typography>
                        <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >
                            {t('msg_deleteAdminConfirmation')} {userEmail} {t("msg_for_Pairing")}
                        </Typography>
                        {md &&
                            <Stack direction={'row-reverse'} spacing={2}>
                                <Button onClick={handlerRemoveApplication} color='error' disableRipple disableFocusRipple >
                                    {t('lbl_yes')}
                                </Button>
                                <Button onClick={handleClose} color='primary' disableRipple disableFocusRipple >
                                    {t('lbl_no')}
                                </Button>
                            </Stack>}
                    </Paper>
                    <Divider />
                    {!md && <Paper >
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                            nextButton={
                                <Button size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handlerRemoveApplication}>
                                    {t('lbl_yes')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant='outlined' onClick={handleClose}  >
                                    {t('lbl_no')}
                                </Button>
                            }
                        />
                    </Paper>}
                </Box>
            </Modal>
        </>
    )
}

export default RemoveApplicationUserPopUp
