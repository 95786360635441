/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '@mui/icons-material/Upload';
import { useSelector } from 'react-redux';
import './endUserInfo.css';
import { postInstallerLogo, removeInstallerLogoImage } from '../../redux/actions/endUserInfoActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import secureLocalStorage from 'react-secure-storage';
  
  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
  }; 

   type LogoDropZoneProps = {
    files:any[]
    setFiles: (files:any[]) => void
   }

export default function LogoDropZone({files,setFiles}:LogoDropZoneProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const {  installerLogo, fetchLogoStatus } = useSelector((state: any) => state.endUserInfoReducer);
    const { currentUser } = useSelector((state: any) => state.userReducer);
    const role = secureLocalStorage.getItem('role')
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { t } = useTranslation();
    //const [upload, setUpload] = useState(false)
    //const [files, setFiles] = useState<any[]>([]);
    const { getRootProps, getInputProps, open } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxFiles: 1,
        accept: {
            'image/jpeg': ['.jpeg', '.png'],
        },
        onDrop: (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => Object.assign(file, {
              preview: URL.createObjectURL(file)
            }))) 
            dispatch(postInstallerLogo(URL.createObjectURL(acceptedFiles[0])))
          }
    });

    const thumbs = files.map((file) => (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: 2,
                    border: '0px solid #eaeaea',
                    marginBottom: 10,
                    marginTop: '10vh',
                    marginLeft: 8,
                    width: '30vh',
                    height: '30vh',
                    padding: 2,
                    boxSizing: 'border-box',
                }}
                key={file.name}
            >
                <div >
                    {files.length > 0 ?
                        <img
                    className={md? '' : 'image'}
                        src={file.preview}
                        style={md?img:{}}
                        // Revoke data uri after image is loaded
                        onLoad={() => {
                           // dispatch(postInstallerLogo(file.preview))
                        }}
                    />
                    :<></>
                }
                </div>
            </div>
        </>
    ));
      useEffect(() => 
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
         () => files.forEach((file) => URL.revokeObjectURL(file.preview))
      , []);

      const handleRemoveLogo = async() => {
        setFiles([])
        await dispatch(removeInstallerLogoImage())
      }
   

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:'column',
            }}
        >
            
               {files.length > 0 ?  
               <>
                {thumbs}
               </>
               :
               <div className={md?'mobil-view-bordered':"bordered"}>
               <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <Grid sx={{ mt: '5%', textAlign: 'center' }} container spacing={2}>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={open} disableElevation startIcon={<UploadIcon />}>
                            {t('btn_uploadLogo')}
                            </Button>
                        </Grid>
                        {md?<></> :<Grid item xs={12}>
                            <Button variant="text" disableElevation>
                                <Typography variant="body2">{t('btn_dragLogo')}</Typography>
                            </Button>
                        </Grid>}
                    </Grid>
                </div>
                </div>
                } 
            
           {files.length > 0 && (role === 'INSTALLER' || role === 'MANAGER') && 
           (<Stack direction={'row'} spacing={2}>
            <Button variant='outlined' sx={{borderWidth:2, borderColor:'#007BC1'}} startIcon={<ChangeCircleIcon color='primary' />} color='primary' onClick={open}>{t('btn_cgnLogo')}</Button>
            <Button variant='outlined' sx={{borderWidth:2, borderColor:'#CA3C3D'}} startIcon={<DeleteIcon color='error' />} color='error' onClick={handleRemoveLogo}>{t('btn_rmvLogo')}</Button>
            </Stack>)}
        </div>
    );
}
