/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation, Trans } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import {
    fetchRemoteServicingEmailDestinations,
    performDeleteRemoteServicingEmailDestinations,
} from '../../redux/actions/remoteServicingActions';
import { useSelector } from 'react-redux';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    user: any;
};

export default function DeleteEmailDestinationsModal({ open, setOpen, user }: Props): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = React.useState(0);
    const { deleteStatus } = useSelector((state: any) => state.remoteServicingReducer);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [save, setSave] = React.useState(false);
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    };

    React.useEffect(() => {
        if (save) {
            if (deleteStatus.status === 200) {
                setMessage(deleteStatus.message);
                dispatch(fetchRemoteServicingEmailDestinations(user?.panelId));
            } else if (deleteStatus.status === 404) {
                setMessage(deleteStatus.message);
            } else if (deleteStatus.status === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } else if (deleteStatus.status === 400) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true);
            setSave(false);
        }
    }, [save]);

    const handleDelete = async () => {
        const body = {
            panelId: user.panelId,
            emailAddress: user.emailAddress,
        };
        await dispatch(performDeleteRemoteServicingEmailDestinations(body, user.panelId));
        setSave(true);
        setOpen(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: md ? 300 : 400,
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 20,
                        p: 0,
                    }}
                >
                    <Paper elevation={0} sx={{ pb: 1 }}>
                        <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant="h6">
                            Delete Email Config
                        </Typography>
                        <Typography sx={{ pl: 2, pb: 4, pt: 0 }}>
                            Are you sure you want to delete email {user.emailAddress}?
                        </Typography>
                    </Paper>
                    <Divider />
                    <Paper>
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            activeStep={activeStep}
                            sx={{
                                maxWidth: 400,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                            }}
                            nextButton={
                                <Button
                                    size="medium"
                                    disableElevation
                                    sx={{ backgroundColor: '#CA3C3D',":hover":{backgroundColor:'#CA3C3D'} }}
                                    variant="contained"
                                    onClick={handleDelete}
                                >
                                    {t('lbl_delete')}
                                </Button>
                            }
                            backButton={
                                <Button  size="small" variant="outlined" onClick={handleClose}>
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />
                    </Paper>
                </Box>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </div>
    );
}
