/* eslint-disable @typescript-eslint/naming-convention */
const resources = {
    "menu_oveview": "Overview",
    "menu_endUserInfo": "End User Info",
    "menu_accntUsrs": "Accounts",
    "menu_serviceReports": "Service Reports",
    "menu_settings": "Settings",
    "menu_help": "Information",
    "lbl_cloudId": "Cloud ID",
    "lbl_logOut": "Log Out",
    "lbl_contactSupport": "CONTACT SUPPORT",
    "lbl_estates": "Estates",
    "lbl_ESTATES": "ESTATES",
    "lbl_panels": "Panels",
    "lbl_PANELS": "PANELS",
    "lbl_connected": "Connected",
    "lbl_disconnected": "Disconnected",
    "lbl_manageEstates": "Manage estates",
    "lbl_newEstate": "New Estate",
    "lbl_estate": "Estate",
    "lbl_search": "Search…",
    "lbl_manage_estateAndpanels": "Manage Estates and Panels",
    "btn_cancel": "Cancel",
    "btn_save": "Save",
    "msg_panels_moved": "Panels moved successfully",
    "lbl_name": "Name",
    "lbl_description": "Description",
    "btn_next": "Next",
    "lbl_contact_name": "Contact Name",
    "lbl_contact_phNo": "Contact Phone Number",
    "lbl_country": "Country",
    "lbl_address": "Address",
    "lbl_postcode": "Postcode",
    "lbl_selectedPanels": "Selected Panels",
    "lbl_close": "Close",
    "msg_newEstate_created": "New estate created successfully",
    "msg_estateExists": "Estate with this name already exists",
    "tooltip_ARC_Access": "ARC Access",
    "tooltip_usrEmail_alerts": "User Email Alerts",
    "tooltip_usrAccess": "User Access",
    "tooltip_installerEmail_alerts": "Installer Email Alerts",
    "tooltip_remoteServicing": "Remote Servicing",
    "lbl_allPanel": "All panels",
    "lbl_favorites": "Favorites",
    "lbl_ARC_enabled": "ARC enabled",
    "lbl_end_usrEmail_enabled": "End user email enabled",
    "lbl_end_usrAccess_disabled": "End user access disabled",
    "lbl_installer_emailAlerts_enabled": "Installer email alerts enabled",
    "lbl_remoteServicing_enabled": "Remote servicing enabled",
    "msg_no_panels_found": "No panels available for above filters",
    "lbl_move_panel": "Move Panel",
    "lbl_delete_panel": "Delete Panel",
    "lbl_delete": "Delete",
    "lbl_delete_estate": "Delete Estate",
    "msg_delete_estate": "Are you sure you want to delete the estate",
    "msg_no_action_revert": "This action can't be reverted.",
    "msg_estate_deleted": "Estate deleted successfully",
    "msg_deleteEstate_error": "Error in deleting estate",
    "msg_noSearch_results": "No results matching",
    "msg_deletePanel": "Are you sure you want to delete the panel",
    "msg_deletePanel_error": "Error in deleting panel",
    "msg_panelDeleted": "Panel deleted successfully",
    "lbl_viewEstate": "View Estate",
    "lbl_goToPanel": "Go to panel",
    "lbl_subEstate": "Sub-estate",
    "msg_noEstatesConfigured": "No estates configured",
    "msg_noPanelsConnected": "No panels connected",
    "tab_notifications": "PANEL HISTORY",
    "tab_overview": "OVERVIEW",
    "tab_panelLog": "PANEL LOG",
    "tab_ARC_reporting": "ARC REPORTING",
    "tab_connect": "CONNECT",
    "tab_keypad": "KEYPAD",
    "tab_remoteServicing": "REMOTE SERVICING",
    "tab_mobileApp_usrs": "MOBILE APP USERS",
    "lbl_mobAppUsrs": "Mobile Application Users",
    "lbl_summary": "Summary",
    "lbl_status": "Status",
    "lbl_panelId": "Panel ID",
    "lbl_macAddress": "MAC Address",
    "lbl_firmware": "Firmware",
    "msg_panelIdCopied": "PanelID copied to clipboard",
    "lbl_panelAccess": "Panel Access",
    "lbl_enabled": "Enabled",
    "lbl_disabled": "Disabled",
    "lbl_connectionStats": "Connection Stats",
    "lbl_totalTime": "Total time(hrs)",
    "lbl_connectedTime": "Connected(hrs)",
    "lbl_disconnectedTime": "Disconnected(hrs)",
    "lbl_connectedPercent": "Connected(%)",
    "lbl_siteDetails": "Site Details",
    "lbl_cloudReference": "Cloud Reference",
    "lbl_siteAddress": "Site Address",
    "lbl_optional": "Optional",
    "lbl_somethingWrong": "Something went wrong",
    "msg_siteDetails_edited": "Site details edited successfully",
    "lbl_ARCProvider": "ARC Provider",
    "lbl_ARCName": "ARC Name",
    "lbl_accntNo": "Account Number",
    "lbl_IP_version": "SIA-IP Version",
    "lbl_primaryIP": "Primary IP",
    "lbl_secondaryIP": "Secondary IP",
    "lbl_primaryPort": "Primary Port",
    "lbl_secondaryPort": "Secondary Port",
    "lbl_encryption": "Encyption",
    "option_no_encryption": "NO ENCRYPTION",
    "lbl_report_email": "Report Email",
    "msg_ARC_edited": "ARC access edited successfully",
    "msg_ARC_enabled": "ARC access enabled successfully",
    "msg_ARC_updated": "ARC access enabled and Updated",
    "btn_back": "Back",
    "msg_ARC_disabled": "ARC access Disabled",
    "msg_error": "Error",
    "lbl_dateOfService": "Date of next service",
    "lbl_serviceFrquency": "Service frequency",
    "option_svc_frqncy_1": "Monthly",
    "option_svc_frqncy_2": "6 Monthly",
    "msg_remote_srvcng_enable": "Remote servicing enabled successfully",
    "msg_remote_srvcng_disabled": "Remote servicing disabled successfully",
    "msg_email_alert_enabled": "Installer email alert enabled successfully",
    "msg_email_alert_edited": "Installer email alert edited successfully",
    "lbl_email_alert_disable": "Disable Installer Email Alerts",
    "msg_email_alert_disable": "Are you sure you want to disable installer email alerts?",
    "btn_disable": "Disable",
    "msg_emailAlert_disabled": "Installer email alert disabled successfully",
    "lbl_enable_usrAccess": "Enable User Access",
    "lbl_disable_usrAccess": "Disable User Access",
    "msg_enable_usrAccess": "Are you sure you want to enable user access?",
    "btn_enable": "Enable",
    "msg_usrAccess_enabled": "User access enabled successfully",
    "msg_usrAccess_disabled": "User access disabled successfully",
    "msg_usrAccess_error": "Error in enabling/disabling User Access",
    "btn_addUsr": "Add User",
    "lbl_addUsr": "add users so that they get alerted on events for this panel",
    "lbl_events": "Events",
    "lbl_sets": "Sets",
    "lbl_alarms": "Alarms",
    "lbl_tampers": "Tampers",
    "lbl_system": "System",
    "msg_emailAlert_added": "User email alerts added successfully.",
    "lbl_edit_emailAlerts": "Edit User Email Alerts",
    "msg_usrEmail_edited": "User email edited successfully",
    "lbl_delete_usrEmail": "Delete User Email Alerts",
    "msg_delete_usrEmail": "Are you sure you want to delete user email alerts?",
    "msg_usr_emailDeleted": "User email alert deleted successfully",
    "tootltip_exportNotification": "Export Notifications",
    "lbl_delivered": "DELIVERED",
    "lbl_failed": "FAILED",
    "lbl_information": "Information",
    "lbl_evntDetails": "Event Details",
    "lbl_systmName": "System Name",
    "lbl_Id": "ID",
    "lbl_partition": "Partition",
    "lbl_user": "User",
    "lbl_username": "Username",
    "lbl_text": "Text",
    "lbl_verbose": "Verbose",
    "lbl_timestamp": "Timestamp",
    "lbl_code": "Code",
    "lbl_group": "Group",
    "lbl_SIA_IP": "SIA IP DIRECT",
    "lbl_CLS_Dualcom": "CSL DualCom",
    "lbl_webway": "WebWayOne",
    "lbl_noResponse": "NO RESPONSE",
    "lbl_rejected": "REJECTED",
    "lbl_readyForDelivering": "READY FOR DELIVERING",
    "lbl_sent": "SENT",
    "lbl_exportLogs": "Export Logs",
    "lbl_failedSvc": "Failed Service Reports",
    "lbl_ScheduledSvc": "Planned Service Reports",
    "lbl_pendingSvc": "No Scheduled or Failed Services",
    "lbl_cmpltd_svcReports": "Completed Service Reports",
    "lbl_report_no": "Report Number",
    "lbl_cmpltd_date": "Completed date",
    "lbl_cmpltd_Classification": "Classification",
    "lbl_cmpltd_View": "View",
    "lbl_service_report": "Service Report",
    "lbl_delete_svcReport": "Delete Service Report",
    "msg_delete_svcReport": "Are you sure you want to delete service report?",
    "msg_svcReport_deleted": "Service report deleted successfully",
    "btn_perform_remoteSvc": "Perform Remote Service",
    "msg_remoteSvc_initiated": "Remote service initiated",
    "msg_remoteSvc_inProgress": "The service is in progress",
    "lbl_errorOccurred": "Error occurred",
    "lbl_noCmpltd_svc": "No Completed Services",
    "lbl_noCmpltd_svcReports": "No Completed Service Reports",
    "lbl_mobApp_usrs": "Mobile App Users",
    "msg_usr_authorized": "User not authorized",
    "msg_internal_error": "Internal server error",
    "msg_try_later_error": "Something went wrong. Please try again.",
    "lbl_info_diplayed": "Information",
    "lbl_compnyName": "Company Name",
    "lbl_officeNo": "Office Phone Number",
    "lbl_outOfHrs": "Out of Hours Phone Number",
    "lbl_contactEmail": "Contact E-mail",
    "lbl_logo": "Logo",
    "btn_uploadLogo": "Upload Logo",
    "btn_dragLogo": "or drag logo here",
    "msg_endUsrInfo_edited": "End user info edited successfully",
    "btn_remove": "Remove",
    "lbl_userType": "User Type",
    "lbl_login": "Login",
    "lbl_email_notification": "E-mail Notification",
    "lbl_delete_accntUsr": "Delete Account User",
    "msg_deleteUsr": "Are you sure you want to delete user?",
    "msg_deleteUsr_error": "Error in deleting account user",
    "msg_accntUsr_deleted": "Account user deleted successfully",
    "lbl_firstName": "First Name",
    "lbl_lastName": "Last Name",
    "lbl_typeUsr": "Type User",
    "lbl_emailNotifications": "Email notifications",
    "lbl_userPemissions": "User Permissions",
    "msg_userEdited": "User edited successfully",
    "lbl_unauthorizedUser": "Unauthorized user",
    "lbl_someError": "Some error occurred",
    "lbl_pwd": "Password",
    "lbl_repeat_pwd": "Repeat Password",
    "lbl_engineer": "Engineer",
    "lbl_manager": "Manager",
    "lbl_installer": "Installer",
    "lbl_officeStaff": "Office Staff",
    "msg_noMatch_pwd": "Unmatched passwords!",
    "msg_usr_added": "User added successfully",
    "lbl_panel": "Panel",
    "lbl_accnt_holder": "Account holder",
    "lbl_spoken_pwd": "Spoken Password",
    "lbl_language": "Language",
    "lbl_cmpnyInfo": "Company information",
    "lbl_firstPh": "First Phone",
    "lbl_secondPh": "Second Phone",
    "lbl_thirdPh": "Third Phone",
    "lbl_oldPwd": "Old Password",
    "lbl_newPwd": "New Password",
    "lbl_repeatPwd": "Repeat Password",
    "msg_accntHolder_edited": "Account holder edited successfully",
    "lbl_firstPhType": "First Phone Type",
    "lbl_firstPhone": "First Phone Number",
    "lbl_secondPhone": "Second Phone Number",
    "lbl_thirdPhone": "Third Phone Number",
    "lbl_secondPhType": "Second Phone Type",
    "lbl_thirdPhType": "Third Phone Type",
    "lbl_home": "Home",
    "lbl_mobile": "Mobile",
    "lbl_office": "Office",
    "msg_cmpnyInfo_edited": "Company information edited successfully",
    "lbl_termsConditions": "Terms and Conditions",
    "lbl_privacyPolicy": "Privacy Policy",
    "msg_email_invalid": "Email is invalid",
    "msg_unmatchedPwd": "Unmatched passwords!",
    "lbl_installerGrp": "Installer Group",
    "msg_usrExist": "User with the login already exists",
    "lbl_editUsr": "Edit User",
    "msg_field_required": "This field is required",
    "lbl_disable_ARC": "Disable ARC Access",
    "msg_disable_ARC": "Are you sure you want to disable ARC access?",
    "lbl_SiaEvnts": "Sia Events",
    "lbl_SIAEvnt": "SIA Event",
    "msg_prohibited_chars": "input contains prohibited characters",
    "msg_inputLength": "input length should not be more than 30 characters",
    "lbl_favPanels": "FAVORITE PANELS",
    "lbl_lastConnection": "Last connection",
    "lbl_androidPh": "Android Phone",
    "lbl_iPhone": "My iPhone",
    "lbl_alexa": "Alexa",
    "lbl_gHome": "Google Home",
    "lbl_removePairing": "Remove pairing",
    "msg_removePairing": "Are you sure you want to remove pairing for this app user?",
    "lbl_no": "No",
    "lbl_yes": "Yes",
    "lbl_device": "Device",
    "lbl_appDetails": "Application Details",
    "lbl_myAndroid": "My Android",
    "lbl_myAlexa": "My Alexa",
    "lbl_myiPhone": "My iPhone",
    "lbl_mygHome": "My Google Home",
    "lbl_appID": "Application ID",
    "lbl_mobOS": "Mobile OS",
    "lbl_token": "Token",
    "msg_didNotMovePanl": "Did not move any panel",
    "lbl_panlsSelected": "Panels Selected",
    "lbl_panlSelected": "Panel Selected",
    "lbl_ARCCode": "ARC Code",
    "lbl_response": "Response",
    "lbl_data": "Data",
    "lbl_state": "State",
    "lbl_signal_Timeout": "Signal Timeout",
    "lbl_sysID": "System ID",
    "lbl_date": "Date",
    "lbl_remove_destination": "Remote servicing email destinations",
    "lbl_emailAddress": "Email Address",
    "Reports": "Reports",
    "lbl_green": "GREEN",
    "lbl_amber": "AMBER",
    "lbl_red": "RED",
    "lbl_reportDetails": "Report Details",
    "lbl_dueDate": "Due date",
    "btn_edit": "Edit",
    "lbl_nameAZ": "Name (A-Z)",
    "lbl_nameZA": "Name (Z-A)",
    "lbl_mostAlarms": "Most alarms",
    "lbl_fewerAlarms": "Fewer alarms",
    "lbl_updatedRecent": "Updated recently",
    "lbl_none": "None",
    "msg_confirmation": "Are you sure you want to",
    "msg_enable": "enable",
    "msg_disable": "disable",
    "msg_edited": "edited successfully",
    "btn_addEmail": "Add Email Destination",
    "lbl_emailDestinationAdd": "add email destination so that remote service reports can be sent via email",
    "lbl_termsToUseWebsite": "The terms on which you may use our website",
    "lbl_termsToUseWebsite_1.1": "These Terms of Use (together with the other documents referred to below) set out the terms on which you may use our website",
    "lbl_termsToUseWebsite_1.1part": "We recommend that you print a copy of these Terms of Use for future reference.",
    "lbl_termsToUseWebsite_1.2": "You should read these Terms of Use carefully before using our website, as by using our website you are confirming that you accept these Terms of Use and that you will comply with them.",
    "lbl_termsToUseWebsite_1.3": "If you don’t accept these Terms of Use you should leave our website immediately",
    "lbl_termsToUseWebsite_1.4": "The following policy also apply to your use of our website",
    "lbl_termsToUseWebsite_1.4.1": "Our privacy and cookies policy",
    "lbl_termsToUseWebsite_1.4.1part": "By using our website you are consenting to us processing any personal data that you provide to us or that we collect from you as a result of your use of our website. Our privacy policy sets out what we do with that personal data",
    "lbl_termsToUseWebsite_1.5": "You are responsible for ensuring that anyone else who accesses our website through your internet connection is aware of these Terms of Use and the policies referred to above and that they comply with them.",
    "lbl_chngesToTerms": "Changes to these Terms of Use and our other policies",
    "lbl_chngesToTerms_2.1": "We make changes to these Terms of Use from time to time. You should therefore check these Terms of Use whenever you return to our website to see whether any changes have been made, as these will be binding on you.",
    "lbl_chngesToTerms_2.2": "We also make changes to our privacy policy and cookies policy from time to time, so you should also check these regularly to see whether any changes have been made.",
    "lbl_aboutUs": "About us",
    "lbl_aboutUs_3.1": "We, Eaton Electrical Products Ltd, operate this website at www.eatonsecureconnect.com. We are a company registered in England and Wales under company number 01342230 and our registered office is at 6 Jephson Court, Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_aboutUs_3.2": "You can contact us",
    "lbl_aboutUs_3.2part": "By email at",
    "lbl_restrictionsOnUseOfWebsite": "Restrictions on your use of our website",
    "lbl_restrictionsOnUseOfWebsite_4.1": "You may only use our website for lawful purposes and in compliance with all applicable laws, including without limitation data protection and privacy laws, laws relating to copyright of content and laws relating to unsolicited commercial electronic messages.",
    "lbl_restrictionsOnUseOfWebsite_4.2": "In addition, you must not",
    "lbl_restrictionsOnUseOfWebsite_4.2.1": "use our website to send or transmit, whether directly or indirectly, unsolicited or unauthorised advertising or promotional material, chain letters or pyramid selling schemes;",
    "lbl_restrictionsOnUseOfWebsite_4.2.2": "use our website for mail bombing or flooding or to make deliberate attempts to overload a system;",
    "lbl_restrictionsOnUseOfWebsite_4.2.3": "use our website to post content or link to content where you know that or ought to have known that the owner of that content has not consented to or authorised that posting or linking or where that posting or linking infringes the rights of that owner;",
    "lbl_restrictionsOnUseOfWebsite_4.2.4": "knowingly or recklessly introduce to our website, or use our website to transmit, any viruses, trojan horses, worms, logic bombs, time-bombs, keystroke loggers, spyware, malware, adware or other materials which are malicious or harmful;",
    "lbl_restrictionsOnUseOfWebsite_4.2.5": "attempt to gain unauthorised access to our website, the server, equipment or network on which our website is stored, any server, computer or database connected to our website or any software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.6": "use our website to attempt to gain unauthorised access to any other website, internet account, server, computer, equipment, system, network, data or information;",
    "lbl_restrictionsOnUseOfWebsite_4.2.7": "use our website to monitor data or traffic on any network or system;",
    "lbl_restrictionsOnUseOfWebsite_4.2.8": "use our website to collect or use information, including without limitation email addresses, screen names or other identifiers, by deceit (such as phishing, internet scamming, password robbery, spidering, scraping and harvesting);",
    "lbl_restrictionsOnUseOfWebsite_4.2.9": "use our website to distribute software;",
    "lbl_restrictionsOnUseOfWebsite_4.2.10": "carry out a denial-of-service attack or a distributed denial-of-service attack on our website or use our website to carry out any such attack on any other website;",
    "lbl_restrictionsOnUseOfWebsite_4.2.11": "use our website:",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.1": "for any purpose which; or",
    "lbl_restrictionsOnUseOfWebsite_4.2.11.2": "to send, transmit, publish, upload, download, use, store, reproduce or knowingly receive any material that, does not comply with the content standards set out in",
    "lbl_para8": "paragraph 8",
    "lbl_restrictionsOnUseOfWebsite_4.2.12": "use our website to groom, harm or take advantage of minors in any way or to attempt to do so; or",
    "lbl_restrictionsOnUseOfWebsite_4.2.13": "use our website in violation of export laws, controls, regulations or sanction policies of the United States or any other country.",
    "lbl_restrictionsOnUseOfWebsite_4.3": "If you breach any term of these Terms of Use your right to use our website will immediately end. In addition we may take such other actions including, without limitation, legal action as we consider appropriate.",
    "lbl_restrictionsOnUseOfWebsite_4.4": "If you breach any term of these Terms of Use, or if we receive a request from a law enforcement authority to do so, we may report your identity, details of any breach and any other information we consider necessary to relevant law enforcement authorities.",
    "lbl_pwdNSecRequirement": "Passwords and security requirements",
    "lbl_pwdNSecRequirement_5.1": "If you register an account on our website you must provide accurate and complete registration information and keep that information up to date at all times.",
    "lbl_pwdNSecRequirement_5.2": "Use of certain parts of this website is password protected. In order to use it you will need a user identification code or password in order to access it. It is your responsibility to keep this information secret and confidential and not to disclose it to any other person.",
    "lbl_pwdNSecRequirement_5.3": "If you think that somebody else might know your user identification code or password then you must log in to your account and change these as soon as possible",
    "lbl_pwdNSecRequirement_5.4": "We reserve the right to disable any user identification code or password at any time if, in our reasonable opinion, you fail to comply with any of these Terms of Use, if we consider that you are using our website to download content or infringe in any manner the rights of another person or if we consider that there may be a security risk.",
    "lbl_webSiteNContent": "Our website and its content",
    "lbl_webSiteNContent_6.1": "We do not guarantee that access to our website, or the content on it (including any content which you upload to our website), will always be available or uninterrupted. Your right to access our website is given on a temporary basis only.",
    "lbl_webSiteNContent_6.2": "We reserve the right to change our website and the content on it, including without limitation by adding or removing content or functionality, at any time and without notice.",
    "lbl_webSiteNContent_6.3": "We cannot guarantee that all content on our website is accurate, complete or up to date. We therefore make no warranties, representations, undertakings or guarantees that the content on our website is accurate, complete or up to date.",
    "lbl_webSiteNContent_6.4": "The content on our website is provided for general information purposes only. Nothing on our website is intended to be advice on which you should rely. You should always obtain appropriate specialist advice before taking, or not taking, action on the basis of any content on our website.",
    "lbl_webSiteNContent_6.5": "We make no warranties, representations, undertakings or guarantees that our website is secure or free from bugs or viruses. You are responsible for ensuring that the device from which you access our website has suitable anti-virus software in place.",
    "lbl_contentUploaded": "Content uploaded to our website by you or by other users",
    "lbl_contentUploaded_7.1": "If you upload content to our website you must comply with the content standards set out in",
    "lbl_contentUploaded_7.1part": "in respect of that content",
    "lbl_contentUploaded_7.2": "You warrant to us that you own any content which you upload to our website or, if you are not the owner, that you have the right to upload it.",
    "lbl_contentUploaded_7.3": "You will continue to own any content which you upload to our website, but for so long as that content remains on our website you grant us a world-wide licence to use, store, copy, modify, perform, distribute, display and make available that content and to permit other users of our website to use, download, and print and reproduce it in accordance with our Terms of Use.",
    "lbl_contentUploaded_7.4": "You will indemnify us for any losses, liabilities, costs, expenses, claims or proceedings that we suffer or incur, or will suffer or incur, as a result of or in connection with any breach by you of your obligations or warranties in this",
    "lbl_para7": "paragraph 7",
    "lbl_contentUploaded_7.5": "We are not responsible for, and make no warranties, representations, undertakings or guarantees in respect of, any content on our website which is uploaded by a user. Any view expressed by any user of our website is their view and not our view nor the view of our officers or employees.",
    "lbl_contentUploaded_7.6": "We are not responsible for the storage or back-up of any content uploaded to our website, and will not be responsible for any loss, corruption or destruction of such content.",
    "lbl_contentUploaded_7.7": "We recommend that you back-up any content that you upload to our website.",
    "lbl_contentUploaded_7.8": "We reserve the right to remove any content that you have uploaded to our website at any time.",
    "lbl_contentUploaded_7.9": "We also reserve the right to disclose your identity to any person who claims that any content you have uploaded to our website infringes their intellectual property rights or their right to privacy or is defamatory.",
    "lbl_contentStd": "Content standards",
    "lbl_contentStd_8.1": "Please remember that any content you upload to our website may be viewed by other users, so always think carefully before you submit any content.",
    "lbl_contentStd_8.2": "Any content that you upload to our website must:",
    "lbl_contentStd_8.2.1": "comply with all applicable laws;",
    "lbl_contentStd_8.2.2": "be accurate (where it states facts); and",
    "lbl_contentStd_8.2.3": "be genuinely held (where it states opinions).",
    "lbl_contentStd_8.3": "You must not upload to our website any content which:",
    "lbl_contentStd_8.3.1": "is defamatory, obscene, offensive, indecent, malicious, hateful or inflammatory;",
    "lbl_contentStd_8.3.2": "is pornographic or sexually explicit",
    "lbl_contentStd_8.3.3": "exploits minors;",
    "lbl_contentStd_8.3.4": "promotes violence or terrorism;",
    "lbl_contentStd_8.3.5": "is discriminatory based on race, sex, religion, nationality, disability, sexual orientation or age;",
    "lbl_contentStd_8.3.6": "infringes any copyright, database right, trade mark or other intellectual property right of any other person;",
    "lbl_contentStd_8.3.7": "solicits or attempts to solicit passwords or personal identifying information for commercial or unlawful purposes from other users;",
    "lbl_contentStd_8.3.8": "is fraudulent or likely to deceive any person;",
    "lbl_contentStd_8.3.9": "is made in breach of any legal duty owed to anyone else, such as a contractual duty or a duty of confidence;",
    "lbl_contentStd_8.3.10": "creates a risk to a person’s health or safety or public health or safety;",
    "lbl_contentStd_8.3.11": "promotes or constitutes illegal or unlawful activity;",
    "lbl_contentStd_8.3.12": "compromises national security;",
    "lbl_contentStd_8.3.13": "interferes with an investigation by a law enforcement or regulatory authority;",
    "lbl_contentStd_8.3.14": "is threatening or abusive or invades another person’s privacy or causes annoyance, inconvenience or needless anxiety;",
    "lbl_contentStd_8.3.15": "is likely to harass, upset, embarrass, alarm or annoy any other person;",
    "lbl_contentStd_8.3.16": "impersonates any person or misrepresents your identity or affiliation with any person;",
    "lbl_contentStd_8.3.17": "gives the impression it comes from us;",
    "lbl_contentStd_8.3.18": "constitutes advertising material; or",
    "lbl_contentStd_8.3.19": "advocates, promotes or assists any unlawful activity, including but not limited to copyright infringement or computer misuse.",
    "lbl_IntellctlProprty": "Intellectual property rights",
    "lbl_IntellctlProprty_9.1": "We, or our licensors, own the copyright and all other intellectual property rights in our website and all content on it other than content uploaded by or linked to by others.",
    "lbl_IntellctlProprty_9.2": "You may download the content we own from our website and/or print a reasonable number of copies of any page on our website. You must not make any changes to any content which you have downloaded or printed from our website. If you reproduce the downloaded or printed content in any medium you must ensure that you reproduce it accurately and completely and include a statement acknowledging that it is sourced from our website",
    "lbl_IntellctlProprty_9.3": "If you use, download, print or reproduce content from our website in breach of these Terms of Use your right to use our website will immediately end and you must return to us or destroy (at our option) all digital and paper copies of the content that you have made.",
    "lbl_linksToOtherWebsite": "Links from our website to other websites",
    "lbl_linksToOtherWebsite_text": "Our website may contain links to other websites from time to time. These links are provided for your convenience. We have no control over, and no responsibility or liability to you for, those other websites or their content, and any use or access by you to such websites will be subject to the relevant terms and conditions of use of those websites and not these Terms of Use.",
    "lbl_linksFromOtherWebsite": "Links from other websites to our website",
    "lbl_linksFromOtherWebsite_11.1": "You may create a link to our website from another website, but only if you comply with the following conditions:",
    "lbl_linksFromOtherWebsite_11.1.1": "you must notify us of your intention to create a link to our website from another website and obtain our written approval prior to creating such link;",
    "lbl_linksFromOtherWebsite_11.1.2": "you may only link to the homepage of our website. You must not create a link to any other page of our website, nor frame any page of our website;",
    "lbl_linksFromOtherWebsite_11.1.3": "you may only link to our website from a website which you own and which complies with the standards set out in",
    "lbl_linksFromOtherWebsite_11.1.4": "the creation of the link will not introduce to our website, or use our website to transmit, any viruses, trojan horses, worms, logic bombs, time-bombs, keystroke loggers, spyware, malware, adware or other materials which are malicious or harmful;",
    "lbl_linksFromOtherWebsite_11.1.5": "you will comply with such requirements and/or policies as we may notify you of from time to time in respect to any link to our website from another website; and",
    "lbl_linksFromOtherWebsite_11.1.6": "you must not suggest that we have any association with you or that we approve or endorse your website or any of your products or services.",
    "lbl_linksFromOtherWebsite_11.2": "We reserve the right to require you to remove any link to our website at any time and without giving you prior notice",
    "lbl_ourLiability": "Our liability to you",
    "lbl_ourLiability_12.1": "Nothing in these Terms of Use limits or excludes our liability to you for:",
    "lbl_ourLiability_12.1.1": "death or personal injury resulting from negligence;",
    "lbl_ourLiability_12.1.2": "fraud or fraudulent misrepresentation; or",
    "lbl_ourLiability_12.1.3": "any other matter for which we are not permitted by law to exclude or limit our liability.",
    "lbl_ourLiability_12.2": "As these Terms of Use govern your access and use of our website, all warranties, conditions and other terms relating to our website and/or its content which would otherwise be implied by law (whether by statute, common law or otherwise) are excluded.",
    "lbl_ourLiability_12.3": "We will not be liable to you for any loss or damage arising out of or in connection with your use of (or inability to use) our website or your use of or reliance upon any content on our website, whether in contract, tort (including without limitation negligence), misrepresentation, restitution, under statute or otherwise.",
    "lbl_ourLiability_13": "Applicable law",
    "lbl_ourLiability_13.1": "If you are a consumer these Terms of Use are governed by the law of England and Wales and the courts of England and Wales have non-exclusive jurisdiction to determine any dispute arising out of or in connection with them. If you are resident in Scotland you may also bring proceedings in Scotland and if you are resident in Northern Ireland you may also bring proceedings in Northern Ireland. Residents of other countries in which the website is marketed are subject to the law of England and Wales",
    "lbl_ourLiability_13.2": "If you are a business user these Terms of Use (and any non-contractual obligations arising out of or in connection with them) are governed by the law of England and Wales and the courts of England and Wales have exclusive jurisdiction to determine any dispute arising out of or in connection with them (including without limitation in relation to any non-contractual obligations). Residents of other countries in which the website is marketed are subject to the law of England and Wales",
    "lbl_privPolicy": "Privacy Policy",
    "lbl_privPolicy_text": "The Eaton SecureConnect website is a website and portal operated by Eaton Electrical Products Limited, a company registered at Companies House under registration number 01342230, whose registered address is Jephson Court Tancred Close, Queensway, Royal Leamington Spa, Warwickshire, CV31 3RZ.",
    "lbl_privPolicy_text1": "Eaton Electrical Products Limited",
    "lbl_privPolicy_textWe": "we",
    "lbl_privPolicy_textUs": "us",
    "lbl_privPolicy_textOur": "our",
    "lbl_privPolicy_text2": "is the data controller in respect of personal information submitted by you to us through this website.",
    "lbl_privPolicy_text3": "This notice (together with our terms of use:",
    "lbl_privPolicy_text4": "and any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, through this website will be processed by us. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.",
    "lbl_privPolicy_text5": "If you have any questions about this notice or do not agree with it, please contact us at dataprotection@eaton.com before using this website.",
    "lbl_privPolicy_text6": "We may change this notice from time to time by updating this page. You should check this page from time to time to ensure you are happy with any changes. This notice is effective from December 20, 2019.",
    "lbl_infoWeCollect": "What information do we collect?",
    "lbl_infoWeCollect_text": "You will be able to access the website without giving us any information. However, most of the services provided through the website (such as those available through the portal) will only be available if we process certain information about you.",
    "lbl_infoWeCollect_text1": "We may collect the following information:",
    "lbl_infoWeCollect_text2": "your name and contact information (including address, email address and telephone numbers) and company details;",
    "lbl_infoWeCollect_text3": "your account registration information if you become a registered user of the website (including your user log-in details and password);",
    "lbl_infoWeCollect_text4": "the country from which you access the website;",
    "lbl_infoWeCollect_text5": "information regarding your personal or professional interests, demographics, experiences with our products and contact preferences in order to provide you with further correspondence about our products and services;",
    "lbl_infoWeCollect_text6": "information regarding your customers (including their names, addresses, products they have purchased and systems installed at their premises, information regarding their alarm status (such as whether or not it is activated and/or functioning properly), CCTV images, and whether they are a user of the Eaton SecureConnect mobile application);",
    "lbl_infoWeCollect_text7": "records of any contact between you and us; and",
    "lbl_infoWeCollect_text8": "any information you voluntarily provide to us or input through the website and/or helpline.",
    "lbl_whatWeDowithInfo": "What do we do with the information we collect?",
    "lbl_whatWeDowithInfo_text": "We use this information to provide you with our products and services, and in particular for the following reasons",
    "lbl_whatWeDowithInfo_text1": "to enable us to provide you with support and technical assistance in relation to the products that you have purchased from us;",
    "lbl_whatWeDowithInfo_text2": "to inform you of maintenance downtime periods in relation to the portal and other services provided to you;",
    "lbl_whatWeDowithInfo_text3": "internal record keeping; and",
    "lbl_whatWeDowithInfo_text4": "to improve our products and services.",
    "lbl_whatWeDowithInfo_text5": "We may contact you by e-mail, phone or mail",
    "lbl_whatWeDowithInfo_text6": "We process this personal data for the purposes of fulfilling our obligations and exercising our rights in connection with our contract(s) with you and for our legitimate interests which include providing products, services and solutions for our customers and Eaton-accredited installers, complying with our legal, contractual and moral obligations, increasing the efficiency of our operations and protecting our business",
    "lbl_infoShareWith": "Who do we share this information with?",
    "lbl_infoShareWith_text": "Except as set out below, we will not transfer, disclose, sell, distribute or lease your personal information to third parties other than our subsidiaries and/or parent company unless we have your permission or are required by law.",
    "lbl_infoShareWith_text1": "Sometimes (and with your approval where required), we'll share your personal data with carefully selected third parties outside our corporate group. We may do this for the following reasons:",
    "lbl_infoShareWith_text2": "to enable such third parties to carry out services for us, including our carefully selected website hosting provider;",
    "lbl_infoShareWith_text3": "to enable your employer, principal and/or contractor to view details contained in the portal that relate to its installer business and/or the products that it has purchased;",
    "lbl_infoShareWith_text4": "in response to lawful requests by public authorities, including to meet national security or law enforcement requirements;",
    "lbl_infoShareWith_text5": "when we believe it is necessary to comply with the law or protect our or another person's rights, property, or safety; and/or",
    "lbl_infoShareWith_text6": "if there is (or is to be) any change in ownership of our business or assets then we may wish to share your information so that the new (prospective) owners may continue to operate our business effectively and continue to provide services to customers. This may include new shareholders or any organisation that might take an assignment or transfer of any agreements we have entered into with our customers.",
    "lbl_infoShareWith_text7": "This process may include sending personal data to other countries within the European Economic Area. If, for any reason, we transfer your personal data outside of the European Economic Area, we will implement safeguards to ensure the appropriate levels of protection for all such personal data and we will make available or provide a means for you to obtain a copy of such safeguards.",
    "lbl_howLongStoreData": "How long will we store your personal data?",
    "lbl_howLongStoreData_text": "How long we keep your information for depends on the basis on which it was provided, but generally:",
    "lbl_howLongStoreData_text1": "we will keep the information that is necessary to enable us to provide you with a service that you have requested through or in connection with this website for as long as it takes us to provide that service;",
    "lbl_howLongStoreData_text2": "we will generally keep records of any transactions you enter into with us for a minimum of six years from the end of the relationship. This is so that we can resolve issues and respond to any complaints or disputes that arise in that period. Otherwise we will keep the information where appropriate for legitimate business needs;",
    "lbl_howLongStoreData_text3": "we will keep other information about you if it is necessary for us to do so to comply with the law, or where appropriate for legitimate business needs.",
    "lbl_sensativeInfo": "Sensitive Information",
    "lbl_sensativeInfo_text": "Information about you which is considered sensitive or a special category of personal data under data protection laws can include information about your medical or health conditions, racial or ethnic origin, political opinions, trade union membership, religious or philosophical beliefs, genetic data, biometric data, sexual life and sexual orientation, and suspected or proven criminal activity and related proceedings. If we need to process sensitive personal data, you will be notified of such processing and asked to specifically agree to the use of such information as appropriate.",
    "lbl_security": "Security",
    "lbl_security_text": "We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place physical, electronic and managerial procedures to safeguard and secure the information we collect online.",
    "lbl_security_text1": "We endeavour to take all reasonable steps to protect your personal data. However, please be aware that there are inherent security risks of providing information and dealing online over the internet and we cannot therefore guarantee the security of any personal data disclosed online. We ask that you do not provide us with any sensitive personal data online (please see the 'Sensitive Information' section above) unless we specifically ask for this.",
    "lbl_cookies": "Cookies",
    "lbl_cookies_text": "Like many companies, we use “cookies” and similar tools on our website to improve its performance and enhance your user experience.",
    "lbl_cookies_text1": "What are cookies?",
    "lbl_cookies_text2": "A cookie is a small text file which is placed on the device that you use to access the website (your",
    "lbl_cookies_text3": "Cookies help to analyse web traffic and allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. Certain cookies may contain personal data – for example, if you click to “remember me” when logging in, a cookie may store your username. Most cookies won’t collect personal data that identifies you, and will instead collect more general information, such as how users arrive at and use our website, or a user’s general location.",
    "lbl_cookies_text4": "What do we use cookies for?",
    "lbl_cookies_text5": "We may place cookies or similar files on your Device for security purposes, to tell us whether you have visited the website before, to remember your language preferences, if you are new visitor or to otherwise facilitate site navigation, and to personalize your experience while visiting our websites. Cookies allow us to collect technical and navigational information, such as browser type, time spent on our websites and pages visited. Cookies may enhance your online experience by saving your preferences while you are visiting a particular website. Overall, cookies help us provide you with a better website by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your Device or any information about you, other than the data you choose to share with us.",
    "lbl_cookies_text6": "What kinds of cookies do we use?",
    "lbl_cookies_text7": "Generally, the cookies that are used on the website can be broken down in to the following categories:",
    "lbl_cookies_text8": "Session Cookies:",
    "lbl_cookies_text9": "These cookies are used 'in-session' each time you visit and then expire when you leave a website or shortly thereafter: they are not stored on your Device permanently, do not contain any personal data and help by minimizing the need to transfer personal data across the internet. These cookies can be deleted or you can refuse to allow their use, but this may hamper the performance and your experience in using the websites. These cookies also take time stamps that record when you access a website and when you leave a website.",
    "lbl_cookies_text10": "Tracking Cookies:",
    "lbl_cookies_text11": "These cookies enable us to recognise repeat visitors to our websites. By matching an anonymous, randomly generated identifier, a tracking cookie keeps track of where a user of our websites came from, what search engine they may have used, what link they clicked on, what keyword they used and where they were in the world when they accessed a website. By monitoring this data, we can make improvements to our websites.",
    "lbl_cookies_text12": "Persistent Cookies:",
    "lbl_cookies_text13": "This type of cookie is saved on your Device for a fixed period (sometimes for a few hours, sometimes for a year or longer) and is not deleted when the browser is closed. Persistent cookies are used where we need to remember who you are for more than one browsing session. For example, this type of cookie can be used to store your preferences, so that they are remembered for the next visit to a website.",
    "lbl_cookies_text14": "Performance or Analytical Cookies:",
    "lbl_cookies_text15": "Performance cookies are used to analyse how the websites are used and to monitor their performance, which allows us to improve your experience in using the websites. These cookies help us to tailor the content of the",
    "lbl_cookies_text16": "websites",
    "lbl_cookies_text17": "to reflect what the website users find most interesting and to identify when technical issues with the websites arise. We may also use this data to compile reports to help us to analyse how websites are used, what the most common issues are and how we can improve the websites.",
    "lbl_cookies_text18": "Can the cookies be blocked?",
    "lbl_cookies_text19": "You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you would prefer not to accept cookies, most browsers will allow you to: (i) change your browser settings to notify you when you receive a cookie, which lets you choose whether or not to accept it; (ii) to disable existing cookies; or (iii) to set your browser to automatically reject any cookies. However, please be aware that if you disable or reject cookies, some features and services on our websites may not work properly because we may not be able to recognize and associate you with your account(s). In addition, the offers we provide when you visit us may not be as relevant to you or tailored to your interests.",
    "lbl_cookies_text20": "Instructions for blocking or allowing Cookies in Popular Web Browsing Software Internet Explorer 7 and 8 Firefox Google Chrome Apple Safari AboutCookies.org",
    "lbl_linking": "Linking",
    "lbl_linking_text": "We may link to other websites which are not within our control. Once you have left our website, we cannot be responsible for the protection and privacy of any information which you provide. You should exercise caution and look at the privacy statement applicable to the website in question.",
    "lbl_yourRights": "Your rights",
    "lbl_yourRights_text": "You may request a copy of any personal information which we hold about you. A small fee may be payable. You can do this by writing to us at dataprotection@eaton.com",
    "lbl_yourRights_text1": "In some circumstances, you may be entitled to receive your personal data in a structured, commonly used and machine-readable format.",
    "lbl_yourRights_text2": "If you think any information we have about you is incorrect or incomplete, please write to or e-mail us as soon as possible. We will correct or update any information, as appropriate, as soon as possible.",
    "lbl_yourRights_text3": "You have a right to require us to restrict our processing of your personal data in some circumstances where:",
    "lbl_yourRights_text4": "you contest the accuracy of the personal data that we process about you;",
    "lbl_yourRights_text5": "our processing of your data is unlawful;",
    "lbl_yourRights_text6": "we no longer need the personal data for the purposes for which we are processing it, but you require the personal data for the establishment, exercise or defence of legal claims;",
    "lbl_yourRights_text7": "you contest whether the legitimate grounds of the controller override those of the data subject.",
    "lbl_yourRights_text8": "If you have any questions, comments or suggestions about our use of your personal data please write to us at dataprotection@eaton.com",
    "lbl_yourRights_text9": "You also have a right to lodge a complaint with the Information Commissioner’s Office if you are unhappy with our processing of your personal data.",
    "lbl_noticeToCalfrniaResidents": "Notice to California Residents",
    "lbl_noticeToCalfrniaResidents_text": "This section applies to California Residents.",
    "lbl_noticeToCalfrniaResidents_text1": "As described in the ‘What Information Do We Collect?’ we might collect personal information including identifiers, commercial information, internet or other network activity information, geolocation information, professional or employment-related information and inferences drawn from any of the information identified above to create a profile about a consumer.",
    "lbl_noticeToCalfrniaResidents_text2": "As described in ‘Who Do We Share Your Information With?’ section, personal information we collect from consumers might be shared for business purposes with third parties. We might have disclosed all of the categories of personal information listed above, based on the use case, for a business purpose in the past 12 months.",
    "lbl_noticeToCalfrniaResidents_text3": "We do not sell your personal information.",
    "lbl_noticeToCalfrniaResidents_text4": "As a California resident you have certain rights, subject to legal limitations and applicable exceptions, regarding the collection, use, and sharing of your personal information. Those right are in particular:",
    "lbl_noticeToCalfrniaResidents_text5": "The right to know",
    "lbl_noticeToCalfrniaResidents_text6": "You have the right to request information about the categories of personal information we have collected about you, the categories of sources from which we collected the personal information, the purposes for collecting the personal information, the categories of third parties with whom we have shared your personal information, and the purpose for which we shared your personal information (“Categories Report”). You may also request information about the specific pieces of personal information we have collected about you (“Specific Pieces Report”). Please specify which request you want to execute.",
    "lbl_noticeToCalfrniaResidents_text7": "The right to delete",
    "lbl_noticeToCalfrniaResidents_text8": "You have the right to request that we delete personal information that we have collected from you.",
    "lbl_noticeToCalfrniaResidents_text9": "The right to opt-out",
    "lbl_noticeToCalfrniaResidents_text10": "You have the right to opt out of the sale of your personal information.",
    "lbl_noticeToCalfrniaResidents_text11": "In accordance with applicable law, we will not discriminate against you for exercising these rights. You may use an authorized agent to exercise your rights on your behalf. If you are making any of the requests above through an authorized agent, we will request written authorization from you and will seek to verify as described above or we will accept a legal Power of Attorney under the California Probate Code to the authorized agent.",
    "lbl_noticeToCalfrniaResidents_text12": "You can execute your right at any time by contacting us by sending an email to",
    "lbl_noticeToCalfrniaResidents_text13": "by calling +1-800-386-1911 or by using this",
    "lbl_noticeToCalfrniaResidents_text14": "online form",
    "lbl_noticeToCalfrniaResidents_text15": "In order to exercise your rights, we will need to obtain information to locate you in our records or verify your identity depending on the nature of the request. If you are submitting a request on behalf of a household, we will need to verify each member of the household in the manner set forth in this section. Therefore, based on the request we might reach out to you for further information.",
    "lbl_noticeToCalfrniaResidents_text16": "We will respond to a Request to Opt-Out within 15 days. We will respond to Requests to Delete and Requests to Know within 45 days, unless we need more time in which case, we will notify you and may take up to 90 days total to respond to your request.",
    "lbl_viewSubEstate": "View Sub-estate",
    "lbl_installers": "Installers",
    "lbl_managedPanels": "Unmanaged panels",
    "lbl_days": "Days",
    "lbl_email": "E-mail",
    "lbl_company": "Company",
    "lbl_removeInstaller": "Remove Installer",
    "lbl_confirm": "Confirm",
    "msg_deleteAdminConfirmation": "Are you sure you want to remove",
    "lbl_editDetails": "Edit Details",
    "msg_adminAdded": "Administrator edited successfully",
    "msg_adminDeleted": "Administartor deleted successfully",
    "msg_errorDeleteAdmin": "Error in deleting administartor",
    "lbl_addAdmin": "Add Administrator",
    "lbl_info": "Info",
    "lbl_noOfPanels": "Number of panels",
    "lbl_dateRegistered": "Date Registered",
    "lbl_cmpnyAddress": "Company Address",
    "lbl_addressDetails": "Address Details",
    "txt_addressDetails": "To register for an Company account, Please provide address details of your company.",
    "lbl_contactDetails": "Contact Details",
    "txt_contactDetails": "To register for an Company account, Please provide contact details of your company.",
    "lbl_phoneType": "Phone Type",
    "msg_alreadyAccnt": "There is already an account for this email.",
    "txt_invalidEmail": "invalid email",
    "lbl_createAccnt": "Create an Account",
    "lbl_createAccntDetails": "To register for an Company account, enter the required information below.",
    "msg_pwdValidation": "Minimum 8 characters, at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character",
    "lbl_createPwd": "Create a Password",
    "txt_pwdLength": "Password must be at least 8 characters long, contain at least one upercase character, once number, and once special character.",
    "lbl_licenseAgreememnt": "License Agreement",
    "lbl_licenseAgreememntDetails": "To register for an Company account, You will need to read and agree to the Terms and Conditions.",
    "lbl_accntActivation": "Account Activation",
    "msg_accntActivation": "Congratulations, you have been registered with the login of",
    "lbl_accntActivationEmailSent": "We have sent an activation email to you, Please click link in that message to activate your account.",
    "lbl_notReceive": "Didn’t receive email?",
    "lbl_sendAgain": "Send Again",
    "lbl_cnfrmPwd": "Confirm Password",
    "msg_accntExists": "There is already an account for this email.",
    "lbl_forgotPwd": "If the User ID exists then a link to reset your password will be sent to",
    "lbl_RmtSvsMsg": "Unable to proceed, the panel is in installer mode",
    "lbl_planned": "PLANNED",
    "lbl_noPlannedService": "No Planned Service Reports",
    "lbl_rmtServInitiationMsg": "A manual remote service has been requested, please wait 5-10 mins for the report to be completed.",
    "lbl_okay": "Okay",
    "lbl_pwdReset": "Password reset",
    "lbl_setPwd": "Set password",
    "lbl_pwdResetMsg": "To reset your password, please enter a new password below.",
    "lbl_pwdResetSuccess": "Password reset success",
    "lbl_pwdResetSuccessMsg": "You password has been reset successfully. Log in to the app using your updated password.",
    "lbl_pwdResetErrMsg": "Password reset already requested in the last 15 minutes.",
    "btn_download_notifications": "Download Panel History",
    "btn_download_logs": "Download Logs",
    "lbl_userAccDelete": "User account deletion",
    "lbl_userAccDeleteConfirm": "Do you want to confirm account deletion?",
    "lbl_userAccDeleteMsg": "To request the removal an user account please enter the account email address below and then the submit button.",
    "lbl_userAccDeleteSubmitMsg": "Your request for user account deletion has been submitted. If the User ID exists then a link to reset your password will be sent to",
    "btn_rmvLogo": "Remove logo",
    "btn_cgnLogo": "Change logo",
    "lbl_endUserMsg": "The information provided here will be displayed in both the Mobile application and on Remote Servicing reports",
    "lbl_dwnPanelLog": "Download panel logs",
    "lbl_estatelessPanels": "Panels not within an Estate",
    "lbl_siteComments": "Comments",
    "mainHeadingforsystemConfig": "Header",
    "lbl_EatonGCMorFCMurl": "Eaton GCM or FCM  url",
    "lbl_EatonapiKey": "Eaton API Key",
    "lbl_eatonApplepassword": "Eaton Apple Password",
    "lbl_applePushNotification": "Apple use production push notification",
    "headingaccountSettings": "Account Setting",
    "lbl_account_default_state": "Account default State",
    "lbl_choose_state_for_new_account": "Choose state for new account installer",
    "lbl_account_disable_after": "Account disable after",
    "lbl_the_top_limit_in_days": "The top limit in days, after which an account will be disabled",
    "lbl_remove_unpaired_account_after": "Remove unpaired account after",
    "lbl_helper_text": "Helper Text",
    "lbl_cloud_password": "Cloud password",
    "lbl_user_registration_disable_time": "User registration disable time",
    "lbl_invalid_login_attempt": "Invalid login attempt lockout duration",
    "lbl_maximum_sign_in_attempt": "Maximum sign in attempts",
    "lbl_seconds": "seconds",
    "lbl_times": "times",
    "headingCertificates": "Certificates",
    "lbl_eaton_Apple_certificaates": "Eaton Apple certificate",
    "lbl_no_file_selected": "No File selected",
    "lbl_upload_file": "Upload File",
    "lbl_heartbeat_interval": "Heartbeat interval",
    "lbl_miliseconds": "miliseconds",
    "lbl_WS_session_drop_after": "WS session drop after",
    "lbl_jwt_time_to_live": "JWT time to live",
    "lbl_communication_log_size": "Communication log size",
    "lbl_panel_log_size": "Panel log download Size",
    "lbl_nesting_group_level": "Nesting group level",
    "lbl_ws_sessions_close_qty": "WS Sessions close qty",
    "lbl_async_context_timeout": "Async context timeout",
    "lbl_refer_header": "Referer header",
    "heading_Liceense_Agrrement": "License agreement",
    "heading_proxy": "Proxy",
    "lbl_proxy_host": "Proxy host",
    "lbl_proxy_port": "Proxy port",
    "lbl_use_proxy": "Use proxy",
    "lbl_fichet_gcmorfcm": "Fichet GCM or FCM url",
    "lbl_fichetApiKey": "Fichet API Key",
    "lbl_fichet_applePsword": "Fichet Apple password",
    "lbl_fichet_applepassword": "Fichet Apple certificate",
    "lbl_discard": "Discard",
    "heading_CSL": "CSL Settings",
    "lbl_csl_login": "CSL login",
    "lbl_csl_password": "CSL password",
    "lbl_csl_primary_ip": "CSL primary server IP",
    "lbl_csl_primary_port": "CSL primary server Port",
    "lbl_csl_secondary_IP": "CSL secondary server IP",
    "lbl_csl_secondary_port": "CSL secondary server Port",
    "lbl_csl_primary_protocol": "CSL primary server protocol",
    "lbl_csl_secondary_portocol": "CSL secondary server protocol",
    "lbl_poll_fails": "CSL poll fails after",
    "lbl_minutes": "minutes",
    "button_CSL_ARC": "CSL ARC codes",
    "button__csl_countries": "CSL Countries",
    "lbl_dual_com_login": "CSL DualCom login",
    "lbl_dual_com_password": "CSL DualCom password",
    "lbl_poll_failer_helper": "Send poll fail sia message to CSL if panel disconnected longer than this value in minutes",
    "lbl_add_email": "Add E-mail",
    "lbl_csl_support_team_e-mail_address": "CSL support team e-mail addresses",
    "lbl_Support_email_list_of_CSL_arc_provider": "Support email list of CSL arc provider",
    "lbl_support_team_email_address": "Support team e-mail addresses",
    "lbl_email_address_of_support_team": "Email addresses of support team",
    "heading_Permissions": "Permissions",
    "lbl_Enable_auth": "Enable auth",
    "lbl_Mail_transport_protocol_authorization": "Mail transport protocol authorization",
    "lbl_EnableTLS": "Enable TLS",
    "lbl_Mail_transport_protocol_enable": "Mail transport protocol enable",
    "heading_Info_SMTP": "Info SMTP",
    "lbl_SMTP_host": "SMTP host",
    "lbl_Mail_trasport_protocol_host": "Mail transport protocol host",
    "lbl_SMTP_port": "SMTP port",
    "lbl_Mail_trasport_protocol_post": "Mail transport protocol post",
    "lbl_SMTP_user": "SMTP user",
    "lbl_Mail_transport_protocol_user": "Mail transport protocol user",
    "lbl_SMTP_password": "SMTP password",
    "lbl_Mail_transport_protocol_password": "Mail transport protocol password",
    "lbl_Protocol": "Protocol",
    "lbl_Mail_transport_protocol": "Mail transport protocol",
    "lbl_Form": "Form",
    "lbl_Mail_transport_protocol_source": "Mail transport protocol source",
    "heading_Email_Settings": "Email Settings",
    "lbl_Email_Text_Language": "Email Text Language",
    "lbl_User_registration_e-mail": "User registration e-mail",
    "lbl_helper_text_for_user_register_mail": "This text will be sent to the user email.${user.login} will be replaced on login of the user, who will receive email ${user.name} - user name, ${user-cloudId} - user cloud id , ${confirm.link} - email confirmation link",
    "lbl_State_change_e-mail": "State change e-mail",
    "lbl_helpertext_for_state_email_change": "This text will be sent to the user email.${user.login} will be replaced on login of the user, who will receive email ${user.name} - user name, ${user.state} user state, ${user-cloudId} - user cloud id",
    "lbl_Forgot_login_e-mail_subject": "Forgot login e-mail subject",
    "lbl_Email_subject_for_login_reminder": "Email subject for login reminder",
    "lbl_Forgotlogine-mail": "Forgot login e-mail",
    "lbl_helpertext_for_forget_login_mail": "When you change description please insert variable ${user.name} and ${user.login} when you want to see user name and user login respectively",
    "lbl_App_user_creation_e-mail": "App user creation e-mail",
    "lbl_helper_text_for_app_user_creation": "This text will be sent to the user email. ${user.login} will be replaced on login of the user, who will receive email ${user.name} - user name ${confirm.link} - email confirmation link",
    "lbl_Forgot_passworde-mail": "Forgot password e-mail",
    "lbl_helper_text_for_forgetpassworde-mail": "This text will be sent to the user email. ${user.name} - user name ${secretLink} - URL for password changing",
    "lbl_Maintenance_notification_subject": "Maintenance notification subject",
    "lbl_Subject_for_all_user_maintenancenotification": "Subject for all user maintenance notification",
    "lbl_Maintenance_notificationtext": "Maintenance notification text",
    "lbl_helper_text_for_maintain_notigication": "This text will be sent to all user when it is necessary to notify them about some event on cloud server",
    "lbl_Raised_alert_event_e-mail_text": "Raised alert event e-mail text",
    "lbl_helper_text_for_raised_alert_event": "This will be sent to panel support team. ${site.name} - will be replaced to panel cloud reference name ${panel.id} - will be replaced on cloud panel id ${sia.code} - alert event SIA code ${description} - alert event SIA code description ${time.stamp} - timestamp when the alert was raised ${panel.link} -  Hyperlink to cloud panel screen",
    "lbl_Self_test_failure_text": "Self test failure text",
    "lbl_helper_text_for_test_failure_Text": "This text will be sent to support team.${event.ipAdd} - ip address of the failed node ${event.type} - event type ${event.message} - message ${event.connected} - connection status ${event.date} - timestamp of event",
    "lbl_SIA_message_failure_e-mail_subject": "SIA message failure e-mail subject",
    "lbl_Subject_to_message_about_fails_in_sending_sia_events": "Subject to message about fails in sending sia events",
    "lbl_SIA_message_failuree-mail_text": "SIA message failure e-mail text",
    "lbl_Email_text_about_siaevent_sendingproblems": "Email text about sia event sending problems",
    "lbl_time_in_seconds_for_locking_account": "Time in seconds for locking account when too much failed login attepts happened",
    "lbl_max_failed_login": "Max number of failed login attempts before locking account",
    "lbl_seconds_for_temporarily_disable_new_user_registration": "Time in seconds for temporarily disable new user registration",
    "lbl_credentials_for_the_cloud_user_into_the_panel": "Please provide credentials for the cloud user into the panel",
    "lbl_Days_to_remove_unpaired_user_accounts": "Days to remove unpaired user accounts",
    "lbl_google_firebase_push_notification": "Google or Firebase push notification server url",
    "lbl_api_key": "Api Key",
    "lbl_Apple_push_notification_server_password": "Apple push notification server password",
    "lbl_determines_apple_server": "Determines which Apple servers should be used: production or sandbox",
    "lbl_upload_certificate_for_eaton": "Upload a certificate for Eaton Apple Push Notification",
    "lbl_Heartbeat_intervalinmilliseconds": "Heartbeat interval in milliseconds",
    "lbl_Atime_delay_between_closing_session": "A time delay between closing session",
    "lbl_json_web_token": "JSON web token time to live",
    "lbl_hazelcast_max_comminication_log_size": "Hazelcast max communication log size",
    "lbl_Max_size_of_log_entries": "Max size of log entries to download from panel",
    "lbl_max_nesting_group_level": "Max nesting group level",
    "lbl_count_of_Web_token_which_will_be_closed": "A count of web socket sessions, which will be closed",
    "lbl_timeout_for_external_async_request": "Timeout for external async request",
    "lbl_pattern_for_refer_header": "Pattern for referer header",
    "heading_Support_actions": "Support actions",
    "lbl_User_e-mail": "User e-mail",
    "lbl_Action": "Action",
    "btn_Startaction": " Start action",
    "heading_Download_Reports": "Download Reports",
    "lbl_Installer_report": "Installer report",
    "lbl_Download": "Download",
    "lbl_App_userReport": "App user report",
    "lbl_Managed_panelsreport": "Managed panels report",
    "lbl_Unmanaged_panels_report": "Unmanaged panels report",
    "lbl_ARC_report": "ARC report",
    "heading_confirmation": "Confirmation",
    "msg_for_Pairing": "This will remove all user information (pairing, mobile devices) and he will not be able to access it anymore",
    "msg_for_remove_pariring": "Are you sure you want to remove pairing between",
    "msg_for_disable_registration": "Are you sure you want to temporary disable registrations from cloud and app?",
    "lbl_User_Info": "User Info",
    "error_pwd_info_capital": "Please use uppercase letters (X,Y,Z...),",
    "error_pwd_small": "lowercase letters (x,y,z...),",
    "error_pwd_number": "numbers (1,2,3,4...),",
    "error_pwd_special": "and special characters (!@#$%^&*)in the password in the password",
    "error_pwd_length": "Password length should not be less than 6 characters.",
    "lbl_installer_Details": "Installer Details",
    "lbl_error_for_Date_Range":"No Matching result for selected date range",
};

export default resources;