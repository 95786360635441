/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { TextField } from '@mui/material';
import { NameDescriptionFormProps } from './newEstateTypes';
import { useTranslation } from 'react-i18next';

export default function NameDescriptionForm({setFilled,name,description,setName,setDescription}:NameDescriptionFormProps):JSX.Element {
  const { t } = useTranslation();
  
    if(name !== '' && description !== ''){
      setFilled(false)
    }
    else{
      setFilled(true)
    }
 
  return (
      <div>
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField inputProps={{ maxLength: 24 }} fullWidth id="filled-basic" 
                      label={t('lbl_name')} variant="filled" 
                      value={name} onChange={(e) => setName(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" 
                      label={t('lbl_description')} variant="filled" 
                      value={description} onChange={(e) => setDescription(e.target.value)} />
              </Grid>
            </Grid>
            </Box>
            </div>
  );
}
