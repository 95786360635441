/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Appbar from '../../components/Appbar/Appbar';
import { Box, Grid, Paper, styled, useMediaQuery, useTheme } from '@mui/material';
import SupportAction from '../../components/SupportTools/SupportAction/SupportAction';
import DownloadReports from '../../components/SupportTools/DownloadReports/DownloadReports';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,

}));

export function SupportTools(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            <div className="main-div">
                <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                    <Appbar toolbarMenu={false} title={'Support tools'} />
                    <Box sx={md ? { flexGrow: 1, pb: 40, maxHeight: '100vh', overflow: "auto" } : { marginLeft: 8, marginRight: 8, pb: 20, mt: 7, }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Item>
                                    <SupportAction />
                                </Item>
                            </Grid>
                            <Grid item xs={12}>
                                <Item>
                                    <DownloadReports />
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </div>
        </>
    )
}