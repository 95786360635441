/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Divider, Paper, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { verifyEmailAddress } from '../../redux/actions/registerScreenActions';
import { useSelector } from 'react-redux';

type Props = {
  setEmailVerify:(emailVerify:boolean) => void
  email:string
  setEmail:(email:string) => void
  firstName:string
  setFirstName:(firstName:string) => void
  lastName:string
  setLastName:(lastName:string) => void
}

export default function CreateAccount({setEmailVerify,email,setEmail,firstName,setFirstName,lastName,setLastName}:Props):JSX.Element {
  const dispatch = useTypedDispatch()
  const [helperText, setHelperText] = useState('')
  const [helperTextFirstName, setHelperTextFirstName] = useState('')
  const [helperTextLastName, setHelperTextLastName] = useState('')
  const [verify, setVerify] = useState(false)
  const {emailVerifyStatus} = useSelector((state:any) => state.registerScreenReducer)
  const { t, i18n } = useTranslation();

  const isValidEmail = (mail:string) => {
    const isValid = /\S+@\S+\.\S+/.test(mail);
    return isValid
  }

  const handleMail = async(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value)
    if(isValidEmail(e.target.value)){
      await dispatch(verifyEmailAddress({email:e.target.value}))
      setVerify(true)
    }
    else{
      if(e.target.value.length > 0)
      setHelperText('Invalid email address')
      else 
      setHelperText('This field is required')
    }
  }
  
try{
 if(verify){
  if(emailVerifyStatus === 400){
    setHelperText(t('msg_accntExists'))
    setEmailVerify(false)
  }
  else if(emailVerifyStatus === 200){
    setHelperText('')
    setEmailVerify(true)
  }
  else{
    setHelperText(t('txt_invalidEmail'))
    setEmailVerify(false)
  }
  setVerify(false)
 }
}
catch(e){
  console.log(e)
}
 
  return (
      <div>
        <Paper elevation={0} sx={{ paddingTop:2, paddingBottom:3, paddingLeft:3, paddingRight:2 }}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant='h6'>{t('lbl_createAccnt')}</Typography>
              <Typography variant='body1'>{t('lbl_createAccntDetails')}</Typography>
              </Stack>
        </Paper>
            <Divider />
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" inputProps={{maxLength : 70}}
                      label={t('lbl_emailAddress')} variant="filled" 
                      helperText={helperText}
                      error={helperText.length > 0}
                      value={email} onChange={(e) => handleMail(e)}
                     />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth inputProps={{maxLength : 30,minLength:2}} id="filled-basic" 
                      label={t('lbl_firstName')} variant="filled" 
                      helperText={helperTextFirstName}
                      error={helperTextFirstName.length > 0}
                      value={firstName} onChange={(e) => {
                        setFirstName(e.target.value)
                        if(e.target.value.length === 0){
                          setHelperTextFirstName('This field is required')
                        }
                        else if(e.target.value.length >= 1 && e.target.value.length < 2 ){
                          setHelperTextFirstName('Input length should not be less than 2 characters')
                        }
                        else if(e.target.value.length >= 30){ 
                          setHelperTextFirstName('Input length should not be more than 30 characters')
                        }
                        else{
                          setHelperTextFirstName('')
                        }
                      }}
                       />
              </Grid>
              <Grid item xs={12}>
                <TextField inputProps={{maxLength : 30, minLength:2}} fullWidth id="filled-basic" 
                      label={t('lbl_lastName')} variant="filled"
                      helperText={helperTextLastName}
                      error={helperTextLastName.length > 0}
                      value={lastName} onChange={(e) => {
                        setLastName(e.target.value)
                        if(e.target.value.length === 0){
                          setHelperTextLastName('This field is required')
                        }
                        else if(e.target.value.length >= 1 && e.target.value.length < 2 ){
                          setHelperTextLastName('Input length should not be less than 2 characters')
                        }
                        else if(e.target.value.length >= 30){ 
                          setHelperTextLastName('Input length should not be more than 30 characters')
                        }
                        else{
                          setHelperTextLastName('')
                        }
                      }}
                       />
              </Grid>
            </Grid>
            </Box>
            </div>
  );
}
