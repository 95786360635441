/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ConnectionStats = ():JSX.Element => {
  const { panelConnectionStats}  = useSelector((state:any) => state.specificPanelAndEstateReducer)
  const {statisticsFor30Days, statisticsFor60Days, statisticsFor90Days} = panelConnectionStats
  const { t } = useTranslation();
  return (
    <>
       <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
              <TableCell variant='head' style={{color:'#1397D9'}}>{t('lbl_connectionStats')}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
          </TableRow>
          <TableRow>
              <TableCell></TableCell>
              <TableCell variant='head'>
                  30 {t('lbl_days')}
              </TableCell>
              <TableCell variant='head'>60 {t('lbl_days')}</TableCell>
              <TableCell variant='head'>90 {t('lbl_days')}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>{t('lbl_totalTime')}</TableCell>
              <TableCell>{statisticsFor30Days?.totalTime}</TableCell>
              <TableCell>{statisticsFor60Days?.totalTime}</TableCell>
              <TableCell>{statisticsFor90Days?.totalTime}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>{t('lbl_connectedTime')}</TableCell>
              <TableCell>{statisticsFor30Days?.connectedHours}</TableCell>
              <TableCell>{statisticsFor60Days?.connectedHours}</TableCell>
              <TableCell>{statisticsFor90Days?.connectedHours}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>{t('lbl_disconnectedTime')}</TableCell>
              <TableCell>{statisticsFor30Days?.disconnectedHours}</TableCell>
              <TableCell>{statisticsFor60Days?.disconnectedHours}</TableCell>
              <TableCell>{statisticsFor90Days?.disconnectedHours}</TableCell>
          </TableRow>
          <TableRow>
              <TableCell variant='head'>{t('lbl_connectedPercent')}</TableCell>
              <TableCell>{statisticsFor30Days?.connectedPercents}</TableCell>
              <TableCell>{statisticsFor60Days?.connectedPercents}</TableCell>
              <TableCell>{statisticsFor90Days?.connectedPercents}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </>
  )
}

export default ConnectionStats