/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */


import { AppUserReports, ArcDownloadReports, InstallerDownloadReports, ManagedPanelReports } from "../../components/SupportTools/SupportToolsDownloadReports";
import { systemToolsType } from "../constants/systemToolActionType"

const initialState = {
    enableRegistration: <any>{},
    disableRegistration: <any>{},
    maintenanceNotification: <any>{},
    generateTestNotification: <any>{},
    removePairing: <any>{},
    removeApplicationUser: <any>{},
    appUserReports: <AppUserReports>{},
    managedPanelsDownloadReports: <ManagedPanelReports>{},
    unmanagedPanelDownloadReports: <ManagedPanelReports>{},
    arcDownloadReports: <ArcDownloadReports>{},
    installerDownloadReports: <InstallerDownloadReports>{}
}
export const systemToolReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case systemToolsType?.ENABLE_REGISTRATION:
            return { ...state, enableRegistration: action.payload }
            break;

        case systemToolsType?.DISABLE_REGISTRATION:
            return { ...state, disableRegistration: action.payload }
            break;

        case systemToolsType?.SEND_MAINTENANCE_NOTIFICATION:
            return { ...state, maintenanceNotification: action.payload }
            break;

        case systemToolsType?.GENERATE_TEST_NOTIFICATION:
            return { ...state, generateTestNotification: action.payload }
            break;

        case systemToolsType?.REMOVE_PAIRING:
            return { ...state, removePairing: action.payload }
            break;

        case systemToolsType?.REMOVE_APPLICATION_USER:
            return { ...state, removeApplicationUser: action.payload }
            break;

        case systemToolsType?.APP_USER_DOWNLOAD_USER:
            return { ...state, appUserReports: action.payload }
            break;

        case systemToolsType?.MANAGED_PANELS_DOWNLOAD_REPORTS:
            return { ...state, managedPanelsDownloadReports: action.payload }
            break;

        case systemToolsType?.UNMANAGED_PANELS_DOWNLOAD_REPORTS:
            return { ...state, unmanagedPanelDownloadReports: action.payload }
            break;

        case systemToolsType?.ARC_DOWNLOAD_REPORTS:
            return { ...state, arcDownloadReports: action.payload }
            break;

        case systemToolsType?.INSTALLER_DOWNLOAD_REPORTS:
            return { ...state, installerDownloadReports: action.payload }
            break;
        default:
            return { ...state }
            break;
    }
}