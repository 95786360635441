/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */

import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Button, Paper, Typography, useMediaQuery, useTheme, styled, TextField, InputAdornment, Divider, Snackbar } from '@mui/material';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import SearchIcon from '@mui/icons-material/Search';
import PendingAndFailedServices from './PendingAndFailedServices';
import CompletedServicesReports from './CompletedServicesReports';
import { fetchCompletedReportsListForPanel, fetchPendingAndFailedReportsListForPanel, performRemoteServicing } from '../../redux/actions/remoteServicingActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from '../CircularLoader/Loader';
import ScheduledServices from './ScheduledServices';
import RemoteServiceInitiationMessageModal from '../ServiceReportsModals/RemoteServiceInitiationMessageModal';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function PanelRemoteServicing(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const [counter, setCounter] = React.useState<number>(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [dataLoaded, setDataLoaded] = React.useState(false);
    const [openRemoteServiceMessage, setOpenRemoteServiceMessage] = React.useState(false);
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { performRemoteServicingStatus, performRemoteServicingStatusMessage,performRemoteServicingData } = useSelector((state: any) => state.remoteServicingReducer);
    const { pendingAndFailedReportsList } = useSelector((state: any) => state.remoteServicingReducer);
    const { t } = useTranslation();
    const [failedReportCall, setFailedReportCall] = React.useState(true);


    React.useEffect(() => {
        if (counter !== 0) {
            if (performRemoteServicingStatus === 200) {
                if(performRemoteServicingData?.success === true){
                setMessage(`${t('msg_remoteSvc_initiated')}`);
                setOpenRemoteServiceMessage(true)
                }
                else{
                    setMessage(`${t('msg_remoteSvc_inProgress')}`);
                    setSnackOpen(true)
                }
            } 
            else {
                if(performRemoteServicingStatus === 403){
                   
                    setMessage(`${t('lbl_RmtSvsMsg')}`)
                }
                else{
                setMessage(`${t('lbl_somethingWrong')}`);
                }
                setSnackOpen(true);

                
                const interval = setTimeout(() => {
                    dispatch(fetchCompletedReportsListForPanel(specificPanel?.panelId))
                }, 5000)

            }
        }
        if(failedReportCall){
        dispatch(fetchPendingAndFailedReportsListForPanel(specificPanel?.panelId))
        setFailedReportCall(false)
        }
        setTimeout(()=>{
            setDataLoaded(true);
        }, 2000)
    }, [counter])

    const handlePerformRemoteService = async () => {
        setDataLoaded(false)

        const body = {
           // "trigger": "true"
        }
        await dispatch(performRemoteServicing(specificPanel?.panelId, body));
        setCounter(counter + 1);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
                {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div style={md ? {} : { maxHeight: '100vh', overflow: 'auto' }}>
            {!dataLoaded && (
                    <Loader />
                )}
            <Box
                sx={
                    md
                        ? {
                            flexGrow: 1,
                            marginLeft: -2,
                            marginRight: -2,
                            marginTop: 0,
                            maxHeight: '100vh',
                            overflow: 'auto',
                            pb: 40,
                        }
                        : {
                            flexGrow: 1,
                            marginLeft: 5,
                            marginRight: 5,
                            marginTop: 2,
                            pb: 40,
                        }
                }
            >
                <Grid container spacing={md ? 2 : 4}>
                    <Grid item  xs={md ? 12 : 3}>
                        <Item sx={{ p: md ? 2 : 0 }} elevation={0}>
                            <Button
                                variant="contained"
                                sx={{ width: '100%' }}
                                onClick={handlePerformRemoteService}
                                disableElevation
                                startIcon={<Maintenance />}
                            >
                                {t('btn_perform_remoteSvc')}
                            </Button>
                        </Item>
                    </Grid>
                    {md ? <></> : <Grid item xs={9}></Grid>}
                    <Grid item xs={12}>
                        <Item elevation={1} sx={{ pb: 2, backgroundColor:md?'#f7f8f8':'#ffff' }}>
                            <Box sx={{ flexGrow: 1, marginLeft: md ? 0 : 2, marginRight: md ? 0 : 2, marginTop: 2 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Item elevation={0}></Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item elevation={md ? 0 : 1}>
                                            <Typography sx={{ p: 2 }} variant="subtitle2" color="primary">
                                            {t('lbl_ScheduledSvc')}
                                            </Typography>
                                            {md && <Divider />}
                                            <ScheduledServices />
                                        </Item>
                                    </Grid>
                                   { pendingAndFailedReportsList.length !== 0 && pendingAndFailedReportsList !== undefined && pendingAndFailedReportsList.length !== null &&
                                   <Grid item xs={12}>
                                        <Item elevation={md ? 0 : 1}>
                                            <Typography sx={{ p: 2 }} variant="subtitle2" color="primary">
                                            {t('lbl_failedSvc')}
                                            </Typography>
                                            {md && <Divider />}
                                            <PendingAndFailedServices />  
                                        </Item>
                                    </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Item elevation={md?0:1}>
                                            <Typography sx={{ p: 2 }} variant="subtitle2" color="primary">
                                                {t('lbl_cmpltd_svcReports')}
                                            </Typography>
                                            {md && <Divider />}
                                            <CompletedServicesReports />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
                <Snackbar
                    open={snackOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackClose}
                    message={message}
                    action={action}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </Box>
            <RemoteServiceInitiationMessageModal open={openRemoteServiceMessage} setOpen={setOpenRemoteServiceMessage} />
        </div>
    );
}
