export const authWorkflowFrench =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Email ou mot de passe incorrect',
            INVALID_CREDENTIALS: `Votre combinaison nom d'utilisateur/mot de passe n'est pas reconnue.`,
            GENERIC_ERROR: "Votre demande n'a pas pu être traitée pour le moment.",
        },
        HEADER: {
            FORGOT_PASSWORD: 'Mot de passe oublié',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Veuillez sélectionner un mot de passe. Assurez-vous que votre mot de passe répond aux exigences de complexité nécessaires décrites ci-dessous.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                `Veuillez saisir l'e-mail du compte associé au compte.<br/><br/>` +
                `Si cet e-mail possède un compte chez Eaton, vous recevrez une réponse dans les délais <1>{{time}}</1>.`,
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowFrench =  {
    translation: {
        ACTIONS: {
            FINISH: 'Finition',
            NEXT: 'Suivant',
            BACK: 'Dos',
            CREATE_ACCOUNT: 'Créer un compte',
            OKAY: "D'accord",
            CANCEL: 'Annuler',
            CONTINUE: 'Continuer',
            DONE: 'Fait',
            LOG_IN: 'Se connecter',
            LOG_OUT: 'Se déconnecter',
            RESEND: 'Envoyer à nouveau',
            REMEMBER: 'Souviens-toi de moi',
            SUBMIT: 'Soumettre',
        },
        LABELS: {
            EMAIL: 'Adresse e-mail',
            PASSWORD: 'Mot de passe',
            FORGOT_PASSWORD: 'Mot de passe oublié?',
            NEED_ACCOUNT: `Besoin d'un compte?`,
        },
        MESSAGES: {
            EMAIL_SENT: 'Email envoyé',
            WELCOME: 'Bienvenu',
            REQUEST_ERROR: `Désolé, un problème est survenu lors de l'envoi de votre demande.`,
            ERROR: 'Erreur!',
            EMAIL_ENTRY_ERROR: 'Veuillez entrer un email valide',
            CONTACT: 'Contactez un représentant du support Eaton',
            LOADING: 'Chargement...',
            PASSWORD_REQUIRED_ERROR: 'Mot de passe requis',
        },
        FORMS: {
            FIRST_NAME: 'Prénom',
            LAST_NAME: 'Nom de famille',
            PASSWORD: 'Mot de passe',
            CONFIRM_PASSWORD: 'Confirmez le mot de passe',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 caractères',
            NUMBERS: 'Un nombre',
            UPPER: 'Une lettre majuscule',
            LOWER: 'Une lettre minuscule',
            SPECIAL: 'Un personnage spécial',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowFrench = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: 'Chargement du contrat de licence utilisateur final...',
                AGREE_TERMS: `J'ai lu et j'accepte les conditions générales`,
            },
            STEPS: {
                CREATE_ACCOUNT: 'Créer un compte',
                LICENSE: 'Accord de licence',
                VERIFY_EMAIL: 'Vérifier les courriels',
                PASSWORD: 'Créer un mot de passe',
                ACCOUNT_DETAILS: 'Détails du compte',
                COMPLETE: 'Compte créé!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: 'Entrez vos coordonnées ci-dessous pour finaliser la création du compte.',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `Pour créer un compte Eaton, entrez les informations requises ci-dessous. Vous devrez vérifier votre adresse e-mail pour continuer.`,
                VERIFY_EMAIL: {
                    RESEND: `Renvoyer l'e-mail de vérification`,
                    VERIFICATION: 'Le code de vérification',
                },
            },
        },
    },
};