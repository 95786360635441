/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';

export default function EditAdminForm({
    firstName,
    lastName,
    login,
    userType,
    setFirstName,
    setLastName,
    setLogin,
    setUserType,
    language,
    setLanguage
}: any): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [helperText, setHelperText] = React.useState('')
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const { t } = useTranslation();

    React.useEffect(() => {
       
    }, [])

    const isValidEmail = (email:string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
      }
    
    const handleLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLogin(e.target.value)
       if(!isValidEmail(e.target.value)){
        setHelperLoginText(`${t('msg_email_invalid')}`)
       }
       else{
        setHelperLoginText('')
       }
       
    }


    const userTypes = [
        {
            code: 'ADMIN',
            value: 'Administrator',
        },
        {
            code: 'NORMAL_ADMIN',
            value: 'Normal Administrator',
        },
    ];

    const languages = [
        {
            code : 'en_US',
            value: 'English',
        },
        {
            code:'de_DE',
            value: 'Deutsch',
        },
        {
            code:'nl_NL',
            value: 'Nederlands',
        },
        {
            code:'fr_FR',
            value: 'Francais',
        },
        {
            code:'el_GR',
            value: 'Ἑλληνική',
        },
        {
            code:'it_IT',
            value: 'Italiano',
        },
        {
            code:'pt_PT',
            value: 'Portugues',
        },
    ];

    return (
        <Box
            sx={{
                flexGrow: 1,
                paddingTop: 3,
                paddingBottom: 5,
                paddingLeft: 3,
                paddingRight: 3,
                maxHeight:md?'75vh': 450,
                overflow: 'auto',
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={md?12:6}>
                    <TextField inputProps={{ maxLength: 24 }} value={firstName} fullWidth id="filled-basic" label={t('lbl_firstName')} variant="filled" onChange={(e) => setFirstName(e.target.value)} />
                </Grid>
                <Grid item xs={md?12:6}>
                    <TextField inputProps={{ maxLength: 24 }} value={lastName} fullWidth id="filled-basic" label={t('lbl_lastName')} variant="filled" onChange={(e) => setLastName(e.target.value)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField inputProps={{ maxLength: 24 }} value={login} fullWidth type={'email'} id="filled-basic" label={t('lbl_login')} variant="filled" error={helperLoginText.length > 0} helperText={helperLoginText} onChange={(e) => handleLogin(e)} />
                </Grid>
                <Grid item xs={12}>
                    <TextField select value={language} fullWidth id="filled-basic" label={'Select Language'} variant="filled" onChange={(e) => setLanguage(e.target.value)} >
                        {languages.map((user,i) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField select value={userType} fullWidth id="filled-basic" label={t('lbl_typeUsr')} variant="filled" onChange={(e) => setUserType(e.target.value)} >
                        {userTypes.map((user) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </Box>
    );
}
