/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Spacer } from '@brightlayer-ui/react-components';
import { Divider, MobileStepper, Snackbar, useMediaQuery, useTheme } from '@mui/material';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import {
    fetchPanelARCSettings,
    fetchSpecificPanelAndEstate,
    panelArcAccessDisable,
} from '../../redux/actions/specificPanelActions';
import { useParams } from 'react-router';
import { useTranslation, Trans } from 'react-i18next';

type ARCAccessDisableModalProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export default function ARCAccessDisableModal({ open, setOpen }: ARCAccessDisableModalProps): JSX.Element {
    const { id } = useParams();
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { specificPanel, arcDisableStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [count, setCount] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const dispatch = useTypedDispatch();
    const { t, i18n } = useTranslation();

    React.useEffect(() => {
        if (arcDisableStatus === 204) {
            setMessage(`${t('msg_ARC_disabled')}`);
        } else {
            setMessage(`${t('msg_error')}`);
        }
        dispatch(fetchSpecificPanelAndEstate(Number(id)));
        dispatch(fetchPanelARCSettings(Number(id)));
    }, [count]);

    const handleClose = () => setOpen(false);

    const handleDisable = async () => {
        setOpen(false);
        await dispatch(panelArcAccessDisable(specificPanel.panelId));
        setSnackOpen(true);
        setCount((prev) => prev + 1);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: md ? 300 : 400,
                    bgcolor: 'background.paper',
                    border: '0px solid #000',
                    boxShadow: 20,
                    p: 0,
                }}
                >
                    <Typography sx={{ p: 3 }} id="modal-modal-title" variant="h6" component="h2">
                    {t('lbl_disable_ARC')}
                    </Typography>
                    <Typography sx={{ pl: 3, pr: 3, pt: 1, pb: 4 }} id="modal-modal-description">
                    {t('msg_disable_ARC')}
                    </Typography>
                    {md ? <></> : <Divider />}
                    {md ? <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10, paddingRight: 5 }}>
                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        <Button disableRipple disableFocusRipple onClick={handleClose} sx={{ backgroundColor: 'inherit' }}>{t('btn_cancel')}</Button>
                        <Button disableRipple disableFocusRipple onClick={handleDisable} sx={{ backgroundColor: 'inherit' }}>{t('btn_disable')}</Button>
                    </div> :
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            activeStep={0}
                            sx={{
                                maxWidth: 400,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                            }}
                            nextButton={
                                <Button
                                    size="medium"
                                    variant="contained"
                                    sx={{ backgroundColor: '#CA3C3D' }}
                                    onClick={handleDisable}
                                >
                                    {t('btn_disable')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant="outlined" onClick={handleClose}>
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />}
                </Box>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    );
}
