/* eslint-disable @typescript-eslint/await-thenable */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-shadow */
import { Dispatch } from 'react';
import { ARDSchedulerProps, PendingAndFailedReportsArrayProps, RemoteServiceReportArrayProps } from '../../components/RemoteServicingModal/remoteServicingTypeProps';
//import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
import { remoteServicingActionTypes } from '../constants/remoteServicingActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { REACT_APP_BASE_URL, REACT_ARD_BASE_URL } from '../../entities/Endpoints';

const setReportsListForPanel = (reportsList: RemoteServiceReportArrayProps): any => {
    return {
        type: remoteServicingActionTypes.All_REPORTS,
        payload: reportsList,
    };
};

const setFailedAndPendingReportsListForPanel = (pendingAndFailedReportsList: PendingAndFailedReportsArrayProps): any => {
    return {
        type: remoteServicingActionTypes.All_PENDING_AND_FAILED_REPORTS,
        payload: pendingAndFailedReportsList,
    };
};

const setDeleteReportFromListForPanel = (deleteReportStatus: number): any => {
    return {
        type: remoteServicingActionTypes.DELETE_REPORTS,
        payload: deleteReportStatus,
    };
};

const setPerformRemoteServicingStatus = (status: number): any => {
    return {
        type: remoteServicingActionTypes.PERFORM_REMOTE_SERVICING,
        payload: status,
    };
}
const setPerformRemoteServicingData = (performRemoteServicingData: any): any => {
    return {
        type: remoteServicingActionTypes.PERFORM_REMOTE_SERVICING_DATA,
        payload: performRemoteServicingData,
    };
}
const setPerformRemoteServicingStatusMessage = (statusMessage: any): any => {
    return {
        type: remoteServicingActionTypes.PERFORM_REMOTE_SERVICING_MESSAGE,
        payload: statusMessage,
    };
}
 export const getPerformRemoteServicingStatus = (enabled: any): any => {
    return {
        type: remoteServicingActionTypes.GET_PERFORM_REMOTE_SERVICING_STATUS,
        payload: enabled,
    };
}
 export const setDeleteFailedServiceStatus = (deleteFailedServiceStatus: number): any => {
    return {
        type: remoteServicingActionTypes.GET_DELETE_FAILED_SERVICE_STATUS,
        payload: deleteFailedServiceStatus,
    };
}

 export const setARDScheduler = (scheduler: ARDSchedulerProps): any => {
    return {
        type: remoteServicingActionTypes.GET_ARD_SCHEDULER,
        payload: scheduler,
    };
}

 export const setARDSchedulerStatus = (schedulerStatus: number): any => {
    return {
        type: remoteServicingActionTypes.GET_ARD_SCHEDULER_STATUS,
        payload: schedulerStatus,
    };
}

 export const setRemoteServicingEmailDestinationsStatus = (registerStatus: any): any => {
    return {
        type: remoteServicingActionTypes.GET_ARD_EMAIL_REGISTER_STATUS,
        payload: registerStatus,
    };
}

 export const setDeleteRemoteServicingEmailDestinationsStatus = (deleteStatus: any): any => {
    return {
        type: remoteServicingActionTypes.GET_ARD_EMAIL_DELETE_STATUS,
        payload: deleteStatus,
    };
}

 export const setEmailDestinations = (emailDestinations: any): any => {
    return {
        type: remoteServicingActionTypes.GET_ARD_EMAIL_DESTINATIONS,
        payload: emailDestinations,
    };
}

export function fetchCompletedReportsListForPanel(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/panels/ard/snapshot/${panelId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setReportsListForPanel(data.reverse()))
            })
    }
}
export function fetchPendingAndFailedReportsListForPanel(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/scheduler/${panelId}/failures`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setFailedAndPendingReportsListForPanel(data.reverse()))
            })
    }
}
export function deleteFailedServiceReport(snapshotId: number, panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/scheduler/${panelId}/failures/${snapshotId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((res) => {
                dispatch(setDeleteFailedServiceStatus(res.status))
            })
    }
}

export function deleteCompletedReportFromListForPanel(panelId: string, reportId: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/panels/ard/snapshot/${panelId}/${reportId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then(async (data) => {
                await dispatch(setDeleteReportFromListForPanel(data.status))
            })
    }
}

export function performRemoteServicing(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/scheduler/${panelId}/manualTrigger`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(async(res) => {
                if(res.status === 200){
                const data = await res.json()               
                await dispatch(setPerformRemoteServicingStatus(res.status))
                await dispatch(setPerformRemoteServicingData(data))
                } 
            })
    }
}

export function setRemoteServicingEnabled(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/enabled/${panelId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(async(res) => {
               
                await dispatch(setPerformRemoteServicingStatus(res.status))
                
            })
    }
}

export function getRemoteServicing(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/enabled/${panelId}`, {
            method: 'GET',
            credentials: 'include',
        }).then(async(res) => {
                if(res.status === 200 && res !== undefined){
                   const data :any = await res.json()
                   if(data.state === 'installer'){
                    await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:data.state}))
                   }
                   else
                    await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:data.enabled}))
                }
                else{
                    await dispatch(getPerformRemoteServicingStatus({status:res.status,enabled:false}))
                }
            })
    }
}

export function fetchARDscheduler(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/scheduler/${panelId}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then(async(res) => {
                if(res.status === 200){
                    const data = await res.json()
                    await dispatch(setARDScheduler(data))
                }
                else{
                    await dispatch(setARDScheduler({
                        desiredServiceMonthDay:0,
                        panelId:'no-panel',
                        nextServiceDate:'0000-00-00',
                        intervalInMonths:0
                    }))
                }
            })
    }
}

export function performARDScheduler(panelId: string, body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/ardconfig/scheduler/${panelId}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((res) => dispatch(setARDSchedulerStatus(res.status)))
    }
}

export function performRemoteServicingEmailDestinations(body: any,panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/comms-email-service/email-config/${panelId}`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((res) =>res.json())
            .then((data) => dispatch(setRemoteServicingEmailDestinationsStatus(data)))
    }
}

export function performEditRemoteServicingEmailDestinations(body: any,panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/comms-email-service/email-config/${panelId}`, {
            method: 'PATCH',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((res) =>res.json())
            .then((data) => dispatch(setRemoteServicingEmailDestinationsStatus(data)))
    }
}
export function performDeleteRemoteServicingEmailDestinations(body: any, panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/comms-email-service/email-config/${panelId}`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((res) =>res.json())
            .then((data) => dispatch(setDeleteRemoteServicingEmailDestinationsStatus(data)))
    }
}

export function fetchRemoteServicingEmailDestinations(panelId: string) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_ARD_BASE_URL}/m2m-eaton-web/comms-email-service/email-config/${panelId}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) =>res.json())
            .then((data) => dispatch(setEmailDestinations(data)))
    }
}