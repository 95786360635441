/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { EstateMenuListProps } from './EstateMenuListTypes';
import ManagePanelsModal from '../ManagePanelsAndEstatesModal/ManagePanelsModal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchEstatesAndPanels, fetchPanelsInEstate } from '../../redux/actions/managePanelsAndEstatesActions';
import { EstateProps } from '../ListAndGridView/panelEstateTypes';
import { useSelector } from 'react-redux';
import DeleteEstateModal from '../DeleteEstateModal/DeleteEstateModal';
import { useTranslation } from 'react-i18next';
import ManagePanelsModalForEstate from '../ManagePanelsAndEstatesModal/ManagePanelsModalForEstate';
import secureLocalStorage  from  "react-secure-storage";

export default function EstateMenuList({ path, screen, subEstates, estatePage, anchorEl, open, setAnchorEl, estateId, estateName }: EstateMenuListProps): JSX.Element {
  const dispatch = useTypedDispatch()
  const { currentUser, estates, unassignedPanels } = useSelector((state: any) => state.userReducer);
  const { installerDetails } = useSelector((state: any) => state.installerReducer);
  const specificEstate: any = secureLocalStorage.getItem('estate') || {};
  const { panelsInEstate } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
  const [panelsInEstates, setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const [managePanelsOpen, setManagePanelsOpen] = React.useState(false);
  const [count, setCount] = React.useState(0)
  const [movePanel, setMovePanel] = React.useState(false)
  const { t } = useTranslation();

  const handleClose = (): any => {
    setAnchorEl(null);

  };

  const handleManageEstate = async() => {
    if(estatePage && subEstates !== undefined){
      await dispatch(fetchPanelsInEstate(subEstates.sort((a:EstateProps, b:EstateProps):any => 
    {if(a.ID !== undefined  && b.ID !== undefined)
        {
           return a.ID - b.ID
        }}
    )))
    await dispatch(fetchEstatesAndPanels(specificEstate))
    }
    else {
    await dispatch(fetchPanelsInEstate(estates.sort((a:EstateProps, b:EstateProps) => 
    {if(a.ID !== undefined  && b.ID !== undefined)
        {
           return a.ID - b.ID
        }}
    )))
    if(path === 'panels')
    await dispatch(fetchEstatesAndPanels(currentUser))
    else
    await dispatch(fetchEstatesAndPanels(installerDetails))
  }
    setMovePanel(true)
    setManagePanelsOpen(true)
    setAnchorEl(null);
  };

  const handleDeleteEstate = (): any => {
    setAnchorEl(null);
    setOpenDeleteModal(true)
  };

  return (
    <div>
      <Menu
        elevation={2}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "center" }}
      >
        <MenuItem  onClick={handleManageEstate}>
        {t('lbl_manageEstates')}
        </MenuItem>
        <MenuItem onClick={handleDeleteEstate}>
         {t('lbl_delete')}
        </MenuItem>
      </Menu>
      {
        screen === 'estate' ? <ManagePanelsModalForEstate estatePage={estatePage} movePanel={movePanel} setCount={setCount} count={count} panelsInEstates={panelsInEstates} setPanelsInEstates={setPanelsInEstates} open={managePanelsOpen} setOpen={setManagePanelsOpen} />
        :
        <ManagePanelsModal path={path} estatePage={estatePage} movePanel={movePanel} setCount={setCount} count={count} panelsInEstates={panelsInEstates} setPanelsInEstates={setPanelsInEstates} open={managePanelsOpen} setOpen={setManagePanelsOpen} />
      }
      <DeleteEstateModal estatePage={estatePage} open={openDeleteModal} setOpen={setOpenDeleteModal} selectedEstateId={estateId} selectedEstateName={estateName} />
    </div>
  );
}
