/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-floating-promises */

import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchInstallerEmailAlerts, fetchInstallerEmailAlertsArray, fetchPanelARCSettings, fetchSpecificPanelAndEstate, fetchUserEmailAlerts } from '../../redux/actions/specificPanelActions';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Maintenance } from '@brightlayer-ui/icons-mui';
import { Report } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getRemoteServicing } from '../../redux/actions/remoteServicingActions';
import PeopleIcon from '@mui/icons-material/People';

const PanelAccess = (): JSX.Element => {
    const { specificPanel, panelARCSettings, installerEmailAlertsStatusValue, userEmailAlerts } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { enabled } = useSelector((state: any) => state.remoteServicingReducer)
    const navigate = useNavigate();
    //const dispatch = useTypedDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const handlePanelAccessEditButton = () => {
        navigate(`/panel/${id}/panel-access`);
    };

    useEffect(() => {
        // const getPanelData = async () => {
        //     await dispatch(fetchSpecificPanelAndEstate(Number(id)));
        //     await dispatch(fetchInstallerEmailAlerts(specificPanel?.panelId))
        //     await dispatch(fetchInstallerEmailAlertsArray(specificPanel?.panelId))
        //     await dispatch(getRemoteServicing(specificPanel?.panelId))
        //     await dispatch(fetchUserEmailAlerts(specificPanel?.panelId))
        //     await dispatch(fetchPanelARCSettings(Number(id)));
        // }
        // if(specificPanel?.panelId !== undefined)
        // getPanelData();
    }, []);

    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head" style={{ color: '#1397D9' }}>
                                {t('lbl_panelAccess')}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                <IconButton sx={{ mt: -1, mb: -1 }} size="small" onClick={handlePanelAccessEditButton}>
                                    <EditIcon sx={{ mt: 0 }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t('tooltip_ARC_Access')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Checkbox
                                        disabled
                                        sx={{ mt: -0.9, mb: -1 }}
                                        size={'small'}
                                        icon={<Report color="action" />}
                                        checkedIcon={<Report sx={{color:'#005EAB'}} />}
                                        checked={specificPanel?.arcAccessEnabled === true}
                                    />
                                    &ensp;
                                    {specificPanel?.arcAccessEnabled === true ? (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_enabled')}</Typography>
                                    ) : (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_disabled')}</Typography>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t('tooltip_remoteServicing')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    {/* <Checkbox
                                        disabled
                                        sx={{ mt: -0.9, mb: -1 }}
                                        size={'small'}
                                        icon={<Maintenance color="error" />}
                                        checkedIcon={<Maintenance color="success" />}
                                        checked={enabled?.enabled}
                                    /> */}
                                    {enabled?.enabled === true ? (
                                        <Maintenance sx={{ ml: 1.1, mt: 0.3,color:'#005EAB' }} fontSize="small" />
                                    ) : (
                                        <Maintenance sx={{ ml: 1.1, mt: 0.3 }} fontSize="small" color="action" />
                                    )}
                                    &ensp;&ensp;
                                    {enabled?.enabled !== undefined && enabled?.enabled === true ? (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_enabled')}</Typography>
                                    ) : (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_disabled')}</Typography>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t('tooltip_installerEmail_alerts')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Checkbox
                                        disabled
                                        sx={{ mt: -0.9, mb: -1 }}
                                        size={'small'}
                                        icon={<NotificationsActiveIcon color="action" />}
                                        checkedIcon={<NotificationsActiveIcon sx={{color:'#005EAB'}} />}
                                        checked={installerEmailAlertsStatusValue}
                                    />
                                    &ensp;
                                    {installerEmailAlertsStatusValue ? (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_enabled')}</Typography>
                                    ) : (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_disabled')}</Typography>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t('tooltip_usrAccess')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Checkbox
                                        disabled
                                        sx={{ mt: -0.9, mb: -1 }}
                                        size={'small'}
                                        icon={<PeopleIcon color="action" />}
                                        checkedIcon={<PeopleIcon sx={{color:'#005EAB'}} />}
                                        checked={specificPanel?.userAccessEnabled === true}
                                    />
                                    &ensp;
                                    {specificPanel?.userAccessEnabled === true ? (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_enabled')}</Typography>
                                    ) : (
                                        <Typography sx={{ color: '#424E54' }}>{t('lbl_disabled')}</Typography>
                                    )}
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head">{t('tooltip_usrEmail_alerts')}</TableCell>
                            <TableCell>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <Checkbox
                                        disabled
                                        sx={{ mt: -0.9, mb: -1 }}
                                        size={'small'}
                                        icon={<ContactMailIcon color="action" />}
                                        checkedIcon={<ContactMailIcon sx={{color:'#005EAB'}} />}
                                        checked={userEmailAlerts?.siaEmailSettings?.length > 0}
                                    />
                                    &ensp;
                                    <Typography sx={{ color: '#424E54' }}>
                                        {userEmailAlerts?.siaEmailSettings?.length > 0
                                            ? t('lbl_enabled')
                                            : t('lbl_disabled')}
                                    </Typography>
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default PanelAccess;
