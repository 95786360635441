/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useParams } from 'react-router';
import { AlertsAccessModalProps } from './AlertsAccessModalProps';
import { fetchInstallerEmailAlerts, fetchInstallerEmailAlertsArray, fetchReportGroups, putInstallerEmailAlerts } from '../../redux/actions/specificPanelActions';
import AlertsEvents from './AlertsEvents';
import { ReportGroupsProps } from '../ARCAccessModal/ARCAccessProps';
import { useTranslation, Trans } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    overFlow: '',
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

export default function AlertsAccessModal({ panelId, open, setOpen, installerEmailAlertsStatus, setInstallerEmailAlertsStatus, isEdit, setIsEdit }: AlertsAccessModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch();
    const { specificPanel, reportGroups, putInstallerEmailAlertsStatus, alerts } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const [activeStep, setActiveStep] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [availableGroups, setAvailableGroups] = React.useState<string[]>([]);
    const { t } = useTranslation();

    const steps = [
        {
            component: <AlertsEvents reportGroups={reportGroups} setAvailableGroups={setAvailableGroups} alerts={alerts} />,
        },
    ];

    React.useEffect(() => {
        if (putInstallerEmailAlertsStatus !== 0) {
            if (putInstallerEmailAlertsStatus === 200) {
                setInstallerEmailAlertsStatus(true)
                if (isEdit) {
                    setMessage(`${t('msg_email_alert_edited')}`)
                } else {
                    setMessage(`${t('msg_email_alert_enabled')}`)
                }
            } else if (putInstallerEmailAlertsStatus === 404) {
                setInstallerEmailAlertsStatus(false)
                setMessage(`${t('lbl_somethingWrong')}`)
            }
        }
        dispatch(fetchReportGroups());
        dispatch(fetchInstallerEmailAlertsArray(specificPanel?.panelId))
    }, [count, putInstallerEmailAlertsStatus]);

    const handleClose = () => {
        setOpen(false);
        setActiveStep(0);
    };

    const handleSave = async () => {
        const installerEmailAlertsBody = {
            availableGroups: availableGroups
        }
        await dispatch(putInstallerEmailAlerts(specificPanel.panelId, installerEmailAlertsBody));
        setSnackOpen(true)
        setOpen(false);
        setCount((prevCount) => prevCount + 1);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            {md ? (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                    {t('tooltip_installerEmail_alerts')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                <Paper sx={{ pl: 3, pr: 2, pt: 2, pb: 8,maxHeight: '90vh', overflow: 'auto' }}>
                                    {steps[activeStep].component}
                                </Paper>
                            </Paper>
                            <Divider />
                            <Paper>
                                <MobileStepper
                                    variant="dots"
                                    steps={0}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        position: 'fixed',
                                        top: '100%',
                                        transform: 'translateY(-100%)',
                                        width: '100%',
                                        p: 2,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        <Button size="medium" variant="contained" onClick={handleSave}>
                                            {t('btn_save')}
                                        </Button>
                                    }
                                    backButton={
                                        <Button size="small" variant="outlined" onClick={handleClose}>
                                            {t('btn_cancel')}
                                        </Button>
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            ) : (
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('tooltip_installerEmail_alerts')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{ pl: 3, pr: 2, pt: 2, pb: 2, maxHeight: 400, overflow: 'auto' }}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={0}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 400,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    <Button size="medium" variant="contained" onClick={handleSave}>
                                        {t('btn_save')}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" variant="outlined" onClick={handleClose}>
                                        {t('btn_cancel')}
                                    </Button>
                                }
                            />
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackClose}
                        message={message}
                        action={action}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                </>
            )}
        </div>
    );
}
