/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
import React, { useEffect } from 'react';
import { InfoListItem, ListItemTag } from '@brightlayer-ui/react-components';
import {
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CompletedReportDetailsModal from '../ServiceReportsModals/CompletedServiceReportModal';
import PdfReportModal from '../ServiceReportsModals/PdfReportModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CompletedServiceReportsForAllPanelsTypes } from './serviceReportTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchCompletedReportsForAllPanels } from '../../redux/actions/serviceReportsActions';
import { getDateFormat, getTimeFormat } from '../Timestamp/timestampCal';

export default function CompletedServicesReports(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { completedReportsForAllPanels } = useSelector((state: any) => state.serviceReportReducer);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [openReport, setOpenReport] = React.useState(false)
    const [openPdfReport, setOpenPdfReport] = React.useState(false)
    const [openPdfReportBrowser, setOpenPdfReportBrowser] = React.useState(false)
    const [report, setReport] = React.useState<any>({})
    const [counter, setCounter] = React.useState(0);
    const [openDeleteReport, setOpenDeleteReport] = React.useState(false)
    const dispatch = useTypedDispatch()
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleReportModal = (service: any) => {
        setReport(service)
        setOpenReport(true)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleView = (service: any) => {
        setReport(service)
        setOpenPdfReportBrowser(true)
        setAnchorEl(null);
    };


    return (
        <div>
            {md ?
                <>
                    <Table>
                        <TableBody>
                            {completedReportsForAllPanels?.length !== 0 && completedReportsForAllPanels !== undefined && Array.isArray(completedReportsForAllPanels) &&
                            completedReportsForAllPanels?.map((service:CompletedServiceReportsForAllPanelsTypes, key: number) => (
                                <>
                                    {service?.reportList?.length > 0 && service?.reportList !== undefined &&
                                        service?.reportList?.map((reportList:any, k:number) => 
                                    <TableRow sx={{ backgroundColor: 'white' }}>
                                        <TableCell align="left" padding="normal">
                                            <ListItemTag nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                label={reportList?.analysis}
                                                backgroundColor={reportList?.analysis === 'red' ? '#CA3C3D' : reportList?.analysis === 'green' ? '#39B620' : reportList?.analysis === null ? 'grey': reportList?.analysis === 'initial' ? '#000000' : '#E57F0A' }
                                                fontColor={'#FFFFFF'}

                                            />
                                        </TableCell>
                                        <TableCell align="left" padding="normal">
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography variant="body2">{service?.name === null ? '/ Untitled /':service?.name}</Typography>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right" padding="normal">
                                            <IconButton onClick={() => handleReportModal({code:service?.panelId,name:service?.name,...reportList})} sx={{ mt: -1 }} size="small">
                                                <ChevronRightIcon />
                                            </IconButton>
                                        </TableCell>
                                        <CompletedReportDetailsModal page={'service-reports'} setOpenPdfReport={setOpenPdfReport} report={report} open={openReport} setOpen={setOpenReport} counter={counter} setCounter={setCounter} />
                                        <PdfReportModal page={'service-reports'} report={report} open={openPdfReport} setOpen={setOpenPdfReport} />
                                    </TableRow>)}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </>
                : <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="20%">
                            {t('lbl_panel')}&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="18%">
                            {t('lbl_report_no')}&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="18%">
                            {t('lbl_cmpltd_date')}&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="15%">
                            {t('lbl_cmpltd_Classification')}&ensp;
                                <ArrowDownwardIcon fontSize="small" color="disabled" />
                            </TableCell>
                            <TableCell width="15%"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {completedReportsForAllPanels?.length !== 0 && completedReportsForAllPanels !== undefined && Array.isArray(completedReportsForAllPanels) &&
                        completedReportsForAllPanels?.map((service:CompletedServiceReportsForAllPanelsTypes, key: number) => (

                            <>
                                {service?.reportList?.length > 0 &&
                                        service?.reportList?.map((reportList:any, k:number) => 

                                    <TableRow sx={{ backgroundColor: 'white' }}>
                                    <TableCell align="left" padding="normal">
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <Typography variant="body2">{service?.name === null ? '/ Untitled /':service?.name}</Typography>
                                            <Typography variant="caption">{service?.panelId}</Typography>
                                        </div>
                                    </TableCell>

                                    <TableCell align="left" padding="normal">
                                        <label>{<Typography variant="body1">{reportList?.id}</Typography>}</label>
                                    </TableCell>
                                    <TableCell align="left" padding="normal">
                                        <label>{<Typography variant="body1">
                                        {`${getDateFormat(Number(reportList?.sysTimestampEnd) * 1000)} , ${getTimeFormat(Number(reportList?.sysTimestampEnd) * 1000)}`}
                                        </Typography>}</label>
                                    </TableCell>
                                    <TableCell align="left" padding="normal">
                                        <Typography
                                            sx={{ color: reportList?.analysis === 'red' ? '#CA3C3D' : reportList?.analysis === 'green' ? '#39B620' : reportList?.analysis === null ? 'grey': reportList?.analysis === 'initial' ? '#000000' : '#E57F0A' }}
                                            variant="body1"
                                        >
                                            {reportList.analysis}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right" padding="normal">
                                        <IconButton onClick={handleClick} sx={{ mt: -1 }} size="small">
                                            <MoreVertIcon />
                                        </IconButton>
                                        <div>
                                            <Menu
                                                id="basic-menu"
                                                anchorEl={anchorEl}
                                                sx={{ width: '100%' }}
                                                open={open}
                                                onClose={handleClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'basic-button',
                                                }}
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                                            >
                                                <MenuItem sx={{ width: 200 }} onClick={() => handleView(service)}>{t('lbl_cmpltd_View')}</MenuItem>
                                                <MenuItem onClick={handleClose}>{t('lbl_delete')}</MenuItem>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>)}
                            </>
                        ))}
                    </TableBody>
                </Table>}
            <PdfReportModal page={'service-reports'} report={report} open={openPdfReportBrowser} setOpen={setOpenPdfReportBrowser} />
        </div>
    );
}
