/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/require-await */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    Switch,
    Button,
    Divider,
    MobileStepper,
    Paper,
    useTheme,
    useMediaQuery,
    TextField,
    TableCell,
    Table,
    TableRow,
    IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import { fetchRemoteServicingEmailDestinations, performEditRemoteServicingEmailDestinations, performRemoteServicingEmailDestinations } from '../../redux/actions/remoteServicingActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};

type RemoteServicingEmailDestinationsModalProps = {
    type:string
    user:any
    open:boolean
    setOpen: (open:boolean) => void
} 

export default function RemoteServicingEmailDestinationsModal({ type, user, open, setOpen }: RemoteServicingEmailDestinationsModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch()
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
    const { registerStatus } = useSelector((state: any) => state.remoteServicingReducer)
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [email, setEmail] = React.useState<string>('');
    const [green, setGreen] = React.useState(false);
    const [amber, setAmber] = React.useState(false);
    const [red, setRed] = React.useState(false);
    const [failed, setFailed] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [save, setSave] = React.useState(false);
    const [helperLoginText, setHelperLoginText] = React.useState('');
    const [message, setMessage] = React.useState('');
    const { t } = useTranslation();

    React.useEffect(() => {
        if(save){
            if (registerStatus.status === 200) {
                setMessage(registerStatus.message);
                dispatch(fetchRemoteServicingEmailDestinations(specificPanel?.panelId))
            } 
            else if (registerStatus.status === 404) {
                setMessage(registerStatus.message);
            } 
            else if (registerStatus.status === 500) {
                setMessage(`${t('msg_internal_error')}`);
            } 
            else if (registerStatus.status === 400 ) {
                setMessage(`${t('lbl_unauthorizedUser')}`);
            } else {
                setMessage(`${t('lbl_someError')}`);
            }
            setSnackOpen(true)
            setSave(false)
        }

        if(type === 'edit'){
            setEmail(user.emailAddress)
            setGreen(user.sendGreen === 1 ? true:false)
            setAmber(user.sendAmber === 1 ? true:false)
            setRed(user.sendRed === 1 ? true:false)
            setFailed(user.sendFailed === 1 ? true:false)
            setVerified(user.verified === 1 ? true:false)
        } else if(type === 'add'){
            setEmail('')
            setGreen(false)
            setAmber(false)
            setRed(false)
            setFailed(false)
            setVerified(false)
        }
       
    }, [save,open]);

    const handleClose = () => {
        setEmail('')
        setHelperLoginText('')
        setGreen(false)
        setAmber(false)
        setRed(false)
        setOpen(false);
    };

    const handleSave = async() => {
        const body ={
            panelId:specificPanel?.panelId,
            emailAddress:email,
            sendGreen:green,
            sendAmber:amber,
            sendRed:red,
            sendFailed:failed,
        }
        if(type === 'add')
        await dispatch(performRemoteServicingEmailDestinations(body, specificPanel?.panelId))
        else 
        await dispatch(performEditRemoteServicingEmailDestinations(body, specificPanel?.panelId))
        setSave(true)
        setOpen(false);
    };

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const isValidEmail = (login: string) => {
        const isValid = /\S+@\S+\.\S+/.test(login);
        return isValid;
    };

    const handleEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEmail(e.target.value);
        if (!isValidEmail(e.target.value)) {
            setHelperLoginText(`${t('msg_email_invalid')}`);
        } else {
            setHelperLoginText('');
        }
    };
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: md ? '100%' : 450,
                        height:md? '100%' : 640,
                        backgroundColor: 'background.paper',
                        boxShadow: 20,
                        p: 0,
                    }}
                >
                    {md ? (
                    <Paper elevation={4} sx={{
                        display:'flex',
                        flexDirection:'column',
                        paddingTop: 2,
                        paddingBottom: 3,
                        paddingLeft: 2,
                        paddingRight: 2
                        }}>
                        <Paper
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                            elevation={0}
                        >
                            <IconButton onClick={handleClose}>
                                <ArrowBackIcon />
                            </IconButton>
                            &ensp;&ensp;
                            
                            <Typography sx={{ pt: 0.5 }} variant="h6">
                            {t('lbl_remove_destination')}
                            </Typography>
                            
                        </Paper>
                        <Typography sx={{ pt: 1, pl: 2, pb: 0,pr:2 }} variant="body1">
                            Select which types of reports are required to be sent to an email address.
                            </Typography>
                            <Typography sx={{ pt: 1, pl: 2, pb: 2,pr:2 }} variant="body1">
                                On saving, a verification link will be sent to the email address, 
                                this must be activated before reports are emailed.
                            </Typography>
                        </Paper>
                    ) : (
                        <Paper elevation={0} sx={{ pb: 1 }}>
                            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant="h6">
                            {t('lbl_remove_destination')}
                            </Typography>
                            <Typography sx={{ pt: 1, pl: 2, pb: 0, pr:2 }} variant="body1">
                                Select which types of reports are required to be sent to an email address.
                            </Typography>
                            <Typography sx={{ pt: 1, pl: 2, pb: 2, pr:2 }} variant="body1"> 
                                On saving, a verification link will be sent to the email address, 
                                this must be activated before reports are emailed.
                            </Typography>
                        </Paper>
                    )}
                    <Divider />
                    <Paper
                        elevation={0}
                        sx={{
                            height: md ? '50vh' : 350,
                            overflow: 'auto',
                            paddingRight: md ? 4 : 2,
                            paddingLeft: md ? 4 : 2,
                            paddingTop: 2,
                        }}
                    >
                        <Grid item xs={12}>
                            <TextField
                                value={email}
                                fullWidth
                                label={t('lbl_emailAddress')}
                                variant="filled"
                                error={helperLoginText.length > 0}
                                helperText={helperLoginText}
                                //disabled={verified}
                                onChange={(e) => handleEmail(e)}
                            />
                        </Grid>
                        <br></br>
                        <Grid sx={{pb:2}} item xs={12}>
                            <label>{t('Reports')}:</label>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_green')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={green}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setGreen(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_amber')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={amber}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setAmber(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{t('lbl_red')}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={red}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setRed(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <strong>{'FAILED'}</strong>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Switch
                                            checked={failed}
                                            sx={{ mt: -1 }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            onChange={(e) => setFailed(e.target.checked)}
                                        />
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </Grid>
                    </Paper>
                    <Paper>
                        <MobileStepper
                            variant="dots"
                            steps={0}
                            position="static"
                            sx={
                                md
                                    ? {
                                          position: 'fixed',
                                          top: '100%',
                                          transform: 'translateY(-100%)',
                                          width: '100%',
                                          p: 2,
                                          backgroundColor: 'inherit',
                                      }
                                    : {
                                          maxWidth: 450,
                                          flexGrow: 1,
                                          paddingTop: 2,
                                          paddingBottom: 2,
                                          paddingLeft: 3,
                                          paddingRight: 3,
                                          backgroundColor: 'inherit',
                                      }
                            }
                            nextButton={
                                <Button size="medium" disableElevation variant="contained" 
                                disabled={(email === '') || !isValidEmail(email) } 
                                onClick={handleSave}>
                                    {t('btn_save')}
                                </Button>
                            }
                            backButton={
                                <Button size="small" variant="outlined" onClick={handleClose}>
                                    {t('btn_cancel')}
                                </Button>
                            }
                        />
                    </Paper>
                </Box>
            </Modal>
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </div>
    );
}
