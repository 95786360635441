/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */

import React, { useState } from 'react'
import { Box, Button, Divider, IconButton, MobileStepper, Modal, Paper, Snackbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import InstallerAddUserForm from './InstallerAddUserForm';
import InstallerAddGroup from './InstallerAddGroup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { getAddUserForInstaller } from '../../../redux/actions/installersActions';
import { useSelector } from 'react-redux';
import { InstallersProps } from '../InstallersTypes';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
};
type AddUserModalType = {
    open: boolean,
    setOpen: (open: boolean) => void;
    count: number
    setCount: (count: number) => void
}

function AddUserModal({ open, setOpen, count, setCount }: AddUserModalType): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = React.useState(0);
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [login, setLogin] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');
    const [repeatPassword, setRepeatPassword] = React.useState<string>('');
    const [userType, setUserType] = React.useState<string>('');
    const [availableGroups, setAvailableGroups] = React.useState<number[]>([]);
    const [status, setStatus] = useState(false)
    const { t } = useTranslation();
    const dispatch = useTypedDispatch();
    const [installerObj, setInstallerObj] = React.useState<InstallersProps>({
        ID: 0,
        firstName: '',
        groupId: 0,
        lastName: '',
        installerInfo: {
            cloudId: '',
            installerState: '',
            installerCompanyName: '',
            contactEmail: '',
            address: {
                address: '',
                country: '',
                postCode: ''
            },
            firstPhoneNumber: {
                number: '',
                type: ''
            },
            spokenPassword: ''
        },
        login: '',
    })

    React.useEffect(() => {
        let installerDetails = localStorage.getItem('installer') || ""
        setInstallerObj(JSON.parse(installerDetails))
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const isValidEmail = (email: string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
    }

    const handleSave = async () => {
        const body = {
            firstName: firstName,
            groupId: installerObj?.groupId,
            lastName: lastName,
            login: login,
            password: password,
            repeatPassword: repeatPassword,
            userType: userType,
        };
        await dispatch(getAddUserForInstaller(body, availableGroups))
        setStatus(true);
        setOpen(false);
        setCount(count + 1)
    }

    const steps = [
        {
            component: (
                <InstallerAddUserForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    login={login}
                    setLogin={setLogin}
                    password={password}
                    setPassword={setPassword}
                    repeatPassword={repeatPassword}
                    setRepeatPassword={setRepeatPassword}
                    userType={userType}
                    setUserType={setUserType}
                />
            )
        },
        { component: <InstallerAddGroup setAvailableGroups={setAvailableGroups} /> },
    ]

    return (
        <>
            {md ?
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box
                            sx={{
                                position: 'absolute' as 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '100%',
                                height: '100%',
                                backgroundColor: 'background.paper',
                                boxShadow: 20,
                                p: 0,
                            }}
                        >
                            <Paper elevation={0} sx={{ pb: 0 }}>
                                <Paper
                                    elevation={4}
                                    sx={{
                                        paddingTop: 2,
                                        paddingBottom: 1,
                                        paddingLeft: 2,
                                        paddingRight: 2,
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <IconButton onClick={handleClose}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                    &ensp;&ensp;
                                    <Typography sx={{ pt: 0.5 }} variant="h6">
                                        {t('btn_addUsr')}
                                    </Typography>
                                </Paper>
                                <Divider />
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <Paper sx={{
                                position: 'absolute' as 'absolute',
                                top: '100vh',
                                zIndex: 1000,
                                transform: 'translateY(-100%)',
                                width: '100%',
                            }}>
                                <MobileStepper
                                    variant="dots"
                                    steps={2}
                                    position="static"
                                    activeStep={activeStep}
                                    sx={{
                                        maxWidth: '100%',
                                        flexGrow: 1,
                                        paddingTop: 2,
                                        paddingBottom: 4,
                                        paddingLeft: 3,
                                        paddingRight: 3,
                                        backgroundColor: 'inherit',
                                    }}
                                    nextButton={
                                        activeStep < 1 ? (
                                            <Button
                                                size="medium"
                                                variant="contained"
                                                disabled={
                                                    firstName === '' ||
                                                    lastName === '' ||
                                                    login === '' ||
                                                    password === '' ||
                                                    repeatPassword === '' ||
                                                    userType === '' ||
                                                    password !== repeatPassword ||
                                                    (!isValidEmail(login))
                                                }
                                                onClick={handleNext}
                                            >
                                                {t('btn_next')}
                                            </Button>
                                        ) : (
                                            <Button size="medium" variant="contained" onClick={handleSave}>
                                                {t('btn_save')}
                                            </Button>
                                        )
                                    }
                                    backButton={
                                        activeStep < 1 ? (
                                            <Button size="small" variant="outlined" onClick={handleClose}>
                                                {t('btn_cancel')}
                                            </Button>
                                        ) : (
                                            <Button size="small" variant="outlined" onClick={handleBack}>
                                                {t('btn_back')}
                                            </Button>
                                        )
                                    }
                                />
                            </Paper>
                        </Box>
                    </Modal>
                </> :
                <>
                    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={style}>
                            <Paper
                                elevation={0}
                                sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}
                            >
                                <Typography variant="h6">{t('btn_addUsr')}</Typography>
                            </Paper>
                            <Divider />
                            <Paper elevation={0} sx={{}}>
                                {steps[activeStep].component}
                            </Paper>
                            <Divider />
                            <MobileStepper
                                variant="dots"
                                steps={2}
                                position="static"
                                activeStep={activeStep}
                                sx={{
                                    maxWidth: 500,
                                    flexGrow: 1,
                                    paddingTop: 2,
                                    paddingBottom: 2,
                                    paddingLeft: 3,
                                    paddingRight: 3,
                                    backgroundColor: 'inherit',
                                }}
                                nextButton={
                                    activeStep < 1 ? (
                                        <Button
                                            size="medium"
                                            variant="contained"
                                            disabled={
                                                firstName === '' ||
                                                lastName === '' ||
                                                login === '' ||
                                                password === '' ||
                                                repeatPassword === '' ||
                                                userType === '' ||
                                                password !== repeatPassword ||
                                                (!isValidEmail(login))
                                            }
                                            onClick={handleNext}
                                        >
                                            {t('btn_next')}
                                        </Button>
                                    ) : (
                                        <Button size="medium" variant="contained"
                                            onClick={handleSave}
                                        >
                                            {t('btn_save')}
                                        </Button>
                                    )
                                }
                                backButton={
                                    activeStep < 1 ? (
                                        <Button size="small" variant="outlined"
                                            onClick={handleClose}

                                        >
                                            {t('btn_cancel')}
                                        </Button>
                                    ) : (
                                        <Button size="small" variant="outlined"
                                            onClick={handleBack}
                                        >
                                            {t('btn_back')}
                                        </Button>
                                    )
                                }
                            />
                        </Box>
                    </Modal>
                </>
            }
        </>
    )
}

export default AddUserModal
