/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import { MenuItem, Switch, TextField, Typography, useMediaQuery, useTheme, Box, Grid } from '@mui/material';
import { InstallerAddUserFormProps } from "../InstallersTypes"
import { useTranslation } from 'react-i18next';

function InstallerAddUserForm({ firstName, setFirstName, lastName, setLastName, login, setLogin, password, setPassword, repeatPassword, setRepeatPassword, userType, setUserType }: InstallerAddUserFormProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const [helperText, setHelperText] = React.useState('')
    const [helperLoginText, setHelperLoginText] = React.useState('')
    const { t } = useTranslation();
    const [error, setError] = useState('');

    const validatePassword = (pwd: any) => {
        const errors = [];
        if (!/[A-Z]/.test(pwd)) {
            errors.push(`${t('error_pwd_info_capital')}`);
        }
        if (!/[a-z]/.test(pwd)) {
            errors.push(`${t('error_pwd_small')}`);
        }
        if (!/\d/.test(pwd)) {
            errors.push(`${t('error_pwd_number')}`);
        }
        if (!/[!@#$%^&*(),.?"':{}|<>]/.test(pwd)) {
            errors.push(`${t('error_pwd_special')}`);
        }
        if (pwd.length < 6) {
            errors.push(`${t('error_pwd_length')}`);
        }
        return errors.join(' ');
    };

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pwd = e.target.value;
        setPassword(pwd);
        const errorMessage = validatePassword(pwd);
        setError(errorMessage);
    }

    const handleRepeatPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRepeatPassword(e.target.value)
        if (e.target.value === password) {
            setHelperText('')
        }
        else {
            setHelperText(`${t('msg_unmatchedPwd')}`)
        }
    }
    const isValidEmail = (email: string) => {
        const isValid = /\S+@\S+\.\S+/.test(email);
        return isValid
    }

    const handleLogin = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLogin(e.target.value)
        if (!isValidEmail(e.target.value)) {
            setHelperLoginText(`${t('msg_email_invalid')}`)
        }
        else {
            setHelperLoginText('')
        }

    }


    const userTypes = [
        {
            code: 'ENGINEER',
            value: 'Engineer',
        },
        {
            code: 'MANAGER',
            value: 'Manager',
        },
        {
            code: 'OFFICE_STAFF',
            value: 'Office Staff',
        },
    ];


    return (
        <>
            <Box
                sx={{
                    flexGrow: 1,
                    paddingTop: 3,
                    paddingBottom: 5,
                    paddingLeft: 3,
                    paddingRight: 3,
                    maxHeight: md ? '75vh' : 450,
                    overflow: 'auto',
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={md ? 12 : 6}>
                        <TextField inputProps={{ maxLength: 24 }} value={firstName} fullWidth id="filled-basic-firstname" label={t('lbl_firstName')} variant="filled" onChange={(e) => setFirstName(e.target.value)} />
                    </Grid>
                    <Grid item xs={md ? 12 : 6}>
                        <TextField inputProps={{ maxLength: 24 }} value={lastName} fullWidth id="filled-basic-lastname" label={t('lbl_lastName')} variant="filled" onChange={(e) => setLastName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField inputProps={{ maxLength: 48 }} value={login} fullWidth type={'email'} id="filled-login" label={t('lbl_login')} variant="filled" error={helperLoginText.length > 0} helperText={helperLoginText} onChange={(e) => handleLogin(e)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={password} type={'password'} fullWidth id="filled-password" label={t('lbl_pwd')} variant="filled" onChange={(e) => handlePassword(e)} error={!!error} helperText={error} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField value={repeatPassword} type={'password'} fullWidth id="filled-repeat-password" label={t('lbl_repeat_pwd')} variant="filled" error={helperText.length > 0 && password.length > 0} helperText={helperText} onChange={(e) => handleRepeatPassword(e)} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField select value={userType} fullWidth id="filled-basic-typeof-user" label={t('lbl_typeUsr')} variant="filled" onChange={(e) => setUserType(e.target.value)} >
                            {userTypes.map((user) => (
                                <MenuItem key={user.code} value={user.code}>
                                    {user.value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default InstallerAddUserForm
