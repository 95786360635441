/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme, TextField, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { EditSiteDetailsProps } from './SpecificPanelEstateTypes';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchEstateIDForInstaller, putSiteDetails } from '../../redux/actions/specificPanelActions';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import secureLocalStorage from 'react-secure-storage';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function EditSiteDetailsModal({ open, setOpen, count, setCount }: EditSiteDetailsProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const { estateList } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const dispatch = useTypedDispatch()
  const { specificPanel, specificPanelEstate, editSiteStatus, installerEstateID } = useSelector((state: any) => state.specificPanelAndEstateReducer)
  const [estateName, setEstateName] = React.useState(specificPanelEstate?.name);
  const [selectedEstateId, setSelectedEstateId] = React.useState(0);
  const [contactName, setContactName] = React.useState(specificPanel?.siteContactName);
  const [number, setNumber] = React.useState(specificPanel?.siteContactPhoneNumber);
  const [address, setAddress] = React.useState('');
  const [counter, setCounter] = React.useState(0);
  const [comments, setComments] = React.useState(specificPanel?.comments);
  const userRole = secureLocalStorage.getItem('role');
  const { id } = useParams()
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchEstateIDForInstaller(specificPanel?.installerUser?.ID))
    if (counter !== 0) {
      setSelectedEstateId(installerEstateID)
    } else {
      setContactName(specificPanel?.siteContactName)
      setNumber(specificPanel?.siteContactPhoneNumber)
      setComments(specificPanel?.comments)
      let fullAddress = '';
      let address1 = '';
      let country = '';
      let postCode = '';
      if (`${specificPanel?.address?.address}` !== 'undefined' && `${specificPanel?.address?.address}` !== '') {
        address1 = `${specificPanel?.address?.address} `
      }
      if (`${specificPanel?.address?.country}` !== 'undefined' && `${specificPanel?.address?.country}` !== '') {
        country = `${specificPanel?.address?.country} `
      }
      if (`${specificPanel?.address?.postCode}` !== 'undefined' && `${specificPanel?.address?.postCode}` !== '') {
        postCode = `${specificPanel?.address?.postCode}`
      }
      fullAddress = address1.concat(country, postCode)
      setAddress(fullAddress)
      if (specificPanelEstate?.type === 'ESTATE') {
        setEstateName(specificPanelEstate?.name);
      } else {
        setEstateName('None');
      }
    }
  }, [counter])

  const handleClose = () => {
    setOpen(false);
  }

  const handleEditSiteDetails = async () => {
    let selectedEstate;
    let estateType;
    if (estateName !== 'None') {
      selectedEstate = estateName
      estateType = "ESTATE"
    } else {
      selectedEstate = ''
      estateType = "INSTALLER"
    }
    const panelIdArray = [specificPanel?.ID];

    const editSiteDetailsBody = {
      ID: specificPanel?.ID,
      cloudReferenceName: specificPanel?.cloudReferenceName,
      connectionType: "ETHERNET",
      comments: comments,
      installerId: specificPanel?.installerId,
      address: {
        address: address,
        postCode: specificPanel?.installerInfo?.postCode,
        country: specificPanel?.installerInfo?.country,
      },
      installerUser: {
        ID: specificPanel?.installerUser?.ID,
        firstName: specificPanel?.installerUser?.firstName,
        groupId: specificPanel?.installerUser?.groupId,
        installerInfo: {
          ID: specificPanel?.installerUser?.installerInfo.ID,
          cloudId: specificPanel?.installerUser?.installerInfo?.cloudId,
          contactEmail: specificPanel?.installerUser?.installerInfo?.contactEmail,
          email: specificPanel?.installerUser?.installerInfo?.email,
          installerCompanyName: specificPanel?.installerUser?.installerInfo?.installerCompanyName,
          installerPrimaryContact: "firstPhoneNumber",
          installerState: "ACTIVE",
          firstPhoneNumber: {
            number: specificPanel?.installerUser?.installerInfo?.firstPhoneNumber.number,
            type: specificPanel?.installerUser?.installerInfo?.firstPhoneNumber.type
          },
          secondPhoneNumber: {
            number: specificPanel?.installerUser?.installerInfo?.secondPhoneNumber.number,
            type: specificPanel?.installerUser?.installerInfo?.secondPhoneNumber.type
          },
          thirdPhoneNumber: {
            number: specificPanel?.installerUser?.installerInfo?.secondPhoneNumber.number,
            type: specificPanel?.installerUser?.installerInfo?.secondPhoneNumber.type
          },
          address: {
            address: specificPanel?.installerUser?.installerInfo?.address,
            postCode: specificPanel?.installerUser?.installerInfo?.postCode,
            country: specificPanel?.installerUser?.installerInfo?.country,
          },
        },
        lastName: specificPanel?.installerUser?.lastName,
        locale: specificPanel?.installerUser?.locale,
        login: specificPanel?.installerUser?.login,
        userType: specificPanel?.installerUser?.userType
      },
      name: specificPanel?.cloudReferenceName,
      panelId: specificPanel?.panelId,
      siteContactName: contactName,
      siteContactPhoneNumber: number,
      status: specificPanel?.status
    }
    await dispatch(putSiteDetails(Number(id), editSiteDetailsBody, selectedEstateId, panelIdArray))
    setOpen(false);
    setCount(count + 1);
  }


  const handleEstateChange = async (event: any) => {
    setEstateName(event.target.value);
    const selectedEstateObj = estateList?.filter((estate: any) => estate.name === event.target.value);
    if (selectedEstateObj.length > 0) {
      setSelectedEstateId(selectedEstateObj[0].ID)
    } else {
      await dispatch(fetchEstateIDForInstaller(specificPanel?.installerUser?.ID));
      setCounter((prev) => prev + 1)
    }
  };

  const handleBackClick = (): any => {
    setOpen(false);
  }

  return (
    <div>
      {md ? <>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100vw',
            height: '100vh',
            backgroundColor: 'background.paper',
            boxShadow: 20,
            p: 0,
          }} >
            <Paper elevation={4} sx={{
              paddingTop: 5, paddingBottom: 1, paddingLeft: 2, paddingRight: 2,
              display: 'flex', flexDirection: 'row'
            }}>
              <IconButton
                color={'default'}
                onClick={handleBackClick}
                edge={'start'}
                style={{ marginRight: theme.spacing(3) }}
                size="large"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography sx={{ pt: 0.5 }} variant='h6'>{t('lbl_siteDetails')}</Typography>
            </Paper>
            <Divider />
            <div style={{ maxHeight: '70vh', overflow: 'auto' }} >
              <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic"
                      disabled={true}
                      label={t('lbl_cloudReference')} variant="filled" value={specificPanel?.cloudReferenceName} />
                  </Grid>
                  {userRole !== 'ADMIN' &&
                  <Grid item xs={12}>
                    <TextField fullWidth select value={estateName} label={t('lbl_estate')} variant="filled" onChange={handleEstateChange}>
                      <MenuItem value="None">
                      {t('lbl_none')}
                      </MenuItem>
                      {
                        estateList?.map((estate: any, key: number) => (
                          <MenuItem key={key} value={estate.name}>
                            {estate.name}
                          </MenuItem>
                        ))
                      }
                    </TextField>
                  </Grid>
                  }
                  <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 30 }}
                      label={t('lbl_contact_name')} variant="filled" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 30 }}
                      label={t('lbl_contact_phNo')} variant="filled" value={number} onChange={(e) => setNumber(e.target.value)} />
                    <label style={{ marginLeft: 15 }}><Typography variant="caption">Optional</Typography></label>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 100 }}
                      label={t('lbl_siteAddress')} variant="filled" value={address} onChange={(e) => setAddress(e.target.value)} />
                    <label style={{ marginLeft: 15 }}><Typography variant="caption">{t('lbl_optional')}</Typography></label>
                  </Grid>
                  <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" multiline={true} rows={4} inputProps={{ maxLength: 200 }}
                        label={t('lbl_siteComments')} variant="filled" value={comments} onChange={(e) => setComments(e.target.value)} />
                      <label style={{ marginLeft: 15 }}><Typography variant="caption">{t('lbl_optional')}</Typography></label>
                    </Grid>
                </Grid>
              </Box>
            </div>
            <Paper sx={{
              zIndex: 1000,
              position: 'absolute' as 'absolute',
              top: '90vh',
              transform: 'translateY(-100%)',
              width: '100%'
            }}>
              <MobileStepper
                variant="dots"
                steps={0}
                position="static"
                activeStep={activeStep}
                sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
                nextButton={
                  <Button size="medium" disableElevation variant='contained' onClick={handleEditSiteDetails}>
                    {t('btn_save')}
                  </Button>
                }
                backButton={
                  <Button size="small" variant='outlined' onClick={handleClose}  >
                    {t('btn_cancel')}
                  </Button>
                }
              />
            </Paper>
          </Box>
        </Modal>
      </>
        :
        <>
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} >
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                <Typography variant='h6'>{t('lbl_siteDetails')}</Typography>
              </Paper>
              <Divider />
              <div style={{ maxHeight: '70dvh', overflow: 'auto' }} >
                <Box sx={{ flexGrow: 1, paddingTop: 3, paddingBottom: 5, paddingLeft: 3, paddingRight: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic"
                        label={t('lbl_cloudReference')} variant="filled" value={specificPanel?.cloudReferenceName} disabled={true} />
                    </Grid>
                    {userRole !== 'ADMIN' &&
                    <Grid item xs={12}>
                      <TextField fullWidth select value={estateName} label={t('lbl_estate')} variant="filled" onChange={handleEstateChange}>
                        <MenuItem value="None">
                        {t('lbl_none')}
                        </MenuItem>
                        {
                          estateList?.map((estate: any, key: number) => (
                            <MenuItem key={key} value={estate.name}>
                              {estate.name}
                            </MenuItem>
                          ))
                        }
                      </TextField>
                    </Grid>
                    }
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 30 }}
                        label={t('lbl_contact_name')} variant="filled" value={contactName} onChange={(e) => setContactName(e.target.value)} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 30 }}
                        label={t('lbl_contact_phNo')} variant="filled" value={number} onChange={(e) => setNumber(e.target.value)} />
                      <label style={{ marginLeft: 15 }}><Typography variant="caption">{t('lbl_optional')}</Typography></label>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" inputProps={{ maxLength: 100 }}
                        label={t('lbl_siteAddress')} variant="filled" value={address} onChange={(e) => setAddress(e.target.value)} />
                      <label style={{ marginLeft: 15 }}><Typography variant="caption">{t('lbl_optional')}</Typography></label>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth id="filled-basic" multiline={true} rows={4} inputProps={{ maxLength: 200 }}
                        label={t('lbl_siteComments')} variant="filled" value={comments} onChange={(e) => setComments(e.target.value)} />
                      <label style={{ marginLeft: 15 }}><Typography variant="caption">{t('lbl_optional')}</Typography></label>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <Divider />
              <Paper >
                <MobileStepper
                  variant="dots"
                  steps={0}
                  position="static"
                  activeStep={activeStep}
                  sx={{
                    maxWidth: 400,
                    flexGrow: 1,
                    paddingTop: 2,
                    paddingBottom: 2,
                    paddingLeft: 3,
                    paddingRight: 3, backgroundColor: 'inherit'
                  }}
                  nextButton={
                    <Button size="medium" disableElevation variant='contained' onClick={handleEditSiteDetails}>
                      {t('btn_save')}
                    </Button>
                  }
                  backButton={
                    <Button size="small" variant='outlined' onClick={handleClose}  >
                      {t('btn_cancel')}
                    </Button>
                  }
                />
              </Paper>
            </Box>
          </Modal>
        </>}

    </div>
  );
}
