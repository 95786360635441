/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */

import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, MobileStepper, useMediaQuery, useTheme } from '@mui/material';
import { AlertsDisableModalProps } from './AlertsAccessModalProps';
import { Spacer } from '@brightlayer-ui/react-components';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { panelInstallerEmailAlertsDisable } from '../../redux/actions/specificPanelActions';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';

export default function AlertsDisableModal({ setInstallerEmailAlertsStatus, installerEmailAlertsStatus, panelId, open, setOpen, flagForInstallerEmailAlertsDisable, setFlagForInstallerEmailAlertsDisable }: AlertsDisableModalProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => setOpen(false);
    const dispatch = useTypedDispatch();
    const { t } = useTranslation();
    const { installerEmailAlertsDisableStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer);

    React.useEffect(() => {
        if (installerEmailAlertsDisableStatus !== 0) {
            setFlagForInstallerEmailAlertsDisable(true);
            if (installerEmailAlertsDisableStatus === 200) {
                setInstallerEmailAlertsStatus(false)
            } else {
                setInstallerEmailAlertsStatus(true)
            }
        }
    }, [installerEmailAlertsDisableStatus]);

    const handleDisable = async () => {
        await dispatch(panelInstallerEmailAlertsDisable(panelId))
        setOpen(false);
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: md ? 300 : 400,
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}
                >
                    <Typography sx={{ pl: 2, pt: 3, pb: 2 }} id="modal-modal-title" variant="h6" component="h2">
                    {t('lbl_email_alert_disable')}
                    </Typography>
                    <Typography sx={{ pl: 2, pr: 3, pb: 3 }} id="modal-modal-description" variant="body1">
                    {t('msg_email_alert_disable')}
                    </Typography>
                    {md ? <></> : <Divider />}
                    {md ? <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10, paddingRight: 5 }}>
                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                        <Button disableRipple disableFocusRipple onClick={handleClose} sx={{ backgroundColor: 'inherit' }}>{t('btn_cancel')}</Button>
                        <Button disableRipple disableFocusRipple onClick={handleDisable} sx={{ backgroundColor: 'inherit' }}>{t('btn_disable')}</Button>
                    </div> : <MobileStepper
                        variant="dots"
                        steps={0}
                        position="static"
                        activeStep={0}
                        sx={{
                            maxWidth: 400,
                            flexGrow: 1,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingLeft: 3,
                            paddingRight: 3,
                            backgroundColor: 'inherit',
                        }}
                        nextButton={
                            <Button onClick={handleDisable} size="medium" variant="contained">
                               {t('btn_disable')}
                            </Button>
                        }
                        backButton={
                            <Button size="medium" variant="outlined" onClick={handleClose}>
                               {t('btn_cancel')}
                            </Button>
                        }
                    />}
                </Box>
            </Modal>
        </div>
    );
}
