/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import './panel-tabs.css';
import { makeStyles } from '@mui/styles';
import { Panel } from '../../components/SpecificPanelDetails/Panel';
import PanelAppbar from '../../components/SpecificPanelDetails/SpecificPanelAppbar';
import PanelNotifications from '../../components/PanelNotifications/PanelNotifications';
import PanelARCReporting from '../../components/PanelARCReporting/PanelARCReporting';
import { Button, Paper, Table, TableBody, TableCell, TableRow, useMediaQuery, useTheme } from '@mui/material';
import MobileApplicationUsers from '../../components/MobileApplicationUsers/MobileApplicationUsers';
import PanelLogs from '../../components/PanelLogs/PanelLogs';
import { PanelRemoteServicing } from '../../components/RemoteServicingTab/PanelRemoteServicing';
import IFrameTab from '../../components/IFrameTab/IFrameTab';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import secureLocalStorage  from  "react-secure-storage";

const useStyles = makeStyles({
    tabs: {
        '& .MuiTabs-indicator': {
            height: 2,
            color: 'black',
        },
        '& .MuiTab-root.Mui-selected': {
            color: '#007BC1',
        },
        '& .MuiTab-root.Mui-disabled': {
            color: 'black',
        },
        '& .MuiTabs-scrollButtons.Mui-disabled ': {
            opacity: 1,
        },
    },
});

export function PanelDetailTabs(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    //const { currentUser } = useSelector((state: any) => state.userReducer);
    const role = secureLocalStorage.getItem('role')
    const [value, setValue] = React.useState('1');
    const classes = useStyles();
    const { t } = useTranslation();

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const handleTabClick = (str: string) => {
        setValue(str);
    };

    return (
        <div className="main-tab">
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <PanelAppbar />
                <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
        
        <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor:'#ffff' }}>
        <Paper elevation={md? 3: 0} >
          <TabList variant={md ? 'scrollable':'fullWidth'} centered className={classes.tabs} textColor='inherit' TabIndicatorProps={{style: {background:'#007BC1',textEmphasisColor:'#007BC1'}}} onChange={handleChange} aria-label="lab API tabs example" >
            <Tab label={t('tab_overview')} value="1" />
            <Tab label={t('tab_notifications')} value="2" />
            {role === 'ADMIN' && <Tab label={t('tab_panelLog')} value="3" />}
           {(role === 'INSTALLER' || role === 'MANAGER' || role === 'ENGINEER' || role === 'OFFICE_STAFF' || role === 'ADMIN' )&& 
           <Tab label={t('tab_ARC_reporting')} value="4" />}
            {role !== 'OFFICE_STAFF' && <Tab label={t('tab_connect')} value="5" />}
            {role !== 'OFFICE_STAFF' && <Tab label={t('tab_keypad')} value="6" />}
            <Tab label={t('tab_remoteServicing')} value="7" />
            <Tab label={t('tab_mobileApp_usrs')} value="8" />
          </TabList>
          </Paper>
        </Box>
        <TabPanel value="1"><Panel /></TabPanel>
        <TabPanel value="2"><PanelNotifications /></TabPanel>
        <TabPanel value="3"><PanelLogs /></TabPanel>
         <TabPanel value="4"><PanelARCReporting /></TabPanel>
        <TabPanel value="5">
          <IFrameTab value='connect' />
          </TabPanel>
        <TabPanel value="6">
          <IFrameTab value='keypad' />
          </TabPanel>
        <TabPanel value="7"><PanelRemoteServicing /></TabPanel>
        <TabPanel value="8"><MobileApplicationUsers /></TabPanel>
      </TabContext>
    </Box>
    </div>
    </div>
    );
}
