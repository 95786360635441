/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Button, Divider, Grid, IconButton, Paper, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Appbar from '../../components/Appbar/Appbar';
import EditIcon from '@mui/icons-material/Edit';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import EmailIcon from '@mui/icons-material/Email';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import BusinessIcon from '@mui/icons-material/Business';
import { InfoListItem, Spacer } from '@brightlayer-ui/react-components';
import EditEndUserInfoModal from '../../components/EditEndUserInfoModal/EditEndUserInfoModal'
import './endUserInfo.css'
import UploadIcon from '@mui/icons-material/Upload';
import LogoDropZone from '../../components/EndUserInfoLogoDragAndDrop/LogoDropZone';
import { fetchEndUserInfo, fetchInstallerLogo, fetchInstallerLogoImageStatus } from '../../redux/actions/endUserInfoActions';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useSelector } from 'react-redux';
import base64ToImage from 'base64-to-image';
import { useTranslation } from 'react-i18next';
import { getCurrentUserDetails } from '../../redux/actions/accountUsersActions';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),

}));

export function EndUserInfo(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch()
    const { endUserInfo, installerLogo, fetchLogoStatus } = useSelector((state: any) => state.endUserInfoReducer);
    const uploadedFile = [installerLogo]
    const [files, setFiles] = useState<any[]>([]);
    const [editUserInfoModalOpen, seteditUserInfoModalOpen] = useState(false)
    const [count, setCount] = useState(0)
    const { currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const { t } = useTranslation();

    const handleEditAccountModal = (): any => {
        seteditUserInfoModalOpen(true)
    }

    useEffect(() => {
        void (async():Promise<any> => {
            await dispatch(getCurrentUserDetails());
            await dispatch(fetchEndUserInfo())
            await dispatch(fetchInstallerLogo())
            await dispatch(fetchInstallerLogoImageStatus())
        })();
        if(fetchLogoStatus === 404) setFiles([])
        else setFiles([{name:'',preview:installerLogo}])
    },[count,installerLogo.length,fetchLogoStatus])

    
    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} subtitle='All Panels' title={'End User Info'} />
                <Box sx={{ flexGrow: 1, marginLeft: md ? '1%' : '10%', marginRight: md ? '1%' : '10%', marginTop:md?3: 6 }}>
                    <Grid container spacing={md?5:4} style ={{ paddingTop: 2}}>
                        <Grid item xs={12}>
                            <Item sx={{p:2}} elevation={0.5}>
                                <Typography variant='subtitle2' color={'primary'}>
                                {t('lbl_endUserMsg')}
                                </Typography>
                            </Item>
                        </Grid>
                        <Grid item xs={md? 12:6}>
                            <Item elevation={md?2:1}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid sx={{ height:md? 400: '60vh' }} item xs={12} style ={{ paddingTop: 4}}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Typography sx={{ p: 2, color:'#727E84' }} variant='subtitle2'>
                                                        {t('lbl_information')}
                                                    </Typography>
                                                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                   {(currentUserDetails?.userType === 'INSTALLER' || currentUserDetails?.userType === 'MANAGER')  &&
                                                    <IconButton onClick={handleEditAccountModal} style={{ paddingRight: 18}}>
                                                        <EditIcon />
                                                    </IconButton>}
                                                    <EditEndUserInfoModal files={files} setCount={setCount} count={count} open={editUserInfoModalOpen} setOpen={seteditUserInfoModalOpen} />
                                                </div>
                                                <Divider />
                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    title={t('lbl_compnyName')}
                                                    subtitle={endUserInfo.sharingCompanyName}
                                                    iconAlign='left'
                                                    icon={<BusinessCenterIcon />}
                                                    divider='partial'
                                                />
                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    title={t('lbl_officeNo')}
                                                    subtitle={endUserInfo.officePhoneNumber}
                                                    iconAlign='left'
                                                    icon={<BusinessIcon />}
                                                    divider='partial'
                                                />
                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    title={t('lbl_outOfHrs')}
                                                    subtitle={endUserInfo.outOfHoursPhoneNumber}
                                                    iconAlign='left'
                                                    icon={<NightsStayIcon />}
                                                    divider='partial'
                                                />
                                                <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                    title={t('lbl_contactEmail')}
                                                    subtitle={endUserInfo.contactEmail}
                                                    iconAlign='left'
                                                    icon={<EmailIcon />}
                                                    divider='partial'
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                        <Grid item xs={md? 12:6}>
                            <Item elevation={md?2:1}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid sx={{ height:md? '60vh': '60vh' }} item xs={12} style ={{ paddingTop: 4}}>
                                            <div>
                                                <Typography sx={{ p: 2, color:'#727E84' }} variant='subtitle2' >
                                                {t('lbl_logo')}
                                                </Typography>
                                            </div>
                                            <Divider />
                                            
                                            <LogoDropZone files={files} setFiles={setFiles} />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Item>
                        </Grid>
                        {md && <Grid item xs={12}></Grid>}
                    </Grid>
                </Box>
            </div>
            </div>
        </>
    )
}