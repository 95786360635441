/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { CurrentUserProps } from '../../components/ListAndGridView/panelEstateTypes';
import { settingsActionTypes } from '../constants/settingsActionTypes';

const initialState = {
    currentUserSettings: <CurrentUserProps>{},
    editCurrentUserSettingsStatus: <number>0,
    settingsCountries: <any>[],
    setSelectedMenuHiglighted: <string>''
}

export const settingsReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case settingsActionTypes.INSTALLER_SETTINGS:
            return { ...state, currentUserSettings: action.payload }
            break;
        case settingsActionTypes.EDIT_INSTALLER_SETTINGS:
            return { ...state, editCurrentUserSettingsStatus: action.payload }
            break;
        case settingsActionTypes.INSTALLER_SETTINGS_COUNTRIES:
            return { ...state, settingsCountries: action.payload }
            break;
            case settingsActionTypes.SET_SELECTED_SETTINGS:
            return { ...state, setSelectedMenuHiglighted: action.payload }
            break;
        default:
            return { ...state }
    }
}