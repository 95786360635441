/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Switch, TextField, Typography } from '@mui/material';
import { InfoListItem } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { EditUserGroupsProps, UserGroupProps } from './editUserModalTypes';
import './switchh.css';
import { useTranslation, Trans } from 'react-i18next';


export default function EditUserGroups({setAvailableGroups}:EditUserGroupsProps):JSX.Element {
    const { currentUser } = useSelector((state:any) => state.userReducer)
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const { userGroups, availableGroups } = useSelector((state:any) => state.accountUsersReducer)
    const { t } = useTranslation();
    const [checkedList, setCheckedList] = React.useState<boolean[]>([]);
    const [userGroupsId, setUserGroupsId] = React.useState<number[]>([]);
    const [groupSet, setGroupSet] = React.useState(true)
    checkedList.length = userGroups.length;

    React.useEffect(() => {
        if(groupSet === true){
            for (let i = 0; i < checkedList.length; i++) {
                checkedList[i] = availableGroups.some((group:UserGroupProps) => group.ID === userGroups[i].ID)
            }
            setGroupSet(false)
        }
        for (let i = 0; i < checkedList.length; i++) {
            if (checkedList[i] === true) {
                userGroupsId[i] = userGroups[i].ID;
            } else {
                userGroupsId[i] = 0;
            }
        }
        const list = userGroupsId.filter((group) => group > 0);
        if(checkedList[0] === true)
        setAvailableGroups([currentUser.groupId])
        else
        setAvailableGroups(list);
    }, [...checkedList]);

    const handleClick = (key: number): any => {
        if(key===0){
            const li = checkedList.map((c:boolean) => !checkedList[key])
            setCheckedList(li)
        }
        else{
        const openListIns = false
        const openListOne = checkedList.slice(1, key);
        const openListTwo = checkedList.slice(key + 1, checkedList.length);
        const openListThree = !checkedList[key];
        setCheckedList([openListIns,...openListOne, openListThree, ...openListTwo]);

       if([...openListOne, openListThree, ...openListTwo].filter((l) => l===true).length === 0 ){
            setCheckedList([false,...openListOne, openListThree, ...openListTwo]);
            }
        if([...openListOne, openListThree, ...openListTwo].filter((l) => l===false).length === 0 ){
            setCheckedList([true,...openListOne, openListThree, ...openListTwo]);
            }
        
        }
    };
  
  return (
      <Box
          sx={{
              flexGrow: 1,
              paddingTop: 3,
              paddingBottom: 5,
              paddingLeft: 3,
              paddingRight: 3,
              maxHeight: 450,
              overflow: 'auto',
          }}
      >
          <Grid container spacing={3}>
              <Grid item xs={12}>
                  <Typography variant="body2">{t('lbl_userPemissions')}:</Typography>
              </Grid>
              <Grid item xs={12}>
                  {userGroups?.map((userGroup: UserGroupProps, key: number) => (
                      <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                          key={key}
                          title={userGroup.ID === currentUser.groupId ? `${t('lbl_installerGrp')}` : userGroup.name}
                          backgroundColor={'#ffffff'}
                          dense={true}
                          fontColor={'#424e54'}
                          hidePadding={true}
                          iconAlign={'center'}
                          iconColor={'#424e54'}
                          divider="full"
                          rightComponent={
                              <>
                                  {/* <Switch onClick={(): any => handleClick(key)} checked={checkedList[key]} /> */}
                                  <label className="toggle-switch">
                                      <input type="checkbox" onClick={(): any => handleClick(key)} checked={checkedList[key]} />
                                      <span className="switch" />
                                  </label>
                              </>
                          }
                      />
                  ))}
              </Grid>
          </Grid>
      </Box>
  );
}
