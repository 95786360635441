/* eslint-disable @typescript-eslint/naming-convention */
import { Box, Divider, Grid, Paper, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import Appbar from '../../components/Appbar/Appbar';
import { useTranslation } from 'react-i18next';

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),
}));

export function Help(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} title={t('menu_help')} />
                <Box sx={{ flexGrow: 1, marginLeft: md ? '1%' : '10%', marginRight: md ? '1%' : '10%', marginTop: 2, pb:md?5:17 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 2 }} variant="subtitle2" color={'primary'}>
                                            {t('lbl_termsConditions')}
                                        </Typography>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 1 }}>
                                            {sm ? (
                                                <ol style={{ inlineSize: 360, listStyleType: 'none',marginLeft:-20 }}>
                                                    <li>
                                                        <strong>1. {t('lbl_termsToUseWebsite')}</strong>
                                                    </li>
                                                    <br />
                                                    <li>
                                                        <span>1.1</span> {t('lbl_termsToUseWebsite_1.1')},{' '}
                                                        <a href="https://www.eatonsecureconnect.com">
                                                            {' '}
                                                            www.eatonsecureconnect.com
                                                        </a>
                                                        . {t('lbl_termsToUseWebsite_1.1part')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        1.2 {t('lbl_termsToUseWebsite_1.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        1.3 {t('lbl_termsToUseWebsite_1.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        1.4 {t('lbl_termsToUseWebsite_1.4')}:
                                                    </li>
                                                    <br />
                                                    <li>
                                                        1.4.1 {t('lbl_termsToUseWebsite_1.4.1')}{' '}
                                                        <a href="http://notices.eatonsecureconnect.com/SecureConnectPrivacyNotice.pdf">
                                                            {' '}
                                                            SecureConnectPrivacyNotice.pdf{' '}
                                                        </a>
                                                        . {t('lbl_termsToUseWebsite_1.4.1part')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        1.5 {t('lbl_termsToUseWebsite_1.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        <strong>
                                                            2. {t('lbl_chngesToTerms')}
                                                        </strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        2.1 {t('lbl_chngesToTerms_2.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        2.2 {t('lbl_chngesToTerms_2.2')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>3. {t('lbl_aboutUs')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        3.1 {t('lbl_aboutUs_3.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        3.2 {t('lbl_aboutUs_3.2')}:
                                                        <br />
                                                        {t('lbl_aboutUs_3.2part')}{' '}
                                                        <a
                                                            onClick={(): any =>
                                                                window.open(
                                                                    'mailto:admin@eatonsecureconnect.com?subject='
                                                                )
                                                            }
                                                        >
                                                            <u>admin@eatonsecureconnect.com.</u>
                                                        </a>{' '}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>4. {t('lbl_restrictionsOnUseOfWebsite')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        4.1 {t('lbl_restrictionsOnUseOfWebsite_4.1')}
                                                    </li>
                                                    <br />
                                                    <li>4.2 {t('lbl_restrictionsOnUseOfWebsite_4.2')}:</li>
                                                    <br />

                                                    <li>
                                                        4.2.1 {t('lbl_restrictionsOnUseOfWebsite_4.2.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.2 {t('lbl_restrictionsOnUseOfWebsite_4.2.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.3 {t('lbl_restrictionsOnUseOfWebsite_4.2.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.4 {t('lbl_restrictionsOnUseOfWebsite_4.2.4')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.5 {t('lbl_restrictionsOnUseOfWebsite_4.2.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.6 {t('lbl_restrictionsOnUseOfWebsite_4.2.6')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.7 {t('lbl_restrictionsOnUseOfWebsite_4.2.7')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.8 {t('lbl_restrictionsOnUseOfWebsite_4.2.8')}
                                                    </li>
                                                    <br />
                                                    <li>4.2.9 {t('lbl_restrictionsOnUseOfWebsite_4.2.9')}</li>
                                                    <br />
                                                    <li>
                                                        4.2.10 {t('lbl_restrictionsOnUseOfWebsite_4.2.10')}
                                                    </li>
                                                    <br />
                                                    <li>4.2.11 {t('lbl_restrictionsOnUseOfWebsite_4.2.11')}</li>
                                                    <br />

                                                    <li>4.2.11.1 {t('lbl_restrictionsOnUseOfWebsite_4.2.11.1')}</li>
                                                    <br />
                                                    <li>
                                                        4.2.11.2 {t('lbl_restrictionsOnUseOfWebsite_4.2.11.2')} <strong>{t('lbl_para8')}</strong>;
                                                    </li>
                                                    <br />

                                                    <li>
                                                        4.2.12 {t('lbl_restrictionsOnUseOfWebsite_4.2.12')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.2.13 {t('lbl_restrictionsOnUseOfWebsite_4.2.13')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        4.3 {t('lbl_restrictionsOnUseOfWebsite_4.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        4.4 {t('lbl_restrictionsOnUseOfWebsite_4.4')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>5. {t('lbl_pwdNSecRequirement')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        5.1 {t('lbl_pwdNSecRequirement_5.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        5.2 {t('lbl_pwdNSecRequirement_5.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        5.3 {t('lbl_pwdNSecRequirement_5.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        5.4 {t('lbl_pwdNSecRequirement_5.4')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        <strong>6. {t('lbl_webSiteNContent')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        6.1 {t('lbl_webSiteNContent_6.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        6.2 {t('lbl_webSiteNContent_6.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        6.3 {t('lbl_webSiteNContent_6.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        6.4 {t('lbl_webSiteNContent_6.4')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        6.5 {t('lbl_webSiteNContent_6.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        <strong>
                                                            7. {t('lbl_contentUploaded')}{' '}
                                                        </strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        7.1 {t('lbl_contentUploaded_7.1')} <strong>{t('lbl_para8')}</strong> {t('lbl_contentUploaded_7.1part')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.2 {t('lbl_contentUploaded_7.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.3 {t('lbl_contentUploaded_7.3')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.4 {t('lbl_contentUploaded_7.4')}{' '}
                                                        <strong>{t('lbl_para7')}</strong>.
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.5 {t('lbl_contentUploaded_7.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.6 {t('lbl_contentUploaded_7.6')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.7 {t('lbl_contentUploaded_7.7')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.8 {t('lbl_contentUploaded_7.8')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        7.9 {t('lbl_contentUploaded_7.9')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        <strong>8. {t('lbl_contentStd')} </strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        8.1 {t('lbl_contentStd_8.1')}
                                                    </li>
                                                    <br />
                                                    <li>8.2 {t('lbl_contentStd_8.2')}</li>
                                                    <br />

                                                    <li>8.2.1 {t('lbl_contentStd_8.2.1')}</li>
                                                    <br />
                                                    <li>8.2.2 {t('lbl_contentStd_8.2.2')}</li>
                                                    <br />
                                                    <li>8.2.3 {t('lbl_contentStd_8.2.3')}</li>
                                                    <br />

                                                    <li> 8.3 {t('lbl_contentStd_8.3')}</li>
                                                    <br />

                                                    <li>
                                                        8.3.1 {t('lbl_contentStd_8.3.1')}
                                                    </li>
                                                    <br />
                                                    <li>8.3.2 {t('lbl_contentStd_8.3.2')}</li>
                                                    <br />
                                                    <li>8.3.3 {t('lbl_contentStd_8.3.3')}</li>
                                                    <br />
                                                    <li>8.3.4 {t('lbl_contentStd_8.3.4')}</li>
                                                    <br />
                                                    <li>
                                                        8.3.5 {t('lbl_contentStd_8.3.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.6 {t('lbl_contentStd_8.3.6')}{' '}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.7 {t('lbl_contentStd_8.3.7')}
                                                    </li>
                                                    <br />
                                                    <li>8.3.8 {t('lbl_contentStd_8.3.8')}</li>
                                                    <br />
                                                    <li>
                                                        8.3.9 {t('lbl_contentStd_8.3.9')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.10 {t('lbl_contentStd_8.3.10')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.11 {t('lbl_contentStd_8.3.11')}
                                                    </li>
                                                    <br />
                                                    <li>8.3.12 {t('lbl_contentStd_8.3.12')}</li>
                                                    <br />
                                                    <li>
                                                        8.3.13 {t('lbl_contentStd_8.3.13')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.14 {t('lbl_contentStd_8.3.14')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.15 {t('lbl_contentStd_8.3.15')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        8.3.16 {t('lbl_contentStd_8.3.16')}{' '}
                                                    </li>
                                                    <br />
                                                    <li>8.3.17 {t('lbl_contentStd_8.3.17')}</li>
                                                    <br />
                                                    <li>8.3.18 {t('lbl_contentStd_8.3.18')}</li>
                                                    <br />
                                                    <li>
                                                        8.3.19 {t('lbl_contentStd_8.3.19')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>9. {t('lbl_IntellctlProprty')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        9.1 {t('lbl_IntellctlProprty_9.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        9.2 {t('lbl_IntellctlProprty_9.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        9.3 {t('lbl_IntellctlProprty_9.3')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>10. {t('lbl_linksToOtherWebsite')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                    {t('lbl_linksToOtherWebsite_text')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>11. {t('lbl_linksFromOtherWebsite')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        11.1 {t('lbl_linksFromOtherWebsite_11.1')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        11.1.1 {t('lbl_linksFromOtherWebsite_11.1.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        11.1.2 {t('lbl_linksFromOtherWebsite_11.1.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        11.1.3 {t('lbl_linksFromOtherWebsite_11.1.3')}{' '}
                                                        <strong>{t('lbl_para8')}</strong>;
                                                    </li>
                                                    <br />
                                                    <li>
                                                        11.1.4 {t('lbl_linksFromOtherWebsite_11.1.4')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        11.1.5 {t('lbl_linksFromOtherWebsite_11.1.5')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        11.1.6 {t('lbl_linksFromOtherWebsite_11.1.6')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        11.2 {t('lbl_linksFromOtherWebsite_11.2')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>12. {t('lbl_ourLiability')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        12.1 {t('lbl_ourLiability_12.1')}
                                                    </li>
                                                    <br />

                                                    <li>12.1.1 {t('lbl_ourLiability_12.1.1')}</li>
                                                    <br />
                                                    <li>12.1.2 {t('lbl_ourLiability_12.1.2')}</li>
                                                    <br />
                                                    <li>
                                                        12.1.3 {t('lbl_ourLiability_12.1.3')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        12.2 {t('lbl_ourLiability_12.2')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        12.3 {t('lbl_ourLiability_12.3')}
                                                    </li>
                                                    <br />

                                                    <li>
                                                        <strong>13. {t('lbl_ourLiability_13')}</strong>
                                                    </li>
                                                    <br />

                                                    <li>
                                                        13.1 {t('lbl_ourLiability_13.1')}
                                                    </li>
                                                    <br />
                                                    <li>
                                                        13.2 {t('lbl_ourLiability_13.2')}
                                                    </li>
                                                    <br />
                                                </ol>
                                            ) : (
                                                <ol style={sm ? { inlineSize: 300 } : {}}>
                                                    <li>
                                                        <strong>{t('lbl_termsToUseWebsite')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            <span>1.1</span> {t('lbl_termsToUseWebsite_1.1')},{' '}
                                                            <a href="https://www.eatonsecureconnect.com">
                                                                {' '}
                                                                www.eatonsecureconnect.com
                                                            </a>
                                                            . {t('lbl_termsToUseWebsite_1.1part')}
                                                        </li>
                                                        <li>
                                                            1.2 {t('lbl_termsToUseWebsite_1.2')}
                                                        </li>
                                                        <li>
                                                            1.3 {t('lbl_termsToUseWebsite_1.3')}
                                                        </li>
                                                        <li>
                                                            1.4 {t('lbl_termsToUseWebsite_1.4')}:
                                                            <ol style={{ listStyleType: 'none' }}>
                                                                <li>
                                                                    1.4.1 {t('lbl_termsToUseWebsite_1.4.1')}{' '}
                                                                    <a href="http://notices.eatonsecureconnect.com/SecureConnectPrivacyNotice.pdf">
                                                                        {' '}
                                                                        SecureConnectPrivacyNotice.pdf{' '}
                                                                    </a>
                                                                    .{t('lbl_termsToUseWebsite_1.4.1part')}.
                                                                </li>
                                                            </ol>
                                                        </li>
                                                        <li>
                                                            1.5 {t('lbl_termsToUseWebsite_1.5')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>
                                                        {t('lbl_chngesToTerms')}
                                                        </strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            2.1 {t('lbl_chngesToTerms_2.1')}
                                                        </li>
                                                        <li>
                                                            2.2 {t('lbl_chngesToTerms_2.2')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_aboutUs')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            3.1 {t('lbl_aboutUs_3.1')}
                                                        </li>
                                                        <li>
                                                            3.2 {t('lbl_aboutUs_3.2')}:
                                                            <br />
                                                            {t('lbl_aboutUs_3.2part')}{' '}
                                                            <a
                                                                onClick={(): any =>
                                                                    window.open(
                                                                        'mailto:admin@eatonsecureconnect.com?subject='
                                                                    )
                                                                }
                                                            >
                                                                <u>admin@eatonsecureconnect.com.</u>
                                                            </a>{' '}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_restrictionsOnUseOfWebsite')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            4.1 {t('lbl_restrictionsOnUseOfWebsite_4.1')}
                                                        </li>
                                                        <li>4.2 {t('lbl_restrictionsOnUseOfWebsite_4.2')}:</li>
                                                        <ol style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                4.2.1 {t('lbl_restrictionsOnUseOfWebsite_4.2.1')}
                                                            </li>
                                                            <li>
                                                                4.2.2 {t('lbl_restrictionsOnUseOfWebsite_4.2.2')}
                                                            </li>
                                                            <li>
                                                                4.2.3 {t('lbl_restrictionsOnUseOfWebsite_4.2.3')}
                                                            </li>
                                                            <li>
                                                                4.2.4 {t('lbl_restrictionsOnUseOfWebsite_4.2.4')}
                                                            </li>
                                                            <li>
                                                                4.2.5 {t('lbl_restrictionsOnUseOfWebsite_4.2.5')}
                                                            </li>
                                                            <li>
                                                                4.2.6 {t('lbl_restrictionsOnUseOfWebsite_4.2.6')}
                                                            </li>
                                                            <li>
                                                                4.2.7 {t('lbl_restrictionsOnUseOfWebsite_4.2.7')}
                                                            </li>
                                                            <li>
                                                                4.2.8 {t('lbl_restrictionsOnUseOfWebsite_4.2.8')}
                                                            </li>
                                                            <li>4.2.9 {t('lbl_restrictionsOnUseOfWebsite_4.2.9')}</li>
                                                            <li>
                                                                4.2.10 {t('lbl_restrictionsOnUseOfWebsite_4.2.10')}
                                                            </li>
                                                            <li>4.2.11 {t('lbl_restrictionsOnUseOfWebsite_4.2.11')}</li>
                                                            <ol style={{ listStyleType: 'none' }}>
                                                                <li>4.2.11.1 f{t('lbl_restrictionsOnUseOfWebsite_4.2.11.1')}</li>
                                                                <li>
                                                                    4.2.11.2 {t('lbl_restrictionsOnUseOfWebsite_4.2.11')} <strong> {t('lbl_para8')}</strong>;
                                                                </li>
                                                            </ol>
                                                            <li>
                                                                4.2.12 {t('lbl_restrictionsOnUseOfWebsite_4.2.12')}
                                                            </li>
                                                            <li>
                                                                4.2.13 {t('lbl_restrictionsOnUseOfWebsite_4.2.13')}
                                                            </li>
                                                        </ol>
                                                        <li>
                                                            4.3 {t('lbl_restrictionsOnUseOfWebsite_4.3')}
                                                        </li>
                                                        <li>
                                                            4.4 {t('lbl_restrictionsOnUseOfWebsite_4.4')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_pwdNSecRequirement')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            5.1 {t('lbl_pwdNSecRequirement_5.1')}
                                                        </li>
                                                        <li>
                                                            5.2 {t('lbl_pwdNSecRequirement_5.2')}
                                                        </li>
                                                        <li>
                                                            5.3 {t('lbl_pwdNSecRequirement_5.3')}
                                                        </li>
                                                        <li>
                                                            5.4 {t('lbl_pwdNSecRequirement_5.4')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_webSiteNContent')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            6.1 {t('lbl_webSiteNContent_6.1')}
                                                        </li>
                                                        <li>
                                                            6.2 {t('lbl_webSiteNContent_6.2')}
                                                        </li>
                                                        <li>
                                                            6.3 {t('lbl_webSiteNContent_6.3')}
                                                        </li>
                                                        <li>
                                                            6.4 {t('lbl_webSiteNContent_6.4')}
                                                        </li>
                                                        <li>
                                                            6.5 {t('lbl_webSiteNContent_6.5')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>
                                                        {t('lbl_contentUploaded')}{' '}
                                                        </strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            7.1 {t('lbl_contentUploaded_7.1')}{' '}
                                                            <strong>{t('lbl_para8')}</strong> {t('lbl_contentUploaded_7.1part')}
                                                        </li>
                                                        <li>
                                                            7.2 {t('lbl_contentUploaded_7.2')}
                                                        </li>
                                                        <li>
                                                            7.3 {t('lbl_contentUploaded_7.2')}
                                                        </li>
                                                        <li>
                                                            7.4 {t('lbl_contentUploaded_7.4')} <strong>{t('lbl_para7')}</strong>.
                                                        </li>
                                                        <li>
                                                            7.5 {t('lbl_contentUploaded_7.5')}
                                                        </li>
                                                        <li>
                                                            7.6 {t('lbl_contentUploaded_7.6')}
                                                        </li>
                                                        <li>
                                                            7.7 {t('lbl_contentUploaded_7.7')}
                                                        </li>
                                                        <li>
                                                            7.8 {t('lbl_contentUploaded_7.8')}
                                                        </li>
                                                        <li>
                                                            7.9 {t('lbl_contentUploaded_7.9')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_contentStd')} </strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            8.1 {t('lbl_contentStd_8.1')}
                                                        </li>
                                                        <li>8.2 {t('lbl_contentStd_8.2')}</li>
                                                        <ol style={{ listStyleType: 'none' }}>
                                                            <li>8.2.1 {t('lbl_contentStd_8.2.1')}</li>
                                                            <li>8.2.2 {t('lbl_contentStd_8.2.2')}</li>
                                                            <li>8.2.3 {t('lbl_contentStd_8.2.3')}</li>
                                                        </ol>
                                                        <li>
                                                            {' '}
                                                            8.3 {t('lbl_contentStd_8.3')}
                                                        </li>
                                                        <ol style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                8.3.1 {t('lbl_contentStd_8.3.1')}
                                                            </li>
                                                            <li>8.3.2 {t('lbl_contentStd_8.3.2')}</li>
                                                            <li>8.3.3 {t('lbl_contentStd_8.3.3')}</li>
                                                            <li>8.3.4 {t('lbl_contentStd_8.3.4')}</li>
                                                            <li>
                                                                8.3.5 {t('lbl_contentStd_8.3.5')}
                                                            </li>
                                                            <li>
                                                                8.3.6 {t('lbl_contentStd_8.3.6')}{' '}
                                                            </li>
                                                            <li>
                                                                8.3.7 {t('lbl_contentStd_8.3.7')}
                                                            </li>
                                                            <li>
                                                                8.3.8 {t('lbl_contentStd_8.3.8')}
                                                            </li>
                                                            <li>
                                                                8.3.9 {t('lbl_contentStd_8.3.9')}
                                                            </li>
                                                            <li>
                                                                8.3.10 {t('lbl_contentStd_8.3.10')}
                                                            </li>
                                                            <li>
                                                                8.3.11 {t('lbl_contentStd_8.3.11')}
                                                            </li>
                                                            <li>8.3.12 {t('lbl_contentStd_8.3.12')}</li>
                                                            <li>
                                                                8.3.13 {t('lbl_contentStd_8.3.13')}
                                                            </li>
                                                            <li>
                                                                8.3.14 {t('lbl_contentStd_8.3.14')}
                                                            </li>
                                                            <li>
                                                                8.3.15 {t('lbl_contentStd_8.3.15')}
                                                            </li>
                                                            <li>
                                                                8.3.16 {t('lbl_contentStd_8.3.16')}{' '}
                                                            </li>
                                                            <li>8.3.17 {t('lbl_contentStd_8.3.17')}</li>
                                                            <li>8.3.18 {t('lbl_contentStd_8.3.18')}</li>
                                                            <li>
                                                                8.3.19 {t('lbl_contentStd_8.3.19')}
                                                            </li>
                                                        </ol>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_IntellctlProprty')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            9.1 {t('lbl_IntellctlProprty_9.1')}
                                                        </li>
                                                        <li>
                                                            9.2 {t('lbl_IntellctlProprty_9.2')}
                                                        </li>
                                                        <li>
                                                            9.3 {t('lbl_IntellctlProprty_9.3')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_linksToOtherWebsite')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                        {t('lbl_linksToOtherWebsite_text')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_linksFromOtherWebsite')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            11.1 {t('lbl_linksFromOtherWebsite_11.1')}
                                                        </li>
                                                        <ol style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                11.1.1 {t('lbl_linksFromOtherWebsite_11.1.1')}
                                                            </li>
                                                            <li>
                                                                11.1.2 {t('lbl_linksFromOtherWebsite_11.1.2')}
                                                            </li>
                                                            <li>
                                                                11.1.3 {t('lbl_linksFromOtherWebsite_11.1.2')} <strong>{t('lbl_para8')}</strong>;
                                                            </li>
                                                            <li>
                                                                11.1.4 {t('lbl_linksFromOtherWebsite_11.1.4')}
                                                            </li>
                                                            <li>
                                                                11.1.5 {t('lbl_linksFromOtherWebsite_11.1.5')}
                                                            </li>
                                                            <li>
                                                                11.1.6 {t('lbl_linksFromOtherWebsite_11.1.6')}
                                                            </li>
                                                        </ol>
                                                        <li>
                                                            11.2 {t('lbl_linksFromOtherWebsite_11.2')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_ourLiability')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            12.1 {t('lbl_ourLiability_12.1')}
                                                        </li>
                                                        <ol style={{ listStyleType: 'none' }}>
                                                            <li>
                                                                12.1.1 {t('lbl_ourLiability_12.1.1')}
                                                            </li>
                                                            <li>12.1.2 {t('lbl_ourLiability_12.1.2')}</li>
                                                            <li>
                                                                12.1.3 {t('lbl_ourLiability_12.1.3')}
                                                            </li>
                                                        </ol>
                                                        <li>
                                                            12.2 {t('lbl_ourLiability_12.2')}
                                                        </li>
                                                        <li>
                                                            12.3 {t('lbl_ourLiability_12.3')}
                                                        </li>
                                                    </ol>
                                                    <li>
                                                        <strong>{t('lbl_ourLiability_13')}</strong>
                                                    </li>
                                                    <ol style={{ listStyleType: 'none' }}>
                                                        <li>
                                                            13.1 {t('lbl_ourLiability_13.1')}
                                                        </li>
                                                        <li>
                                                            13.2 {t('lbl_ourLiability_13.2')}
                                                        </li>
                                                    </ol>
                                                </ol>
                                            )}
                                        </Typography>
                                    </div>
                                </div>
                            </Item>
                        </Grid>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 2 }} variant="subtitle2" color={'primary'}>
                                        {t('lbl_privPolicy')}
                                        </Typography>
                                    </div>
                                    <Divider />
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 1 }}>
                                            <ol
                                                style={
                                                    sm
                                                        ? { inlineSize: 360, listStyleType: 'none',marginLeft:-20 }
                                                        : { listStyleType: 'none' }
                                                }
                                            >
                                                <li>
                                                {t('lbl_privPolicy_text')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_privPolicy_text1')} (“<strong>{t('lbl_privPolicy_textWe')}</strong>”, “
                                                    <strong>{t('lbl_privPolicy_textUs')}</strong>”, “<strong>{t('lbl_privPolicy_textOur')}</strong>”) {t('lbl_privPolicy_text2')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_privPolicy_text3')}
                                                    <br />
                                                    <a href="http://notices.eatonsecureconnect.com/SecureConnectTermsOfUse.pdf">
                                                        SecureConnectTermsOfUse.pdf
                                                    </a>{' '}
                                                    {t('lbl_privPolicy_text4')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_privPolicy_text5')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_privPolicy_text6')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_infoWeCollect')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_infoWeCollect_text')}
                                                </li>
                                                <br />
                                                <li>{t('lbl_infoWeCollect_text1')}</li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                    {t('lbl_infoWeCollect_text2')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoWeCollect_text3')}
                                                    </li>
                                                    <li>{t('lbl_infoWeCollect_text4')}</li>
                                                    <li>
                                                    {t('lbl_infoWeCollect_text5')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoWeCollect_text6')}
                                                    </li>
                                                    <li>{t('lbl_infoWeCollect_text7')}</li>
                                                    <li>
                                                    {t('lbl_infoWeCollect_text8')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_whatWeDowithInfo')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_whatWeDowithInfo_text')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                    {t('lbl_whatWeDowithInfo_text1')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_whatWeDowithInfo_text2')}
                                                    </li>
                                                    <li>{t('lbl_whatWeDowithInfo_text3')}</li>
                                                    <li>{t('lbl_whatWeDowithInfo_text4')}</li>
                                                </ul>
                                                <br />
                                                <li>{t('lbl_whatWeDowithInfo_text5')}</li>
                                                <br />
                                                <li>
                                                {t('lbl_whatWeDowithInfo_text6')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_infoShareWith')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_infoShareWith_text')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_infoShareWith_text1')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                    {t('lbl_infoShareWith_text2')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoShareWith_text3')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoShareWith_text4')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoShareWith_text5')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_infoShareWith_text6')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                {t('lbl_infoShareWith_text7')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_howLongStoreData')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_howLongStoreData_text')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                    {t('lbl_howLongStoreData_text1')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_howLongStoreData_text2')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_howLongStoreData_text3')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_sensativeInfo')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_sensativeInfo_text')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_security')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_security_text')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_security_text1')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_cookies')}</strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text')}
                                                </li>
                                                <br />
                                                <li>
                                                    <u>{t('lbl_cookies_text1')}</u>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text2')} “<strong>{t('lbl_device')}</strong>”). {t('lbl_cookies_text3')}
                                                </li>
                                                <br />
                                                <li>
                                                    <u>{t('lbl_cookies_text4')}</u>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text5')}
                                                </li>
                                                <br />
                                                <li>
                                                    <u>{t('lbl_cookies_text6')}</u>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text7')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        <strong>{t('lbl_cookies_text8')}</strong> {t('lbl_cookies_text9')}
                                                    </li>
                                                    <li>
                                                        <strong>{t('lbl_cookies_text10')}</strong> {t('lbl_cookies_text11')}
                                                    </li>
                                                    <li>
                                                        <strong>{t('lbl_cookies_text12')}</strong> {t('lbl_cookies_text13')}
                                                    </li>
                                                    <li>
                                                        <strong> {t('lbl_cookies_text14')}</strong> {t('lbl_cookies_text15')} {t('lbl_cookies_text16')} {t('lbl_cookies_text17')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                    <u>{t('lbl_cookies_text18')}</u>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text19')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_cookies_text20')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_linking')} </strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_linking_text')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_yourRights')} </strong>
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text1')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text2')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text3')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                    {t('lbl_yourRights_text4')}
                                                    </li>
                                                    <li>{t('lbl_yourRights_text5')}</li>
                                                    <li>
                                                    {t('lbl_yourRights_text6')}
                                                    </li>
                                                    <li>
                                                    {t('lbl_yourRights_text7')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text8')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_yourRights_text9')}
                                                </li>
                                                <br />
                                                <li>
                                                    <strong>{t('lbl_noticeToCalfrniaResidents')}</strong>
                                                </li>
                                                <br />
                                                <li>{t('lbl_noticeToCalfrniaResidents_text')}</li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text1')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text2')}
                                                </li>
                                                <br />
                                                <li>{t('lbl_noticeToCalfrniaResidents_text3')}</li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text4')}
                                                </li>
                                                <br />
                                                <ul style={{ listStyleType: 'disc' }}>
                                                    <li>
                                                        <strong>{t('lbl_noticeToCalfrniaResidents_text5')}</strong>. {t('lbl_noticeToCalfrniaResidents_text6')}
                                                    </li>
                                                    <li>
                                                        <strong>{t('lbl_noticeToCalfrniaResidents_text7')}</strong>. {t('lbl_noticeToCalfrniaResidents_text8')}
                                                    </li>
                                                    <li>
                                                        <strong>{t('lbl_noticeToCalfrniaResidents_text9')}</strong>. {t('lbl_noticeToCalfrniaResidents_text10')}
                                                    </li>
                                                </ul>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text11')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text12')} <u>dataprotection@eaton.com</u>, {t('lbl_noticeToCalfrniaResidents_text13')} <u>{t('lbl_noticeToCalfrniaResidents_text14')}</u>.
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text15')}
                                                </li>
                                                <br />
                                                <li>
                                                {t('lbl_noticeToCalfrniaResidents_text16')}
                                                </li>
                                                <br />
                                            </ol>
                                        </Typography>
                                    </div>
                                </div>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
                </div>
            </div>
        </>
    );
}
