/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Divider, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

type Props = {
      companyName:string
      country:string
      language:string
      address:string
      postCode:string
      setCompanyName:(companyName:string) => void
      setCountry:(country:string) => void
      setLanguage:(language:string) => void
      setAddress:(address:string) => void
      setPostCode:(postCode:string) => void
}

export default function AddressDetails({companyName,setCompanyName,country,setCountry,language,setLanguage,address,setAddress,postCode,setPostCode}:Props):JSX.Element {
  const {countries} = useSelector((state:any) => state.registerScreenReducer)
  const { t, i18n } = useTranslation();
  const [helperText, setHelperText] = React.useState('')
  const [helperTextAddress, setHelperTextAddress] = React.useState('')
  const [helperTextPostcode, setHelperTextPostcode] = React.useState('')


  const languages = [
    {
        code : 'en_US',
        value: 'English',
    },
    {
        code:'de_DE',
        value: 'Deutsch',
    },
    {
        code:'nl_NL',
        value: 'Nederlands',
    },
    {
        code:'fr_FR',
        value: 'Francais',
    },
    {
        code:'el_GR',
        value: 'Ἑλληνική',
    },
    {
        code:'it_IT',
        value: 'Italiano',
    },
    {
        code:'pt_PT',
        value: 'Portugues',
    },
];
 
  return (
      <div>
        <Paper elevation={0} sx={{ paddingTop:2, paddingBottom:3, paddingLeft:3, paddingRight:2 }}>
            <Stack direction={'column'} spacing={2}>
              <Typography variant='h6'>{t('lbl_addressDetails')}</Typography>
              <Typography variant='body1'>
              {t('txt_addressDetails')}
              </Typography>
              </Stack>
        </Paper>
            <Divider />
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3, maxHeight:'60vh',overflow:'auto' }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField  fullWidth id="filled-basic" 
                      label={t('lbl_compnyName')} variant="filled" 
                      helperText={helperText}
                      error={helperText.length > 0}
                      value={companyName} onChange={(e) => {
                        setCompanyName(e.target.value)
                        if(e.target.value.length === 0){
                            setHelperText('This field is required')
                          }
                          else if(e.target.value.length >= 1 && e.target.value.length < 2 ){
                            setHelperText('Input length should not be less than 2 characters')
                          }
                          else{
                            setHelperText('')
                          }
                    }}
                     />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic"  select
                      label={t('lbl_country')} variant="filled" 
                      value={country} onChange={(e) => setCountry(e.target.value)}
                       >
                        {countries.map((user:{code:string; name:string},i:number) => (
                            <MenuItem key={i} value={user.name}>
                                {user.name}
                            </MenuItem>
                        ))}
                       </TextField>
              </Grid>
              <Grid item xs={12}>
              <TextField select  fullWidth id="filled-basic" label={t('lbl_language')} variant="filled" 
                        value={language} onChange={(e) => {
                            i18n.changeLanguage(e.target.value.slice(0,-3));
                            setLanguage(e.target.value)
                        }} 
                        >
                        {languages.map((user,i) => (
                            <MenuItem key={user.code} value={user.code}>
                                {user.value}
                            </MenuItem>
                        ))}
                    </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" 
                      label={t('lbl_cmpnyAddress')} variant="filled" 
                      helperText={helperTextAddress}
                      error={helperTextAddress.length > 0}
                      value={address} onChange={(e) => {
                        setAddress(e.target.value)
                        if(e.target.value.length === 0){
                            setHelperTextAddress('This field is required')
                          }
                          else{
                            setHelperTextAddress('')
                          }
                    }}
                       />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" 
                      label={t('lbl_postcode')} variant="filled" 
                      helperText={helperTextPostcode}
                      error={helperTextPostcode.length > 0}
                      value={postCode} onChange={(e) => {
                        setPostCode(e.target.value)
                        if(e.target.value.length === 0){
                            setHelperTextPostcode('This field is required')
                          }
                          else{
                            setHelperTextPostcode('')
                          }
                    }}
                       />
              </Grid>
            </Grid>
            </Box>
            </div>
  );
}
