import i18next from 'i18next';
import { AppDictionaries } from './translations/dictionary';
import { authWorkflowGerman, registrationWorkflowGerman, commonWorkflowGerman } from './translations/dictionary/buiTranslations/bui_de_translations';
import { authWorkflowGreek, registrationWorkflowGreek, commonWorkflowGreek } from './translations/dictionary/buiTranslations/bui_el_translations';
import { authWorkflowFrench, registrationWorkflowFrench, commonWorkflowFrench } from './translations/dictionary/buiTranslations/bui_fr_translations';
import { authWorkflowItalian, registrationWorkflowItalian, commonWorkflowItalian } from './translations/dictionary/buiTranslations/bui_it_translations';
import { authWorkflowNetherland, registrationWorkflowNetherland, commonWorkflowNetherland } from './translations/dictionary/buiTranslations/bui_nl_translations';
import { authWorkflowPortuguese, registrationWorkflowPortuguese, commonWorkflowPortuguese } from './translations/dictionary/buiTranslations/bui_pt_translations';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { authWorkflowEn, commonWorkflowEn, registrationWorkflowEn } from './translations/dictionary/buiTranslations/bui_en_translations';

void i18next
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector) // allows us to infer the language from browser language selection
    .init(
        {
            fallbackLng: 'en',
            ns: ['app'],
            defaultNS: 'app',
            load: 'languageOnly',
            detection: {
                order: ['querystring', 'localStorage', 'navigator'],
                caches: ['localStorage'],
                lookupLocalStorage: 'app-i18nextLng',
                lookupQuerystring: 'lng',
            },
            react: { useSuspense: false },
            interpolation: { escapeValue: false },
            resources: {
                en: {
                    app: {
                        ...AppDictionaries.english,
                    },
                    // provide the custom workflow translations to the workflow namespaces
                    bluiAuth: {
                        ...authWorkflowEn.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowEn.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowEn.translation,
                    },
                },
                fr: {
                    app: {
                        ...AppDictionaries.french,
                    },
                      // provide the custom workflow translations to the workflow namespaces
                      bluiAuth: {
                        ...authWorkflowFrench.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowFrench.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowFrench.translation,
                    },
                },
                el: {
                    app: {
                        ...AppDictionaries.greek,
                    },
                      // provide the custom workflow translations to the workflow namespaces
                      bluiAuth: {
                        ...authWorkflowGreek.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowGreek.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowGreek.translation,
                    },
                },
                nl: {
                    app: {
                        ...AppDictionaries.netherlands,
                    },
                      // provide the custom workflow translations to the workflow namespaces
                      bluiAuth: {
                        ...authWorkflowNetherland.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowNetherland.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowNetherland.translation,
                    },
                },
                pt: {
                    app: {
                        ...AppDictionaries.portuguese,
                    },
                      // provide the custom workflow translations to the workflow namespaces
                      bluiAuth: {
                        ...authWorkflowPortuguese.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowPortuguese.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowPortuguese.translation,
                    },
                },
                de: {
                    app: {
                        ...AppDictionaries.german,
                    },
                     // provide the custom workflow translations to the workflow namespaces
                     bluiAuth: {
                        ...authWorkflowGerman.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowGerman.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowGerman.translation,
                    },
                },
                it: {
                    app: {
                        ...AppDictionaries.italian,
                    },
                      // provide the custom workflow translations to the workflow namespaces
                      bluiAuth: {
                        ...authWorkflowItalian.translation,
                    },
                    bluiRegistration: {
                        ...registrationWorkflowItalian.translation,
                    },
                    bluiCommon: {
                        ...commonWorkflowItalian.translation,
                    },
                },
            },
        },
        // We must provide a function as second parameter, otherwise i18next errors
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (err, _t) => {
            // eslint-disable-next-line no-console
            if (err) return console.log(err);
        }
    );

export default i18next;