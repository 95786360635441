/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { AdministratorsProps } from '../AdministratorsTypes';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { deleteAdministrator, fetchAdminsLength } from '../../../redux/actions/administratorsActions';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

type DeleteAdminProps = {
  data: AdministratorsProps
  open: boolean
  setOpen: (open: boolean) => void
  counter: number
  setCounter: (counter: number) => void
  isMobile: boolean
}

export default function DeleteAdminModal({ counter, setCounter, data, open, setOpen, isMobile }: DeleteAdminProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackSet, setSnackSet] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [count, setCount] = React.useState(0);
  const { deleteAdminStatus } = useSelector((state: any) => state.administratorReducer);
  const [page, setPage] = React.useState(0);
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  }

  const fetching = async () => {
    await dispatch(fetchAdminsLength());
  };

  React.useEffect(() => {
    if (snackOpen) {
      if (deleteAdminStatus !== 0) {
        if (deleteAdminStatus === 204) {
          setMessage(`${t('msg_adminDeleted')}`);
          //void fetching();
        } else {
          setMessage(`${t('msg_errorDeleteAdmin')}`);
        }
        if (isMobile) {
          setCounter(counter + 1)
        }
        setOpen(false);
      }
    }
  }, [count])

  const handleDelete = async () => {
    await dispatch(deleteAdministrator(data.ID));
    await dispatch(fetchAdminsLength());
    setCount((prev) => prev + 1)
    setSnackOpen(true);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
        {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={md ? {
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300,
          backgroundColor: 'background.paper',
          boxShadow: 20,
          p: 0,
        } : style} >
          <Paper elevation={0} sx={{ pb: 1, pr: 2 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{t('lbl_confirm')}</Typography>
            <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >{t('msg_deleteAdminConfirmation')} {data.firstName} {data?.lastName}?</Typography>
            {md &&
              <Stack direction={'row-reverse'} spacing={2}>
                <Button onClick={handleDelete} color='error' disableRipple disableFocusRipple >
                  {t('btn_remove')}
                </Button>
                <Button onClick={handleClose} color='primary' disableRipple disableFocusRipple >
                {t('btn_cancel')}
                </Button>
              </Stack>}
          </Paper>
          <Divider />
          {!md && <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <Button size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handleDelete}>
                  {t('lbl_delete_panel')}
                </Button>
              }
              backButton={
                <Button size="small" variant='outlined' onClick={handleClose}  >
                  {t('btn_cancel')}
                </Button>
              }
            />
          </Paper>}
        </Box>
      </Modal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
