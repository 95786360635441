import { specificPanelAndEstateReducer } from './specificPanelAndEstateReducer';
import { newEstateReducer } from './newEstateReducer';
import { combineReducers } from "redux";
import { userReducer } from "./panelEstateReducer";
import { accountUsersReducer } from './accountUsersReducer';
import { settingsReducer } from './settingsReducer';
import { managePanelsAndEstatesReducer } from './managePanelsAndEstatesReducer';
import { endUserInfoReducer } from './endUserInfoReducer';
import { estateReducer } from './estateReducer';
import { remoteServicingReducer } from './remoteServicingReducer';
import { administratorReducer } from './administratorReducer';
import { installerReducer } from './installerReducer';
import { registerScreenReducer } from './registerScreenReducer';
import { serviceReportReducer } from './serviceReportReducer';
import { systemConfigurationReducer } from './systemConfigurationReducer';
import { systemToolReducer } from './systemToolsReducer';

const reducers = combineReducers({
    userReducer: userReducer,
    newEstateReducer: newEstateReducer,
    specificPanelAndEstateReducer: specificPanelAndEstateReducer,
    accountUsersReducer: accountUsersReducer,
    settingsReducer: settingsReducer,
    managePanelsAndEstatesReducer: managePanelsAndEstatesReducer,
    endUserInfoReducer: endUserInfoReducer,
    estateReducer: estateReducer,
    remoteServicingReducer: remoteServicingReducer,
    administratorReducer: administratorReducer,
    installerReducer: installerReducer,
    registerScreenReducer: registerScreenReducer,
    serviceReportReducer: serviceReportReducer,
    systemConfigurationReducer: systemConfigurationReducer,
    systemToolReducer: systemToolReducer,
})

export default reducers