/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { MenuItem, TextField } from '@mui/material';
import { ContactNameAndAddressFormProps } from './newEstateTypes';
import { country } from '../../constants';
import { useTranslation } from 'react-i18next';

export default function ContactNameAndAddressForm({address,setAddress,setContactName,setNumber}:ContactNameAndAddressFormProps):JSX.Element {
  const { t } = useTranslation();
  const [helpTextContactName, setHelpTextContactName] = React.useState('')
  const [helpTextContactNumber, setHelpTextContactNumber] = React.useState('')
  const [helpTextPostCode, setHelpTextPostCode] = React.useState('')
  
  return (
    <Box sx={{ flexGrow: 1,paddingTop:3, paddingBottom:5, paddingLeft:3, paddingRight:3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" label={t('lbl_contact_name')} variant="filled" 
                helperText={helpTextContactName}
                error={helpTextContactName.length > 0}
                onChange={(e) => {
                  setContactName(e.target.value)
                  if(e.target.value.length === 0){
                    setHelpTextContactName('')
                  }
                  else if(e.target.value.length >= 1 && e.target.value.length < 2 ){
                    setHelpTextContactName('Input length should not be less than 2 characters')
                  }
                  else if(e.target.value.length > 30){
                    setHelpTextContactName('Input length should not be more than 30 characters')
                  }
                  else{
                    setHelpTextContactName('')
                  }
                }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" label={t('lbl_contact_phNo')} variant="filled"
                 helperText={helpTextContactNumber}
                 error={helpTextContactNumber.length > 0}
                onChange={(e) => {
                  setNumber(e.target.value)
                  if(e.target.value.length === 0){
                    setHelpTextContactNumber('')
                  }
                  else if(e.target.value.length >= 1 && e.target.value.length < 2 ){
                    setHelpTextContactNumber('Input length should not be less than 2 characters')
                  }
                  else if(e.target.value.length > 30){
                    setHelpTextContactNumber('Input length should not be more than 30 characters')
                  }
                  else{
                    //setHelpTextContactNumber('')
                    const re = /[^0-9]/g
                    if(re.test(e.target.value)){
                      setHelpTextContactNumber('Input contains prohibited characters')
                    }
                    else{
                      setHelpTextContactNumber('')
                    }
                  }
                }} 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField select value={address.country} fullWidth id="filled-basic" label={t('lbl_country')} variant="filled" onChange={(e) => setAddress({country:e.target.value,address:address.address,postCode:address.postCode})}>
                  {country.map((c) => 
                  (
                    <MenuItem key={c.name} value={c.name}>
                                {c.name}
                            </MenuItem>
                  )
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" label={t('lbl_address')} variant="filled" onChange={(e) => setAddress({country:address.country,address:e.target.value,postCode:address.postCode})} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth id="filled-basic" label={t('lbl_postcode')} variant="filled" 
                 helperText={helpTextPostCode}
                 error={helpTextPostCode.length > 0}
                onChange={(e) => {
                  setAddress({country:address.country,address:address.address,postCode:e.target.value})
                  if(e.target.value.length === 0){
                    setHelpTextPostCode('')
                  }
                  else if(e.target.value.length > 20){
                    setHelpTextPostCode('Input length should not be more than 20 characters')
                  }
                  else{
                    setHelpTextPostCode('')
                  }
                }
                  } />
              </Grid>
            </Grid>
            </Box>
  );
}
