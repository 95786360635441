/* eslint-disable @typescript-eslint/naming-convention */
import { CircularProgress } from '@mui/material'
import React from 'react'

export default function Loader():JSX.Element {
  return (
    <div style={{
        position: 'absolute',
        display: 'flex',
        backgroundColor: 'rgba(255,255,255,0.6)',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 4,
    }}>
        <CircularProgress size={60} />
    </div>
  )
}


