import { SiaEmailOptionsProps } from './../../components/UserEmailAlertsModal/UserEmailAlertsTypes';
import { PanelLogsArrayProps } from './../../components/PanelLogs/PanelLogsTypes';
import { CslCountries, ReportGroupsPropTypes } from './../../components/ARCAccessModal/ARCAccessProps';
import { PanelARCCountryCodesProps, PanelARCSettingsProps } from './../../components/SpecificPanelDetails/SpecificPanelEstateTypes';
import { PanelARCReportingTypeProps } from './../../components/PanelARCReporting/PanelARCReportingTypes';
import { PanelNotificationTypeProps } from './../../components/PanelNotifications/PanelNotificationTypes';
import { ConnectionStatsTypeProps } from '../../components/SpecificPanelDetails/SpecificPanelEstateTypes';
import { SpecificPanelActionTypes } from './../constants/specificPanelActionTypes';
import { PanelProps, EstateProps, EstateArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
import { MobileAppUserByLoginPropTypes, MobileAppUsersPropTypes } from '../../components/MobileApplicationUsers/MobileApplicationUsersTypes';
import { ZoneMonitoringArrayProps } from '../../components/RemoteServicingActivityMonitoring/ZonesMoniteringListTypes';
const initialState = {
    specificPanel: <PanelProps>{},
    specificPanelEstate: <EstateProps>{},
    installerEstateID: <number>0,
    panelConnectionStats: <ConnectionStatsTypeProps>{},
    deletePanelResponse: <number>0,
    panelNotifications: <PanelNotificationTypeProps>[],
    panelView: <string>'',
    estatesPanelView: <string>'',
    panelARCReporting: <PanelARCReportingTypeProps>[],
    panelARCData: <any>[],
    panelARCSettings: <PanelARCSettingsProps>{},
    panelARCCountryCodes : <PanelARCCountryCodesProps>[],
    mobileAppUsers : <MobileAppUsersPropTypes>[],
    deleteMobileAppUserStatus: <number>0,
    userAccessStatus: <number>0,
    reportGroups: <ReportGroupsPropTypes>[],
    cslCountries: <CslCountries>[],
    arcDisableStatus:<number>0,
    arcEnableStatus:<number>0,
    arcConnectivityStatus:<number>0,
    mobileAppUserByLogin:<MobileAppUserByLoginPropTypes>[],
    panelLogs:<PanelLogsArrayProps>[],
    panelLogsData:<PanelLogsArrayProps>[],
    putInstallerEmailAlertsStatus:<number>0,
    installerEmailAlertsDisableStatus: <number>0,
    installerEmailAlertsStatusValue: <boolean>false,
    alerts:<string[]>[],
    userEmailAlerts: <SiaEmailOptionsProps>{},
    addUserEmailAlertsStatus:<number>0,
    data:<any>[],
    deleteEstateStatus : <number>0,
    mobileAppUserByLoginStatus:<number>0,
    htmlCodeStatus:<number>0,
    notificationsLen:<number>0,
    logsLength:<number>0,
    drawerState: <string>'',
    zonesList: <ZoneMonitoringArrayProps>[],
    zoneListModifiedStatus:<number>0,
    estateList : <EstateArrayProps>[],
}

export const specificPanelAndEstateReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL:
            return { ...state, specificPanel: action.payload }
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ESTATE:
            return { ...state, specificPanelEstate: action.payload }
            break;
        case SpecificPanelActionTypes.SET_INSTALLER_ESTATE_LIST:
                return { ...state, estateList: action.payload }
                break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_CONNECTION_STATS:
            return { ...state, panelConnectionStats: action.payload }
            case SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS_STATUS:
            return { ...state, installerEmailAlertsStatusValue: action.payload }
            case SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS:
            return { ...state, alerts: action.payload }
        case SpecificPanelActionTypes.DELETE_SPECIFIC_PANEL:
            return { ...state, deletePanelResponse: action.payload }
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS:
            return {...state, panelNotifications:action.payload}
            break;
            case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS:
            return {...state, panelLogs:action.payload}
            break;
            case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS_DATA:
            return {...state, panelLogsData:action.payload}
            break;
        case SpecificPanelActionTypes.SET_PANEL_VIEW:
            return {...state, panelView:action.payload}
            break;
        case SpecificPanelActionTypes.SET_ESTATES_PANEL_VIEW:
            return {...state, estatesPanelView:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_REPORTING:
            return {...state, panelARCReporting:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_REPORTING_DATA:
            return {...state, panelARCData:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_SETTINGS:
            return {...state, panelARCSettings:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_ARC_COUNTRY_CODES:
            return {...state, panelARCCountryCodes:action.payload}
            break;
            case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_INSTALLER_EMAIL_ALERTS:
            return {...state, putInstallerEmailAlertsStatus:action.payload}
            break;
            case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_SITE_DETAILS:
            return {...state, editSiteStatus:action.payload}
            break;
            case SpecificPanelActionTypes.SET_INSTALLER_ESTATE_ID:
            return {...state, installerEstateID:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_MOBILE_APP_USERS:
            return {...state, mobileAppUsers:action.payload}
            break;
        case SpecificPanelActionTypes.SET_DELETE_MOBILE_APP_USER_STATUS:
            return {...state, deleteMobileAppUserStatus:action.payload}
            break;
            case SpecificPanelActionTypes.SET_INSTALLER_EMAIL_ALERTS_DISABLE_STATUS:
            return {...state, installerEmailAlertsDisableStatus:action.payload}
            break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_USER_ACCESS:
            return {...state, userAccessStatus:action.payload}
            break;
        case SpecificPanelActionTypes.SET_REPORT_GROUPS:
            return {...state, reportGroups:action.payload}
            break;
        case SpecificPanelActionTypes.SET_CSL_COUNTRIES:
            return {...state, cslCountries:action.payload}
            break;
        case SpecificPanelActionTypes.SET_ARC_DISABLE_STATUS:
            return {...state, arcDisableStatus:action.payload}
            break;
        case SpecificPanelActionTypes.SET_ARC_ENABLE_STATUS:
            return {...state, arcEnableStatus:action.payload}
            break;
        case SpecificPanelActionTypes.SET_ARC_CONNECTIVITY_STATUS:
            return {...state, arcConnectivityStatus:action.payload}
            break;
        case SpecificPanelActionTypes.SET_MOBILE_APP_USER_BY_LOGIN:
            return {...state, mobileAppUserByLogin:action.payload}
                break;
        case SpecificPanelActionTypes.SET_USER_EMAIL_ALERTS:
            return {...state, userEmailAlerts:action.payload}
                break;
        case SpecificPanelActionTypes.SET_ADD_USER_EMAIL_ALERTS_STATUS:
            return {...state, addUserEmailAlertsStatus:action.payload}
                break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS_DATA:
            return {...state, data:action.payload}
                break;
        case SpecificPanelActionTypes.DELETE_SPECIFIC_ESTATE:
            return {...state, deleteEstateStatus:action.payload}
                break;
        case SpecificPanelActionTypes.SET_MOBILE_APP_USER_BY_LOGIN_STATUS:
            return {...state, mobileAppUserByLoginStatus:action.payload}
                break;
        case SpecificPanelActionTypes.SET_CONNECT_LOGIN_HTML_CODE:
            return {...state, htmlCodeStatus:action.payload}
                break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_NOTIFICATIONS_LENGTH:
            return {...state, notificationsLen:action.payload}
                break;
        case SpecificPanelActionTypes.SET_SPECIFIC_PANEL_LOGS_LENGTH:
            return {...state, logsLength:action.payload}
                break;
        case SpecificPanelActionTypes.SET_DRAWER_STATE:
            return {...state, drawerState:action.payload}
                break;
        case SpecificPanelActionTypes.SET_ZONES_MONITORING_LIST:
            return {...state, zonesList:action.payload}
                break;
        case SpecificPanelActionTypes.SET_ZONES_MONITORING_LIST_MODIFIED_STATUS:
            return {...state, zoneListModifiedStatus:action.payload}
                break;
        default:
            return { ...state }
            break;
    }
}