import english from './english';
import french from './french';
import italian from './italian';
import german from './german';
import portuguese from './portuguese';
import greek from './greek';
import netehrlands from './netherlands';

export { default as english } from './english';
export { default as french } from './french';
export { default as italian } from './italian';
export { default as german } from './german';
export { default as portuguese } from './portuguese';
export { default as netherlands } from './netherlands';

export const AppDictionaries = {
    english: english,
    french: french,
    italian: italian,
    german: german,
    portuguese: portuguese,
    greek: greek,
    netherlands: netehrlands,
};