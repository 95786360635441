/* eslint-disable @typescript-eslint/await-thenable */

import secureLocalStorage from 'react-secure-storage';
import { LOCAL_USER_DATA, REMEMBER_ME_DATA } from '../constants';

type AuthData = {
    userId: string | undefined;
    email: string | undefined;
    rememberMeData: { user: string; rememberMe: boolean };
};

async function readAuthData(): Promise<AuthData> {
    const jsonUserData = secureLocalStorage.getItem(LOCAL_USER_DATA) || {};
    const userData = (await jsonUserData) as {
        user?: string;
        userId?: string;
    };
    const jsonRememberMeData = secureLocalStorage.getItem(REMEMBER_ME_DATA) || {};
    const rememberMeData = (await jsonRememberMeData) as {
        user: string;
        rememberMe: boolean;
    };
    return {
        userId: userData.userId,
        email: userData.user,
        rememberMeData: rememberMeData,
    };
}

function saveAuthCredentials(user: string, userId: string): void {
    const userData = {
        user: user,
        userId: userId,
    };
    secureLocalStorage.setItem(LOCAL_USER_DATA, userData);
}
function saveRememberMeData(user: string, rememberMe: boolean): void {
    const RememberMeData = {
        user: rememberMe ? user : '',
        rememberMe: rememberMe,
    };
    secureLocalStorage.setItem(REMEMBER_ME_DATA, RememberMeData);
}
function clearAuthCredentials(): void {
    secureLocalStorage.removeItem(LOCAL_USER_DATA);
}
function clearRememberMeData(): void {
    secureLocalStorage.removeItem(REMEMBER_ME_DATA);
}
export const LocalStorage = {
    readAuthData,
    saveAuthCredentials,
    saveRememberMeData,
    clearAuthCredentials,
    clearRememberMeData,
};
