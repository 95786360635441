/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { Button, Divider, IconButton, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Snackbar from '@mui/material/Snackbar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DeletePanelProps } from './DeletePanelModalTypes';
import { useSelector } from 'react-redux';
import { fetchPanelsAndEstates } from '../../redux/actions/panelEstateActions';
import { deleteSpecificPanel } from '../../redux/actions/specificPanelActions';
import { fetchEstates, fetchPanels, fetchUnAssignedPanels } from '../../redux/actions/esateActions';
import { useTranslation, Trans } from 'react-i18next';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'background.paper',
  boxShadow: 20,
  p: 0,
};

export default function DeletePanelModal({ estatePage, open, setOpen, selectedPanelId, selectedPanelName }: DeletePanelProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackSet, setSnackSet] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [count, setCount] = React.useState(0);
  const { specificEstate } = useSelector((state: any) => state.estateReducer);
  const { deletePanelResponse } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const dispatch = useTypedDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  }

  const fetching = async() => {
    await dispatch(fetchEstates(specificEstate?.ID))
    await dispatch(fetchPanels(specificEstate?.ID))
    await dispatch(fetchUnAssignedPanels(specificEstate?.ID))
}

  React.useEffect(() => {
   if(snackSet) 
    {
      if(deletePanelResponse !== 0){
      setSnackOpen(true);
      if (deletePanelResponse !== 204) {
        setMessage(`${t('msg_deletePanel_error')}`);
      } else {
        setMessage(`${t('msg_panelDeleted')}`);
        if(estatePage){
          fetching()
        }
        else{
        dispatch(fetchPanelsAndEstates())
        }
      }
    }
    setSnackSet(false)
  }
  }, [count])

  const handleDelete = async () => {
    await dispatch(deleteSpecificPanel(selectedPanelId));
    setSnackSet(true)
    setCount((prev) => prev + 1)
    setOpen(false);
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
      {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} >
          <Paper elevation={0} sx={{ pb: 1 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{t('lbl_delete_panel')} {selectedPanelName}</Typography>
            <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >{t('msg_deletePanel')} “{selectedPanelName}”? {t('msg_no_action_revert')}</Typography>
          </Paper>
          <Divider />
          <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <Button size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handleDelete}>
                  {t('lbl_delete_panel')}
                </Button>
              }
              backButton={
                <Button size="small" variant='outlined' onClick={handleClose}  >
                  {t('btn_cancel')}
                </Button>
              }
            />
          </Paper>
        </Box>
      </Modal>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={message}
        action={action}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
  );
}
