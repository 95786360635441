/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable @typescript-eslint/await-thenable */
import { Dispatch } from 'react';
import { EstateArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { AccountUsersArrayProps, AccountUsersProps } from '../../components/AccountUsers/AccountUsersTypes';
import { AccountUsersActionTypes } from '../constants/accountUsersActionTypes';
import { CurrentUserProps } from '../../components/ListAndGridView/panelEstateTypes';
import { UserGroupsProps } from '../../components/AccountUsers/AddUserModal/addUserModalTypes';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

const setAccountUsers = (data: AccountUsersArrayProps): any => {
    return {
        type: AccountUsersActionTypes.ACCOUNT_USERS_LIST,
        payload: data,
    };
};

const setUserGroupList = (userGroups: UserGroupsProps): any => {
    return {
        type: AccountUsersActionTypes.USER_GROUPS_LIST,
        payload: userGroups,
    };
};

const setAddUserStatus = (addUserStatus: number): any => {
    return {
        type: AccountUsersActionTypes.ADD_USER_STATUS,
        payload: addUserStatus,
    };
};
const setEditUserStatus = (editUserStatus: number): any => {
    return {
        type: AccountUsersActionTypes.EDIT_USER_STATUS,
        payload: editUserStatus,
    };
};

const setAvailableGroups = (availableGroups: EstateArrayProps): any => {
    return {
        type: AccountUsersActionTypes.AVAILABLE_GROUPS,
        payload: availableGroups,
    };
};

const setDeleteUserRes = (status: number): any => {
    return {
        type: AccountUsersActionTypes.DELETE_USER_STATUS,
        payload: status,
    };
};

const setCurrentUserSettings = (currentUser: CurrentUserProps): any => {
    return {
        type: AccountUsersActionTypes.CURRENTUSER,
        payload: currentUser,
    };
};

export function getCurrentUserDetails() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (data) => {
                await dispatch(setCurrentUserSettings(data))
            })
    }
}

export function fetchAccountUsers() {
    let notificationEnableUsersArray: any;
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/installer`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async (installer) => {
                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${installer.ID}`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data1) => {
                        notificationEnableUsersArray = data1;
                    });

                await fetch(
                    `${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/bygroup/${installer.groupId}?filter=&limit=14&start=0`,
                    {
                        method: 'GET',
                        credentials: 'include',
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        data.forEach((element: AccountUsersProps) => {
                            element.notificationEnabledUserArray = notificationEnableUsersArray.some(
                                (item: AccountUsersProps) => element.ID === item.ID
                            );
                        });
                        dispatch(setAccountUsers(data));
                    });
            });
    };
}

export function setDeleteStatusToDefault() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
         await dispatch(setDeleteUserRes(0));
    };
}

export function fetchUserGroups(id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/list/${id}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setUserGroupList(data)));
    };
}

export function addUser(body: any, list: any, notification: boolean, id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((res) => res.json())
            .then(async (data) => {
                await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${data.ID}/groups`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(list),
                }).then((res) => {
                    dispatch(setAddUserStatus(res.status));
                });
                if (notification === true) {
                    await fetch(
                        `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${data.ID}/to/${id}`,
                        {
                            method: 'PUT',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        }
                    );
                }
            });
    };
}

export function editUser(body: any, list: any, notification: boolean, id: number | undefined, currentUser: CurrentUserProps) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then((res) => {
            dispatch(setEditUserStatus(res.status));
        });
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${id}/groups`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(list),
        });
        if (notification === true) {
            await fetch(
                `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${id}/to/${currentUser.ID}`,
                {
                    method: 'PUT',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
        }
        else {
            await fetch(
                `${REACT_APP_BASE_URL}/m2m-web-admin/rest/assignUserToInstaller/${id}/from/${currentUser.ID}`,
                {
                    method: 'DELETE',
                    credentials: 'include',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
        }
    };
}

export function fetchAvailableGroups(id: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/user/${id}/available`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => dispatch(setAvailableGroups(data)));
    };
}

export function deleteAccountUser(userId: number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/${userId}`, {
            method: 'DELETE',
            credentials: 'include',
        })
            .then((data) => {
                dispatch(setDeleteUserRes(data.status))
            });
    };
}
