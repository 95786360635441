/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, IconButton, MobileStepper, Paper, Snackbar, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation, Trans } from 'react-i18next';
import RemoteServicingEmailDestinationsModal from './RemoteServicingEmailDestinationModal';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '@brightlayer-ui/react-components';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchRemoteServicingEmailDestinations, performEditRemoteServicingEmailDestinations } from '../../redux/actions/remoteServicingActions';
import { useSelector } from 'react-redux';

type Props = {
    open:boolean
    setOpen:(open:boolean) => void
    user: any
}

export default function EmailAddressVerifyModal({ open, setOpen, user }: Props): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useTypedDispatch()
  const { registerStatus } = useSelector((state: any) => state.remoteServicingReducer)
  const [activeStep, setActiveStep] = React.useState(0);
  const [save, setSave] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [openRemoteEmailModal, setOpenRemoteEmailModal] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setActiveStep(0);
  }
  const handleResendVerification = async() => {
    const body ={
      panelId:user?.panelId,
      emailAddress:user.emailAddress,
      sendGreen:user.sendGreen === 1,
      sendAmber:user.sendAmber === 1,
      sendRed:user.sendRed === 1,
      sendFailed:user.sendFailed === 1,
      resendEmailConfirmation:true,
  }
  await dispatch(performEditRemoteServicingEmailDestinations(body, user?.panelId))
    setOpen(false);
    setSave(true)
    setActiveStep(0);
  }

  React.useEffect(() => {
    if(save){
      if (registerStatus.status === 200) {
          setMessage(registerStatus.message);
          dispatch(fetchRemoteServicingEmailDestinations(user?.panelId))
      } 
      else if (registerStatus.status === 404) {
          setMessage(registerStatus.message);
      } 
      else if (registerStatus.status === 500) {
          setMessage(`${t('msg_internal_error')}`);
      } 
      else if (registerStatus.status === 400 ) {
          setMessage(`${t('lbl_unauthorizedUser')}`);
      } else {
          setMessage(`${t('lbl_someError')}`);
      }
      setSnackOpen(true)
      setSave(false)
  }
  }, [save])

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackOpen(false);
};

  const action = (
    <React.Fragment>
        <Button color="primary" size="small" onClick={handleSnackClose}>
        {t('lbl_close')}
        </Button>
    </React.Fragment>
);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 350,
          bgcolor: 'background.paper',
          border: '0px solid #000',
          boxShadow: 20,
          p: 0,
        }} >
          <Paper elevation={0} sx={{ pb: 1,pl:1,pr:1 }}>
            <Stack direction={'row'}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2, pr:2 }} variant='h6'>
              Email address verification pending
            </Typography>
            <IconButton sx={{mt:-3}} onClick={handleClose} size='small'>
              <CloseIcon />
              </IconButton>
            </Stack>
          </Paper>
          <Paper elevation={0}>
            <Typography sx={{ pl: 2, pb: 4, pt: 0, pr:2 }} variant='body1' >
            The email address {user.emailAddress} has not yet been verified.
             Please choose a different email address or request a fresh verification link. 
            </Typography>
            </Paper>
          <Divider />
          <Paper sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3 }}>
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              nextButton={undefined}
              backButton={
                <Button fullWidth variant='contained' 
                 onClick={handleResendVerification}  >
                 Resend verification link
                </Button>
              }
            />
          </Paper>
        </Box>
      </Modal>
      <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
    </div>
  )
}


