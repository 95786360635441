/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Divider, Paper, stepButtonClasses, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EstateProps, PanelArrayProps, PanelProps } from '../ListAndGridView/panelEstateTypes';
import { useSelector } from 'react-redux';
import { ListOfPanelsWithEstatesProps } from './managePanelsTypes';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    DraggableStateSnapshot,
    Droppable,
    DroppableProvided,
    DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import './draggable.css';
import secureLocalStorage  from  "react-secure-storage";

export default function ListOfPanelsWithEstates({
    path,
    setAssignedEstate,
    setDraggedPanels,
    draggedPanels,
    panelsInEstates,
    setPanelsInEstates,
    openModal,
}: ListOfPanelsWithEstatesProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const typeOfDomain: any = secureLocalStorage.getItem('estate') || {};
    const role = secureLocalStorage.getItem('role');
    const [openList, setOpenList] = React.useState<Array<boolean>>([]);
    const { unassignedPanels } = useSelector((state: any) => state.userReducer);
    const dispatch = useTypedDispatch();
    const { panelsInEstate, estates, panels } = useSelector((state: any) => state.managePanelsAndEstatesReducer);
    //const [panelsInEstates,setPanelsInEstates] = React.useState([unassignedPanels].concat(panelsInEstate));
    let [panelsInEstatesUpdated, setPanelsInEstatesUpdated] = React.useState<any>();
    const [assign, setAssign] = React.useState(true);
    let newEstates: any[] = [];
    try{
     newEstates = [
        {
            ID: 0,
            address: {
                address: '',
                country: '',
                postCode: '',
            },
            childPresented: true,
            comments: '',
            contactName: '',
            devicesAssigned: true,
            lastAlert: false,
            level: 0,
            name: (role === 'ADMIN' && (path==='panels')) ? (typeOfDomain?.type === 'INSTALLER' ? 'Estateless panels' : typeOfDomain?.name)
                                    :
                                    typeOfDomain?.type === 'ESTATE' ? typeOfDomain?.name : 'Estateless panels',
            number: '',
            path: '',
            phoneType: '',
            type: '',
        },
    ].concat(
        estates.sort((a: EstateProps, b: EstateProps) => {
            if (a.ID !== undefined && b.ID !== undefined) {
                return a.ID - b.ID;
            }
        })
    );
    }catch(e){console.log(e)}
    openList.length = newEstates.length;

    const handleEstateClick = (key: number) => {
        //slicing openList Array
        const openListOne = openList.slice(0, key);
        const openListTwo = openList.slice(key + 1, openList.length);
        const openListThree = !openList[key];
        setOpenList([...openListOne, openListThree, ...openListTwo]);
    };

    React.useEffect(() => {
       
        panelsInEstatesUpdated = panelsInEstates;
            for (let i = 0; i < openList.length; i++) {
                if (i === 0) openList[i] = false;
                else openList[i] = false;
            }
        
    }, [openModal]);

    const onDragEnd = (result: any) => {
        

        const { destination, source } = result;
        if (!destination) return;

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        if(destination.droppableId === source.droppableId) return;

        const start = source.droppableId;
        const finish = destination.droppableId;

        const startEstate = newEstates.filter((estate: EstateProps) => estate.name === start);
        const finishEstate = newEstates.filter((estate: EstateProps) => estate.name === finish);
        const startEstateIndex = newEstates.findIndex((estate: EstateProps) => estate.ID === startEstate[0].ID);
        const finishEstateIndex = newEstates.findIndex((estate: EstateProps) => estate.ID === finishEstate[0].ID);

        const draggedPanel:PanelProps[] = panels.filter((panel: PanelProps) => panel.cloudReferenceName === result.draggableId);

        //moved panel filtering from one array to appending to another array
        const fromEstate = panelsInEstates[startEstateIndex][1].filter(
            (panel: PanelProps) => panel.cloudReferenceName !== result.draggableId
        );

        const toEstate = panelsInEstates[finishEstateIndex][1].concat(draggedPanel);

        
        setAssignedEstate(finishEstate[0].ID);
        //reading estate ID and Panel ID's
        if (draggedPanels !== undefined) setDraggedPanels([...draggedPanels, [finishEstate[0].ID, draggedPanel[0].ID]]);
        else setDraggedPanels([[finishEstate[0].ID,draggedPanel[0].ID]]);

        //list auto updating
        if (startEstateIndex < finishEstateIndex) {
            const panelsInEstatesOne = panelsInEstates.slice(0, startEstateIndex);
            const panelsInEstatesTwo = [startEstate[0], fromEstate];
            const panelsInEstatesThree = panelsInEstates.slice(startEstateIndex + 1, finishEstateIndex);
            const panelsInEstatesFour = [finishEstate[0], toEstate];
            const panelsInEstatesFive = panelsInEstates.slice(finishEstateIndex + 1, panelsInEstates.length);
            setPanelsInEstates([
                ...panelsInEstatesOne,
                panelsInEstatesTwo,
                ...panelsInEstatesThree,
                panelsInEstatesFour,
                ...panelsInEstatesFive,
            ]);
        } else {
            const panelsInEstatesOne = panelsInEstates.slice(0, finishEstateIndex);
            const panelsInEstatesTwo = [finishEstate[0], toEstate];
            const panelsInEstatesThree = panelsInEstates.slice(finishEstateIndex + 1, startEstateIndex);
            const panelsInEstatesFour = [startEstate[0], fromEstate];
            const panelsInEstatesFive = panelsInEstates.slice(startEstateIndex + 1, panelsInEstates.length);
            setPanelsInEstates([
                ...panelsInEstatesOne,
                panelsInEstatesTwo,
                ...panelsInEstatesThree,
                panelsInEstatesFour,
                ...panelsInEstatesFive,
            ]);
        }
    };

    return (
        <>
            <DragDropContext onDragEnd={(result: any) => onDragEnd(result)}>
                <List
                    sx={{
                        width: '100%',
                        maxWidth:md?'100%': 360,
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 1,
                        paddingRight: 1,
                         
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {newEstates !== undefined && 
                    newEstates.map((estate: EstateProps, key: number) => (
                        <Droppable droppableId={estate.name} key={key}>
                            {(provided: any) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <ListItemButton
                                        onClick={() => handleEstateClick(key)}
                                        sx={{ alignItems: 'left', pl: 0 }}
                                    >
                                        {openList[key] ? (
                                            <>
                                                <ExpandLess sx={{ pl: 1, color: '#007BC1' }} />
                                                <ListItemText sx={{ pl: 1, color: '#007BC1' }} primary={estate.name} />
                                            </>
                                        ) : (
                                            <>
                                                <ExpandMore sx={{ pl: 1, color: '#007BC1' }} />
                                                <ListItemText sx={{ pl: 1, color: '#007BC1' }} primary={estate.name} />
                                            </>
                                        )}
                                    </ListItemButton>

                                    <Divider />
                                    <Collapse in={openList[key]} timeout="auto" unmountOnExit>
                                        <Paper elevation={0} sx={{ maxHeight: 100, overflow: 'auto', width: 390 }}>
                                            <List component="div" disablePadding>
                                                {panelsInEstates[key][1] !== undefined && 
                                                panelsInEstates[key][1]?.map((panel: PanelProps, index: number) => (
                                                    <Draggable
                                                        key={panel?.cloudReferenceName}
                                                        draggableId={panel?.cloudReferenceName}
                                                        index={index}
                                                    >
                                                        {(providedDrag: any) => (
                                                            <ListItemButton
                                                                key={index}
                                                                sx={{ alignItems: 'left', pl: 0 }}
                                                                ref={providedDrag.innerRef}
                                                                {...providedDrag.draggableProps}
                                                                {...providedDrag.dragHandleProps}
                                                            >
                                                                <ListItemText
                                                                    sx={{ pl: key === 0 ? 1 : 6 }}
                                                                    primary={panel?.cloudReferenceName}
                                                                />
                                                            </ListItemButton>
                                                        )}
                                                    </Draggable>
                                                ))}
                                            </List>
                                        </Paper>
                                    </Collapse>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    ))}
                </List>
            </DragDropContext>
        </>
    );
}
function setClassName(arg0: string) {
    throw new Error('Function not implemented.');
}
