import { EstateActionTypes } from './../constants/estateActionTypes';
import { EstateArrayProps, EstateProps, PanelArrayProps } from './../../components/ListAndGridView/panelEstateTypes';
const initialState = {
   specificEstate:<EstateProps>{},
   panels : <PanelArrayProps>[],
   estates : <EstateArrayProps>[],
   unassignedPanel : <PanelArrayProps>[],
   allPanels: <PanelArrayProps>[],
   installerEmailStatuses:<any>[],
   userEmailAlerts:<any>[],
}

export const estateReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case EstateActionTypes.SET_ESTATE:
            return {...state, specificEstate: action.payload }
            break;
        case EstateActionTypes.SET_ESTATES:
            return {...state, estates: action.payload }
            break;
        case EstateActionTypes.SET_PANELS:
            return {...state, panels: action.payload }
            break;
        case EstateActionTypes.SET_PANELS_UNFILTERED:
            return {...state, allPanels: action.payload }
            break;
        case EstateActionTypes.SET_UNASSIGNED_PANELS:
            return {...state, unassignedPanel: action.payload }
            break;
        case EstateActionTypes.INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS:
            return {...state, installerEmailStatuses:[...state.installerEmailStatuses,action.payload]}
            break;
        case EstateActionTypes.USER_EMAIL_ALERTS_FOR_ALL_PANELS:
            return {...state, userEmailAlerts:[...state.userEmailAlerts,action.payload]}
            break;
        default:
            return state
            break;

    }
}