/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
import { Add } from '@mui/icons-material'
import { Box, Button, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { CSVLink } from 'react-csv';
import { useTypedDispatch } from '../../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { getARCDownloadReports, getAppUserDownloadReports, getInstallerDownloadReports, getManagedDownloadReports, getUnmanagedDownloadReports } from '../../../redux/actions/systemToolsActions';
import { useSelector } from 'react-redux';

function DownloadReports(): JSX.Element {
    const theme = useTheme();
    const { t } = useTranslation()
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { appUserReports, managedPanelsDownloadReports, unmanagedPanelDownloadReports, arcDownloadReports, installerDownloadReports } = useSelector((state: any) => state.systemToolReducer);
    const dispatch = useTypedDispatch();

    useEffect(() => {
        dispatch(getAppUserDownloadReports())
        dispatch(getManagedDownloadReports())
        dispatch(getUnmanagedDownloadReports())
        dispatch(getARCDownloadReports())
        dispatch(getInstallerDownloadReports())
    }, [])

    const appHeader = [
        { label: "Time Stamp", key: "creationTimeInMilliSeconds" },
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Login", key: "login" }
    ]

    const appUserReport = {
        filename: "AppUserReports.csv",
        headers: appHeader,
        data: appUserReports,
    };

    const managedPanelHeader = [
        { label: "Cloud Reference", key: "cloudReferenceName" },
        { label: "Installer Name", key: "name" },
        { label: "Installer Cloud Id", key: "cloudID" },
        { label: "ARC Access", key: "arcAccessEnabled" },
        { label: "USER Access", key: "userAccessEnabled" },
        { label: "Status", key: "status" },
    ]

    const managedPanelReports = {
        filename: "ManagedPanelReports.csv",
        headers: managedPanelHeader,
        data: managedPanelsDownloadReports,
    }

    const unManagedPanelHeader = [
        { label: "Cloud Reference", key: "cloudReferenceName" },
        { label: "ARC Access", key: "arcAccessEnabled" },
        { label: "USER Access", key: "userAccessEnabled" },
        { label: "Status", key: "status" },
    ]

    const unManagedPanelReports = {
        filename: "UnmanagedPanelReports.csv",
        headers: unManagedPanelHeader,
        data: unmanagedPanelDownloadReports,
    }

    const ARCReportHeader = [
        { label: "Cloud Reference", key: "panelName" },
        { label: "Panel ID", key: "panelId" },
        { label: "Installer Cloud ID", key: "cloudId" },
        { label: "ARC Provider", key: "provider" },
        { label: "ARC verified", key: "verified" },
        { label: "Last Message", key: "lastMessageASCII" },
        { label: "Last Message Status", key: "lastMessageStatus" },
        { label: "First Connection Date", key: "firstConnectionDate" }
    ]

    const ArcReportsReports = {
        filename: "ARCReports.csv",
        headers: ARCReportHeader,
        data: arcDownloadReports,
    }

    const InstallerHeader = [
        { label: "Name", key: "name" },
        { label: "Cloud ID", key: "cloudId" },
        { label: "Email", key: "email" },
        { label: "Company", key: "installerCompanyName" },
        { label: "Address", key: "fullAddress" },
        { label: "Contact", key: "contact" },
        { label: "Panels Count", key: "deviceCount" },
    ]

    const InstallerReportsReports = {
        filename: "InstallerReports.csv",
        headers: InstallerHeader,
        data: installerDownloadReports,
    }

    return (
        <>
            <TableContainer style={{ marginTop: '26px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant='head' style={{ color: '#1397D9' }}>
                                <Typography sx={{ fontWeight: 600 }}>
                                    {t('heading_Download_Reports')}
                                </Typography>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    {md ? <>
                        <TableBody>
                            <TableRow>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TableCell width='100%' align='left'>
                                        <Typography sx={{ fontWeight: 600 }}>{t('lbl_Installer_report')}</Typography>
                                        {(installerDownloadReports?.length > 0 && installerDownloadReports !== undefined) ? <>
                                            <CSVLink {...InstallerReportsReports}>
                                                <Button variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                                                    <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                                </Button>
                                            </CSVLink>
                                        </> : <>
                                            <Button variant="outlined" disabled fullWidth sx={{ marginTop: 2 }}>
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </>}
                                    </TableCell>
                                </div>
                            </TableRow>

                            <TableRow>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TableCell width='100%' align='left'>
                                        <Typography sx={{ fontWeight: 600 }}>{t('lbl_App_userReport')}</Typography>
                                        {(appUserReports?.length > 0 && appUserReports !== undefined) ? <>
                                            <CSVLink {...appUserReport}>
                                                <Button variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                                                    <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                                </Button>
                                            </CSVLink>
                                        </> : <>
                                            <Button variant="outlined" disabled fullWidth sx={{ marginTop: 2 }}>
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </>}
                                    </TableCell>
                                </div>
                            </TableRow>

                            <TableRow>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TableCell width='100%' align='left'>
                                        <Typography sx={{ fontWeight: 600 }}>{t('lbl_Managed_panelsreport')}</Typography>
                                        {(managedPanelsDownloadReports?.length > 0 && managedPanelsDownloadReports !== undefined) ? <>
                                            <CSVLink {...managedPanelReports}>
                                                <Button variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                                                    <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                                </Button>
                                            </CSVLink>
                                        </> : <>
                                            <Button variant="outlined" disabled fullWidth sx={{ marginTop: 2 }}>
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </>}
                                    </TableCell>
                                </div>
                            </TableRow>

                            <TableRow>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TableCell width='100%' align='left'>
                                        <Typography sx={{ fontWeight: 600 }}>{t('lbl_Unmanaged_panels_report')}</Typography>
                                        {(unmanagedPanelDownloadReports?.length > 0 && unmanagedPanelDownloadReports !== undefined) ? <>
                                            <CSVLink {...unManagedPanelReports}>
                                                <Button variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                                                    <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                                </Button>
                                            </CSVLink>
                                        </> : <>
                                            <Button variant="outlined" disabled fullWidth sx={{ marginTop: 2 }}>
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </>}
                                    </TableCell>
                                </div>
                            </TableRow>

                            <TableRow>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TableCell width='100%' align='left'>
                                        <Typography sx={{ fontWeight: 600 }}>{t('lbl_ARC_report')}</Typography>
                                        {(arcDownloadReports?.length > 0 && arcDownloadReports !== undefined) ? <>
                                            <CSVLink {...ArcReportsReports}>
                                                <Button variant="outlined" fullWidth sx={{ marginTop: 2 }}>
                                                    <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                                </Button>
                                            </CSVLink>
                                        </> : <>
                                            <Button variant="outlined" disabled fullWidth sx={{ marginTop: 2 }}>
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </>}
                                    </TableCell>
                                </div>
                            </TableRow>
                        </TableBody>
                    </> : <>

                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        {t('lbl_Installer_report')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    {(installerDownloadReports?.length > 0 && installerDownloadReports !== undefined) ? <>
                                        <CSVLink {...InstallerReportsReports}>
                                            <Button variant="outlined">
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </CSVLink>
                                    </> : <>
                                        <Button variant="outlined" disabled>
                                            <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                        </Button>
                                    </>}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        {t('lbl_App_userReport')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    {(appUserReports?.length > 0 && appUserReports !== undefined) ? <>
                                        <CSVLink {...appUserReport}>
                                            <Button variant="outlined" >
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </CSVLink>
                                    </> : <>
                                        <Button variant="outlined" disabled>
                                            <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                        </Button>
                                    </>}

                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        {t('lbl_Managed_panelsreport')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    {(managedPanelsDownloadReports?.length > 0 && managedPanelsDownloadReports !== undefined) ? <>
                                        <CSVLink {...managedPanelReports}>
                                            <Button variant="outlined" >
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </CSVLink>
                                    </> : <>
                                        <Button variant="outlined" disabled>
                                            <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                        </Button>
                                    </>}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        {t('lbl_Unmanaged_panels_report')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    {(unmanagedPanelDownloadReports?.length > 0 && unmanagedPanelDownloadReports !== undefined) ? <>
                                        <CSVLink {...unManagedPanelReports}>
                                            <Button variant="outlined" >
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </CSVLink>
                                    </> : <>
                                        <Button variant="outlined" disabled>
                                            <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                        </Button>
                                    </>}
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell>
                                    <Typography>
                                        {t('lbl_ARC_report')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='right'>
                                    {(arcDownloadReports?.length > 0 && arcDownloadReports !== undefined) ? <>
                                        <CSVLink {...ArcReportsReports}>
                                            <Button variant="outlined" >
                                                <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                            </Button>
                                        </CSVLink>
                                    </> : <>
                                        <Button variant="outlined" disabled>
                                            <Add /> &nbsp; &nbsp; {t('lbl_Download')}
                                        </Button>
                                    </>}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </>}

                </Table>
            </TableContainer>
        </>
    )
}

export default DownloadReports
