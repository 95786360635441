/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react'
import {
    AppBar,
    Button,
    Checkbox,
    IconButton,
    Theme,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Spacer } from '@brightlayer-ui/react-components';
//import { useSecurityActions } from '@brightlayer-ui/react-auth-shared';
import { LocalStorage } from '../../store/local-storage';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import SearchIcon from '@mui/icons-material/Search';
import Usermenu from '../UserMenu/UserMenu';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            backgroundColor: 'white'
        },
    })
);

export default function PanelAppbar(): JSX.Element {
    const theme = useTheme();
    const classes = useStyles(theme);
    const md = useMediaQuery(theme.breakpoints.up('md'));
    //const securityHelper = useSecurityActions();
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer)
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBackClick = (): any => {
        navigate(-1)
    }

    const logOut = (): void => {
        LocalStorage.clearAuthCredentials();
        //securityHelper.onUserNotAuthenticated();
        try {
            fetch(`${REACT_APP_BASE_URL}/m2m-auth/rest/auth/logout`, {
                method: 'POST'
            })
        }
        catch (err) { console.log(err) }
    };
    return (
        <>
            <AppBar elevation={md ? 0 : 0} position={'sticky'}>
                <Toolbar className={classes.toolbar}>
                    {md ?
                        <IconButton
                            color={'default'}
                            onClick={handleBackClick}
                            edge={'start'}
                            style={{ marginRight: theme.spacing(3) }}
                            size="large"
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        : (
                            <IconButton
                                color={'default'}
                                onClick={handleBackClick}
                                edge={'start'}
                                style={{ marginRight: theme.spacing(3) }}
                                size="large"
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        )}
                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                        <Typography variant='h6' sx={{ color: '#424E54' }} >&ensp;&nbsp;{specificPanel?.cloudReferenceName}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'row' }} >
                            <Checkbox sx={{ mt: -0.9 }} size={'small'} icon={<CloudOffIcon color='action' />} disabled checkedIcon={<CloudDoneIcon color='success' />} checked={specificPanel?.status === 'CONNECTED'} />
                            {specificPanel?.status === 'CONNECTED' ? <Typography sx={{ color: '#424E54' }} >{t('lbl_connected')}</Typography> : <Typography sx={{ color: '#424E54' }} >{t('lbl_disconnected')}</Typography>}
                        </div>
                    </div>
                    <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                    <Usermenu />
                </Toolbar>
            </AppBar>
        </>
    )
}