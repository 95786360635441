export const authWorkflowNetherland =  {
    translation: {
        LOGIN: {
            INCORRECT_CREDENTIALS: 'Incorrecte email of wachtwoord',
            INVALID_CREDENTIALS: 'Uw gebruikersnaam/wachtwoord-combinatie wordt niet herkend.',
            GENERIC_ERROR: 'Uw verzoek kan op dit moment niet worden verwerkt.',
        },
        HEADER: {
            FORGOT_PASSWORD: 'Wachtwoord vergeten',
        },
        CHANGE_PASSWORD: {
            PASSWORD_INFO:
                'Selecteer een wachtwoord. Zorg ervoor dat uw wachtwoord voldoet aan de noodzakelijke complexiteitsvereisten die hieronder worden beschreven.',
        },
        FORGOT_PASSWORD: {
            INSTRUCTIONS_ALT:
                `Voer het e-mailadres van het account in dat aan het account is gekoppeld.<br/><br/>` +
                `Als deze e-mail een account bij Eaton heeft, ontvangt u binnen enkele dagen een reactie <1>{{time}}</1>.`,
        },
    },
}
// Common Keys shared by Auth and Registration workflows
export const commonWorkflowNetherland =  {
    translation: {
        ACTIONS: {
            FINISH: 'Finish',
            NEXT: 'Volgende',
            BACK: 'Rug',
            CREATE_ACCOUNT: 'Account aanmaken',
            OKAY: 'Oké',
            CANCEL: 'Annuleren',
            CONTINUE: 'Doorgaan',
            DONE: 'Klaar',
            LOG_IN: 'Log in',
            LOG_OUT: 'Uitloggen',
            RESEND: 'Opnieuw versturen',
            REMEMBER: 'Onthoud me',
            SUBMIT: 'Indienen',
        },
        LABELS: {
            EMAIL: 'E-mailadres',
            PASSWORD: 'Wachtwoord',
            FORGOT_PASSWORD: 'Je wachtwoord vergeten?',
            NEED_ACCOUNT: 'Een account nodig?',
        },
        MESSAGES: {
            EMAIL_SENT: 'Email verzonden',
            WELCOME: 'Welkom',
            REQUEST_ERROR: 'Sorry, er is een probleem opgetreden bij het verzenden van uw verzoek.',
            ERROR: 'Fout!',
            EMAIL_ENTRY_ERROR: 'Gelieve een geldig e-mailadres in te vullen',
            CONTACT: 'Neem contact op met een Eaton-ondersteuningsvertegenwoordiger',
            LOADING: 'Bezig met laden...',
            PASSWORD_REQUIRED_ERROR: 'Wachtwoord benodigd',
        },
        FORMS: {
            FIRST_NAME: 'Voornaam',
            LAST_NAME: 'Achternaam',
            PASSWORD: 'Wachtwoord',
            CONFIRM_PASSWORD: 'bevestig wachtwoord',
        },
        PASSWORD_REQUIREMENTS: {
            LENGTH: '8-16 tekens',
            NUMBERS: 'Een nummer',
            UPPER: 'Een hoofdletter',
            LOWER: 'Een kleine letter',
            SPECIAL: 'Eén speciaal karakter',
        },
    },
}
// Registration Workflow Keys
export const registrationWorkflowNetherland = {
    translation: {
        REGISTRATION: {
            EULA: {
                LOADING: 'Licentieovereenkomst voor eindgebruikers laden...',
                AGREE_TERMS: 'Ik heb de Algemene Voorwaarden gelezen en ga ermee akkoord',
            },
            STEPS: {
                CREATE_ACCOUNT: 'Account aanmaken',
                LICENSE: 'Licentieovereenkomst',
                VERIFY_EMAIL: 'Verifieer Email',
                PASSWORD: 'Maak een wachtwoord',
                ACCOUNT_DETAILS: 'Accountgegevens',
                COMPLETE: 'Account aangemaakt!',
            },
            INSTRUCTIONS: {
                ACCOUNT_DETAILS: 'Vul hieronder uw gegevens in om het aanmaken van een account te voltooien.',
            },
            SELF_REGISTRATION: {
                INSTRUCTIONS: `Om u te registreren voor een Eaton-account, voert u hieronder de vereiste gegevens in. U moet uw e-mailadres verifiëren om door te gaan.`,
                VERIFY_EMAIL: {
                    RESEND: 'Verificatie-e-mail opnieuw verzenden',
                    VERIFICATION: 'Verificatie code',
                },
            },
        },
    },
};