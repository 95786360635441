/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { Button, Divider, MobileStepper, Paper, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { DeleteCompletedReportsForPanelProps } from './DeleteReportsForPanelProps';
import { deleteCompletedReportFromListForPanel } from '../../redux/actions/remoteServicingActions';
import { useTranslation } from 'react-i18next';

export default function DeleteCompletedServiceReportModal({ openDeleteModal, setOpenDeleteModal, selectedReportId, counter, setCounter, open, setOpen }: DeleteCompletedReportsForPanelProps): JSX.Element {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useTypedDispatch();
  const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer);
  const { t } = useTranslation();

  React.useEffect(() => {
    //setOpen(false)
  }, [])

  const handleClose = () => {
    setOpenDeleteModal(false);
    setActiveStep(0);
  }

  const handleDelete = async () => {
    await dispatch(deleteCompletedReportFromListForPanel(specificPanel?.panelId, selectedReportId));
    setOpenDeleteModal(false)
    setCounter(counter + 1)
  }

  return (
    <div>
      <Modal
        open={openDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: md ? 300 : 400,
          bgcolor: 'background.paper',
          border: '0px solid #000',
          boxShadow: 20,
          p: 0,
        }} >
          <Paper elevation={0} sx={{ pb: 1 }}>
            <Typography sx={{ pt: 2, pl: 2, pb: 2 }} variant='h6'>{t('lbl_delete_svcReport')}</Typography>
            <Typography sx={{ pl: 2, pb: 4, pt: 0 }} >{t('msg_delete_svcReport')}</Typography>
          </Paper>
          <Divider />
          <Paper >
            <MobileStepper
              variant="dots"
              steps={0}
              position="static"
              activeStep={activeStep}
              sx={{ maxWidth: 400, flexGrow: 1, paddingTop: 2, paddingBottom: 2, paddingLeft: 3, paddingRight: 3, backgroundColor: 'inherit' }}
              nextButton={
                <Button size="medium" disableElevation sx={{ backgroundColor: '#CA3C3D' }} variant='contained' onClick={handleDelete}>
                  {t('lbl_delete')}
                </Button>
              }
              backButton={
                <Button size="small" variant='outlined' onClick={handleClose}  >
                  {t('btn_cancel')}
                </Button>
              }
            />
          </Paper>
        </Box>
      </Modal>
    </div>
  )
}


