/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { ARDSchedulerProps, PendingAndFailedReportsArrayProps, RemoteServiceReportArrayProps } from '../../components/RemoteServicingModal/remoteServicingTypeProps';
import { remoteServicingActionTypes } from '../constants/remoteServicingActionTypes';

const initialState = {
    remoteServiceAllReports: <RemoteServiceReportArrayProps>[],
    pendingAndFailedReportsList: <PendingAndFailedReportsArrayProps>[],
    deleteReportForPanelStatus: <number> 0,
    performRemoteServicingStatus: <number> 0,
    performRemoteServicingData: <any> {},
    deleteFailedServiceStatus: <number>0,
    performRemoteServicingStatusMessage: <any> {},
    enabled: <any> {},
    scheduler:<ARDSchedulerProps>{},
    schedulerStatus: <number>0,
    registerStatus:<any>{},
    emailDestinations:<any>{},
    deleteStatus:<any>{},
}

export const remoteServicingReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case remoteServicingActionTypes.All_REPORTS:
            return { ...state, remoteServiceAllReports: action.payload }
            break;
        case remoteServicingActionTypes.All_PENDING_AND_FAILED_REPORTS:
            return { ...state, pendingAndFailedReportsList: action.payload }
            break;
            case remoteServicingActionTypes.DELETE_REPORTS:
            return { ...state, deleteReportForPanelStatus: action.payload }
            break;
            case remoteServicingActionTypes.PERFORM_REMOTE_SERVICING:
            return { ...state, performRemoteServicingStatus: action.payload }
            break;
            case remoteServicingActionTypes.PERFORM_REMOTE_SERVICING_DATA:
            return { ...state, performRemoteServicingData: action.payload }
            break;
            case remoteServicingActionTypes.PERFORM_REMOTE_SERVICING_MESSAGE:
            return { ...state, performRemoteServicingStatusMessage: action.payload }
            break;
            case remoteServicingActionTypes.GET_PERFORM_REMOTE_SERVICING_STATUS:
            return { ...state, enabled: action.payload }
            break;
            case remoteServicingActionTypes.GET_DELETE_FAILED_SERVICE_STATUS:
            return { ...state, deleteFailedServiceStatus: action.payload }
            break;
            case remoteServicingActionTypes.GET_ARD_SCHEDULER:
            return { ...state, scheduler: action.payload }
            break;
            case remoteServicingActionTypes.GET_ARD_SCHEDULER_STATUS:
            return { ...state, schedulerStatus: action.payload }
            break;
            case remoteServicingActionTypes.GET_ARD_EMAIL_REGISTER_STATUS:
            return { ...state, registerStatus: action.payload }
            break;
            case remoteServicingActionTypes.GET_ARD_EMAIL_DESTINATIONS:
            return { ...state, emailDestinations: action.payload }
            break;
            case remoteServicingActionTypes.GET_ARD_EMAIL_DELETE_STATUS:
            return { ...state, deleteStatus: action.payload }
            break;
        default:
            return { ...state }
    }
}