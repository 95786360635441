/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint no-unused-expressions: ["error", { "allowTernary": true }] */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {
    AppBar,
    Avatar,
    Button,
    Checkbox,
    Collapse,
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Toolbar,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { EmptyState, InfoListItem, Spacer, ThreeLiner } from '@brightlayer-ui/react-components';
import { useSelector } from 'react-redux';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { useParams } from 'react-router-dom';
import {
    deleteMobileAppUser,
    fetchMobileAppUserByLogin,
    fetchMobileAppUserByLoginStatus,
    fetchMobileAppUsers,
} from '../../redux/actions/specificPanelActions';
import Snackbar from '@mui/material/Snackbar';
import { MobileAppUserByLoginProps, MobileAppUserProps } from './MobileApplicationUsersTypes';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import { STATUS_CODES } from '../../entities/RequestStatus';
import { STATUS_MESSAGES } from '../../entities/Constants';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { AmazonAlexa, Google } from '@brightlayer-ui/icons-mui';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import { ArrowBack } from '@mui/icons-material';
import './checkbox.css';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import { getAMorPM, getDate, getDateFormat, getHours, getMinutes, getMonth, getSeconds, getTimeFormat, getYear } from '../Timestamp/timestampCal';
import secureLocalStorage from 'react-secure-storage';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0),

    color: theme.palette.text.secondary,
}));

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 0,
};

export default function MobileApplicationUsers(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const ms = useMediaQuery(theme.breakpoints.up('md'));
    const [deleteAppUserModal, setDeleteAppUserModal] = React.useState(false);
    const [mobileViewModalOpen, setMobileViewModalOpen] = React.useState(false);
    const [mobileUser, setMobileUser] = React.useState<MobileAppUserProps>();
    const { currentUser } = useSelector((state: any) => state.userReducer);
    //const currentUser = JSON.parse(localStorage.getItem('currentUser') || '')
    const [openModal, setOpenModal] = React.useState(false);
    const [mobileUserLog, setMobileUserLog] = React.useState<MobileAppUserByLoginProps>();
    const role = secureLocalStorage.getItem('role')
    const handleModalOpen = (mobUserLog: MobileAppUserByLoginProps) => {
        setMobileUserLog(mobUserLog);
        setOpenModal(true);
    };
    const handleModalClose = () => setOpenModal(false);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const { specificPanel, mobileAppUsers, deleteMobileAppUserStatus, mobileAppUserByLogin, mobileAppUserByLoginStatus } = useSelector(
        (state: any) => state.specificPanelAndEstateReducer
    );
    const dispatch = useTypedDispatch();
    const { id } = useParams();
    const [openList, setOpenList] = React.useState<Array<boolean>>([]);
    const { t } = useTranslation();
    openList.length = mobileAppUsers.length;

    const handleDeleteAppUserModalOpen = () => {
        setDeleteAppUserModal(true);
    };
    const handleDeleteAppUserModalClose = () => {
        setDeleteAppUserModal(false);
    };

    const handleMobileViewModal = async(mobUsers: MobileAppUserProps, panelId: string, login: string, key: number) => {
       await dispatch(fetchMobileAppUserByLogin(panelId, login));
        setMobileUser(mobUsers);
        if (md === true) setMobileViewModalOpen(true);
    };

    const handleMobileViewModalClose = () => {
        setMobileViewModalOpen(false);
    };



    const handleMobUserClick = async(panelId: string, login: string, key: number) => {
        await dispatch(fetchMobileAppUserByLogin(panelId, login));
        await dispatch(fetchMobileAppUserByLoginStatus(panelId, login));
        
            if (!openList[key]) {
                for (let i = 0; i < openList.length; i++) {
                    openList[i] = false;
                }
            }
            //slicing openList Array
            const openListOne = openList.slice(0, key);
            const openListTwo = openList.slice(key + 1, openList.length);
            const openListThree = !openList[key];
            setOpenList([...openListOne, openListThree, ...openListTwo]);
        
    };

    const handleDeleteIcon = (panelId: string, mobUser: string | undefined) => {
        dispatch(deleteMobileAppUser(panelId, mobUser));
        if (md === true) {
            setDeleteAppUserModal(false);
        }
        setOpen(true);
    };
    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    React.useEffect(() => {
    if(openList.some((l) => l === true)){
        const key = openList.findIndex((l) => l=== true)
        if(mobileAppUserByLoginStatus === 200){
            setOpenList(openList)
        }
        else{
            if(mobileAppUserByLoginStatus === 401){
                setMessage(`${t('msg_usr_authorized')}`)
                setOpen(true)
            }
            else{
                setMessage(`${t('msg_internal_error')}`)
                setOpen(true)
            }
        }
    }
    else{
        for (let i = 0; i < openList.length; i++) {
            openList[i] = false;
        }

        switch (deleteMobileAppUserStatus) {
            case STATUS_CODES.HTTP_OK:
                setMessage(STATUS_MESSAGES.HTTP_OK);
                break;
            case STATUS_CODES.HTTP_ERROR_INVALID_REQUEST:
                setMessage(STATUS_MESSAGES.HTTP_ERROR_INVALID_REQUEST);
                break;
            case STATUS_CODES.HTTP_ERROR_RESTRICTED_USER_ACCESS:
                setMessage(STATUS_MESSAGES.HTTP_ERROR_RESTRICTED_USER_ACCESS);
                break;
            case STATUS_CODES.HTTP_ERROR_UNAUTHORIZED_USER:
                setMessage(STATUS_MESSAGES.HTTP_ERROR_UNAUTHORIZED_USER);
                break;
            case STATUS_CODES.HTTP_ERROR_NOT_FOUND:
                setMessage(STATUS_MESSAGES.HTTP_ERROR_NOT_FOUND);
                break;
            case STATUS_CODES.HTTP_INTERNAL_SERVER_ERROR:
                setMessage(STATUS_MESSAGES.HTTP_INTERNAL_SERVER_ERROR);
                break;
            default:
                setMessage(`${t('msg_try_later_error')}`);
                break;
        }

        dispatch(fetchMobileAppUsers(Number(id)));
    }
    }, [deleteMobileAppUserStatus,...openList]);

    const action = (
        <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    

    return (
        <div style={md ? {} : { maxHeight: '80vh', overflow: 'auto' }}>
            <Box
                sx={
                    md
                        ? {
                              flexGrow: 1,
                              marginLeft: -3,
                              marginRight: -3,
                              marginTop: -1,
                              maxHeight: '100vh',
                              overflow: 'auto',
                              pb: 40,
                          }
                        : { flexGrow: 1, marginLeft: 2, marginRight: 2, marginTop: 2, pb: 40 }
                }
            >
                <Grid container spacing={md ? 0 : 2}>
                    <Grid item xs={12}>
                        <Item elevation={1}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Grid container spacing={2}>
                                    {md ? (
                                        ''
                                    ) : (
                                        <>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    <Typography variant="subtitle2" color="primary">
                                                        &ensp;&ensp;&ensp;&ensp;{t('lbl_mobAppUsrs')}
                                                    </Typography>
                                                </Item>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Item elevation={0}>
                                                    {mobileAppUsers?.length === 0 ? '':<Divider />}
                                                </Item>
                                            </Grid>
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <Item elevation={0}>
                                            {mobileAppUsers?.length === 0 ? (
                                                <>
                                                    <EmptyState nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                        icon={<Avatar sx={{ bgcolor: '#727E84' }}><PersonIcon sx={{ color: '#ffff' }} fontSize="medium" /></Avatar>}
                                                        description={[
                                                            <Typography
                                                                sx={{
                                                                    color: '#6A767C',
                                                                    fontWeight: 600,
                                                                    fontSize: 14,
                                                                }}
                                                                key={0}
                                                                color="action"
                                                            >
                                                                {t('No mobile application users')}
                                                            </Typography>,
                                                        ]}
                                                        title={undefined}
                                                    />
                                                </>
                                            ) : (
                                                currentUser?.userType !== 'END_USER' &&
                                                mobileAppUsers?.map((mobUsers: MobileAppUserProps, key: number) => (
                                                    <div key={key}>
                                                        <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                            title={undefined}
                                                            subtitle={undefined}
                                                            info={undefined}
                                                            iconAlign="left"
                                                            leftComponent={
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                    }}
                                                                >
                                                                    <div>
                                                                        <ThreeLiner nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                                                            subtitle={
                                                                                md ? (
                                                                                    `${mobUsers?.firstName} ${mobUsers?.lastName}`
                                                                                ) : (
                                                                                    <input
                                                                                        readOnly
                                                                                        value={`${mobUsers?.firstName} ${mobUsers?.lastName}`}
                                                                                        style={{
                                                                                            border: 'none',
                                                                                            outline: 'none',
                                                                                            fontSize: 16,
                                                                                            fontFamily: 'Open Sans',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: 600,
                                                                                            lineHeight: 2,
                                                                                            backgroundColor: 'inherit',
                                                                                        }}
                                                                                    />
                                                                                )
                                                                            }
                                                                            info={mobUsers?.login}
                                                                        />
                                                                    </div>
                                                                    &ensp;&ensp;&ensp;&ensp;&ensp;&ensp;
                                                                    {ms && (
                                                                        <div
                                                                            style={{
                                                                                display: 'flex',
                                                                                flexDirection: 'column',
                                                                            }}
                                                                        >
                                                                            <Typography variant="caption">
                                                                                {t('lbl_lastConnection')}
                                                                            </Typography>
                                                                            <div
                                                                                style={{
                                                                                    paddingTop: 0,
                                                                                }}
                                                                            >
                                                                                <strong>
                                                                                    {getHours(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                    :
                                                                                    {getMinutes(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                    :
                                                                                    {getSeconds(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                </strong>
                                                                                &ensp;
                                                                                
                                                                            </div>
                                                                            <div>
                                                                                <Typography variant="caption">
                                                                                    {getDate(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                    /
                                                                                    {getMonth(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                    /
                                                                                    {getYear(
                                                                                        mobUsers?.creationTimeInMilliSeconds
                                                                                    )}
                                                                                </Typography>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            }
                                                            rightComponent={
                                                                ms && (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                        }}
                                                                    >
                                                                       {(role === 'ADMIN' || currentUser?.userType === 'INSTALLER' || currentUser?.userType === 'MANAGER' || currentUser?.userType === 'ENGINEER') && 
                                                                       <IconButton
                                                                            onClick={() =>
                                                                                handleDeleteIcon(
                                                                                    specificPanel.panelId,
                                                                                    mobUsers.login
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>}
                                                                        &ensp;&ensp;
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleMobUserClick(
                                                                                    specificPanel.panelId,
                                                                                    mobUsers.login,
                                                                                    key
                                                                                )
                                                                            }
                                                                            className="check-hide"
                                                                            style={{ paddingTop: 1 }}
                                                                        >
                                                                            <span
                                                                                className={
                                                                                    openList[key]
                                                                                        ? 'arrow-up'
                                                                                        : 'arrow-down '
                                                                                }
                                                                            />
                                                                        </IconButton>
                                                                    </div>
                                                                )
                                                            }
                                                            icon={
                                                                md ? (
                                                                    <InfoIcon color="action" />
                                                                ) : (
                                                                    <Avatar sx={{ bgcolor: '#007BC1' }}>
                                                                        <PersonIcon sx={{ color: '#ffff' }} />
                                                                    </Avatar>
                                                                )
                                                            }
                                                            chevron={md}
                                                            onClick={() =>
                                                                handleMobileViewModal(
                                                                    mobUsers,
                                                                    specificPanel.panelId,
                                                                    mobUsers.login,
                                                                    key
                                                                )
                                                            }
                                                        />
                                                        <Divider />
                                                        <Collapse
                                                            in={Array.isArray(mobileAppUserByLogin) && openList[key]}
                                                        >
                                                            <Box sx={{ flexGrow: 1, p: 2 }}>
                                                                <Grid container spacing={1}>
                                                                    {mobileAppUserByLogin?.length > 0 &&
                                                                        mobileAppUserByLogin?.length !== undefined &&
                                                                        mobileAppUserByLogin?.map(
                                                                            (mobUserLog: MobileAppUserByLoginProps) => (
                                                                                <>
                                                                                    <Grid item xs={1.5}>
                                                                                        <Chip
                                                                                            clickable
                                                                                            onClick={() =>
                                                                                                handleModalOpen(
                                                                                                    mobUserLog
                                                                                                )
                                                                                            }
                                                                                            label={
                                                                                                mobUserLog?.mobileOSType ===
                                                                                                'ANDROID'
                                                                                                    ? `${t(
                                                                                                          'lbl_androidPh'
                                                                                                      )}`
                                                                                                    : mobUserLog?.mobileOSType ===
                                                                                                      'IOS'
                                                                                                    ? `${t(
                                                                                                          'lbl_iPhone'
                                                                                                      )}`
                                                                                                    : mobUserLog?.mobileOSType ===
                                                                                                      'ALEXA'
                                                                                                    ? `${t(
                                                                                                          'lbl_alexa'
                                                                                                      )}`
                                                                                                    : `${t(
                                                                                                          'lbl_gHome'
                                                                                                      )}`
                                                                                            }
                                                                                            icon={
                                                                                                mobUserLog?.mobileOSType ===
                                                                                                'ANDROID' ? (
                                                                                                    <AndroidIcon />
                                                                                                ) : mobUserLog?.mobileOSType ===
                                                                                                  'ALEXA' ? (
                                                                                                    <AmazonAlexa />
                                                                                                ) : mobUserLog?.mobileOSType ===
                                                                                                  'IOS' ? (
                                                                                                    <AppleIcon />
                                                                                                ) : (
                                                                                                    <Google />
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        )}
                                                                </Grid>
                                                            </Box>
                                                        </Collapse>
                                                        <Divider />
                                                    </div>
                                                ))
                                            )}
                                            <Modal
                                                open={mobileViewModalOpen}
                                                onClose={handleMobileViewModalClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Paper
                                                    sx={
                                                        md
                                                            ? {
                                                                  position: 'absolute' as 'absolute',
                                                                  top: '50%',
                                                                  left: '50%',
                                                                  transform: 'translate(-50%, -50%)',
                                                                  width: '100vw',
                                                                  height: '100vh',
                                                                  backgroundColor: 'background.paper',
                                                                  boxShadow: 20,
                                                                  p: 0,
                                                              }
                                                            : { backgroundColor: 'white', width: 500, height: 800 }
                                                    }
                                                >
                                                    <AppBar elevation={3} sx={{ backgroundColor: 'white',  paddingTop: 6 }}>
                                                        <Toolbar>
                                                            <IconButton onClick={handleMobileViewModalClose}>
                                                                <ArrowBack color="action" />
                                                            </IconButton>
                                                            &ensp;
                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                <Typography sx={{ color: '#424E54' }} variant="h6">
                                                                    {mobileUser?.firstName}&ensp;{mobileUser?.lastName}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ color: '#424E54', mb: 1 }}
                                                                    variant="body1"
                                                                >
                                                                    {mobileUser?.login}
                                                                </Typography>
                                                            </div>
                                                            &ensp;&ensp;
                                                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                            {(role === 'ADMIN' || currentUser?.userType === 'INSTALLER' || currentUser?.userType === 'MANAGER' || currentUser?.userType === 'ENGINEER' )&&
                                                                <IconButton onClick={handleDeleteAppUserModalOpen}>
                                                                <DeleteIcon color="action" />
                                                            </IconButton>}
                                                            <Modal
                                                                open={deleteAppUserModal}
                                                                onClose={handleDeleteAppUserModalClose}
                                                                aria-labelledby="modal-modal-title"
                                                                aria-describedby="modal-modal-description"
                                                            >
                                                                <Box
                                                                    sx={{
                                                                        position: 'absolute' as 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        width: 400,
                                                                        bgcolor: 'background.paper',
                                                                        border: '0px solid #000',
                                                                        boxShadow: 24,
                                                                        p: 4,
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        id="modal-modal-title"
                                                                        variant="h6"
                                                                        component="h2"
                                                                    >
                                                                        {t('lbl_removePairing')}
                                                                    </Typography>
                                                                    <Typography
                                                                        id="modal-modal-description"
                                                                        sx={{ mt: 2 }}
                                                                    >
                                                                        {t('msg_removePairing')}
                                                                    </Typography>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            flexDirection: 'row',
                                                                        }}
                                                                    >
                                                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                                        <Button
                                                                            onClick={handleDeleteAppUserModalClose}
                                                                            sx={{ mt: 2, border: 'none' }}
                                                                            variant="outlined"
                                                                        >
                                                                            {t('lbl_no')}
                                                                        </Button>
                                                                        &ensp;&ensp;
                                                                        {(role === 'ADMIN' || currentUser?.userType === 'INSTALLER' || currentUser?.userType === 'MANAGER' || currentUser?.userType === 'ENGINEER')&&
                                                                         <Button
                                                                            onClick={() =>
                                                                                handleDeleteIcon(
                                                                                    specificPanel.panelId,
                                                                                    mobileUser?.login
                                                                                )
                                                                            }
                                                                            sx={{ mt: 2, border: 'none' }}
                                                                            variant="outlined"
                                                                        >
                                                                            {t('lbl_yes')}
                                                                        </Button>}
                                                                    </div>
                                                                </Box>
                                                            </Modal>
                                                        </Toolbar>
                                                    </AppBar>
                                                    <Paper
                                                        elevation={0}
                                                        sx={
                                                            md
                                                                ? {
                                                                      paddingTop: 15,
                                                                      paddingBottom: 1,
                                                                      paddingLeft: 2,
                                                                      paddingRight: 2,
                                                                  }
                                                                : {
                                                                      width: 400,
                                                                      height: 600,
                                                                      position: 'absolute' as 'absolute',
                                                                      top: '50%',
                                                                      left: '50%',
                                                                      transform: 'translate(-50%, -50%)',
                                                                      mt: 5,
                                                                  }
                                                        }
                                                    >
                                                        <div>
                                                            <Typography sx={{ pl: 9, pt: 4 }} variant="caption">
                                                                {t('lbl_lastConnection')}
                                                            </Typography>
                                                        </div>
                                                        <Typography variant="caption">
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    marginLeft: 70,
                                                                }}
                                                            >
                                                                <div
                                                                    style={{
                                                                        paddingTop: 0,
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        {
                                                                            <Typography variant="caption">
                                                                                <strong>
                                                                                    {getHours(
                                                                                        mobileUser?.creationTimeInMilliSeconds
                                                                                            ? mobileUser?.creationTimeInMilliSeconds
                                                                                            : 0
                                                                                    )}
                                                                                    :
                                                                                    {getMinutes(
                                                                                        mobileUser?.creationTimeInMilliSeconds
                                                                                            ? mobileUser?.creationTimeInMilliSeconds
                                                                                            : 0
                                                                                    )}
                                                                                    :
                                                                                    {getSeconds(
                                                                                        mobileUser?.creationTimeInMilliSeconds
                                                                                            ? mobileUser?.creationTimeInMilliSeconds
                                                                                            : 0
                                                                                    )}
                                                                                </strong>
                                                                            </Typography>
                                                                        }
                                                                    </strong>
                                                                    &ensp;
                                                                    
                                                                </div>
                                                                &ensp;&ensp;
                                                                <div>
                                                                    <Typography variant="caption">
                                                                        {getDate(
                                                                            mobileUser?.creationTimeInMilliSeconds
                                                                                ? mobileUser?.creationTimeInMilliSeconds
                                                                                : 0
                                                                        )}
                                                                        /
                                                                        {getMonth(
                                                                            mobileUser?.creationTimeInMilliSeconds
                                                                                ? mobileUser?.creationTimeInMilliSeconds
                                                                                : 0
                                                                        )}
                                                                        /
                                                                        {getYear(
                                                                            mobileUser?.creationTimeInMilliSeconds
                                                                                ? mobileUser?.creationTimeInMilliSeconds
                                                                                : 0
                                                                        )}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <Typography sx={{ pl: 9, pt: 2 }} variant="caption">
                                                                {t('lbl_device')}
                                                            </Typography>
                                                            <Box sx={{ mt: 2, mb: 2, pl: 9 }} flexGrow={1}>
                                                                <Grid container spacing={1}>
                                                                    {mobileAppUserByLogin?.length > 0 &&
                                                                        mobileAppUserByLogin?.map(
                                                                            (mobUserLog: MobileAppUserByLoginProps) => (
                                                                                <>
                                                                                    <Grid item xs={6}>
                                                                                        <Chip
                                                                                            clickable
                                                                                            onClick={() =>
                                                                                                handleModalOpen(
                                                                                                    mobUserLog
                                                                                                )
                                                                                            }
                                                                                            label={
                                                                                                mobUserLog?.mobileOSType ===
                                                                                                'ANDROID'
                                                                                                    ? `${t(
                                                                                                          'lbl_androidPh'
                                                                                                      )}`
                                                                                                    : mobUserLog?.mobileOSType ===
                                                                                                      'IOS'
                                                                                                    ? `${t(
                                                                                                          'lbl_iPhone'
                                                                                                      )}`
                                                                                                    : mobUserLog?.mobileOSType ===
                                                                                                      'ALEXA'
                                                                                                    ? `${t(
                                                                                                          'lbl_alexa'
                                                                                                      )}`
                                                                                                    : `${t(
                                                                                                          'lbl_gHome'
                                                                                                      )}`
                                                                                            }
                                                                                            icon={
                                                                                                mobUserLog?.mobileOSType ===
                                                                                                'ANDROID' ? (
                                                                                                    <AndroidIcon />
                                                                                                ) : mobUserLog?.mobileOSType ===
                                                                                                  'ALEXA' ? (
                                                                                                    <AmazonAlexa />
                                                                                                ) : mobUserLog?.mobileOSType ===
                                                                                                  'IOS' ? (
                                                                                                    <AppleIcon />
                                                                                                ) : (
                                                                                                    <Google />
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                </>
                                                                            )
                                                                        )}
                                                                </Grid>
                                                            </Box>
                                                        </div>
                                                    </Paper>
                                                    <Snackbar
                                                        open={open}
                                                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                        autoHideDuration={6000}
                                                        onClose={handleClose}
                                                        message={message}
                                                        action={action}
                                                    />
                                                </Paper>
                                            </Modal>

                                            <Modal
                                                open={openModal}
                                                onClose={handleModalClose}
                                                aria-labelledby="modal-modal-title"
                                                aria-describedby="modal-modal-description"
                                            >
                                                <Box
                                                    sx={{
                                                        position: 'absolute' as 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        width: md ? '100%' : 400,
                                                        height: md ? '100%' : 400,
                                                        bgcolor: 'background.paper',
                                                        border: '0px solid #000',
                                                        boxShadow: 24,
                                                        pl: md ? 3 : 0,
                                                    }}
                                                >
                                                    {md && (
                                                        <AppBar elevation={3} sx={{ backgroundColor: 'white' }}>
                                                            <Toolbar>
                                                                <IconButton onClick={handleModalClose}>
                                                                    <CloseIcon color="action" />
                                                                </IconButton>
                                                                &ensp;&ensp;
                                                                <div
                                                                    style={{ display: 'flex', flexDirection: 'column' }}
                                                                >
                                                                    <Typography sx={{ color: '#424E54' }} variant="h6">
                                                                        {t('lbl_appDetails')}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{ color: '#424E54', mb: 1 }}
                                                                        variant="body1"
                                                                    >
                                                                        {mobileUserLog?.mobileOSType === 'ANDROID'
                                                                            ? `${t('lbl_myAndroid')}`
                                                                            : mobileUserLog?.mobileOSType === 'ALEXA'
                                                                            ? `${t('lbl_myAlexa')}`
                                                                            : mobileUserLog?.mobileOSType === 'IOS'
                                                                            ? `${t('lbl_myiPhone')}`
                                                                            : `${t('lbl_myiPhone')}`}
                                                                    </Typography>
                                                                </div>
                                                            </Toolbar>
                                                        </AppBar>
                                                    )}
                                                    {ms && (
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection: 'row',
                                                                padding: 20,
                                                            }}
                                                        >
                                                            {mobileUserLog?.mobileOSType === 'ANDROID' ? (
                                                                <AndroidIcon sx={{ mt: 0.5 }} />
                                                            ) : mobileUserLog?.mobileOSType === 'ALEXA' ? (
                                                                <AmazonAlexa />
                                                            ) : mobileUserLog?.mobileOSType === 'IOS' ? (
                                                                <AppleIcon />
                                                            ) : (
                                                                <Google />
                                                            )}
                                                            <Typography
                                                                id="modal-modal-title"
                                                                variant="h6"
                                                                component="h2"
                                                            >
                                                                &ensp; {t('lbl_appDetails')}
                                                            </Typography>
                                                            <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                                            <IconButton onClick={handleModalClose}>
                                                                <CloseIcon sx={{ mt: -0.5 }} />
                                                            </IconButton>
                                                        </div>
                                                    )}
                                                    {ms && <Divider />}
                                                    {md ? (
                                                        <>
                                                            <Typography
                                                                variant="subtitle1"
                                                                id="modal-modal-description"
                                                                sx={{ pl: 2, pt: 2, mt: md ? 8 : 0 }}
                                                            >
                                                                {t('lbl_appID')}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ pl: 2 }}
                                                                variant="body2"
                                                                id="modal-modal-description"
                                                            >
                                                                {mobileUserLog?.applicationId}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            variant="subtitle1"
                                                            id="modal-modal-description"
                                                            sx={{ p: 2, mt: md ? 8 : 0 }}
                                                        >
                                                            {t('lbl_appID')}&ensp;&ensp;-&ensp;&ensp;
                                                            {mobileUserLog?.applicationId}
                                                        </Typography>
                                                    )}
                                                    {ms && <Divider />}
                                                    {md ? (
                                                        <>
                                                            <Typography
                                                                variant="subtitle1"
                                                                id="modal-modal-description"
                                                                sx={{ pl: 2, pt: 2 }}
                                                            >
                                                                {t('lbl_mobOS')}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ pl: 2 }}
                                                                variant="body2"
                                                                id="modal-modal-description"
                                                            >
                                                                {mobileUserLog?.mobileOSType}
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            variant="subtitle1"
                                                            id="modal-modal-description"
                                                            sx={{ p: 2 }}
                                                        >
                                                            {t('lbl_mobOS')}&ensp;&ensp;-&ensp;&ensp;
                                                            {mobileUserLog?.mobileOSType}
                                                        </Typography>
                                                    )}
                                                    {ms && <Divider />}
                                                    {md ? (
                                                        <>
                                                            <Typography
                                                                variant="subtitle1"
                                                                id="modal-modal-description"
                                                                sx={{ pl: 2, pt: 2 }}
                                                            >
                                                                {t('lbl_token')}
                                                            </Typography>
                                                            <Typography
                                                                sx={{ pl: 2 }}
                                                                variant="body2"
                                                                id="modal-modal-description"
                                                            >
                                                                <div style={{ maxWidth: '100%', overflow: 'auto' }}>
                                                                    {mobileUserLog?.token}
                                                                </div>
                                                            </Typography>
                                                        </>
                                                    ) : (
                                                        <Typography
                                                            variant="subtitle1"
                                                            id="modal-modal-description"
                                                            sx={{ p: 2 }}
                                                        >
                                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                                {t('lbl_token')}&ensp;&ensp;-&ensp;&ensp;
                                                                <div style={{ maxWidth: 1000, overflow: 'auto' }}>
                                                                    {mobileUserLog?.token}
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Modal>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Item>
                    </Grid>
                </Grid>
            </Box>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                action={action}
            />
        </div>
    );
}
