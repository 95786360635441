/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { NavigateFunction } from 'react-router';
import { RegisterScreenActionTypes } from '../constants/registerScreenAgreementActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';

export const setLicenseAgreement = (license: any): any => ({
    type: RegisterScreenActionTypes.SET_LICENSE_AGREEMENT,
    payload: license,
})
export const setCountries = (countries: any): any => ({
    type: RegisterScreenActionTypes.SET_COUNTRIES,
    payload: countries,
})
export const setEmailVerificationStatus = (verificationStatus: number): any => ({
    type: RegisterScreenActionTypes.SET_EMAIL_VERIFY_STATUS,
    payload: verificationStatus,
})
export const setUserDetails = (userDataAndStatus: any): any => ({
    type: RegisterScreenActionTypes.SET_REGISTERED_USER,
    payload: userDataAndStatus,
})
export const setResetPasswordStatus = (resetPasswordStatus: any): any => ({
    type: RegisterScreenActionTypes.SET_RESET_PASSWORD_STATUS,
    payload: resetPasswordStatus,
})

export const setDeleteAccountStatus = (deleteAccountStatus: any): any => ({
    type: RegisterScreenActionTypes.SET_DELETE_ACCOUNT_STATUS,
    payload: deleteAccountStatus,
})

export const setDeleteAccountLinkStatus = (deleteAccountLinkStatus: any): any => ({
    type: RegisterScreenActionTypes.SET_DELETE_ACCOUNT_LINK_STATUS,
    payload: deleteAccountLinkStatus,
})

export function fetchLicenseAgreement() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/notsensitive/license.agreement`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setLicenseAgreement(data));
            });
    }
}
export function fetchCountries() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/countries`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then((data) => {
                dispatch(setCountries(data))
            })
    }
}

export function verifyEmailAddress(mail: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-auth/rest/auth/checkLoginNotExist/${mail.email}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(mail),
        }).then((res) => {
            dispatch(setEmailVerificationStatus(res.status));
        });
    }
}

export function fetchRegisterInstallerUser(body: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/user/`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        }).then(async (res) => {
            if (res.status === 200) {
                const data = await res.json()
                dispatch(setUserDetails({ status: res.status, data: data }))
                console.log(data)
            }
            else {
                dispatch(setUserDetails({ status: res.status, data: '' }))
            }
        });
    }
}

export function fetchResendEmail(email: string, navigate: NavigateFunction) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/confirmEmail/resend/${email}`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => {
                if (res.status === 204) {
                    navigate('/message-sent')
                }
            })

    }
}

export function fetchResetPassword(mail: any, hash: any, navigate: NavigateFunction, pwd: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-eaton-web/rest/forgotPassword/resetPassword/${mail}/${hash}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Password': pwd,
            },

        }).then((res) => {
            dispatch(setDeleteAccountStatus(res.status));
            if (res.status === 204) {
                navigate('/reset-password-success')
            }
        });
    }
}

export function getDeleteUserLinkInEmail(mail: any) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`https://partner.eatonsecureconnect.cloud/m2m-eaton-web/rest/deleteAccount/sendLink/${mail}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },

        }).then((res) => {
            dispatch(setDeleteAccountLinkStatus(res.status));
        });
    }
}

export function deleteUserAccount(mail: any, hash: any, navigate: NavigateFunction) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`https://partner.eatonsecureconnect.cloud/m2m-eaton-web/rest/deleteAccount/delete/${mail}/${hash}`, {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },

        }).then((res) => {
            dispatch(setResetPasswordStatus(res.status));
            if (res.status === 204) {
                navigate('/delete-account-success')
            }
        });
    }
}