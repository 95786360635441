/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/prefer-as-const */
import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { NotificationTextProps, PanelNotificationModalTypeProps } from './PanelNotificationTypes';
import { AppBar, IconButton, Paper, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Spacer } from '@brightlayer-ui/react-components';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '0px solid #000',
    boxShadow: 24,
    p: 0,
};

export default function PanelNotificationModal({ text, open, setOpen }: PanelNotificationModalTypeProps): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const handleClose = () => setOpen(false);
    const [notifiObj, setNotifiObj] = React.useState<NotificationTextProps>({});
    const { t } = useTranslation();
    
    React.useEffect(() => {
        if (text !== '') setNotifiObj(JSON.parse(text));
    }, [text]);

    return (
        <div>
            <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: md ? '0%':'40%',
                        left: md ? '0%':'50%',
                        transform:md? 'translate(0%, 0%)' :'translate(-50%, -50%)' ,
                        width:md? '100%':400,
                        height:md?'100%':'60%',
                        bgcolor: 'background.paper',
                        border: '0px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}>
                    {md ? (
                        <AppBar
                            elevation={2}
                            sx={{
                                pl:2,
                                pt:4,
                                pb:2,
                                backgroundColor: 'white',
                            }}
                            position="static"
                        >
                            <Toolbar variant="dense">
                                <IconButton onClick={handleClose} edge="start" aria-label="menu" sx={{ mr: 2 }}>
                                    <CloseIcon color="action" />
                                </IconButton>
                                <Typography variant="h6" color="#424E54" component="div">
                                {t('lbl_evntDetails')}
                                </Typography>
                            </Toolbar>
                        </AppBar>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                <Typography sx={{ p: 2 }} id="modal-modal-title" variant="h6" component="h2">
                                {t('lbl_evntDetails')}
                                </Typography>
                                <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                <IconButton onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </>
                    )}
                    <Paper
                        elevation={0}
                        sx={{
                            pl:md?3:0,
                            maxHeight: md ? '80%' : 450,
                            overflow: 'auto',
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_panelId')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.panelId}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_systmName')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj['system-name']}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_Id')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.id}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_partition')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.partition ? notifiObj.partition : '-'}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_user')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.user}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_username')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.username}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_text')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.text}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_verbose')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.verbose}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_timestamp')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.timestamp}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_code')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.code}
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            id="modal-modal-description"
                            color="#007BC1"
                            sx={{ mt: 2, pl: 3 }}
                        >
                            {t('lbl_group')}
                        </Typography>
                        <Typography variant="body2" id="modal-modal-description" sx={{ mb: 1, pl: 3 }}>
                            {notifiObj.group}
                        </Typography>
                    </Paper>
                </Box>
            </Modal>
        </div>
    );
}
