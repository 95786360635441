/* eslint-disable arrow-body-style */
import { CompletedServiceReportsForAllPanelsArrayTypes } from '../../components/ServiceReports/serviceReportTypes';
import { REACT_APP_BASE_URL, REACT_ARD_BASE_URL } from '../../entities/Endpoints';
import { ServiceReportsActionTypes } from '../constants/serviceReportsActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';

export const setCompletedReportsForAllPanels = (completedReportsForAllPanels: CompletedServiceReportsForAllPanelsArrayTypes): any => {
    return {
        type: ServiceReportsActionTypes.SET_COMPLETED_REPORTS_FOR_ALL_PANELS,
        payload: completedReportsForAllPanels,
    };
}


export function fetchCompletedReportsForAllPanels() {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(`${REACT_APP_BASE_URL}/m2m-web-admin/rest/auth/currentuser`, {
            method: 'GET',
            credentials: 'include',
        })
            .then((res) => res.json())
            .then(async(currentUser) => 
        await fetch(
            `${REACT_ARD_BASE_URL}/panels/ard/snapshots/${currentUser.ID}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((data) => {
                dispatch(setCompletedReportsForAllPanels(data));
            })
        )
    }
}



