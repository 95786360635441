// export const AUTH_BASE_URL = `https://partner.eatonsecureconnect.cloud/m2m-auth/rest`;
// export const BASE_URL = `https://partner.eatonsecureconnect.cloud/m2m-eaton-web/rest`;
// export const WEB_ADMIN_BASE_URL = `https://partner.eatonsecureconnect.cloud/m2m-web-admin/rest`;


/*** To connect to partner server ****/
export const REACT_APP_BASE_URL = `https://partner.eatonsecureconnect.cloud`;
export const REACT_ARD_BASE_URL = `https://apifarm.eatonsecureconnect.cloud`;

/*** To connect to poc1 server ****/
//export const REACT_APP_BASE_URL = `https://poc1.eatonsecureconnect.org`;
