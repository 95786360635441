/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import Box from '@mui/material/Box';
import { Button, Divider, MobileStepper, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import './register.css'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchResendEmail } from '../../redux/actions/registerScreenActions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: 'background.paper',
    boxShadow: 20,
    p: 0,
  };

export default function ActivationRequired():JSX.Element {  
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const { t } = useTranslation();
    const email = sessionStorage.getItem('mailId') || ''
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()

    const handleLogin = () => {
        navigate('/login')
    }

    const handleResendEmail = async() => {
        await dispatch(fetchResendEmail(email,navigate))
    }
 
  return (
      <div id="main">
          <Box
              sx={
                  md
                      ? {
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '100vw',
                            height: '100dvh',
                            backgroundColor: 'background.paper',
                            boxShadow: 20,
                            p: 0,
                        }
                      : style
              }
          >
              <Paper elevation={0} sx={{ paddingTop: 2, paddingBottom: 3, paddingLeft: 3, paddingRight: 2 }}>
                  <Stack direction={'column'} spacing={2}>
                  <Typography variant="h6">{t('lbl_accntActivation')}</Typography>
                      <Typography variant="body1" sx={{wordWrap:'break-word'}}>
                        Your account the login of “{email}” has not yet been activated.<br />
                        We have sent an activation email to you, Please click link in that message to activate your account.
                        <br />
                      </Typography>
                  </Stack>
              </Paper>
              <Divider />
              <MobileStepper
                  variant="dots"
                  steps={0}
                  position="static"
                  activeStep={0}
                  sx={
                      md
                          ? {
                                zIndex: 1000,
                                position: 'fixed',
                                top: '100dvh',
                                transform: 'translateY(-100%)',
                                width: '100%',
                                p: 2,
                                backgroundColor: 'inherit',
                            }
                          : {
                                maxWidth: 400,
                                flexGrow: 1,
                                paddingTop: 2,
                                paddingBottom: 2,
                                paddingLeft: 3,
                                paddingRight: 3,
                                backgroundColor: 'inherit',
                            }
                  }
                  nextButton={
                      <Button size="medium" variant="contained" onClick={handleResendEmail}>
                          Resend email
                      </Button>
                  }
                  backButton={<Button size="medium" variant="outlined" onClick={handleLogin}>
                  {t('lbl_login')}
              </Button>}
              />
          </Box>
      </div>
  );
}
