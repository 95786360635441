/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function SpeicificInstallerDetails(): JSX.Element {
    const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer)
    const { t } = useTranslation();
    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head" width="45%" style={{ color: '#1397D9' }}>
                                {t('lbl_installer_Details')}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_firstName')}
                            </TableCell>
                            <TableCell width="45%">
                                {specificPanel?.installerUser?.firstName}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_lastName')}
                            </TableCell>
                            <TableCell width="45%">
                                {specificPanel?.installerUser?.lastName}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_email')}
                            </TableCell>
                            <TableCell width="45%">
                                {specificPanel?.installerUser?.installerInfo?.email}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_company')}
                            </TableCell>
                            <TableCell width="45%">
                                {specificPanel?.installerUser?.installerInfo?.installerCompanyName}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_cmpnyAddress')}
                            </TableCell>
                            <TableCell width="45%">
                                {specificPanel?.installerUser?.installerInfo?.address?.address},&nbsp;
                                {specificPanel?.installerUser?.installerInfo?.address?.country},&nbsp;
                                {specificPanel?.installerUser?.installerInfo?.address?.postCode}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SpeicificInstallerDetails
