/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */

import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ReportGroupsProps } from '../ARCAccessModal/ARCAccessProps';
import { AlertEventsProps } from './AlertsAccessModalProps';

export default function AlertsEvents({ reportGroups, setAvailableGroups, alerts }:AlertEventsProps ): JSX.Element {
    const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const [assignGroups, setAssignGroups] = React.useState(true)
  const [checkedList, setCheckedList] = React.useState<boolean[]>([]);
  const [siaEvents, setSiaEvents] = React.useState<string[]>([]);

  if(assignGroups === true ){
    if(alerts?.length > 0){

            for (let i = 0; i < reportGroups.length; i++) {
                for (let j = 0; j < alerts?.length; j++) {
                    if (reportGroups[i].customReportGroup === alerts[j]) {
                        checkedList[i] = true;
                        break;
                    } else checkedList[i] = false;
                }
            }
            setAssignGroups(false);
    }
    else{
        for (let i = 0; i < reportGroups.length; i++) {
                    checkedList[i] = false;
                }
                setAssignGroups(false);
    }
}

  React.useEffect(() => {
    
    for (let i = 0; i < checkedList.length; i++) {
        if (checkedList[i] === true) {
            siaEvents[i] = reportGroups[i].customReportGroup;
        } else {
            siaEvents[i] = '';
        }
    }
    const list = siaEvents.filter((sia) => sia.length > 0);
    setAvailableGroups(list);
      
  }, [...checkedList]);

  const handleClick = (key: number): any => {
    const openListOne = checkedList.slice(0, key);
    const openListTwo = checkedList.slice(key + 1, checkedList.length);
    const openListThree = !checkedList[key];
    setCheckedList([...openListOne, openListThree, ...openListTwo]);
};

    return (
        
            <FormGroup>
                {reportGroups?.map((report: ReportGroupsProps, key: number) => (
                    <FormControlLabel
                        key={key}
                        value={report.customReportGroup}
                        control={<Checkbox onClick={(): any => handleClick(key)} checked={checkedList[key]} />}
                        label={report.description}
                    />
                ))}
            </FormGroup>
     
    );
}
