/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CompletedServiceReportsForAllPanelsArrayTypes } from '../../components/ServiceReports/serviceReportTypes';
import { ServiceReportsActionTypes } from '../constants/serviceReportsActionTypes';

const initialState = {
    completedReportsForAllPanels: <CompletedServiceReportsForAllPanelsArrayTypes>[],
}

export const serviceReportReducer = (state: any = initialState, action: { type: any; payload: any }): any => {
    switch (action.type) {
        case ServiceReportsActionTypes.SET_COMPLETED_REPORTS_FOR_ALL_PANELS:
            return { ...state, completedReportsForAllPanels: action.payload }
            break;
        default:
            return { ...state }
    }
}