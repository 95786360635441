/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { Box, Divider, Grid, IconButton, Paper, Button, styled, Input, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useState } from 'react';
import Appbar from '../../components/Appbar/Appbar';
import './background.css'
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import LanguageIcon from '@mui/icons-material/Language';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BusinessIcon from '@mui/icons-material/Business';
import Snackbar from '@mui/material/Snackbar';
import { InfoListItem, Spacer } from '@brightlayer-ui/react-components';
import AccountHolderEditModal from '../../components/Settings/AccountHolderEditModal/AccountHolderEditModal';
import CompanyInformationEditModal from '../../components/Settings/CompanyInformationEditModal/CompanyInformationEditModal';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import { fetchCurrentUserSettings, getCurrentUserDetails, setDefaultStatusForCurrentUserSettings } from '../../redux/actions/settingsActions';
import { useSelector } from 'react-redux';
import { LANGUAGES } from '../../constants/index';
import { useTranslation } from 'react-i18next';


const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(0),

}))

export function Settings(): JSX.Element {
    const theme = useTheme();
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const dispatch = useTypedDispatch()
    const [accountHolderModalOpen, setAccountHolderModalOpen] = useState(false)
    const [companyInfoEditModalOpen, setCompanyInfoEditModalOpen] = useState(false)
    const [language, setLanguage] = useState('')
    const [message, setMessage] = useState('')
    const [editSetingsSnackOpen, setEditSetingsSnackOpen] = React.useState(false);
    const [count, setCount] = useState(0)
    const [sectionName, setSectionName] = useState('')
    const { currentUserSettings, editCurrentUserSettingsStatus } = useSelector((state: any) => state.settingsReducer);
    const { t } = useTranslation();

    React.useEffect(() => {
        const getInstallerSettings = async () => {
            await dispatch(getCurrentUserDetails());
            await dispatch(fetchCurrentUserSettings());
        }
        getInstallerSettings()
        if (currentUserSettings?.locale !== undefined) {
            const languageObj = LANGUAGES.arrOfLanguages.filter((languageSettings) => languageSettings.locale === currentUserSettings?.locale);
            setLanguage(languageObj[0].language);
        }

        if (editCurrentUserSettingsStatus !== 0) {
            setEditSetingsSnackOpen(true);
            if (editCurrentUserSettingsStatus !== 200) {
                setMessage(`${t('lbl_somethingWrong')}`)
            } else {
                if (sectionName === 'Account holder') {
                    setMessage(`${t('lbl_accnt_holder')} ${t('msg_edited')}`)
                } else {
                    setMessage(`${t('lbl_cmpnyInfo')} ${t('msg_edited')}`)
                }
            }
            dispatch(setDefaultStatusForCurrentUserSettings())
        }
    }, [currentUserSettings?.locale, count])

    const handleEditAccountModal = (): any => {
        setAccountHolderModalOpen(true)
    }
    const handleEditCompanyInfoModal = (): any => {
        setCompanyInfoEditModalOpen(true)
    }

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setEditSetingsSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    return (
        <>
            <div className="main-div">
            <div style={{maxHeight:'100vh',overflow:'auto'}}>
                <Appbar toolbarMenu={false} subtitle='All Panels' title={'Settings'} />
                <Box sx={{ flexGrow: 1, marginLeft: md ? '1%' : '10%', marginRight: md ? '1%' : '10%', marginTop: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 2 }} variant='subtitle2' color={'primary'}>
                                        {t('lbl_accnt_holder')}
                                        </Typography>
                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                        {(currentUserSettings?.userType === 'INSTALLER' || currentUserSettings?.userType === 'MANAGER' || currentUserSettings?.userType === 'ENGINEER' || currentUserSettings?.userType === 'ADMIN') &&
                                            <IconButton onClick={handleEditAccountModal} style={{ paddingRight: 16}}>
                                            <EditIcon />
                                        </IconButton>}
                                        {accountHolderModalOpen &&
                                            <AccountHolderEditModal sectionName={sectionName} setSectionName={setSectionName} open={accountHolderModalOpen} setOpen={setAccountHolderModalOpen} count={count} setCount={setCount} />
                                        }
                                    </div>
                                    <Divider />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_name')}
                                        subtitle={`${currentUserSettings?.firstName} ${currentUserSettings?.lastName}`}
                                        iconAlign='left'
                                        icon={<AccountCircleIcon />}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_contactEmail')}
                                        subtitle={currentUserSettings?.installerInfo?.email}
                                        iconAlign='left'
                                        icon={<EmailIcon />}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_pwd')}
                                        subtitle={[<Input sx={{ border: 'none' }} key={0} readOnly={true} disableUnderline id="basic-input" type="Password" value="••••••••" />]}
                                        iconAlign='left'
                                        icon={<VpnKeyIcon />}
                                        divider='partial'
                                    />
                                    {currentUserSettings?.userType === 'ADMIN' || currentUserSettings?.userType === 'INSTALLER' &&
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_spoken_pwd')}
                                        subtitle={[<Typography key={0} fontStyle={'italic'} >“{currentUserSettings?.installerInfo?.spokenPassword}”</Typography>]}
                                        iconAlign='left'
                                        icon={<RecordVoiceOverIcon />}
                                        divider='partial'
                                    />
                                    }
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_language')}
                                        subtitle={language}
                                        iconAlign='left'
                                        icon={<LanguageIcon />}
                                        divider='partial'
                                    />
                                </div>
                            </Item>
                        </Grid>
                        {currentUserSettings?.userType === 'ADMIN' || currentUserSettings?.userType === 'INSTALLER' &&
                        <Grid item xs={12}>
                            <Item elevation={1}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography sx={{ p: 2 }} variant='subtitle2' color={'primary'}>
                                        {t('lbl_cmpnyInfo')}
                                        </Typography>
                                        <Spacer nonce={undefined} onResize={undefined} onResizeCapture={undefined} />
                                        <IconButton onClick={handleEditCompanyInfoModal} style={{ paddingRight: 16}}>
                                            <EditIcon />
                                        </IconButton>
                                        {companyInfoEditModalOpen &&
                                            <CompanyInformationEditModal sectionName={sectionName} setSectionName={setSectionName} open={companyInfoEditModalOpen} setOpen={setCompanyInfoEditModalOpen} count={count} setCount={setCount} />
                                        }
                                    </div>
                                    <Divider />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_compnyName')}
                                        subtitle={currentUserSettings?.installerInfo?.installerCompanyName}
                                        iconAlign='left'
                                        icon={<BusinessCenterIcon />}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_address')}
                                        subtitle={`${currentUserSettings?.installerInfo?.address?.address} ${currentUserSettings?.installerInfo?.address?.postCode} ${currentUserSettings?.installerInfo?.address?.country}`}
                                        iconAlign='left'
                                        icon={<LocationOnIcon />}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_firstPh')}
                                        subtitle={currentUserSettings?.installerInfo?.firstPhoneNumber?.number}
                                        iconAlign='left'
                                        icon={currentUserSettings?.installerInfo?.firstPhoneNumber?.type === 'HOME' ? <HomeIcon /> :
                                        currentUserSettings?.installerInfo?.firstPhoneNumber?.type === 'MOBILE' ? <PhoneIphoneIcon /> : <BusinessIcon/>}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_secondPh')}
                                        subtitle={currentUserSettings?.installerInfo?.secondPhoneNumber?.number}
                                        iconAlign='left'
                                        icon={currentUserSettings?.installerInfo?.secondPhoneNumber?.type === 'HOME' ? <HomeIcon /> :
                                        currentUserSettings?.installerInfo?.secondPhoneNumber?.type === 'MOBILE' ? <PhoneIphoneIcon /> : <BusinessIcon/>}
                                        divider='partial'
                                    />
                                    <InfoListItem nonce={undefined} onResize={undefined} onResizeCapture={undefined}
                                        title={t('lbl_thirdPh')}
                                        subtitle={currentUserSettings?.installerInfo?.thirdPhoneNumber?.number}
                                        iconAlign='left'
                                        icon={currentUserSettings?.installerInfo?.thirdPhoneNumber?.type === 'HOME' ? <HomeIcon /> :
                                        currentUserSettings?.installerInfo?.thirdPhoneNumber?.type === 'MOBILE' ? <PhoneIphoneIcon /> : <BusinessIcon/>}
                                        divider='partial'
                                    />
                                </div>
                            </Item>
                        </Grid>
                        }
                        <Snackbar
                            open={editSetingsSnackOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackClose}
                            message={message}
                            action={action}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        />
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                </Box>
                </div>
            </div>
        </>
    )
}