export const systemToolsType = {
    ENABLE_REGISTRATION: "ENABLE_REGISTRATION",
    DISABLE_REGISTRATION: "DISABLE_REGISTRATION",
    SEND_MAINTENANCE_NOTIFICATION: "SEND_MAINTENANCE_NOTIFICATION",
    GENERATE_TEST_NOTIFICATION: "GENERATE_TEST_NOTIFICATION",
    REMOVE_PAIRING: "REMOVE_PAIRING",
    REMOVE_APPLICATION_USER: "REMOVE_APPLICATION_USER",
    APP_USER_DOWNLOAD_USER: "APP_USER_DOWNLOAD_USER",
    MANAGED_PANELS_DOWNLOAD_REPORTS: "MANAGED_PANELS_DOWNLOAD_REPORTS",
    UNMANAGED_PANELS_DOWNLOAD_REPORTS: "UNMANAGED_PANELS_DOWNLOAD_REPORTS",
    ARC_DOWNLOAD_REPORTS: "ARC_DOWNLOAD_REPORTS",
    INSTALLER_DOWNLOAD_REPORTS: "INSTALLER_DOWNLOAD_REPORTS"
}