/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import { Checkbox, Typography, IconButton, Button } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@mui/material/Snackbar';
import { useTranslation } from 'react-i18next';

const Summary = (): JSX.Element => {
  const { specificPanel } = useSelector((state: any) => state.specificPanelAndEstateReducer)
  const [copyState, setCopyState] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const { t } = useTranslation();

  const isCopied = (): any => {
    if (specificPanel?.panelId !== undefined) {
      setCopyState(true);
      setMessage(`${t('msg_panelIdCopied')}`);
      setSnackOpen(true);
    }
  }

  const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string):any => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
    setCopyState(false);
  };

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackClose}>
      {t('lbl_close')}
      </Button>
    </React.Fragment>
  );

  return (
    <>
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell variant='head' style={{ color: '#1397D9' }}>{t('lbl_summary')}</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>{t('lbl_status')}</TableCell>
              <TableCell>
                <Typography variant='body1'>
                  <div style={{ display: 'flex', flexDirection: 'row' }} >
                    <Checkbox disabled sx={{ mt: -0.9,mb:-1 }} size={'small'} icon={<CloudOffIcon color='action'/>} checkedIcon={<CloudDoneIcon color='success' />} checked={specificPanel?.status === 'CONNECTED'} />&ensp;
                    {specificPanel?.status === 'CONNECTED' ? <Typography sx={{ color: '#424E54' }} >{t('lbl_connected')}</Typography> : <Typography sx={{ color: '#424E54' }} >{t('lbl_disconnected')}</Typography>}
                  </div>
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>{t('lbl_panelId')}</TableCell>
              <TableCell>{specificPanel?.panelId}</TableCell>
              <TableCell style={{ padding: '10px'}}>
                <CopyToClipboard text={specificPanel?.panelId}
                  onCopy={() => setCopyState(true)}>
                  <IconButton onClick={() => isCopied()} style={{ padding: 0}}><ContentCopyIcon /></IconButton>
                </CopyToClipboard>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>{t('lbl_macAddress')}</TableCell>
              <TableCell>{specificPanel?.macAddress}</TableCell>
              <TableCell></TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant='head'>{t('lbl_firmware')}</TableCell>
              <TableCell>{specificPanel?.swVersion}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {copyState ?
        <Snackbar
          open={snackOpen}
          autoHideDuration={6000}
          onClose={handleSnackClose}
          message={message}
          action={action}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        />
        : null
      }
    </>
  )
}

export default Summary