import { AccountsLogArrayProps } from '../../components/AccountsLog/AccountsLogType';
import { AdministratorsArrayProps } from '../../components/Administrators/AdministratorsTypes';
import { AdminActionTypes } from '../constants/administratorsActionTypes';
const initialState = {
    administrators : <AdministratorsArrayProps>[],
    accountsLog : <AccountsLogArrayProps>[],
    administratorsLength:<number>0,
    addAdminStatus : <number>0,
    editAdminStatus : <number>0,
    deleteAdminStatus : <number>0,
    adminSearchlistLength: <number>0,
    accountLogAllDetails: <AccountsLogArrayProps>[],
    adminLogSearchlistLength: <number>0,
}

export const administratorReducer = (state:any = initialState  , action: { type: any; payload:any }):any => {
        switch(action.type){
            case AdminActionTypes.SET_ADMINS : 
                    return {...state,administrators : action.payload }
                    break;
            case AdminActionTypes.SET_ADMINS_ACCOUNTS_LOG : 
                    return {...state,accountsLog : action.payload }
                    break;
            case AdminActionTypes.SET_ADMINS_LENGTH : 
                    return {...state,administratorsLength : action.payload }
                    break;
            case AdminActionTypes.SET_ADD_ADMINS : 
                    return {...state,addAdminStatus : action.payload }
                    break;
            case AdminActionTypes.SET_EDIT_ADMINS : 
                    return {...state,editAdminStatus : action.payload }
                    break;
            case AdminActionTypes.SET_DELETE_ADMINS : 
                    return {...state,deleteAdminStatus : action.payload }
                    break;
            case AdminActionTypes?.SET_ADMIN_ACCOUNT_SEARCH_LIST_LENGTH:
                  return { ...state, adminSearchlistLength: action.payload }
                  break;
            case AdminActionTypes?.SET_SEARCHED_ACCOUNT_LOGS:
                  return { ...state, adminLogSearchlistLength: action.payload }
                  break;      
            case AdminActionTypes?.SET_ALL_ACCOUNT_LOG_DETAILS:
                  return { ...state, accountLogAllDetails: action.payload }
                  break
            default :
                    return state
                    break;
            
        }
}