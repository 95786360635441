/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import { useTypedDispatch } from '../../redux/dispatchActionTypes/dispatchAndThunkActionTypes';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import EditSiteDetailsModal from '../../components/SpecificPanelDetails/EditSiteDetailsModal';
import { fetchSpecificPanelAndEstate } from '../../redux/actions/specificPanelActions';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getCurrentUserDetails } from '../../redux/actions/accountUsersActions';

export const EstateContext = React.createContext([]);

const SiteDetails = (): JSX.Element => {
    const dispatch = useTypedDispatch()
    const { specificPanel, specificPanelEstate, editSiteStatus } = useSelector((state: any) => state.specificPanelAndEstateReducer)
    //const { currentUser } = useSelector((state: any) => state.userReducer);
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [snackOpen, setSnackOpen] = React.useState(false);
    const [count, setCount] = React.useState(0)
    const { currentUserDetails } = useSelector((state: any) => state.accountUsersReducer)
    const { id } = useParams()
    const { t } = useTranslation();

    const editSiteDetails = (): any => {
        setOpen(true)
    }

    useEffect(() => {
        dispatch(getCurrentUserDetails());
        if (count !== 0) {
            if ((editSiteStatus === 204) || (editSiteStatus === 200)) {
                setMessage(`${t('msg_siteDetails_edited')}`);
            } else {
                setMessage(`${t('lbl_somethingWrong')}`);
            }
            dispatch(fetchSpecificPanelAndEstate(Number(id)))
            setSnackOpen(true);
        }
    }, [count])

    const handleSnackClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackOpen(false);
    };

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackClose}>
            {t('lbl_close')}
            </Button>
        </React.Fragment>
    );

    const setFullAddress = (address: any) => {
        if (specificPanel !== undefined || specificPanel !== null) {
            let address1 = '';
            let country = '';
            let postCode = '';
            if (`${address?.address}` !== 'undefined' && `${address?.address}` !== '') {
                address1 = `${address?.address} `;
            }
            if (`${address?.country}` !== 'undefined' && `${address?.country}` !== '') {
                country = `${address?.country} `;
            }
            if (`${address?.postCode}` !== 'undefined' && `${address?.postCode}` !== '') {
                postCode = `${address?.postCode}`;
            }
            return address1.concat(country, postCode);
        }
    }

    return (
        <>
            <TableContainer>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow>
                            <TableCell variant="head" style={{ color: '#1397D9' }}>
                                {t('lbl_siteDetails')}
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right">
                                {(currentUserDetails?.userType === 'ADMIN' ||
                                    currentUserDetails?.userType === 'INSTALLER' ||
                                    currentUserDetails?.userType === 'MANAGER' ||
                                    currentUserDetails?.userType === 'OFFICE_STAFF') && (
                                    <IconButton onClick={() => editSiteDetails()}>
                                        <EditIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_cloudReference')}
                            </TableCell>
                            <TableCell width="45%">{specificPanel?.cloudReferenceName}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                       
                            <TableRow>
                                <TableCell variant="head" width="45%">
                                    {t('lbl_estate')}
                                </TableCell>
                                {specificPanelEstate?.type === 'ESTATE' ? (
                                    <TableCell width="45%">{specificPanelEstate?.name} </TableCell>
                                ) : (
                                    <TableCell width="45%"></TableCell>
                                )}
                                <TableCell></TableCell>
                            </TableRow>
                        
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_contact_name')}
                            </TableCell>
                            <TableCell width="45%">{specificPanel?.siteContactName}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_contact_phNo')}
                            </TableCell>
                            <TableCell width="45%">{specificPanel?.siteContactPhoneNumber}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_siteAddress')}
                            </TableCell>
                            <TableCell width="45%">{setFullAddress(specificPanel?.address)}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell variant="head" width="45%">
                                {t('lbl_siteComments')}
                            </TableCell>
                            
                                    <TableCell width="45%">{specificPanel?.comments} </TableCell>
                                
                                <TableCell></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            {open ? <EditSiteDetailsModal open={open} setOpen={setOpen} count={count} setCount={setCount} /> : null}
            <Snackbar
                open={snackOpen}
                autoHideDuration={6000}
                onClose={handleSnackClose}
                message={message}
                action={action}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
        </>
    );
}

export default SiteDetails