/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { EstateArrayProps, EstateProps, PanelArrayProps, PanelProps } from './../../components/ListAndGridView/panelEstateTypes';
/* eslint-disable arrow-body-style */
import { EstateActionTypes } from './../constants/estateActionTypes';
import { DispatchTypesProps } from '../dispatchActionTypes/dispatchAndThunkActionTypes';
import { Dispatch } from 'react';
import { REACT_APP_BASE_URL } from '../../entities/Endpoints';
export const setEstate = (estate:EstateProps):any => {
    return {
        type: EstateActionTypes.SET_ESTATE,
        payload: estate,
    };
}

const setEstates = (estates:EstateArrayProps):any => {
    return {
        type: EstateActionTypes.SET_ESTATES,
        payload: estates,
    };
}
const setPanels = (panels:PanelArrayProps):any => {
    return {
        type: EstateActionTypes.SET_PANELS,
        payload: panels,
    };
}
const setPanelsUnFiltered = (panels:PanelArrayProps):any => {
    return {
        type: EstateActionTypes.SET_PANELS_UNFILTERED,
        payload: panels,
    };
}
const setUnassignedPanels = (unassignedPanels: PanelArrayProps): any => {
    return {
        type: EstateActionTypes.SET_UNASSIGNED_PANELS,
        payload: unassignedPanels,
    };
};
const setInstallerEmailAlertsStatusForPanels = (installerEmailStatuses: any): any => {
    return {
        type: EstateActionTypes.INSTALLER_EMAIL_ALERTS_FOR_ALL_PANELS,
        payload: installerEmailStatuses,
    };
};
const setUserEmailAlertsForAllPanels = (userEmailAlerts: any): any => {
    return {
        type: EstateActionTypes.USER_EMAIL_ALERTS_FOR_ALL_PANELS,
        payload: userEmailAlerts,
    };
};

export function fetchEstates(id:number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/group/${id}/children`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((estates) => {
                dispatch(setEstates(estates));
            });
    }}
export function fetchPanels(id:number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/m2m-web-admin/rest/device/recursiveDevice/from/${id}`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((panels) => {
                dispatch(setPanels(panels));
                dispatch(setPanelsUnFiltered(panels));

            });
    }}
export function fetchUnAssignedPanels(id:number) {
    return async function (dispatch: Dispatch<DispatchTypesProps>): Promise<any> {
        await fetch(
            `${REACT_APP_BASE_URL}/v1/panelsMiddleware/${id}?status=`,
            {
                method: 'GET',
                credentials: 'include',
            }
        )
            .then((res) => res.json())
            .then((unassignedPanels) => {
                dispatch(setUnassignedPanels(unassignedPanels))
                // if (unassignedPanels.length !== 0 || unassignedPanels !== undefined) {
                //     unassignedPanels.map(async (panel: PanelProps) => {
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //                 headers: {
                //                     'Content-Type': 'application/json',
                //                 },
                //             }
                //         ).then((res) => {
                //             if (res.status !== 204) {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: true,
                //                     })
                //                 );
                //             } else {
                //                 dispatch(
                //                     setInstallerEmailAlertsStatusForPanels({
                //                         ID: panel.ID,
                //                         name: panel.cloudReferenceName,
                //                         installerEmailAlertsStatus: false,
                //                     })
                //                 );
                //             }
                //         });
                //         await fetch(
                //             `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panel.panelId}`,
                //             {
                //                 method: 'GET',
                //                 credentials: 'include',
                //             }
                //         )
                //             .then((res) => res.json())
                //             .then((userEmail) => dispatch(setUserEmailAlertsForAllPanels(userEmail)));
                //     });
                // }
            });
    }}

    export function fetchEstatePanelsAlertsAndInstallerEmailOption(unassignedPanels:any) {
        return function (dispatch: Dispatch<DispatchTypesProps>): any {
            if (unassignedPanels.length !== 0 || unassignedPanels !== undefined) {
                        unassignedPanels.map(async (panel: PanelProps) => {
                            await fetch(
                                `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/alertevents/${panel.panelId}`,
                                {
                                    method: 'GET',
                                    credentials: 'include',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                }
                            ).then((res) => {
                                if (res.status !== 204) {
                                    dispatch(
                                        setInstallerEmailAlertsStatusForPanels({
                                            ID: panel.ID,
                                            name: panel.cloudReferenceName,
                                            installerEmailAlertsStatus: true,
                                        })
                                    );
                                } else {
                                    dispatch(
                                        setInstallerEmailAlertsStatusForPanels({
                                            ID: panel.ID,
                                            name: panel.cloudReferenceName,
                                            installerEmailAlertsStatus: false,
                                        })
                                    );
                                }
                            });
                            await fetch(
                                `${REACT_APP_BASE_URL}/m2m-eaton-web/rest/config/siaemailoption/${panel.panelId}`,
                                {
                                    method: 'GET',
                                    credentials: 'include',
                                }
                            )
                                .then((res) => res.json())
                                .then((userEmail) => dispatch(setUserEmailAlertsForAllPanels(userEmail)));
                        });
                    }
        };
    }